<template>
    <base-modal mode="wide" classes="terminal-modal">
       

            <update-card @card-updated="openUpdateCard" :close-spinner="closeSpinner" :hide-header="hideHeader"
                         @make-payment="updateCardAndMakePayment"
                         :showMakePayment="showMakePayment"></update-card>

     
    </base-modal>
</template>

<script>
import {createNamespacedHelpers} from 'vuex';
import UpdateCard from "../forms/UpdateCard";

const payments = createNamespacedHelpers('payments');
export default {
    name: "PaymentCardModal",
    components: {UpdateCard},
    props: {
        hideHeader:  Boolean,
        prompt:  Boolean,
        showMakePayment:  Boolean,
        callback: {
            type: Function,
            default: () => {}
        },
        handlePayment: {
            type: Function,
            default: () => {}
        },
        edit: Boolean,
        closeSpinner: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        ...payments.mapActions(['loadPaymentMethod']),
        openUpdateCard() {
            setTimeout(() => {
                let context = this;
                context.loadPaymentMethod().then(() => {
                    if(context.callback) {
                        context.callback();
                    }
                    context.$modal.hide(this.$parent.name);
                });
            }, 50)
        },
        updateCardAndMakePayment: function (changed) {
            // console.log("running updateCardAndMakePayment");
            let context = this;
            setTimeout(() => {
                context.loadPaymentMethod().then(() => {
                    if(context.handlePayment) {
                        // console.log("handling payment");
                        context.handlePayment(changed);
                    }
                    context.$modal.hide(this.$parent.name);
                });

            }, 50)
        }
    }
}
</script>

<style lang="scss">
    #cc-info fieldset:first-child {
        padding-bottom: 15px;
    }
</style>
