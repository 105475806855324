<template>
    <div class="ecocal-filter">
        <form @submit.prevent style="font-size: 14px;">
            <div class="filter-title">
                <h2>Filter Events</h2> 
            </div>
            <div class="filter-block">
                <div class="filter-block--row">
                    <div class="filter-block--col">
                        <span class="filter-subtitle">Impact</span>
                        <div id="aggregate-impact-checkboxes" style="margin-top:10px;">
                            <label for="impacts" style="display: inline-block;">
                            <input type="checkbox" id="impacts" style="height: 14px;" :checked="allImpactsTicked" @change="allImpactsTickChange">
                            All impact levels
                            </label>
                        </div>
                        <div v-for="item in impactSchema.values"
                        :key="item.value"
                        class="u-compact-checkbox">
                            <input 
                            type="checkbox" v-model="impact" :value="+item.value"
                            :id="item.value + layout_id">
                            <label :for="item.value + layout_id">{{ item.name }}</label>
                        </div>
                    </div>
                        <div class="filter-block--col">
                        <span class="filter-subtitle">Countries</span>
                        <div id="aggregate-country-checkboxes" style="margin-top:10px;">
                                <label for="ALL" style="display: inline-block;">
                                <input type="checkbox" id="ALL" style="height: 14px;" :checked="allCountriesTicked" @change="allCountriesTickChange">
                                All countries
                                </label>
                                <label for="MAJ" style="display: inline-block;" class="MAJ-label">
                                    <input type="checkbox" id="MAJ" style="height: 14px;" :checked="majorCountriesTicked" @change="majorCountriesTickChange">
                                    Major Countries
                                </label>
                            </div>
                        <div class="option-cols">
                            <div style="margin: 5px 0;">Major countries</div>
                            <div></div>
                            <div v-for="item in countryTopLeft"
                            :key="item.value"
                            class="u-compact-checkbox"
                            >
                                <input 
                                type="checkbox" v-model="countries" :value="item.value"
                                :id="item.value + layout_id">
                                <label :for="item.value + layout_id">{{ shortenName(item.name) }}</label>
                            </div>
                            <div></div>
                            <div style="margin: 5px 0;">Rest of the world</div>
                            <div></div>
                            <div v-for="item in countryTopRight"
                            :key="item.value"
                            class="u-compact-checkbox"
                            >
                                <input 
                                type="checkbox" v-model="countries" :value="item.value"
                                :id="item.value + layout_id">
                                <label :for="item.value + layout_id">{{ shortenName(item.name) }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-control-fs" style="margin-bottom: 0;">
                        <p class="error"><span v-if="errorMessage">{{ errorMessage }}</span></p>
                        <base-button
                        mode="o-blue"
                        :link="false"
                        @click.native="applyFilters"
                        >Apply
                        </base-button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
// import { diffArray } from '../../../../utility/helper-functions';
import _ from 'lodash';
import { eventBus } from '../../../../main';

const dashboards = createNamespacedHelpers('dashboard');
const ecocalendar = createNamespacedHelpers('calendar');


export default {
    props: ["panel"],
    // components: {
    //     VueFormGenerator
    // },
    created() {
        let userSettings = null;
        const widget = this.$store.getters["widget/getWidgetByImplementationId"](this.implementation_id);

        if(this.layout_id) {
            userSettings = this.activeDashboardInfo.elements[this.layout_id].userSettings;
        }

        for(const item in widget.settings) {
            if(widget.settings[item].formJSON &&
            // widget.settings[item].area.toLowerCase() === this.activeTab
            widget.settings[item].area.toLowerCase() === 'options' ||
            widget.settings[item].area.toLowerCase() === 'appearance'
            ) {
                if(userSettings && (userSettings[item] || userSettings[item] === false)) {

                    this.model = {
                        ...this.model,
                        [item]: userSettings[item]
                    };
                    
                }else{
                    this.model = { ...this.model, ...widget.settings[item].formJSON.model};
                }

                let field = widget.settings[item].formJSON.schema;
                if(field.type === "switch") {
                    field.type = "checkbox";
                }
                this.schema.fields.push(field);
                this.formOptions = widget.settings[item].formJSON.formOptions;
            }
        }

        const impact = this.schema.fields.find(el => el.model === "impact");
        if(impact) {
            this.impact = JSON.parse(JSON.stringify(this.model.impact));
        }
        const countries = this.schema.fields.find(el => el.model === "countries");
        if(countries) {
            this.countries = JSON.parse(JSON.stringify(this.model.countries));
        }
            

        // console.log(this.countryLeft,this.countryRight);

    },
    computed: {
        ...dashboards.mapGetters(['activeDashboardInfoByInstanceID']),
        ...ecocalendar.mapState(['majorCountries','minorCountries','allImpacts']),

        layout_id() {
            return this.panel.layout_id;
        },
        implementation_id() {
            return this.panel.id;
        },
        activeDashboardInfo() {
            return this.activeDashboardInfoByInstanceID(this.$root.instanceID, "dashboard");
        },
        impactSchema() {
            return this.schema.fields.find(el => el.model === "impact");
        },
        countrySchema() {
            // console.log(this.schema.fields)
            return this.schema.fields.find(el => el.model === "countries");
        },
        countryTopLeft() {
            return this.countrySchema.values.slice(0,11);
        },
        countryTopRight() {
            return this.countrySchema.values.slice(11);
        },
        errorMessage() {
            let type;
            if(this.impactError)
                type = "impact level";
            if(this.countryError)
                type = "country";
            
            if(type)
                return "At least one " + type + ' option must be selected.'
            else
                return '';

        }
    },
    data() {
        return {
            impact: [],
            countries: [],
            model: {
            },
            schema: {
                fields: []
            },
            formOptions: {},
            blocker: null,
            error: "At least one option must be selected.",
            impactError: false,
            countryError: false,
            allCountriesTicked: false,
            majorCountriesTicked: false,
            allImpactsTicked: false
        }
    },
    methods: {
        ...dashboards.mapActions(["updateDashboardById"]),
        shortenName(name) {
            if(name.length > 20)
                return name.substr(0,20) + '...';
            
            return name;
        },
        applyFilters() {
            if(this.countryError || this.impactError)
                return;

            const active = _.cloneDeep(this.activeDashboardInfo);

            active.elements[this.layout_id].userSettings.impact = this.impact;
            active.elements[this.layout_id].userSettings.countries = this.countries;

            this.updateDashboardById(active);
            eventBus.$emit("apply-filters", {
                layout_id: this.layout_id, 
                impact: this.impact, 
                countries: this.countries
            });
        },
        allCountriesTickChange() {
            if(!this.allCountriesTicked) {
                this.countries = [...this.majorCountries, ...this.minorCountries];
            }
            else {
                this.countries = [];
            }
        },
        majorCountriesTickChange() {
            if(!this.majorCountriesTicked) {
                this.countries = [...this.majorCountries];
            }else{
                this.countries = this.countries.filter(el => !this.majorCountries.includes(el));
            }
        },
        allImpactsTickChange() {
            if(!this.allImpactsTicked) {
                this.impact = [...this.allImpacts];
            }else{
                this.impact = [];
            }
        },
    },
    watch: {
        impact(nVal) {
            
            if(!nVal.length) {
                this.impactError = true;
            }else{
                this.impactError = false;
            }

            if(this.impact.length === this.allImpacts.length) {
                this.allImpactsTicked = true;
            }
            else {
                this.allImpactsTicked = false;
            }
                

        },
        countries(nVal) {

            const allCountries = [...this.majorCountries, ...this.minorCountries];
            
            if(this.countries.length === allCountries.length) {
                this.allCountriesTicked = true;
                this.majorCountriesTicked = true;
            }
            else {
                this.allCountriesTicked = false;
            }
            if(this.majorCountries.every(el => this.countries.includes(el)) && this.majorCountries.length === this.countries.length)
                this.majorCountriesTicked = true;
            else
                this.majorCountriesTicked = false;


            if(nVal.length === 0) {
                this.countryError = true;
            }else{
                this.countryError = false;
            }

        }
    }
}
</script>


<style lang="scss" scoped>
    .ecocal-filter {
        // background-color: $onboard-survey-background;
        // border: 1px solid $separator-line-color;
        padding-bottom: 25px;
    }

    .filter-title {
        margin: 0 25px 12.5px;

        h2 {
            @include H2;
        }
        
    }

    .filter-block {
        padding: 12.5px 25px 0;
        max-height: 80vh;
        overflow-y: auto;

        @include scrollbarDark;
        @include respond(md) {
            max-height: 70vh;
        } 
    }

    .filter-block--row {
        display: grid;
        grid-template-columns: 1fr;
        max-height: 56vh;
        overflow-y: scroll;
        @include scrollbarDark;

        @include respond(md) {
            grid-template-columns: 0.5fr 1fr;
        }
    }

    .option-cols {
        display: grid;
        grid-template-columns: 1fr;

        @include respond(sm) {
            grid-template-columns: 1fr 1fr;
        }
    }

    .filter-subtitle {
        display: inline-block;
        color: $color-20;
        font-size: 13px;
        padding: 5px 0;
    }


    .error {
        height: 12px;

        & span {
            color: $color-12;
            font-size: 12px;
        }
    }
    .MAJ-label {
        @include respond(sm) {
            margin-left: 15px;
        }
    }
</style>