<template>
    <div class="account-card">
        <div>
            <h2>Profile</h2>
            <form @submit.prevent>
                <div class="profile-main">
                    <div class="profile-left">
                        <div>
                            <base-input
                            elementType="input"
                            :class="{invalid: error.name}"
                            inputType="text"
                            v-model="profile.name"
                            forID="name"
                            inputClass=""
                            formControlClass=""
                            label="Name"
                            @clear-validity="clearValidity"
                            ></base-input>
                            <!-- <div>
                                <label>Name</label>
                            </div>
                            <div>
                                <form-group
                                        id="name"
                                        :invalid-feedback="errorKeys['name']"
                                        :state="validateField(errorKeys['name'])"
                                >
                                    <form-input placeholder="Name" v-model="profile.name"
                                                  :state="validateField(errorKeys['name'])" trim></form-input>
                                </form-group>
                            </div> -->

                        </div>
                        <div>
                            <base-input
                            elementType="input"
                            :class="{invalid: error.email}"
                            inputType="text"
                            v-model="profile.email"
                            forID="email"
                            inputClass=""
                            formControlClass=""
                            label="Email"
                            @clear-validity="clearValidity"
                            ></base-input>
                            <!-- <div>
                                <label>Email*</label>
                            </div>
                            <div>
                                <form-group
                                        id="email"
                                        :invalid-feedback="errorKeys['email']"
                                        :state="validateField(errorKeys['email'])"
                                >
                                    <input placeholder="Email" v-model="profile.email"
                                                  :state="validateField(errorKeys['email'])" trim>
                                </form-group>
                            </div> -->
                        </div>
                        <div>
                            <base-input
                            elementType="select"
                            :class="{invalid: error.country_code}"
                            v-model="profile.country_code"
                            forID="country"
                            inputClass=""
                            formControlClass=""
                            label="Country"
                            @clear-validity="clearValidity"
                            >
                                <option value="" disabled selected hidden class="select-prompt">Country *</option>
                                    <optgroup label="Main">
                                    <option
                                            v-for="country in mainCountries"
                                            :key="country.alpha3Code"
                                            :value="country.alpha3Code"
                                            :selected="profile.country_code === country.alpha3Code"
                                    >{{ country.name }}
                                    </option>
                                </optgroup>

                                <optgroup label="Others">
                                    <option
                                            v-for="country in otherCountries"
                                            :key="country.alpha3Code"
                                            :selected="profile.country_code === country.alpha3Code"
                                            :value="country.alpha3Code"
                                    >{{ country.name }}
                                    </option>
                                </optgroup>
                            </base-input>
                            <!-- <div>
                                <label>Country*</label>
                            </div>
                            <div>
                                <form-select :options="countryOptions" v-model="profile.country_code"/>
                            </div> -->
                        </div>
               
                        <div class="form-control-fs">
                            <div>
                                <label>Mobile Phone</label>
                            </div>
                            <div>
                                <div>
                                    <!-- <vue-phone-number-input 
                                    v-model="phoneCountry"
                                    :dark="true"
                                    :darkColor="'#22294E'"
                                    :no-example="true"
                                    :no-validator-state="true"
                                    :default-country-code="details.country" 
                                    @update="updatePhoneCountrySelect"
                                    :class="{'invalid-input': cardDetails.phone.error}"
                                    ></vue-phone-number-input> -->
                                    <vue-phone-number-input no-example dark size="sm" @update="this.updateSelectedNumber"
                                                            :default-phone-number="selectedNumber.number"
                                                            :default-country-code="selectedNumber.countryCode"
                                                       v-model="selectedNumber.number"   >
                                    </vue-phone-number-input>
<!--                                    <form-input placeholder="Email" v-model="profile.email"-->
<!--                                                  :state="validateField(errorKeys['email'])" trim></form-input>-->
                                </div>

                            </div>
                        </div>

                        <div class="form-control-fs" style="margin-top:30px">
                            <div>
                                <label>Password</label>
                            </div>
                            <div>
                                <base-button
                                mode="o-dark-grey"
                                :link="false"
                                @click.native="openChangePasswordModal"
                                 >Change Password
                                </base-button>
                            </div>
                        </div>
                    </div>
                    <div class="profile-right">
                        <!-- <vue-dropzone
                                :useCustomSlot=true
                                ref="picUpload"
                                id="dropzone-profile"
                                :options="dropzoneOptions"
                                @vdropzone-complete="updateUploadedFile"
                                @vdropzone-file-added="fileAdded"
                                @vdropzone-removed-file="removeAllFiles"
                        >
                            <div style="vertical-align: center" class="dropzone-custom-title">
                                <font-awesome-icon size="lg" :icon="['fa', 'image']" style="margin-top: 25%"/>
                            </div>
                        </vue-dropzone> -->
                    </div>
                </div>

            </form>
            <div class="account-error">
                <div v-for="error in errorMessages" :key="error">
                    <span>{{ error }}<br/></span>
                </div>
            </div>
            <base-button
            :disabled="uploading"
            mode="o-blue"
            :link="false"
            @click.native="saveSettings"
            >Save changes
            </base-button>
        </div>
    </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
// import vue2Dropzone from 'vue2-dropzone';
// import 'vue2-dropzone/dist/vue2Dropzone.min.css';

const general = createNamespacedHelpers('general');
import _ from "lodash";
import AccountService from "../../../services/account/account.service";
import AppService from "../../../services/app.service";
import Swal from "sweetalert2";
import { eventBus } from '../../../main';
import countries from '../../../assets/countries.json';

const CONDITIONS = [
    {test: (model) => model.name?.length > 2 && model.name?.length < 31, message: 'Name must be 3 - 30 characters long', key: "name"},
    {test: (model) => model.email?.length > 0, message: 'Please fill email field.', key: "email"},
    {test: (model) => model.country_code?.length > 0, message: 'Must select a country.', key: "country_code"},

];

export default {
    name: "Profile",
    // components: {vueDropzone: vue2Dropzone},
    data() {
        return {
            dropzoneOptions: {
                url: 'https://httpbin.org/post',
                addRemoveLinks: true,
                maxFilesize: 5,
                maxFiles: 1
            },
            uploadedFile: null,
            removeUserFile: false,
            uploading: false,
            error: {
                name: false,
                email: false,
                country_code: false,
                phone_number: false,
            },
            errorMessages: [],
            countryDetailsById: {},
            countryOptions: [],
            countryDialCodes: [],
            selectedNumber: {
                countryCode: 'AL',
                number: '7777'
            },
            profile: {
                name: '',
                email: '',
                address1: '',
                address2: '',
                city: '',
                region: '',
                postal_code: '',
                country_code: '',
                sms_notification_country: '',
                sms_notification_number: '',
                email_opt_in: '',
                phone_number: '',
                avatar: ''
            }
        }
    },
    computed: {
        ...general.mapState(["user", "countries"]),
        selectedCountry: function () {
            return this.profile?.country_code;
        },
        hasErrors: function () {
            return this.errorMessages?.length > 0;
        },
        mainCountries: function () {
            return this.countryOptions.filter(country => country.group === "main")
        },
        otherCountries: function () {
            return this.countryOptions.filter(country => country.group !== "main")
        },
        smsNotificationCountry () {
            if(!this.profile || !this.profile?.sms_notification_country)
                return "";

            if(this.profile.sms_notification_country.indexOf("+") === 0)
                return this.profile.sms_notification_country.substring(1);

            if(this.profile.sms_notification_country.indexOf("00") === 0)
                return this.profile.sms_notification_country.substring(2);
            
            return this.profile.sms_notification_country;
        }
    },
    watch: {
        selectedCountry: function () {
            this.updateSelectedCountry();
        },
        user: {
            deep: true,
            handler: function (nVal, oVal) {
                if(!_.isEqual(nVal, oVal)) {
                    this.loadProfileData();
                }
                    
            }
        }
    },
    methods: {
        ...general.mapActions(['loadCurrentUserSettings']),
        validate: function () {
            this.errorMessages = [];

            CONDITIONS.forEach((condition) => {
                if (!condition.test(this.profile)) {
                    this.errorMessages.push(condition.message);
                    this.error[condition.key] = true;
                }
            })
            if(this.error.phone_number)
                this.errorMessages.push("Invalid phone number");

            return Object.keys(this.errorMessages).length === 0;
        },
        clearValidity(input) {
            this.error[input] = false;
        },
        openChangePasswordModal: function () {
            const instanceID = this.$root.instanceID;
            const modal = {
                instanceID,
                componentInModal: "change-password",
                classes: "terminal-modal simplified auto-height",
                footer: false,
            };

            eventBus.$emit('open-modal', modal);
        },
        initializeProfilePicture: function (data) {
            if (data.avatar) {
                let imageParts = data.avatar.split(".");
                let imageType = "image/" + imageParts[imageParts.length - 1];
                let file = {size: 200, name: "Icon", type: imageType};
                let url = data.avatar;
                this.$refs.picUpload.manuallyAddFile(file, url);
            }
        },
        updateSelectedCountry: function () {
            if(this.profile.country_code && !this.user.country_code) {
                this.selectedNumber.countryCode = countries.find(el => el.callingCodes?.[0] === "+" + this.smsNotificationCountry)?.cca2;
            }
        },
        updateSelectedNumber: function (result) {
            // if(data.isValid) {
            //     this.cardDetails.phone.val = data.e164; // TEMP!! //or formattedNumber
            //     this.sms_country = data.countryCallingCode; // TEMP!! //or formattedNumber
            //     this.sms_num = data.phoneNumber; // TEMP!! //or formattedNumber
            // }
            // console.log(result);
            const i = this.errorMessages.findIndex(el => el === "Invalid phone number");
                if(i > -1) {
                    this.errorMessages.splice(i,1);
                    this.error.phone_number = false;
                }
                
            if(result.isValid) {
                // console.log(result);
                this.profile.sms_notification_country = result.countryCallingCode;
                this.profile.sms_notification_number = result.phoneNumber;
                this.profile.phone_number = result.e164;
            }else{
                this.errorMessages.push("Invalid phone number");
                this.error.phone_number = true;
            }
        },
        loadProfileData: function () {
            let user = _.cloneDeep(this.user);
            Object.assign(this.profile, user);
            this.selectedNumber.number = user.sms_notification_number;
            console.log()
            this.selectedNumber.countryCode = countries.find(el => el.callingCodes?.[0] === "+" + this.smsNotificationCountry)?.cca2;
        },
        updateUploadedFile: function (response) {
            if (response.status === "success") {
                this.uploadedFile = response.dataURL;
            } else {
                this.uploadedFile = null;
            }
            this.uploading = false;
        },
        removeAllFiles() {
            const currentFile = this.$refs.picUpload.getAcceptedFiles();
            if (currentFile.length) {
                const toBeDeleted = currentFile[0];
                this.$refs.picUpload.removeFile(toBeDeleted);
            }

            this.removeUserFile = true;
            this.removeUserFile = true;
        },
        fileAdded() {
            this.removeAllFiles();
            this.uploading = true;
        },
        saveSettings() {
            this.errorMessages = [];
            const context = this;
            let isValid = this.validate();
            if (isValid) {
                let userObj = _.cloneDeep(this.user);
                Object.assign(userObj, this.profile);
                if (this.uploadedFile) {
                    userObj.avatar = this.uploadedFile;
                } else {
                    delete userObj.avatar;
                }
                userObj.avatar_delete = this.removeUserFile;
                AccountService.saveUserAccountSettings(userObj).then(result => {
                    if (result.data.success) {
                   
                        Swal.fire({
                            title: 'Success!',
                            type: 'info',
                            text: 'The profile information was successfully updated!',
                            showCancelButton: true,
                            cancelButtonColor: '#1d9deb'
                        });
                     
                        context.loadCurrentUserSettings().then(() => {});

                    }
                }).catch(error => {
                    Object.values(error?.response?.data?.data || {}).forEach(message => this.errorMessages.push(message[0]));
                })
            }
        },
        listCountryCodesAndCurrentCountry: function () {
            AppService.listGeneralCountryCodes().then(result => {
                this.countryOptions = result;
            });
        },
    },
    created() {
        this.loadProfileData();
        this.listCountryCodesAndCurrentCountry();
    },
    mounted: function () {
        this.initializeProfilePicture(this.profile);
    },
}
</script>
<style lang="scss">








#dropzone-profile {
  width: 200px;
  height: 200px;
  border-radius: 200px;
  background-color: $separator-line-color;
  padding: 0;
  margin: auto;

  border: none;

  & .dz-default.dz-message {
    color: $input-color-filter;
    width: 150px;
    margin: auto;
    margin-top: 50px;
  }

  & .dz-image {
    width: 200px;
    height: 200px;

    & img {
      width: 200px;
      height: 200px;
      object-fit: cover;
      border-radius: 200px;
    }
  }

  & .dz-preview.dz-processing.dz-image-preview {
    margin: 0;
    border-radius: 200px;
  }

  &.dropzone .dz-preview.dz-image-preview {
    margin: 0;
    border-radius: 200px;
  }

  &.vue-dropzone > .dz-preview .dz-remove {
    margin-left: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

</style>
