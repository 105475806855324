<template>
    <div>
        <template v-if="upcomingPlans.length > 0">
            <div v-for="plan in upcomingPlans" :key="plan.slug" style="width: 100%;">
                <plan-details :plan="plan" :upcoming="true"></plan-details>
            </div>  
        </template>
        <div v-else>
          <span class="no-item-message">You have no upcoming plans.</span>
        </div>
    </div>
</template>

<script>
import SubscriptionMixin from "../../../mixins/SubscriptionMixin";
// import ShopModal from "../shop/ShopModal";
// import EventBus from "../../../store/bus";
import PlanDetails from "../payments/PlanDetails";

export default {
    name: "UpcomingPlanSection",
    components: {PlanDetails},
    mixins: [SubscriptionMixin],
    props: {},
    data: function () {
        return {
            currentDate: new Date(),
            // isSlideDownOpen: false
        }
    },
    // watch: {
    //     isSlideDownOpen: function (value) {
    //         this.$emit("toggleChangePlan", value);
    //     }
    // },
    methods: {
        // closeSlideDowns() {
        //     this.isSlideDownOpen = false;
        // },
        // openPlans() {
        //     let timeout = 0;
        //     if (this.isSlideDownOpen)
        //         timeout = 250;

        //     setTimeout(() => {
        //         this.isSlideDownOpen = true;
        //     }, timeout);
        //     this.closeSlideDowns();
        // },
        // showSelectPromotions: function () {
        //     this.showModal(ShopModal, {activeTab: "currentWidgets"});
        // }
    },
    // created() {
    //     console.log("upcoming", this.upcomingPlans);
    //     // EventBus.$on("scroll-to-top", () => this.$refs?.['subscription']?.scrollIntoView());
    // }
}
</script>


