<template>
    <div
    >
        <div 
        >
            <div style="display: flex; align-items: center;">
                <span class="account-icon-fs-br"
                :style="{backgroundColor: initalColors.background, color: initalColors.text}">
                    {{ initial }}
                </span>
            </div>
        </div>
        <div>{{ rule.user.name }}</div>
        <div>{{ rule.user.email }}</div>
        <div  class="u-from-lg-flex">
            <span v-if="isApproved">{{ formatDate(rule.user.first_logged_in_at) }}</span>
            <span v-else>{{ formatDate(rule.user.created_at)}}</span>
        </div>

        <div style="overflow: visible;"><a v-if="isRequested && !rule.user.first_logged_in_at" href="javascript:void(0);" class="client-icon-link"  @click="resendInvite">
            <VueCustomTooltip label="Resend invite" position="is-left"><font-awesome-icon :icon="['fas', 'paper-plane']" /></VueCustomTooltip>
        </a></div>
        
        <div style="overflow: visible;"><a v-if="isEm" href="javascript:void(0);" class="client-icon-link" @click="deleteUser">
            <span >
                <VueCustomTooltip label="Delete Admin" position="is-left"><font-awesome-icon :icon="['fas', 'trash-alt']" /></VueCustomTooltip>
            </span>
        </a></div>

        <div style="overflow: visible;"><a href="javascript:void(0);" class="client-icon-link" @click="editClient">
            <VueCustomTooltip label="Edit" position="is-left"><font-awesome-icon :icon="['fas', 'file']" /></VueCustomTooltip>
        </a></div>

    </div>
</template>

<script>
import { usersTime } from '../../../../utility/datetime';
import { createNamespacedHelpers } from 'vuex';
import { frontFacingAssets, talkToServer } from '../../../../utility/helper-functions';
import { eventBus } from '../../../../main';
import Swal from 'sweetalert2';
// import _ from 'lodash';

const general = createNamespacedHelpers("general");
const payments = createNamespacedHelpers("payments");
const rules = createNamespacedHelpers("rules");

export default {
    props: ["rule", "sortStatus"],
    // created() {
    //     console.log(this.rule);
    // },
    computed: {
        ...general.mapGetters(['user']),
        ...payments.mapGetters(['widgetsToSelectFromForHomeScreen']),
        ...rules.mapGetters(['userList']),
        // getRuleSetsForThisUser() {
        //     return Object.values(this.getUserRuleSets(this.client.id))[0]  || {rule: {}, widgets: []};
        // },
        isApproved() {
            return !!this.rule.user.first_logged_in_at;
        }, 
        isRequested() {
            return !this.rule.user.first_logged_in_at;
        },
        isEm() {
            return this.user.entityManager;
        },
        timezone() {
            return this.user.timezone;
        },
        editIconSrc() {
            return frontFacingAssets() + "filedit.svg";
        },
        eyeIconSrc() {
            return frontFacingAssets() + "eye.svg";
        },
        initial() {
            return this.rule.user?.name?.substring(0,1).toLocaleLowerCase();
        },
        initalColors() {
            if(this.initial < "d")
                return {background: "#B33C22", text: "#FFFFFF"}
            else if(this.initial < "g")
                return {background: "#FFFFFF", text: "#1C1C1C"}
            else if(this.initial < "k")
                return {background: "#3B9C83", text: "#FFFFFF"}
            else if(this.initial < "o")
                return {background: "#F178B6", text: "#FFFFFF"}
            else if(this.initial < "s")
                return {background: "#FE8001", text: "#FFFFFF"}
            else
                return {background: "#2D9CDB", text: "#FFFFFF"}
        }
    },
    data() {
        return {
            isProcessing: false,
        }
    },
    methods: {
        ...rules.mapActions(["updateRuleSet", "deleteUserListUser"]),

        formatDate(date) {
            return usersTime(date, true, false, false, {
                        dateFormat: "d m y",
                    }, false, this.timezone);
        },
        editRequest(id) {
            const modal = {
                component: "edit-request",
                title: 'Access Manager',
                icon: 'scroll',
                id: id
            }

            eventBus.$emit('open-side-modal', modal);

        },
        editClient() {

            const modal = {
                component: "edit-client",
                title: 'Profile Manager',
                icon: 'scroll',
                user_id: this.rule.user.id
            }

            eventBus.$emit('open-side-modal', modal);

        },
        deleteUser() {
            if(!this.isEm)
                return;
            Swal.fire({
                title: 'Are you sure?',
                type: 'warning',
                text: 'When you click below, this admin will be deleted',
                showCancelButton: true,
                cancelButtonText: 'Don\'t delete',
                cancelButtonColor: '#1d9deb',
                confirmButtonColor: '#ff5656',
                confirmButtonText: 'Yes, delete'
            }).then(result => {
                if(result.value) {
                    const id = this.rule.user.id;
                    talkToServer(`/b/user/${id}/admin`, "DELETE", null, null, true).then(response => {
                        if(response.success)
                            this.deleteUserListUser(id);
                    });
                }
            });
        },
        resendInvite() {
            Swal.fire({
                title: 'Resend invite?',
                type: 'warning',
                text: 'Click below to resend. They will have 1 week to accept the invite.',
                showCancelButton: true,
                cancelButtonText: 'Don\'t send',
                cancelButtonColor: '#ff5656',
                confirmButtonColor: '#1d9deb',
                confirmButtonText: 'Send invite'
            }).then(result => {
                if(result.value) {
                    const id = this.rule.user.id;
                    talkToServer(`/initial-password/${id}`, "POST", null, null, true).then(response => {
                        if(response.success)
                            Swal.fire({
                                title: 'Success',
                                type: 'info',
                                text: "Invite email was sent.",
                                allowOutsideClick: false,
                                showCancelButton: false,
                                confirmButtonColor: '#3B9C83',
                                confirmButtonText: 'OK'
                            }).then(() => {});
                    });
                }
            });
        }
    },
    
}
</script>