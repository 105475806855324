<template>
    <div v-html="resourceText">
    </div>
</template>

<script>
import { eventBus } from '../../../../main';
import { getWebDomain } from '../../../../utility/helper-functions';
import _ from 'lodash';

export default {
    props: ["resource"],
    created() {
        this.processResource();
    },
    // computed: {
        // resourceType() {
        //     const resource = this.resource.resource;
        //     let type = "";

        //     if(resource) {
        //         if(resource.toLowerCase().indexOf('.zip') > -1 )
        //             type = "zip";
        //         else if(resource.toLowerCase().indexOf('.pdf') > -1 ) 
        //             type = "pdf";
        //         else if(resource.toLowerCase().indexOf('.jpg')  > -1  || 
        //         resource.toLowerCase().indexOf('.jpeg')  > -1 || 
        //         resource.toLowerCase().indexOf('.png')  > -1 || 
        //         resource.toLowerCase().indexOf('.bmp') > -1 ) 
        //             type = "image";

        //     }

        //     return type;
        // },
        // resourceLink() {
        //     return getWebDomain() + '/api/widgets/vidlib/attachment/' + this.resource.resource + '?token=' + localStorage.getItem('token');
        
        // }
    // },
    data() {
        return {
            resourceText: ''
        }
    },
    methods: {
         processResource() {
            if(!this.resource?.text) {
                this.resourceText = "";
                return;
            }
            let resourceText = this.resource.text;

             /* eslint-disable */
            const aTags = resourceText.match(/<a[^>]+href=\"(.*?)\"[^>]*>(.*?)<\/a>/g);
            /* eslint-enable */

            // console.log(aTags);
            if(aTags?.length) {
                aTags?.forEach(el => {
                    if(el.indexOf('figcaption') === -1) {
                        const original = el;
                        const updated = el.replace('">', '" target="_blank">');
                        resourceText = resourceText.replace(original, updated);
                    }
                });
            }
            
            const figures = resourceText.match(/<figure(.*?)figure>+/g);
            if(figures?.length) {
                /* eslint-disable */
                
                figures.forEach((fig, index) => {
                    const marker = '#####_' + index + '_#####';
                    resourceText = resourceText.replace(fig, marker);

                    const json =JSON.parse(fig.match(/data-trix-attachment=\"(.*?)\">/)[1].replaceAll('&quot;','"').match(/^(.*?)}/)[0]);
                    if(json.contentType === 'application/pdf') {
                        console.log(json)
                        resourceText = resourceText.replace(marker, `<a href="javascript:void(0);" data-file="${getWebDomain() + '/attachments/' + json.localFileName}" class="processed-figure-pdf" alt="${json.filename}" />${json.filename}</a>`);
                    }
                    else if(json.contentType.indexOf('image') > -1) {
                        resourceText = resourceText.replace(marker, `<img class="processed-figure-img" src="${getWebDomain() + '/attachments/' + json.localFileName}" data-file="${json.localFileName}" alt="${json.filename}" />`);
                    }else{
                        resourceText = resourceText.replace(marker, `
                            <a class="processed-file" href="${getWebDomain() + '/attachments/' + json.localFileName}" data-file="${json.localFileName}" alt="${json.filename}" />${json.filename}
                            </a>
                        `);
                    }

                });
                /* eslint-enable */
            }
            this.resourceText = resourceText;

            this.$nextTick(() => {
                const images = document.querySelectorAll('.processed-figure-img');
                if(images) {
                    images.forEach(image => {
                        const src = image.getAttribute("src");
                        image.addEventListener("click", () => {
                            this.enlargeIMG(src);
                        });
                    });
                }
                const pdfs = document.querySelectorAll('.processed-figure-pdf');
                if(pdfs) {
                    pdfs.forEach(pdf => {
                        const href = pdf.getAttribute("data-file");
                        pdf.addEventListener("click", () => {
                            this.openPDF(href);
                        });
                    });
                }
            });


        },
        openPDF(url) {
            eventBus.$emit('open-modal', {
                componentInModal: "pdf-viewer",
                classes: "terminal-modal simplified auto-height",
                mode: "wide",
                panel: {
                    url: url
                }
            });
        },
        enlargeIMG(src) {
            // const url = getWebDomain() + '/api/widgets/vidlib/attachment/' + src + '?token=' + localStorage.getItem('token');

            eventBus.$emit('open-modal', {
              
                componentInModal: "image-viewer",
                classes: "terminal-modal simplified auto-height",
                panel: {
                    url: src
                },
                mode: "wide"
            });
        }
    },
    watch: {
        resource: {
            handler(nVal, oVal) {
                if(!_.isEqual(nVal, oVal)) {
                    this.processResource();
                }
            },
            deep: true
        }
    }
}
</script>
                    