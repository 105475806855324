<template>
    <div 
    :style="backgroundIMG"
    class="login-overlay">
        <div class="e-login-form">
            <div>
                <div class="login-header">
                    Forgot Your Password?
                </div>
                <div>
                    <div class="form-text-div align-center subtitle u-input-color-filter" style="margin: 10px 0 25px">Enter the email address you normally use to access the terminal. We'll email you password re-set instructions</div>
                    <form @submit.prevent>

                    <base-input
                    elementType="input"
                    :class="{invalid: error.email}"
                    inputType="email"
                    :required="false"
                    v-model="profile.email"
                    forID="email"
                    :placeholder="'sample@email.com'"
                    inputClass="partner-input"
                    formControlClass=""
                    label="Email address"
                    labelClass="medium-font"
                    :invalid="error.email"
                    :invalidMessage="error.email"
                    @clear-validity="clearValidity"
                    ></base-input>

                    <div class="error">{{ serverError }}</div>

                    <div class="button-bar" style="display: flex; justify-content: space-between; margin-top: 20px;">

                        <base-button
                        mode="o-tranparent-grey o-border-radius-0 o-width-125"
                        :link="false"
                        @click.native="$emit('back-to-home')"
                        >Back
                        </base-button>

                        <base-button
                        mode="o-blue o-width-125 o-border-radius-0"
                        :link="false"
                        @click.native="resetPassword"
                        :disabled="isProcessing"
                        :showSpinner="isProcessing"
                        >Reset
                        </base-button>
                        
                    </div>
                </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { frontFacingAssets } from '../../../utility/helper-functions';
import AppService from '../../../services/app.service';
import Swal from "sweetalert2";

const general = createNamespacedHelpers("general");

const CONDITIONS = [
    {test: (model) => model.email?.length > 5 && model.email?.length < 64 && model.email?.indexOf('@') > 0 && model.email?.indexOf('.') > 2, message: 'Please fill email field.', key: "email"},
    
]

export default {
    computed: {
        backgroundIMG() {
            // return "background-color: red;"
            return "background: black url('" + frontFacingAssets() + "Login BG Image.png') no-repeat center -890px; ";
        },
    },
    data() {
        return {
            profile: {},
            error: {},
            isProcessing: false,
            serverError: '',
        }
    },
    methods: {
        ...general.mapActions(["setComponentAfterRefresh"]),
        clearValidity(name) {
            delete this.error[name];
        },
        validateData() {
            this.error = {};
            this.serverError = "";
            CONDITIONS.forEach((condition) => {
                if (!condition.test(this.profile)) {
                    this.error[condition.key] = condition.message;
                }
            })
        },
        resetPassword() {

            this.isProcessing = true;

            if (this.profile.email.length > 0) {
                AppService.resetPassword({
                    refno: window.bootUp.refno,
                    email: this.profile.email
                }).then(() => {
                    this.isProcessing = false;
                    const html = `Please check your email address and follow the necessary steps to reset your password!`
                        Swal.fire({
                            title: 'Check your emails',
                            html: html,
                            type: 'info',
                            confirmButtonColor: '##1d9deb',
                            confirmButtonText: 'OK'
                            // timer: 1500
                        });
                        setTimeout(() => this.$emit('back-to-home'), 2000);
                }).catch(() => {
                    this.isProcessing = false;
                    this.serverError = 'There was an issue with the email you provided. Please try again later!';
                });
            }
        }
    }
}

</script>
