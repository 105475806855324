<template>
    <div class="account-card">
        <h2>Subscriptions</h2>
        <div style="margin-top: 25px;">
            <div class="account-card-section">
                <div>
                    <label>Current plan</label>
                </div>
                <div>
                    <current-plan-section></current-plan-section>
                </div>
            </div>
            
            <div class="account-card-section">
                <div>
                    <label>Upcoming plan</label>
                </div>
                <div>
                    <upcoming-plan-section></upcoming-plan-section>
                </div>
            </div>
           
            <div class="account-card-section">
                <div>
                    <label>Current indicator subscriptions</label>
                </div>
                <div>
                    <current-widgets-section></current-widgets-section>
                </div>
            </div>
            
            <div class="account-card-section">
                <div>
                    <label>Non-subscription products</label>
                </div>
                <div>
                    <current-promos-section></current-promos-section>
                </div>
            </div>
        
        </div>
    </div>
</template>

<script>
import CurrentPlanSection from "./CurrentPlanSection";
import UpcomingPlanSection from "./UpcomingPlanSection";
import CurrentWidgetsSection from "./CurrentWidgetsSection";
import CurrentPromosSection from "./CurrentPromosSection";
export default {
    name: "SubscriptionDetails",
    components: {CurrentPromosSection, CurrentWidgetsSection, UpcomingPlanSection, CurrentPlanSection}
}
</script>

<style scoped>

</style>
