import {findComponentToBeRenderedByCode} from "../utility/helper-functions";

export default {

    methods: {
        findComponentToBeRendered(id) {
            const thisCode = this.$store.getters["widget/getCodeByImplementationId"](id);
            return findComponentToBeRenderedByCode(thisCode);
        }
    }
}
