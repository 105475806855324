import Constants from "../utility/constants";
import httpClient from "../api/HttpClient";
import {createUrl, talkToServer} from "../utility/helper-functions";

export default {
    setupDB: async function(clearStores) {
        return await window.bootUp.iDB.setupDB(clearStores);
    },
    listGeneralCountryCodes: function () {
        return Promise.resolve(Constants["USER_COUNTRIES"]);
    },
    getVersionId: function () {
        return httpClient.get("/terminal-build-number.txt", {noAuth: true, spinner: false});
    },
    resetPassword: function (data) {
        return httpClient.post(createUrl("/password-reset-request"), data , {noAuth: true, spinner: false});
    },
    uploadImage: function (data) {
        return httpClient.post(createUrl("/general/image-upload"), data , {
            spinner: true,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },
    submitBugReport: function (data) {
        return httpClient.post(createUrl("/support/bug-report"), data , {spinner: true});
    },
    submitSupportTicket: function (data) {
        return httpClient.post(createUrl("/support/ticket"), data , {spinner: true});
    },
    getShopSettings: function () {
        return httpClient.get(createUrl('/setting/user/shopsettings'))
    },
    saveShopSettings: function (payload) {
        return httpClient.post(createUrl('/setting/user/shopsettings'), payload);
    },
    saveWatchHistory: function (payload) {
        return talkToServer('/setting/user/watch-history', "POST", null, payload, true);
    },
    lockUserEditing(id) {
        return httpClient.post(createUrl(`/b/user/${id}/lock`), null);
    },
    unLockUserEditing(id) {
        return httpClient.post(createUrl(`/b/user/${id}/unlock`), null);
    }
}
