<template>
    <div class="backdrop" id="sidemodal">
        <div class="sidemodal e-client">
            <div class="sidemodal-header">
                <div class="sidemodal-header-title">
                    <span><img :src="headerIcon" :alt="modal.title" style="padding-top: 3px;"></span>
                    <span style="margin-left: 10px;">{{ modal.title }}</span>
                </div>
                <div class="sidemodal-header-action"><a href="javascript:void(0);" @click="close"><font-awesome-icon :icon="['fas', 'times']"/></a></div>
            </div>
            <div class="sidemodal-body">
                <component
                :is="componentToBeRendered"
                :modal="modal"
                >
                </component>
            </div>
        </div>
    </div>
   
</template>
<script>
import { eventBus } from '../../main';
import { frontFacingAssets } from '../../utility/helper-functions';
import RuleSetup from './broker/components/RuleSetup.vue';
import CreateAccess from './broker/components/CreateAccess.vue';
import EditClient from './broker/components/EditClient.vue';
import EditRequest from './broker/components/EditRequest.vue';

export default {
    props: ["modal"],
    components: {
        CreateAccess,
        EditClient,
        EditRequest,
        RuleSetup
    },
    // created() {
    //     console.log(this.modal);
    // },
    computed: {
        headerIcon() {
            let src = frontFacingAssets() + this.modal.icon + '.svg';
            return src;
        },
        componentToBeRendered() {
            return this.modal.component;
        }
    },
    methods: {
        close() {
            eventBus.$emit("close-modal");
        }
    }
}
</script>

<style lang="scss">
#sidemodal.backdrop {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: rgba(black, 0.5);
    top: 0;
    right: 0;
    display: none;

    @include respond(lg) {
        display: block;
    }

    & .sidemodal {
        display: block;
        position: absolute;
        background-color: $onboard-survey-background;
        top: 0;
        right: 0;
        min-height: 100vh;
        width: 90vw;

        @include respond(lg) {
            width: 50%;
            min-width: 760px;
        }

        & .sidemodal-header {
            padding: 3px 0;
            border-bottom: 1.5px solid $partner-border-3;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $partner-dimmed-text-2;

            & .sidemodal-header-title,
            & .sidemodal-header-action {
                padding: 10px 15px;
            }

            & .sidemodal-header-title {
                display: flex;
                align-items: center;
                letter-spacing: 0.1px;
            }

            & .sidemodal-header-action {
                border-left: 1.5px solid $partner-border-3;
                flex-basis: 50px;
                text-align: center;

                & a {
                    color: $partner-dimmed-text-2;
                }
            }
        }

        & .sidemodal-body {
            padding: 0 35px;
        }
    }

}

</style>