<template>
    <div class="account-card broker-request">
        <div class="broker-request-header">
            <img src="../../../../assets/fillform.svg" alt="Fill in the form">
            <h2>Submit your details</h2>
            <p>We'll contact you in the next 24 hours<br /> to assist with your request.</p>
        </div>
        <form @submit.prevent>
            <div>
                <base-input
                elementType="input"
                :class="{invalid: error.name}"
                inputType="text"
                v-model="profile.name"
                placeholder="Full Name"
                forID="name"
                inputClass=""
                formControlClass=""
                label="Name"
                @clear-validity="clearValidity"
                ></base-input>
            </div>
            <div>
                <base-input
                elementType="input"
                :class="{invalid: error.email}"
                inputType="text"
                v-model="profile.email"
                placeholder="sample@email.com"
                forID="email"
                inputClass=""
                formControlClass=""
                label="Email"
                @clear-validity="clearValidity"
                ></base-input>
            </div>
            <div>
                <base-input
                elementType="input"
                :class="{invalid: error.phone}"
                inputType="text"
                v-model="profile.phone"
                placeholder="+123"
                forID="phone"
                inputClass=""
                formControlClass=""
                label="Phone"
                @clear-validity="clearValidity"
                ></base-input>
            </div>
            <div>
                <base-input
                elementType="input"
                :class="{invalid: error.account}"
                inputType="text"
                v-model="profile.account"
                placeholder="00000000"
                forID="account"
                inputClass=""
                formControlClass=""
                label="Account"
                @clear-validity="clearValidity"
                ></base-input>
            </div>
            <div class="account-error">
                <div v-for="error in errorMessages" :key="error">
                    <span>{{ error }}<br/></span>
                </div>
            </div>
            <div class="col-2-lg-fs flex-center-lg" style="margin-top: 55px;">
                <base-button
                :disabled="submitting"
                mode="o-gray fullwidth"
                :link="false"
                @click.native="cancel"
                >Back
                </base-button>
                <base-button
                :disabled="submitting"
                mode="o-blue fullwidth"
                :link="false"
                @click.native="submit"
                >Submit
                </base-button>
            </div>
        </form>
    </div>
</template>

<script>
import { eventBus } from '../../../main';
import Swal from "sweetalert2";

const CONDITIONS = [
    {test: (model) => model.name?.length > 2 && model.name?.length < 31, message: 'Name must be 3 - 30 characters long', key: "name"},
    {test: (model) => model.email?.length > 0 && model.email?.indexOf('@') > 0 && model.email.indexOf('.') > 2, message: 'Please fill email field.', key: "email"},
    {test: (model) => model.phone.match(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/), message: 'Must provide a valid phone number', key: "phone"},
    {test: (model) => model.account?.length > 4 && model.account?.match(/^[0-9]*$/), message: 'Must provide a broker account number', key: "account"},

];

export default {
    data() {
        return {
            profile: {
                name: '',
                email: '',
                phone: '',
                account: '',
            },
            error: {},
            errorMessages: [],
            submitting: false
        }
    },
    methods: {
        clearValidity(input) {
            this.error[input] = false;
        },
        submit() {
            let isValid = this.validate();
            if (isValid) {
                const html = `We've received your request. We'll contact you within 24 hours to assist.`
            Swal.fire({
                title: 'Success',
                html: html,
                type: 'info',
                confirmButtonColor: '#0062FF',
                confirmButtonText: 'Close'
                // timer: 1500
            }).then(() => {
                    eventBus.$emit('close-modal');
            });
            }
        },
        validate () {
            this.errorMessages = [];

            CONDITIONS.forEach((condition) => {
                if (!condition.test(this.profile)) {
                    this.errorMessages.push(condition.message);
                    this.error[condition.key] = true;
                }
            });

            return Object.keys(this.errorMessages).length === 0;
        },
    }
}
</script>

<style lang="scss" scoped>
    .broker-request {
        @media only screen and (min-width: 992px) {
            padding: 10px 150px 60px;
        }
    }
    .broker-request-header {
        text-align: center;
        margin-bottom: 55px;
    }
</style>