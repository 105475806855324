<template>
    <div class="research-screen">
        <!-- VERSION 1 -->

        <div class="research-screen-filter">
            <div class="research-screen-filter-header">
                <div>
                    <font-awesome-icon :icon="['fas', 'filter']"/> Filters 
                    <a href="javascript:void(0);"
                    @click="isFilterOpen = !isFilterOpen"
                    >
                        <font-awesome-icon v-if="!isFilterOpen" :icon="['fa', 'chevron-down']"/>
                        <font-awesome-icon v-else :icon="['fa', 'chevron-up']"/>
                    </a>
                </div>
            </div>
            <vue-slide-up-down :active="isFilterOpen" :duration="250" class="vue-slide-up-down">
                <div class="research-screen-filter-body" v-if="isFilterOpen">
                    <form @submit.prevent style="font-size: 14px; margin-top: 12px;">
                        <div class="md-grid-2">
                            <div class="md-grid-2">
                                <div>
                                    <div>Countries</div>
                                    <div class="md-grid-2" style="margin: 15px 0;">

                                        <div class="aggregate-country-checkbox">
                                            <label for="ALL" style="display: inline-block;">
                                            <input type="checkbox" id="ALL" style="height: 14px;" :checked="allCountriesTicked" @change="countriesTickChange('all')">
                                            All countries
                                            </label>
                                        </div>

                                        <div class="aggregate-country-checkbox">
                                            <label for="G7" style="display: inline-block;">
                                            <input type="checkbox" id="G7" style="height: 14px;" :checked="g7CountriesTicked" @change="countriesTickChange('g7')">
                                            G7 countries
                                            </label>
                                        </div>

                                        <div class="aggregate-country-checkbox">
                                            <label for="major" style="display: inline-block;">
                                            <input type="checkbox" id="major" style="height: 14px;" :checked="majorCountriesTicked" @change="countriesTickChange('major')">
                                            Major countries
                                            </label>
                                        </div>

                                    </div>
                                    <div class="md-grid-2" style="margin-bottom: 12px;">
                                        <div>
                                            <div v-for="item in countryLeft"
                                            :key="item.code"
                                            class="u-compact-checkbox filter-checkbox"
                                            >   
                                                <input 
                                                type="checkbox" v-model="countries" :value="item.code"
                                                :id="item.code">
                                                <label :for="item.code">{{ item.name }}</label>
                                            </div>
                                        </div>
                                        
                                        <div>
                                            <div v-for="item in countryRight"
                                            :key="item.code"
                                            class="u-compact-checkbox filter-checkbox"
                                            >   
                                                <input 
                                                type="checkbox" v-model="countries" :value="item.code"
                                                :id="item.code">
                                                <label :for="item.code">{{ item.name }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="md-grid-2">
                                <div>
                                    <div>Indicators</div>
                                    <div class="md-grid-2">

                                        <div v-for="col in originalGroupCols.slice(1)"
                                        :key="col.group_id"
                                        class="u-compact-checkbox filter-checkbox"
                                        style="margin: 15px 0;"
                                        >
                                            <div class="aggregate-indicator-checkbox">
                                                <label :for="col.group_id" style="display: inline-block;">
                                                <input type="checkbox" :id="col.group_id" style="height: 14px;" :checked="groupColModel[col.group_id]" @change="groupColTickChange(col.group_id)">
                                                {{ col.name }}
                                                </label>
                                            </div>
                                            <div v-for="data in col.data"
                                            :key="data.data_id"
                                            >
                                                <input 
                                                type="checkbox" v-model="groupedDataIDs" :value="data.data_id"
                                                :id="data.data_id">
                                                <label :for="data.data_id">{{ data.name }}</label>
                                            </div>
                                        </div>
                                        


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <base-button
                            mode="o-blue"
                            :link="false"
                            @click.native="applyFilters"
                            >Apply
                            </base-button>
                        </div>
                    </form>
                </div>
            </vue-slide-up-down>

        </div>


        <!-- <table class="primary-table">
            <thead>
                <table class="secondary-table">
                    <draggable
                    :list="cols"
                    tag="tr"
                    handle=".handle"
                    :move="checkMove"
                    >
                    <th v-for="col in cols"
                    :key="col.code"
                    class="final-cell"
                    >
                        <span>
                            <a href="javascript:void(0);" @click="() => {}">
                                <font-awesome-icon v-if="col.code !== 'empty'" class="handle" :icon="['fas', 'arrows-alt']"/>
                            </a>
                            <span class="country-name"> {{ col.name }}</span>
                        </span>
                    </th>
                    </draggable>
                </table>
            </thead>
            <draggable
            :list="rows"
            tag="tbody"
            handle=".handle"
            >
                <researchtable-mainrow
                v-for="row in rows"
                :key="row.id"
                :row="row"
                :cols="cols"
                :ecodata="ecodata.filter(el => el.group_id === row.group_id)"
                class="main-row"   
                ></researchtable-mainrow>
            </draggable>
        </table> -->
    
        <!-- ENDS VERSION 1 -->
    
        <div class="table-wrapper">
            <table>
                <thead>
                    <draggable
                    :list="groupCols"
                    tag="tr"
                    handle=".handle"
                    :move="checkgroupColMove"
                    >
                        <th v-for="(col, i) in groupCols"
                        :key="col.id"
                        :rowspan="col.rowspan"
                        :colspan="col.data.length === 0 ? 1 : col.data.length"
                        style="max-width: 150px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                        >
                            <font-awesome-icon v-if="i > 0" class="handle" :icon="['fas', 'arrows-alt']"/>
                            {{ col.name }}
                            <a 
                            v-if="i === 0"
                            @click="changeOrder('country')"
                            href="javascript:void(0);"
                            >
                                <sort-up-icon v-if="sortData === 'country'" class="sort-icon" :class="{'highlighted': sortData === 'country' & orderBy === 'desc'}"/>
                                <sort-down-icon class="sort-icon" :class="{'highlighted': sortData === 'country' && orderBy === 'asc'}" />
                            </a>
                            <a 
                            v-if="i === 0"
                            @click="changeOrder('majors-minors')"
                            href="javascript:void(0);"
                            >
                                <redo-icon class="sort-icon" :class="{'highlighted': sortData === 'majors-minors'}" :icon="['fas', 'arrows-alt']"></redo-icon>
                            </a>
                        </th> 
                    </draggable>
                    <draggable
                    :list="groupedData"
                    tag="tr"
                    @end="processGroupCols"
                    handle=".handle"
                    :move="checkgroupMemberMove"
                    >
                        <th v-for="member in groupedData"
                        :key="member.id"
                        >
                        <font-awesome-icon class="handle" :icon="['fas', 'arrows-alt']"/>
                        {{ member.name }}
    
                        <a 
                        @click="changeOrder(member.data_id)"
                        href="javascript:void(0);"
                        >
                            <sort-up-icon v-if="sortData === member.data_id" class="sort-icon" :class="{'highlighted': sortData === member.data_id & orderBy === 'desc'}"/>
                            <sort-down-icon class="sort-icon" :class="{'highlighted': sortData === member.data_id && orderBy === 'asc'}" />
                        </a>
                        </th>
                    </draggable>
                </thead>
                <draggable
                :list="countryRows"
                tag="tbody"
                handle=".handle"
                @end="sortData = null"
                >
                    <tr v-for="country in countryRows"
                    :key="country.code"
                    >
                        <th>
                            <font-awesome-icon class="handle" :icon="['fas', 'arrows-alt']"/>
                            {{ country.name }}</th>
                        <td v-for="(data, ii) in country.data"
                        :key="country.code + ii ">{{ country.data[ii] }}</td>
                    </tr>
                </draggable>
            </table>
        </div>
        <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 15px; margin-top: 20px; padding: 15px; background-color: #1C1C1C">
            <!-- <sample-first-chart></sample-first-chart>
            <sample-first-chart></sample-first-chart> -->
            <!-- <line-chart-wrapper
            :dataPoints="dataPoints"
            :dataIteration="dataIteration"></line-chart-wrapper>
            <bar-chart-wrapper
            :dataPoints="dataPoints"
            :dataIteration="dataIteration"
            ></bar-chart-wrapper> -->
        </div>
    
    </div>
</template>

<script>
import Draggable from 'vuedraggable';
import _ from 'lodash';
import { createNamespacedHelpers } from 'vuex';
import { researchCountries, researchData } from '../../test';
import SortDownIcon from '../assets/SortDownIcon.vue';
import SortUpIcon from '../assets/SortUpIcon.vue';
import RedoIcon from '../assets/RedoIcon.vue';
import { diffArray } from '../utility/helper-functions';
const research = createNamespacedHelpers('research');

export default {
    components: {
        Draggable,
        RedoIcon,
        SortUpIcon,
        SortDownIcon
    },
    created() {
        this.allCountries = researchCountries;
        this.countries = this.allCountries.filter(el => el.major).map(el => el.code);
        this.processGroupCols();
        this.processCountryRows();
        
        this.dataPoints = {
            datasets: [
                {
                    label: "Data 1",
                    borderColor: "#16CEB9",
                    pointBackgroundColor: "#16CEB9",
                    borderWidth: 1,
                    fill: false,
                    pointBorderColor: "#16CEB9",
                    backgroundColor: "#16CEB9",
                    data: [190, 310, 30, 50, 20],
                },
                {
                    label: "Data 2",
                    borderColor: "#81c784",
                    pointBackgroundColor: "#81c784",
                    borderWidth: 1,
                    fill: false,
                    pointBorderColor: "#81c784",
                    backgroundColor: "#81c784",
                    data: [19, 8, 21, 3, 4],
                }
            ],
            labels: [
                "2020-12-01",
                "2020-12-02",
                "2020-12-03",
                "2020-12-04",
                "2020-12-05",
            ]
        }
    

        setTimeout(() => {

            this.dataPoints.labels = [
                "2020-12-31",
                "2020-12-30",
                "2020-12-29",
                "2020-12-28",
                "2020-12-27",
            ];

            this.dataPoints.datasets[0].data = [210, 298, 45, 43, 26];
            this.dataPoints.datasets[1].data = [22, 12, 56, 43, 32];
            // this.dataPoints.datasets = [
            //     {
            //         label: "Container 1",
            //         borderColor: "#16CEB9",
            //         pointBackgroundColor: "black",
            //         borderWidth: 1,
            //         fill: false,
            //         pointBorderColor: "black",
            //         backgroundColor: "#16CEB9",
            //         data: [13, 8, 21, 3, 4]
            //     },
            //     {
            //         label: "Container 2",
            //         borderColor: "#81c784",
            //         pointBackgroundColor: "black",
            //         borderWidth: 1,
            //         fill: false,
            //         pointBorderColor: "black",
            //         backgroundColor: "#81c784",
            //         data: [19, 31, 3, 5, 2],
            //     }
            // ],
            this.dataIteration++;
        },2500);
        // setTimeout(() => {
        //     console.log("new2")
        //     this.dataPoints = [
        //         [8, 8, 2, 1, -6],
        //         [5, 6, 4, -2, 1]
        //     ];
        //     this.dataIteration++;
        // },4500);
    },
    computed: {
        ...research.mapGetters(['groupCols', 'originalGroupCols']),
        countriesThatCount() {
            return this.allCountries.slice(0);
        },
        countryLeft() {
            return this.countriesThatCount.slice(0,12);
        },
        countryRight() {
            return this.countriesThatCount.slice(12);
        },
    },
    data() {
        return {
            groupedData: [],
            countryRows: [],
            sortData: "majors-minors",
            orderBy: "asc",
            isFilterOpen: false,
            countries: [],
            allCountriesTicked: false,
            g7CountriesTicked: false,
            majorCountriesTicked: false,
            groupColModel: {},
            groupedDataIDs: ["inflation-yoy","inflation-mom","gdp-total"],
            groupedColsToDisplay: [],
            dataPoints: [],
            dataIteration: 0
        }
        
    },
    methods: {
        ...research.mapActions(['setGroupCols']),
        checkgroupColMove(a) {
            // console.log(a,b,c)
            if(a.draggedContext.futureIndex === 0) 
                return false;

            // console.log(a)
            // this.setGroupCols();

        },
        // end(a,b,c,d) {
        //     console.log(a,b,c,d);
        //     // let groupCols = [];
        //     // // this.groupCols.forEach(el => {
        //     // //     groupedData.push(...el.members);
        //     // // });
        //     // groupedData = this.groupCols.map(el => el.members).flat();

        //     // // console.log(groupedData);
        //     // this.groupedData = groupedData;
        // },
        checkgroupMemberMove(a) {
            if(a.draggedContext.element.group_id !== a.relatedContext.element.group_id)
                return false;
            
            let list = [...a.relatedContext.list];
            
            list.splice(a.draggedContext.index,1);
            list.splice(a.draggedContext.futureIndex,0,a.draggedContext.element)
          
            console.log(list.map(el => el.data_id));
            this.groupedDataIDs = list.map(el => el.data_id);

            // const groupCols = _.cloneDeep(this.groupCols);

            // groupCols.forEach(element => {
            //     element.data = [];
            //     const found = list.filter (el => el.group_id === element.group_id);
            //     // console.log(found);
            //     if(found)
            //         element.data = found;
            // });

            // // console.log("gr cols",groupCols);

            // this.setGroupCols();
            
            // this.processCountryRows();
        },
        processCountryRows() {
            const sortIndex = this.groupedData.map(el => el.data_id).findIndex(el => el === this.sortData);
            let countryRows = researchCountries.filter(el => this.countries.includes(el.code));
            countryRows.sort((a, b) => this.countryRows.indexOf(a) - this.countryRows.indexOf(b));
            // let countryRows = [...researchCountries].splice(1);
            countryRows.forEach(el => {
                const dataIDs = this.groupedData.map(el => el.data_id);
                const countryData = dataIDs.map(id => {
                    // console.log(id, el);
                    const dataElements = researchData.find(dataElement => dataElement.data_id === id);
                    if(dataElements) {
                        const found = dataElements.values.find(val => {
                            if(Object.prototype.hasOwnProperty.call(val, el.code))
                                return val[el.code]
                        });
                        
                        // console.log(found)
                        if(found)
                            return found[el.code];
                        else
                            return 'N/A';
                        
                    }
                });
                el.data = countryData;
                // console.log(countryRows);
            });

            if(this.sortData === 'majors-minors')
                countryRows = _.orderBy(countryRows, ['major', 'name'], [true, 'asc']);
            else if(this.sortData === 'country') 
                countryRows = _.orderBy(countryRows, el => el.name, this.orderBy);
            else if(this.sortData)
                countryRows = _.orderBy(countryRows, el => el.data[sortIndex], this.orderBy);

            this.countryRows = countryRows;
        },
        changeOrder(id) {
            if(id !== this.sortData) {
                this.orderBy = "asc";
            }else{
                if(this.orderBy === 'asc')
                    this.orderBy = 'desc';
                else
                    this.orderBy = 'asc'
            }
            this.sortData = id;
            this.processCountryRows();
        },
        countriesTickChange(tickbox) {
            this.allCountriesTicked = false;
            this.g7CountriesTicked = false;
            this.majorCountriesTicked = false;
            const oldCountries = [...this.countries];
            this.countries = [];
            this.$nextTick(() => {
                if(tickbox === 'all') {
                    const isAll = this.allCountries.every(el => oldCountries.includes(el.code));
                    if(!isAll)
                        this.countries = this.allCountries.map(el => el.code);
                }
                else if(tickbox === 'g7') {
                    const isG7 = this.allCountries.filter(el => el.g7).every(el => oldCountries.includes(el.code));
                    // console.log(isG7)
                    const g7s = this.allCountries.filter(el => el.g7).map(el => el.code);
                    if(isG7)
                        this.countries = diffArray(oldCountries, g7s);
                    else
                        this.countries = [...this.countries, ...g7s];
                }
                else if(tickbox === 'major') {
                    const isMajor = this.allCountries.filter(el => el.major).every(el => oldCountries.includes(el.code));
                    
                    const majors = this.allCountries.filter(el => el.major).map(el => el.code);
                    if(isMajor)
                        this.countries = diffArray(oldCountries, majors);
                    else
                        this.countries = [...this.countries, ...majors];
                }
            });
            
        },
        groupColTickChange(tickbox) {
            console.log(tickbox);
            const ids = this.originalGroupCols.find(el => el.group_id === tickbox).data.map(el => el.data_id);
            if(!this.groupColModel[tickbox]) {
                ids.forEach(el => {
                    if(!this.groupedDataIDs.includes(el))
                        this.groupedDataIDs.push(el);
                });
            }else{
                ids.forEach(el => {
                    const index = this.groupedDataIDs.indexOf(el);
                    if(index > -1)
                        this.groupedDataIDs.splice(index,1);
                });
            }
                
        },
        applyFilters() {
            this.isFilterOpen = false;
            this.processGroupCols();
            this.processCountryRows();
        },
        processGroupCols() {
            const originalGroupCols = _.cloneDeep(this.originalGroupCols);
            const groupedColsToDisplay = [];
            this.groupedDataIDs.forEach(el => {
                const groupedData = [...originalGroupCols.map(el => el.data).flat()];
                const found = groupedData.find(data => data.data_id === el);

                if(found) {
                    const foundGroup = groupedColsToDisplay.find(elem => elem.group_id === found.group_id);
                    
                    let groupColData;
                    if(!foundGroup) {
                        groupColData = _.cloneDeep(originalGroupCols.find(elem => elem.group_id === found.group_id));
                        groupColData.data = [found];
                        groupedColsToDisplay.push(groupColData);
                    }else{
                        const dataGr = groupedColsToDisplay.find(elem => elem.group_id === found.group_id);
                        dataGr.data.push(found);
                    }
                    

                //    if(groupColData)
                    

                }


            });
            this.setGroupCols(groupedColsToDisplay);
            // const defaultCountryCol = this.originalGroupCols[0]
            // if(groupedColsToDisplay[0]?.id !== defaultCountryCol?.id)
            //     groupedColsToDisplay.unshift(defaultCountryCol);

            // this.groupCols = _.cloneDeep(groupedColsToDisplay);
            // this.groupedData = this.groupCols.map(el => el.data).flat();
            // console.log(...this.groupCols.slice(1).map(el => el.data).flat().map(el => el.data_id));
        }
    },
    watch: {
        groupCols: {
            handler: function(nVal) {
                if(nVal) {
                    // console.log("nVal",nVal.map(el => el.data).flat())
                    this.groupedData = [...nVal.map(el => el.data).flat()];
                    this.processCountryRows();
                }
            },
            deep: true
        },
        // groupedData: {
        //     handler: function(nVal) {
        //         if(nVal) {
        //             this.processCountryRows();
        //         }
        //     },
        //     deep: true
        // },
        countries(nVal) {
            // const ticked = diffArray(nVal,oVal);
            // console.log(nVal,oVal);
            // let rows = this.countryRows;
            // if(ticked && ticked.length === 1) {
            //     const i = rows.findIndex(el => el.code === ticked[0]);
            //     if(i > -1) {
            //         rows.splice(i,1);
            //     }
            //     else {
            //         const found = this.allCountries.find(el => el.code === ticked[0]);
            //         if(found)
            //             rows.push(found)
            //     }
            // }else{
            //     rows = [this.allCountries[0], ...this.allCountries.filter(el => nVal.includes(el.code))];
            // }

            // this.countryRows = rows;
            if(this.allCountries.every(el => nVal.includes(el.code)))
                this.allCountriesTicked = true; 
            else {
                this.allCountriesTicked = false; 
            }
            if(this.allCountries.filter(el => el.g7).every(el => nVal.includes(el.code))) {
                this.g7CountriesTicked = true;
            }
            else {
                this.g7CountriesTicked = false;
            }
            if(this.allCountries.filter(el => el.major).every(el => nVal.includes(el.code))) {
                this.majorCountriesTicked = true;
            }
            else {
                this.majorCountriesTicked = false;
            }
            
        },
        groupedDataIDs(nVal) {
            // console.log(this.groupColModel)
            const obj = {};
            this.originalGroupCols.slice(1).forEach(el => {
                obj[el.group_id] = el.data.map(elem => elem.data_id);
            });
            
            Object.entries(obj).forEach(key => {
                const groupID = key[0];
                const dataIDs = key[1];
                
                if(dataIDs.every(el => nVal.includes(el)))
                    this.groupColModel[groupID] = true;
                else
                    this.groupColModel[groupID] = false;
            });

            
        }
    }
}


//-------------------------------------- VERSION 1
// import Draggable from 'vuedraggable';
// import ResearchtableMainrow from './ui/ResearchComponent/ResearchtableMainrow.vue'
// import { researchCountries, researchData, researchDataGroups } from '../../test';
// import { diffArray } from '../utility/helper-functions';


// export default {
//     components: {
//         Draggable,
//         ResearchtableMainrow,
//     },
//     created() {
        
//         this.allCountries = researchCountries;
//         this.countries = this.allCountries.filter(el => el.major).map(el => el.code);
        
//         this.rows = researchDataGroups;
//         this.ecodata = researchData;
//         // setTimeout(() => {
//         //     aggregate[0].values[0].AUS = "1400B";
//         //     aggregate[2].values[0].AUS = "3.8%";
//         //     this.ecodata = aggregate;
//         // }, 3000)
        
//     },
//     computed: {
//         countriesThatCount() {
//             return this.allCountries.slice(1);
//         },
//         countryTopLeft() {
//             return this.countriesThatCount.slice(0,5);
//         },
//         countryTopRight() {
//             return this.countriesThatCount.slice(5,11);
//         },
//         countryBottomLeft() {
//             return this.countriesThatCount.slice(11,16);
//         },
//         countryBottomRight() {
//             return this.countriesThatCount.slice(16,22);
//         },
//     },
//     data() {
//         return {
//             rows: [],
//             cols: [],
//             ecodata: [],
//             countries: [],
//             isFilterOpen: false,
//             allCountriesTicked: false,
//             g7CountriesTicked: false,
//             majorCountriesTicked: false,
//         }
//     },
//     methods: {
//         clicked(c) {
//             alert(c);
//         },
//         action(a, b, c, d) {
//             console.log(a,b,c,d)
           
//         },
//         checkMove(a) {
//             if(a.draggedContext.futureIndex === 0) 
//                 return false;
//         },
//         countriesTickChange(tickbox) {
//             this.allCountriesTicked = false;
//             this.g7CountriesTicked = false;
//             this.majorCountriesTicked = false;
//             const oldCountries = [...this.countries];
//             this.countries = [];
//             this.$nextTick(() => {
//                 if(tickbox === 'all') {
//                     const isAll = this.allCountries.slice(1).every(el => oldCountries.includes(el.code));
//                     if(!isAll)
//                         this.countries = this.allCountries.slice(1).map(el => el.code);
//                 }
//                 else if(tickbox === 'g7') {
//                     const isG7 = this.allCountries.filter(el => el.g7).every(el => oldCountries.includes(el.code));
//                     // console.log(isG7)
//                     const g7s = this.allCountries.filter(el => el.g7).map(el => el.code);
//                     if(isG7)
//                         this.countries = diffArray(oldCountries, g7s);
//                     else
//                         this.countries = [...this.countries, ...g7s];
//                 }
//                 else if(tickbox === 'major') {
//                     const isMajor = this.allCountries.filter(el => el.major).every(el => oldCountries.includes(el.code));
                    
//                     const majors = this.allCountries.filter(el => el.major).map(el => el.code);
//                     if(isMajor)
//                         this.countries = diffArray(oldCountries, majors);
//                     else
//                         this.countries = [...this.countries, ...majors];
//                 }
//             });
            
//         }
//     },
//     watch: {
//         countries(nVal, oVal) {
//             const ticked = diffArray(nVal,oVal);
//             // console.log(nVal,oVal);
//             let cols = this.cols;
//             if(ticked && ticked.length === 1) {
//                 const i = cols.findIndex(el => el.code === ticked[0]);
//                 if(i > -1) {
//                     cols.splice(i,1);
//                 }
//                 else {
//                     const found = this.allCountries.find(el => el.code === ticked[0]);
//                     if(found)
//                         cols.push(found)
//                 }
//             }else{
//                 cols = [this.allCountries[0], ...this.allCountries.filter(el => nVal.includes(el.code))];
//             }

//             this.cols = cols;
//             if(this.allCountries.slice(1).every(el => nVal.includes(el.code)))
//                 this.allCountriesTicked = true; 
//             else {
//                 this.allCountriesTicked = false; 
//             }
//             if(this.allCountries.filter(el => el.g7).every(el => nVal.includes(el.code))) {
//                 this.g7CountriesTicked = true;
//             }
//             else {
//                 this.g7CountriesTicked = false;
//             }
//             if(this.allCountries.filter(el => el.major).every(el => nVal.includes(el.code))) {
//                 this.majorCountriesTicked = true;
//             }
//             else {
//                 this.majorCountriesTicked = false;
//             }
            
//         }
//     }
// }
//-------------------------------------- END VERSION 1
</script>

// <style lang="scss">
//     .research-screen {
//         // @include standardContainer;

//         &-filter {
//             margin: 15px 0 20px 0;
//             color: $input-color;

//             &-header {
//                 margin-bottom: 10px;

//                 & a {
//                     color: $input-color;
//                 }
//             }
//         } 

//         .sort-icon {
//             & path {
//                 fill: $separator-line-color;
//             }
//             &.highlighted path {
//                 fill: $input-color;
//             }
//         }

//         .aggregate-country-checkbox label,
//         .aggregate-indicator-checkbox label {
//             color: $input-color;
//             letter-spacing: 0.5px;
//             text-transform: uppercase;
//         }

//         .filter-checkbox {
//             width: 185px;
//         }

    
//         .table-wrapper {
//             max-width: 1350px;
//             overflow-x: auto;
//             color: $input-color-filter;

//             width: 100%;
//             max-height: 450px;
//             overflow: auto;
//             border: 1px solid $separator-line-color;

//             @include scrollbarDark;
//         }
//         table {
//             border: none;
//             border-collapse: separate;
//             border-spacing: 0;
//             // table-layout: fixed;
//             // border: 1px solid white;
//             width: max-content;

//         }
//         table td,
//         table th {
//             border: 1px solid $separator-line-color;
//             padding: 5px 5px;
//         }
//         table thead tr:first-child th {
//             padding: 3px;
//             position: sticky;
//             top: 0;
//             z-index: 1;
//             width: 150px;
//             background: $onboard-survey-background;
        
//         }
//         table thead tr:nth-child(2) th {
//             padding: 3px;
//             position: sticky;
//             top: 32px;
//             z-index: 1;
//             width: 150px;
//             background: $onboard-survey-background;
        
//         }
//         table tbody th {
//             position: relative;
//         }
//         table thead tr:first-child th:first-child{
//             position: sticky;
//             left: 0;
//             z-index: 2;
//             background: $onboard-survey-background;
//         }
//         table tbody th {
//             position: sticky;
//             left: 0;
//             z-index: 1;
//             background: $onboard-survey-background;
//         }
//     }

//     //-------------------------------------- VERSION 1

//     // .research-screen {
//     //     @include standardContainer;
//     //     color: $input-color;

//     //     overflow-y: auto;

//     //     &-filter {
//     //         margin: 15px 0 20px 0;

//     //         &-header {
//     //             margin-bottom: 10px;

//     //             & a {
//     //                 color: $input-color;
//     //             }
//     //         }
//     //     } 

//     //     & table {
//     //         width: 100%;
//     //         border-collapse: separate;
//     //         border-spacing: 0;

//     //         & tr {
//     //             height: 27px;
//     //         }
//     //     }
//     //     & .primary-table {
//     //         border: 1px solid $separator-line-color;
//     //         // min-width: 1250px;
//     //         width: max-content;
            
//     //         color: $input-color-filter;
//     //         font-size: 12px;

//     //     }
//     //     & th, & td {
//     //         padding: 0;

//     //         & a {
//     //             text-decoration: none;
//     //             color: $input-color;
//     //             font-size: 12px;
//     //             letter-spacing: 0.2px;
//     //             text-transform: uppercase;

//     //             // & svg {
//     //             //     font-size: 11px;
//     //             // }
//     //         }

//     //         & svg {
//     //             font-size: 14px;
//     //         }

//     //         &.final-cell {
//     //             border: 1px solid $separator-line-color;
//     //         }
            
//     //     }
//     //     & th {
//     //         & > span {
//     //             display: flex;
//     //             align-items: center;
                
//     //         }
//     //         & .country-name {
//     //             font-size: 11px;
//     //         }
//     //     }
        
//     //     & .main-row:nth-child(2n) {
//     //         background-color: transparent;
//     //     }
//     //     & .main-row:not(:nth-child(2n)) {
//     //         background-color: $card-details-background;
//     //     }

//     //     .secondary-table th:first-child,
//     //     .tertiary-table td:first-child {
//     //         width: 200px;
//     //     }
//     //     .secondary-table th:not(:first-child),
//     //     .tertiary-table td:not(:first-child) {
//     //         width: 101px;

//     //     }

//     //     .handle {
//     //         margin: 0 5px;
//     //         padding: 0 2px;
//     //         color: $input-color-filter;
//     //     }

//     //     & .md-grid-2.lg-grid-4 {

//     //         & > div:nth-child(2) {
//     //             @include respond(md) {
//     //                 grid-row: 2;
//     //             }
//     //             @include respond(lg) {
//     //                 grid-row: auto;
//     //             }

//     //         } 
            
//     //     }

//     // }

//     //-------------------------------------- ENDS VERSION 1
// </style>