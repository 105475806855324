import {dayjs} from "../../utility/datetime";

export default {
    setAnnouncements(state, payload) {
        let recentAnnouncements = payload?.data?.data?.recent?.filter(announcement => {
            let announcementDate = dayjs(announcement.created_at);
            let currentDate = dayjs();
            return announcementDate.diff(currentDate, 'days') <= 30;
        })
        state.announcements = recentAnnouncements || [];
    },
    addAnnouncements(state, payload) {
        state.announcements.push(payload);
    },
    setAnnouncementAsReadById(state, payload) {
        const index = state.announcements.findIndex(entry => entry.id === payload);
        let updatedAnnouncement = state.announcements[index];
        updatedAnnouncement.read = true;
        updatedAnnouncement.read_at = new Date();
        state.announcements.splice(index, 1, updatedAnnouncement);
    }
}
