<template>
    <div class="client-profile-overview">
        <div class="client-profile-overview-header">
            
            <div class="client-profile-overview-header-title">{{ title }}</div>
            <div class="client-profile-overview-header-subtitle">{{ subtitle }}</div>
            <div class="client-profile-overview-header-controls">
                <div class="client-profile-overview-header-controls-search">
                    <form @submit.prevent style="display: flex;">

                    <base-input
                    elementType="input"
                    inputType="text"
                    v-model="searchTerm"
                    forID="searchTerm"
                    :placeholder="'Name/email/phone/agent...'"
                    inputClass="partner-input no-border o-height-40"
                    formControlClass="o-flex profilesearch"
                    labelClass="medium-font"
                    data-lpignore="true"
                    ></base-input>
                    <base-button
                    style="margin-left: 15px;"
                    mode="o-blue o-height-40 form-control-fs"
                    :link="false"
                    @click.native="searchClients"
                    >Search
                    </base-button>
                    <base-button
                    v-if="searchMode"
                    style="margin-left: 15px;"
                    mode=".o-tranparent-grey o-height-40 form-control-fs"
                    :link="false"
                    @click.native="clearSearch"
                    ><font-awesome-icon :icon="['fas', 'times']"/>
                    </base-button>
                    </form>
                </div>
                <div class="client-profile-overview-header-controls-sort">
                </div>
                <div class="client-profile-overview-header-controls-action">
                    <div 
                    v-if="sortStatus !== 'approved'"
                    class="reqested-archives">
                        <a v-if="sortStatus === 'requested'" href="javascript:void(0);" @click="sortStatus = 'archived'"><font-awesome-icon :icon="['fa', 'eye']" style="margin-right: 5px;" />View archived requests</a>
                        <a v-else-if="sortStatus === 'archived'" href="javascript:void(0);" @click="sortStatus = 'requested'"><font-awesome-icon :icon="['fa', 'times']" style="margin-right: 5px;"/>Close archived requests</a>
                    </div>
                    <div>
                        <base-button
                        mode="green o-height-40"
                        :link="false"
                        @click.native="addNewClient"
                        ><font-awesome-icon :icon="['fas', 'plus']"/> Create new Access
                        </base-button>
                    </div>
                    
                </div>
            </div>

        </div>
        <div v-for="table in tableData" :key="table.id">
            <custom-paginator
            :paginable="'client-profile-table'"
            :items="sortedClientData"
            :paginatorProps="paginatorProps"
            :sortStatus="sortStatus"
            :perPage="10"
            :startAt="startAt"
            @start-at="num => updateStartAt(num)"
            >
            </custom-paginator>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import _ from "lodash";
import { eventBus } from '../../../../main';
import { randomizeBetween } from '../../../../utility/helper-functions';
import CustomPaginator from '../../CustomPaginator.vue';

const general = createNamespacedHelpers("general");
const rules = createNamespacedHelpers("rules");

export default {
    props: ["activeList"],
    components: {
        CustomPaginator
    },
    created() {
        this.sortStatus = this.activeList;
        this.sortRuleEntries();
        this.tableData = [{
            is: '',
            id: randomizeBetween(0,10000000),
            startAt: this.startAt
        }]
    },
    computed: {
        ...general.mapGetters(['user']),
        ...rules.mapGetters(['rules', 'userList', 'bms', "getAllIndRuleUsers"]), 
        timezone() {
            return this.user.timezone;
        },
        title() {
            if(this.sortStatus === 'approved')
                return 'Access Granted';
            else if(this.sortStatus === 'requested')
                return 'Client Access Requests';
            else
                return 'Archived Client Requests';
        },
        subtitle() {
            if(this.sortStatus === 'approved')
                return 'A list of every access request that has been granted by your team';
            else if(this.sortStatus === 'requested')
                return 'The clients below have requested access to the analyst desk.'
            else
                return '';
        },
        paginatorProps() {
            return {
                sortStatus: this.sortStatus
            }
        }
    },
    data() {
        return {
            sortedClientData: {},
            searchTerm: "",
            sortStatus: "",
            searchMode: false,
            tableData: [],
            startAt: 1
        }
    }, 
    methods: {
        searchClients() {
            if(this.searchTerm.length > 0) {
                this.searchMode = true;
                this.sortRuleEntries();
            }
        },
        sortRuleEntries() {
            // const sortedMonths = {};
            // console.log(this.getAllIndRuleUsers)
            let rules = _.cloneDeep(this.getAllIndRuleUsers).filter(el => !el.user.bm);

            // console.log(rules)
            
            if(this.sortStatus === "approved")
                rules = _.orderBy(rules.filter(el => el.rule.rule_status === this.sortStatus || el.rule.rule_status === "cancelled"), el => el.rule.created_at, "desc");
            else
                rules = _.orderBy(rules.filter(el => el.rule.rule_status === this.sortStatus), el => el.rule.created_at, "desc");

            if(this.searchTerm) {
                const sTerm = this.searchTerm.toLowerCase();

                // const filterUsers = () => {
                //     const emailFilter = clients.filter(el => el.email.toLowerCase().indexOf(sTerm) > -1);
                //     if(email)
                //         return true;
                //     else 
                // }

                rules = _.orderBy(rules.filter(el => el.user.email?.toLowerCase()?.indexOf(sTerm) > -1 || el.user.name?.toLowerCase()?.indexOf(sTerm) > -1 || el.user.phone_number?.indexOf(sTerm) > -1 || this.bms.find(mgr => mgr.id === el.user.account_manager_id && mgr.name?.toLowerCase().indexOf(sTerm) > -1)), el => el.rule.created_at, "desc");
            }


            // rules.forEach(rule => {
            //     let thisMonth = usersTime(rule.rule.created_at, true, true, false, {
            //         dateFormat: "y-m-d"
            //     }, false, this.timezone);

            //     thisMonth = thisMonth.substring(0,7);

            //     if(!sortedMonths[thisMonth])
            //         sortedMonths[thisMonth] = {};

            //     // this.sortedItems[thisDay].push(headline);
            // });

            // Object.keys(sortedMonths).forEach(el => {
                
            //     const thisMonthClients = rules.filter(rl => rl.rule.created_at.indexOf(el) > -1);

            //     thisMonthClients.forEach(rule => {
                    
            //         let thisDay = usersTime(rule.rule.created_at, true, true, false, {
            //             dateFormat: "y-m-d"
            //         }, false, this.timezone);

            //         thisDay = thisDay.substring(0,10);

            //         if(!sortedMonths[el][thisDay])
            //             sortedMonths[el][thisDay] = [];

            //         sortedMonths[el][thisDay].push(rule);

            //     });
            // });

            this.sortedClientData = rules;
            this.tableData = [{
                is: 'client-profile-table',
                id: randomizeBetween(0,10000000),
                startAt: this.startAt
            }]
            // console.log("so", this.sortedClientData);
        },
        addNewClient() {

            const modal = {
                component: "create-access",
                title: 'Profile Manager',
                icon: 'scroll',
                user_id: null
            }

            eventBus.$emit('open-side-modal', modal);

        },
        clearSearch() {
            this.searchTerm = "";
            this.searchMode = false;
            this.sortRuleEntries();
        },
        updateStartAt(num) {
            this.startAt = num;
            // console.log(this.startAt);
        }
    },
    watch: {
        activeList(val) {
            this.startAt = 1;
            this.sortStatus = val;
             this.tableData = [{
                is: 'client-profile-table',
                id: randomizeBetween(0,10000000),
                startAt: this.startAt
            }];
        },
        sortStatus() {
            this.sortRuleEntries();
        },
        userList: {
            handler: function() {
                this.sortRuleEntries();
            },
            deep: true
        },
        rules: {
            handler: function() {
                this.sortRuleEntries();
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
    .client-profile-overview {
        color: $input-color;
        background-color: $onboard-survey-card-background;
        padding-left: 20px;
        padding-right: 20px;
        min-height: calc(100vh - 135px);
    }
    .client-profile-overview-header {
        padding-top: 30px;
    }
    .client-profile-overview-header-title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
    }
    .client-profile-overview-header-subtitle {
        font-size: 14px;
        color: $partner-dimmed-text-1;
        margin-bottom: 15px;
    }
    .client-profile-overview-header-controls {
        @include respond(lg) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    .client-profile-overview-header-controls-search {
        @include respond(lg) {
            flex-basis: 450px;
        }

        & .profilesearch {
            @include respond(lg) {
                flex-basis: 280px;
            }
        }
    }
    .client-profile-overview-header-controls-sort {
        @include respond(lg) {
            margin-left: 50px;
            // flex-basis: 250px;
        }
    }
    .client-profile-overview-header-controls-action {
        
        & a {
            color: $checkbox-active;
        }
        // margin-right: auto;

        @include respond(lg) {
            
            display: flex;
            align-items: center;
            flex-basis: 600px;
            justify-content: flex-end;
        }

        .reqested-archives {
            @include respond(lg) {
                margin-right: 20px;
                text-align: right;
            }
        }
    }
</style>