<template>
<!-- v-if="showTabs" -->
    <div
    class="tabs-menu"
    id="content"
    ref="tabMenu"
    :class="type"
    v-resize @resize="onResize"
    >
        <draggable v-if="draggable" v-model="clonedTabs" @change="sorted">
            <transition-group class="item-wrapper">
                <div v-for="tab in clonedTabs" :key="tab.id"
                class="item-container">
                    <tab-menu-item
                    :tabMenuWidth="tabMenuWidth"
                    :active="active"
                    :type="type"
                    :miniMenu="type === 'dashboards'"
                    @menu-item-clicked="obj => $emit('menu-item-clicked', obj)"
                    :tab="tab"></tab-menu-item>
                </div>
            </transition-group>
        </draggable>

        <div v-else class="item-wrapper">
            <div v-for="tab in clonedTabs" :key="tab.id"
            class="item-container">
                <tab-menu-item
                :tabMenuWidth="tabMenuWidth"
                :active="active"
                :type="type"
                :miniMenu="type === 'dashboards'"
                @menu-item-clicked="obj => $emit('menu-item-clicked', obj)"
                :tab="tab"></tab-menu-item>
            </div>
        </div>
        
        <div
        v-if="type === 'dashboards'"
        class="tab-filler"
        >
        </div>

    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { getParameterByName } from '../../utility/helper-functions';
import Draggable from 'vuedraggable';
import TabMenuItem from './TabMenuItem.vue';
import _ from "lodash";

const dashboards = createNamespacedHelpers('dashboard');

export default {
    props: ["type", "tabs", "active", "draggable"],
    components: {
        Draggable,
        TabMenuItem
    },
    created() {
        this.clonedTabs = this.clonedTabs = _.cloneDeep(this.tabs);
    },
    mounted() {
        this.calculateWidth();
        // console.log(this.tabMenuWidth);
    },
    computed: {
        // ...dashboards.mapState(['fullscreenId']),
        showTabs() {
            // if(this.type === 'dashboards')
            //     if(!this.isPopOut && this.fullscreenId === 0)
            //         return true;
            //     else
            //         return false;

            return true;
        }
    },
    data() {
        return {
            isPopOut: getParameterByName('popout'),
            tabMenuWidth: 0,
            clonedTabs: []
        }
    },
    methods: {
        ...dashboards.mapActions(['setSystemDashboards']),
        onResize() {
            this.$nextTick(() => {
                this.calculateWidth();
            });
        },
        calculateWidth() {
            if(this.$refs.tabMenu) {
                this.tabMenuWidth = this.$refs.tabMenu.offsetWidth;
            }
        },
        sorted() {
            if(this.type !== 'dashboards')
                return;
                
            this.clonedTabs.forEach((el, index, arr) => {
                el.precedence = 100 - arr.length + index + 1;
            });
            this.setSystemDashboards(this.clonedTabs);
        }
    },
    watch: {
        tabs: {
            handler: function(nVal, oVal) {
                if(!_.isEqual(nVal,oVal)) {
                    this.clonedTabs = _.cloneDeep(nVal);
                }
            }
        }
    }
}
</script>

<style lang="scss">
.tabs-menu {
    // display: flex;
    color: $input-color;

    & .item-wrapper {
        display: flex;
    }

}
.tabs-menu--item {

    display: flex;

    &:hover,
    &.active {
        background-color: $separator-line-color;
    }
    &.disabled-item {
        color: $input-color-filter;
        &:hover {
            background-color: $onboard-survey-background;
            cursor: default;

            // & a {
            //     pointer-events: none;
            // }
        }
    }

    & a {
        color: inherit;
        cursor: pointer;
        display: inline-block;
        padding: 0 8px;
        font-size: 14px;

    }

    & .tab-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }


}

.tabs-menu.widget-config,
.tabs-menu.playlist {
    display: flex;
    height: unset!important;
}
.tabs-menu.widget-config {

    & .tabs-menu--item {
        margin: 0 1px;
        padding: 6px 10px;
        border-radius: 20px;

    }
}
.tabs-menu.playlist {
    justify-content: center;

    & .tabs-menu--item {
        margin: 0 1px;
        padding: 3px 5px;
        border-radius: 20px;

    }
}

.tabs-menu.dashboards,
.tabs-menu.clientprofiles {

    border-radius: 8px;

    & .tab-filler {
        // background-color: $onboard-survey-background;
        flex: 1;
        position: relative;
    }

    background-color: $onboard-survey-card-background;

    display: none;

    @include respond(lg) {
        display: flex;
    }

    &.tab-menu-section {
        margin-left: 10px;
        margin-right: 10px;
    }


    max-width: 100%;

    & .tabs-menu--item {

        padding: 3px 4px;

        & .tab-border {
            border-right: 1px solid $input-icon-color;
            margin: 6px;
            display: inline-block;
        }

        &:hover,
        &.active {
            border-radius: 8px;
            background-color: $separator-line-color;
        }

        & .tab-text {

            & svg {
                margin-right: 6px;
                font-size: 12px;
                color: $input-icon-color;
            }
        }



    }

}

.tabs-menu.clientprofiles {
    
    display: flex;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: $card-details-background;
    
    &.tab-menu-section {
        margin-left: 0;
        margin-right: 0;
    }

    & .tabs-menu--item {
        color: $client-border-1;

        & .tab-text {
            display: flex;
            align-items: center;

            & img {
                height: 16px;
                margin-right: 5px;
            }
        }

        & a {
            font-size: 15px;
        }
    }

    & .tabs-menu--item.active,
    & .tabs-menu--item:hover {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background-color: $onboard-survey-card-background;
        color: $input-color;
    }
}

.size-md .tabs-menu--item {

    & .tabs-menu--item {
        margin: 0 1px;
        padding: 6px 10px;
        border-radius: 20px;

    }
}

</style>