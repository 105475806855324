import Dexie from 'dexie';
import { dateTimedifference, nowUTC } from '../utility/datetime';
import { arrayIntersect, createDateSortedObj, mergeArraysByProp, searchForTweetChildren, sortDataBy, talkToServer } from '../utility/helper-functions';
import _ from 'lodash';
import { store } from '../store/store';

class indexedDB {
    constructor() {
        this.lastHeadlineTime = null;
        this.newLogin = false;
        this.isThereDBData = false;
        this.promo30DayVideoCat = 76;
        this.promofmcVideoCat = 84;
        this.partialTweetArray = [];
        this.partialTweetCheck = null;
        this.stores = {

            availableCategories: 'id, *availableCategories',
            lookupCategories: 'id, parent_id',
            lookupProperties: 'id, name',

            glossary: 'id',

            headlines: 'id, published_at, source_id, *categories, *props',

            video_library_videos: 'video_id, id, categories*, video_name',

            tweet_boards: 'id',
            tweet_channels: 'id, board_id',
            tweet_tweets: 'uid, tweet_id, board_id, channel_id, thread, author.screenName',

            livestreams: 'id, widget_implementation_id',
            livestream_questions: 'id, live_stream_id',

            ecocalendar: 'id, timestamp',

            interest_rates: 'id, central_bank_code'


        };

        // const shortAgo = moment().add(-3,'seconds').utc().format("YYYY-MM-DDTHH:mm:ssZ");
        // const lastRefreshBlocker = this.getRefreshBlocker();

        // if(this.newLogin ||
        //     (lastRefreshBlocker &&
        //     lastRefreshBlocker < shortAgo)) {
        //     // console.log('Refresh RECENT');
        //     this.resetDB();
        //     this.fetchBulkData();
        // }else{
        //     // console.log('Refresh');
        //     this.lastHeadlineTime = lastRefreshBlocker;
        //     this.isThereDBData = true;
        // }


        // this.setRecurringJobs();

    }

    init() {

    }

    async setupDB(clearStores) {

        this.db = new Dexie('FinancialSource');
        await this.db.version(4).stores(this.stores);
        await this.db.open();

        if(clearStores) {
            await this.clearStores();
        }
        return {};
    }

    clearStores() {
        // const f = u => {
        //     console.log(u);
        // }
        // console.log("f",f);
        const mainObject = this;
        const availableCategories = function() {
            return mainObject.db.availableCategories.clear();
        }
        const lookupCategories = function() {
            return mainObject.db.lookupCategories.clear();
        }
        const lookupProperties = function() {
            return mainObject.db.lookupProperties.clear();
        }
        const glossary = function() {
            return mainObject.db.glossary.clear();
        }
        const headlines = function() {
            return mainObject.db.headlines.clear();
        }
        const video_library_videos = function() {
            return mainObject.db.video_library_videos.clear();
        }
        const tweet_boards = function() {
            return mainObject.db.tweet_boards.clear();
        }
        const tweet_channels = function() {
            return mainObject.db.tweet_channels.clear();
        }
        const tweet_tweets = function() {
            return mainObject.db.tweet_tweets.clear();
        }
        const livestreams = function() {
            return mainObject.db.livestreams.clear();
        }
        const livestream_questions = function() {
            return mainObject.db.livestream_questions.clear();
        }
        const ecocalendar = function() {
            return mainObject.db.ecocalendar.clear();
        }
        const interest_rates = function() {
            return mainObject.db.interest_rates.clear();
        }
        
        return Promise.all([availableCategories(), lookupCategories(), lookupProperties(), glossary(), headlines(), video_library_videos(), tweet_boards(), tweet_channels(), tweet_tweets(), livestreams(), livestream_questions(), ecocalendar(), interest_rates()]);
    
    }

    deleteDB () {
        return Dexie.delete("FinancialSource");
    }

    deleteRecordByID(objStore, id) {
        return this.db[objStore].delete(id);
    }

    // processCacheData(data) {
    //     const records = JSON.parse(JSON.stringify(data));
    //     delete records.user;
    //     for(const type in records) {
    //         this.storeRecords(records[type]);
    //     }

    // }

    storeRecords(processingObject) {

        let { objStore, data } = processingObject;

        return this.db[objStore].bulkPut(data, {allKeys: true});
        // .then(function(allKeys) {
        //     console.log("done saving " + objStore + " " + allKeys.length);
        //     return data;
        // });
    }

    //methods
    // getRecordsWithComplexQueries(queryObject, processingObject) {

    // }

    // getRecordsOnSchedule() {

    // }
    getRecords(queryObject) {
        const { objStore, boardID, query, forceFetch, name, channelID, sources, categories, props, status, fromTo, impID, type, code } = queryObject;

        if(objStore === "tweet_boards") {
            return this.db?.[objStore]?.toArray();
        }
        else if(objStore === "tweet_channels") {
            return this.db[objStore].where('board_id').equals(boardID).toArray();
        }
        else if(objStore === "tweet_tweets" && boardID) {
            // if localstorage can't prove we retrieved 10 per channel:
            if(forceFetch) {
                return talkToServer(query, "GET", null, null, true).then(response => {

                    this.createUniqueIndex(response);

                    const processingObject = {
                        objStore: "tweet_tweets",
                        data: response
                    }
                    return this.storeRecords(processingObject);
                });
            }

            else {

                return this.db[objStore].where('board_id').equals(boardID).toArray();
            }


        }
        else if(name === "thread-fetching") {
            return this.getRecordsFromServer(queryObject);
        }
        else if(name === "tweets-load-more") {
            return this.db.tweet_tweets.where('channel_id').equals(channelID).toArray();
        }

        // headlines
        else if(name === "initial-headline-fetch" ||
        name === "headlines-load-more") {
            return this.db[objStore].where('source_id').anyOf(sources).and((el => arrayIntersect(el.categories, categories))).distinct().toArray().then(response => {
                // console.log("headline response", response);
                if(props.length)
                    return response.filter(headline => arrayIntersect(headline.props,props));

                return response;
            });
        }

        //livestreams
        else if(name === "initial-livestream-fetch") {
            return this.db[objStore].where('widget_implementation_id').equals(impID).toArray().then(response => {
                // console.log("live stream from idb", response)
                if(!response.length) {

                    return talkToServer('/widgets/live-streams', "GET", null, null, true).then(response => {
                        // console.log('live streams', response);
                        if(response &&
                        response.data) {

                            const processingObject = {
                                objStore: objStore,
                                data: response.data
                            }

                            this.storeRecords(processingObject);

                            return response.data.filter(el=> el.widget_implementation_id === impID);
                        }

                    });

                }else{
                    return response;
                }
            });
        }
        else if(name === "livestreams-load-more") {
            return this.db[objStore].where('widget_implementation_id').equals(impID).toArray().then(response => {
                if(response && response.length) {

                    if(status === "finished") {
                        return response.filter(video => video.status === "ready-for-playback");
                    }else{
                        return response.filter(video => video.status === 'live' || video.status === 'scheduled');
                    }

                }else{
                    return [];
                }
            });
        }

        //eco calendar
        else if(name === "initial-ecocalendar-fetch") {

            const queryObject = {
                name: "ecocalendar-load-more",
                objStore: "ecocalendar",
                fromTo: fromTo
            };

            return this.db[objStore].toArray().then(response => {
                if(!response.length) {

                    const renderFromTo = fromTo;

                    // queryObject.fromTo = {
                    //     from: addDays(-5),
                    //     to: addDays(5)
                    // }

                    return this.getRecordsFromServer(queryObject).then(() => {
                        return this.getRecordsBetween("ecocalendar", renderFromTo).then(r => r);
                    });

                }
                else{
                    // const mainObject = this;
                    return (async () => {

                        // let fromDateData = function () {
                        //     return mainObject.db[objStore].where('timestamp').startsWith(fromTo.from).toArray();
                        // };
                        // let toDateData = function () {
                        //     return mainObject.db[objStore].where('timestamp').startsWith(fromTo.to).toArray();
                        // };

                        // let fromto = await Promise.all([fromDateData(), toDateData()]);

                        // console.log("fromto",fromto[0],fromto[1]);

                        // if(!fromDateData.length || toDateData.length)
                        // return this.getRecordsFromServer(queryObject);

                        const daysBetween = dateTimedifference(fromTo.from, fromTo.to, 'days');

                        const range = await this.getRecordsBetween("ecocalendar", fromTo);
                        const daysortedObj = createDateSortedObj(range, false);

                        const daysortedObjLength = Object.keys(daysortedObj).length;

                        if(daysBetween + 1 === daysortedObjLength)
                            return range;
                        else{

                            return this.getRecordsFromServer(queryObject).then(() => {
                                return this.getRecordsBetween("ecocalendar", fromTo).then(r => r);
                            });
                        }
                        // console.log("range",range);



                    })();

                }
            });
        }

        // video library
        else if(name === "initial-video-fetch" ||
        name === 'videos-load-more') {

            const mainObject = this;

            let lookupCategories =  function() {

                // const fetchProp = categories.length === 1 ? 'parent_id' : 'id';
                const fetchProp = 'id';

                return mainObject.db['lookupCategories'].where(fetchProp).anyOf(categories).toArray();
                // return mainObject.db['lookupCategories'].where('parent_id').anyOf(categories).toArray();
            };
            let videos;
            // if(type === 'course')
                videos =  function() {
                    // console.log("vid categories", categories);
                    return mainObject.db[objStore].where('categories').anyOf(categories).toArray();
                   
                };
            // else
            //     videos =  function() {
            //         // console.log("vid categories", categories);
            //         if(categories.length === 1)
            //             return mainObject.db[objStore].where('categories').anyOf(categories).toArray();
            //         else
            //             return [];
            //     };
            
            // console.log(queryObject);


            return Promise.all([lookupCategories(), videos()]).then(response => {
                response.forEach(el => {
                    // console.log(el)
                    let order = "orderbyASC";
                    let prop = "order_by";
                    if(type !== "course") {
                        order = "timeDESC";
                        prop = "created_at";
                    }

                    if(el.length)
                        sortDataBy(el, prop, order);
                });

                // if(type === 'course') {
                    return {
                        categories: response[0],
                        videos: response[1]
                    }
                // }else
                    // return [ ...response[0], ...response[1] ];
            });
        }

        // irpt
        else if(name === "initial-intrate-fetch") {
            return this.db[objStore].toArray().then(response => {
                if(!response.length) {

                    return talkToServer(query, "GET", null, null, true).then(response => {
                        console.log('irpt', response);
                        if(response &&
                        response.data) {

                            const processingObject = {
                                objStore: objStore,
                                data: response.data
                            }

                            this.storeRecords(processingObject);

                            return response.data;
                        }

                    });

                }else{
                    return response;
                }
            });
        }
        else if(name === "intrate-bank-fetch") {
            return this.db[objStore].where('central_bank_code').equals(code).toArray().then(response => {
                    return response;
                }
            );
        }
        // glossary
        else if(name === "initial-phrase-fetch") {
            return this.db[objStore].toArray().then(response => {
                if(!response.length) {

                    return talkToServer(query, "GET", null, null, true).then(response => {
                        console.log('glossary', response);
                        if(response &&
                        response.data) {

                            const processingObject = {
                                objStore: objStore,
                                data: response.data
                            }

                            this.storeRecords(processingObject);

                            return response.data;
                        }

                    });

                }else{
                    return response;
                }
            });
        }
    
    }

    async getMoreRecords(queryObject) {
        const { name, channelID, offset, limit, oldestTweetTime, sources, categories, props, isSearchMode, fromTo, from, to, keywords, slug } = queryObject;

        let dataFromServer;
        let startIndexServer;
        let dataLeftInLocalDB;
        let localDBRecords = 0;
        let getFromServer = false;
        let result = [];
        let forceLocalOnly = name === "livestreams-load-more" || name === "videos-load-more" ? true : false;

        if(!isSearchMode || forceLocalOnly) {
            let data;
            if(name !== "videos-load-more")
                data = await this.getRecords(queryObject);

            else if(name === "videos-load-more") {
                // console.log("qqq", queryObject)
                data = await this.getRecords(queryObject);

                if(name !== "videos-load-more")
                    sortDataBy(data, "created_at", "timeDESC");
                
                data = data.videos.filter(element => !element.deleted_at);
                localDBRecords = data.length;
            
            }

            if(name === "livestreams-load-more") {
                data = await this.getRecords(queryObject);
            }

            if(name === "videos-load-more") {
                let videos = data.filter(element => element.categories);
                // console.log("v1", data);
                videos = videos.filter(element => element.categories.includes(categories[0]));
                let cats = data.filter(element => element.parent_id);

                if(from && to) {
                    videos = videos.filter(element => element.created_at >= from && element.created_at <= to);
                }

                if(keywords !== "*") {
                    videos = videos.filter(element => element.video_name.toLowerCase().indexOf(keywords.toLowerCase()) > -1);
                    cats = cats.filter(element => element.display_name.toLowerCase().indexOf(keywords.toLowerCase()) > -1);
                }

                data = [...cats, ...videos];
                
                if(data.length)
                    sortDataBy(data, "order_by", "orderByASC");
                localDBRecords = data.length;
            }

            localDBRecords-offset >= 0 ? dataLeftInLocalDB = localDBRecords-offset : dataLeftInLocalDB = 0;

            const startIndex = offset;
            let endIndex = offset+limit;

            let localData = data;
            if(startIndex && startIndex > -1 && endIndex && endIndex > -1)
                localData = data.slice(startIndex,endIndex);

            // console.log("s e",startIndex,endIndex);

            // console.log("localData",localData);

            //if there is enough data locally
            if(dataLeftInLocalDB >= limit ||
            forceLocalOnly) {
                return localData;
            }
            else{
                //there is some data locally, but less than what we need
                getFromServer = true;

                if(dataLeftInLocalDB > 0){
                    result = localData;

                    dataFromServer = limit - dataLeftInLocalDB;

                    startIndexServer = offset + localData.length;

                }
                //if there is none left locally
                else {
                    dataFromServer = limit;

                    startIndexServer = offset;
                }
            }

        }

        if(isSearchMode || getFromServer) {

            if(isSearchMode) {
                dataFromServer = limit;

                startIndexServer = offset;
            }

            // console.log("data from server", dataFromServer);
            // console.log("start index server", startIndexServer);

            let objStore;
            let count;
            let filterProp;

            // queryObject.offset = startIndexServer;
            // queryObject.limit = dataFromServer;

            if(name === "tweets-load-more") {
                queryObject.name = "load-more-channel-fetch";
                queryObject.query = `/widgets/tweetdeck/tweets/channel/${channelID}/${oldestTweetTime}`;
            }

            if(name === "headlines-load-more" &&
            sources.length &&
            categories.length) {
                const sourceString = "?source=" + encodeURIComponent(sources.join(","));
                const categoryString = "&categories=" + encodeURIComponent(categories.join(","));
                let propetyString = '';

                if(props)
                    propetyString = "&properties=" + props;

                const params = sourceString + categoryString + propetyString;

                queryObject.name = name;
                queryObject.query = `/widgets/${slug}/en/search/${fromTo}/${startIndexServer}/${dataFromServer}/${keywords}${params}`;

            }


            let serverResult = await this.getRecordsFromServer(queryObject);


            if(name === "tweets-load-more") {

                this.createUniqueIndex(serverResult);

                objStore = "tweet_tweets";
                count = 10;
                filterProp = "uid";

            }

            if(name === "headlines-load-more") {

                serverResult = serverResult.data;

                // console.log("server",serverResult);

                objStore = "headlines";
                count = 25;
                filterProp = "id";
            }


            // console.log("big fetch",dataFromServer, serverResult);
            if(!isSearchMode)
                this.storeRecords({
                    objStore: objStore,
                    data: serverResult
                });

            let toBeRenderedFromServer = serverResult.splice(0, count+1);

            const filteredFromServer = mergeArraysByProp(toBeRenderedFromServer, result, filterProp);

            return filteredFromServer;
        }

    }

    getRecordsFromServer(queryObject) {

        const { query, name, fromTo, sources, categories, prop, offset, limit, keywords, objStore, ids } = queryObject;

        if(name === "thread-fetching" ||
        name === "load-more-channel-fetch") {
            return talkToServer(query, "GET", null, null, true).then(response => {
                return response;
            });
        }
        else if(name === "headlines-load-more") {
            // console.log(query);
            return talkToServer(query, "GET", null, null, true).then(response => {
                return response;
            });
        }
        else if(name === "headlines-inital-fetch-server") {
            return talkToServer(query, "GET", null, null, true).then(response => {
                const processingObject = {
                    objStore: objStore,
                    data: response.data
                };
                return this.storeRecords(processingObject).then(r => r);

            });
        }
        else if(name === "category-headlines") {
            const sourceString = "?source=" + encodeURIComponent(sources.join(","));
            let categoryString = '';
            let propetyString = '';

            if(categories?.length)
                categoryString = "&categories=" + encodeURIComponent(categories.join(","));
            
            if(prop)
                propetyString = "&properties=" + prop;

            const params = sourceString + categoryString + propetyString;

            
            return talkToServer(`${query}/${fromTo}/${offset}/${limit}/${keywords}${params}`, "GET", null, null, true).then(response => {
                // console.log("res",response);

                const processingObject = {
                    objStore: objStore,
                    data: response.data
                };

                return this.storeRecords(processingObject).then(r => r);

            });
        }
        else if(name === "ecocalendar-load-more") {
            const from = '?dateFrom=' + encodeURIComponent(fromTo.from);
            const to = '&dateTo=' + encodeURIComponent(fromTo.to);
            const slug = '/widgets/calendar/events' + from + to + (ids ? '&missingIds=' + ids.join(',') : '');

            return talkToServer(slug, "GET", null, null, true).then(response => {
                // console.log('eco cal', response);
                if(response &&
                response.data) {

                    const processingObject = {
                        objStore: objStore,
                        data: response.data
                    };


                    return this.storeRecords(processingObject).then(() => {
                        return response.data;
                    });




                }

            });
        }
        else if(name === "videos-inital-fetch-server") {
            return talkToServer(query, "GET", null, null, true).then(response => {

                const processingObject = {
                    objStore: objStore,
                    data: response.data
                };

                return this.storeRecords(processingObject).then(() => {
                    return response.data;
                });

            });
        }
        else if(name === "intrate-inital-fetch-server") {
            return talkToServer(query, "GET", null, null, true).then(response => {

                const processingObject = {
                    objStore: objStore,
                    data: response.data
                };


                return this.storeRecords(processingObject).then(() => {
                    return response.data;
                });

            });
        }
        else if(name === "phrase-inital-fetch-server") {
            return talkToServer(query, "GET", null, null, true).then(response => {

                const processingObject = {
                    objStore: objStore,
                    data: response.data
                };


                return this.storeRecords(processingObject).then(() => {
                    return response.data;
                });

            });
        }

    }






    getSocialBoards() {
        const queryObject = {
            objStore: "tweet_boards"
        }
        return this.getRecords(queryObject);
    }

    getSocialChannels(id) {
        const queryObject = {
            objStore: "tweet_channels",
            boardID: id
        }
        return this.getRecords(queryObject);
    }

    getTweetsByBoard(id, forceFetch) {
        const queryObject = {
            query: `/widgets/tweetdeck/tweets/board/${id}`,
            objStore: "tweet_tweets",
            boardID: id,
            forceFetch: forceFetch
        }
        return this.getRecords(queryObject);
    }

    getThreadFromServer(obj) {
        const queryObject = {
            name: "thread-fetching",
            query: `/widgets/tweetdeck/tweets/thread/${obj.tweet_id}/${obj.channelID}`,
        }
        return this.getRecords(queryObject);
    }

    getUpperMostParentInThread(id) {
        return this.db.tweet_tweets.get(id, data => {

            if(data && data.thread) {
                const parentID = data.thread;
                // console.log("thread", parentID);
                return this.getUpperMostParentInThread(parentID);
            }else if(data) {
                // console.log("final", data.tweet_id);
                return data.uid;
            }

            return null;

        });
    }

    getTweetsInThreadUp(tweetID,tweetArray) {
        //get all the existing categories

        return this.db.tweet_tweets.get(tweetID, data => {

            tweetArray.push(data);
            if(data && data.thread) {
                const parentID = data.thread;
                return this.getTweetsInThreadUp(parentID, tweetArray);
            }else{
                return tweetArray;
            }

        });
    }

    getTweetsInThreadDown(threadID) {
        //get all the existing categories

        const channelID = +threadID.match(/_(.*?)_/)[1];

        // console.log(channelID);

        return this.db.tweet_tweets.where('channel_id').equals(channelID).toArray().then(data => {

            let results = [];

            searchForTweetChildren(data, threadID, results);

            return results;




        });
    }

    getBulkTweetData() {
        return (async () => {

            let boards = function () {
                return talkToServer("/widgets/tweetdeck/boards", "GET", null, null, true);
            };
            let channels = function () {
                return talkToServer("/widgets/tweetdeck/channels", "GET", null, null, true);
            };

            try{

                let data = await Promise.all([boards(), channels()]);

                await this.storeRecords({
                    objStore: "tweet_boards",
                    data: data[0]
                });
                await this.storeRecords({
                    objStore: "tweet_channels",
                    data: data[1]
                })
                return data[0];

            } catch(error) {
                console.log(`error: ${error}`);
            }

        })();
    }

    deleteTweetsByHandle(handle) {
            return this.db.tweet_tweets.where("author.screenName").equalsIgnoreCase(handle).delete();
    }

    deleteTweetsFromChannelByHandle(channel, handle) {
        console.log(channel, handle);
        return this.db.tweet_tweets.where("channel_id").equals(channel).and(el => el.author.screenName.toLowerCase() === handle.toLowerCase()).delete();

    }




    getRecordByProperty(queryObject) {
        const { name, objStore, prop, value } = queryObject;

        if(name === "fetch-tweet-by-uid" || !name){
            return this.db[objStore].where(prop).equals(value).toArray();
        }


        else if(name === "fetch-stream-question-by-id") {
            return this.db[objStore].where(prop).equals(value).toArray()
            .then(response => {
                if(!response.length) {
                    return talkToServer(`/widgets/live-streams/${value}/questions`, "GET", null, null, true).then(response => {
                        if(response && response.data) {

                            const processingObject = {
                                objStore: objStore,
                                data: response.data
                            };

                            this.storeRecords(processingObject);
                            return response.data;

                        }

                    });
                }
                return response;

            });
        }
    }

    createUniqueIndex(arr) {
        arr.forEach(element => {
            const uidStart = `${element.board_id}_${element.channel_id}_`;
            element.uid = uidStart + element.tweet_id;

            if(element.thread)

                element.thread = uidStart + element.thread;
            element.created_at = element.created_at.replace('.000000','');
        });

    }

    getRecordsBetween(objStore, fromTo) {
        const fromTime = fromTo.from + ' 00:00:00';
        const toTime =  fromTo.to + ' 23:59:59';

        return this.db[objStore].where('timestamp').between(fromTime, toTime, true, true).toArray();

    }

    getLookupCategories() {
        return this.db.lookupCategories.toArray();
    }

    async getCatsUpwards(current, topCats) {
        let foundCats = [];
        let currentToProcess = current;
        let stop = false;

        const categories = await this.db["lookupCategories"].toArray();

        // console.log(current,topCats,categories);

        do {
            let found = categories.find(el => el.id === currentToProcess);
            // console.log("found", found);
            if(topCats.includes(currentToProcess))
                stop = true;
            if(found) {
                foundCats.push(found);
                currentToProcess = found.parent_id;
            }
            
        }
        while(!stop) 
        
        return foundCats;
    }

    getNextUpcomingLiveStreamByImpIDs(arr) {
        // console.log(arr);
        return this.db.livestreams.where('widget_implementation_id').anyOf(arr).and(el => el.status === "scheduled" || el.status === "live").toArray().then(r => {
            if(!r.length)
                return [];

            const ordered = _.orderBy(r, el => el.starts_at, "asc");
                // console.log(ordered);

            const obj = {}
            ordered.forEach(el => {
                if(!obj[el.widget_implementation_id])
                    obj[el.widget_implementation_id] = el;
            });

            return obj;

        });
    }

    // async getAvailableLookupCategories() {
    //     let availables = await this.db.availableCategories.toArray();
    //     // if(!availables.length)
    //     //     return [];

    //     availables = availables[0].availableCategories;

    //     let categories = await this.db.lookupCategories.toArray();

    //     categories = categories.filter(cat => availables.indexOf(cat.id) > -1);

    //     return categories;
    // }

    getCalEventsByIds(ids) {
        return this.db.ecocalendar.bulkGet(ids).then(response => {
            console.log(response)
            return response;
        });
    }

    getEventsMissingEventsForToday() {
        return this.db.ecocalendar.toArray().then(response => {
            const now = nowUTC();
            const startOfToday =  `${now.substring(0,10)} 00:00:00`;
            const majorCountries = [...store.state.calendar.majorCountries, 'HKG'];
            // console.log(startOfToday, now, majorCountries);
            return response.filter(el => el.timestamp >= startOfToday && 
                el.timestamp <= now && 
                majorCountries.includes(el.country) && 
                // el.type.toLowerCase() !== "holiday" && 
                // !el.name.toLowerCase().includes('speech') && 
                // !el.name.toLowerCase().includes('minutes') && 
                el.actual === null && 
                (el.forecast || el.forecast === 0) && 
                el.impact > 0); // 
        });
    }














    // getTweetsInThreadUp(id, []) {

    // },
    // getTweetsInThreadDown(highestParent, []) {

    // },
}

export default new indexedDB;
