<template>
    <div>
        <button @click="toggleAccordion" class="collapsible" :class="computedClass" style="cursor:pointer;">
            {{ title }}
        </button>
        <br/>
        <div class="content" v-if="open">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "Collapsible",
    props: {
        title: {
            type: String,
            default: ''
        },
        buttonClass: {
            type: String,
            default: ''
        }
    },
    data: function () {
        return {
            open: false
        }
    },
    computed: {
        computedClass: function () {
            return this.open ? 'active ' + this.buttonClass: this.buttonClass + '';
        }
    },
    methods: {
        toggleAccordion: function () {
            this.open = !this.open;
        }
    }
}
</script>

<style scoped>
.content {
    padding: 0 0px;
}
.howItWorksBtn {
    background: #d5ad35;
    color: #fefefe;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 32px;
    padding-right: 32px;
    border: none;
    max-width: 150px;
    cursor: pointer;

}
.defaultButton {
    background: green;
    color: white;
    border: none;
    width: 125px;
    height: 40px;
    cursor: pointer;
}
.widgetsButton {
    border: none;
    width: 125px;
    height: 40px;
    cursor: pointer;
}
.howItWorksBtn:hover {
    background: #f3ce60;
}
.btn {
    border: none;
    color: whitesmoke;
    border-radius: 10%;
    background-color: inherit;
    padding: 14px 28px;
    font-size: 16px;
    cursor: pointer;
    display: inline-block;
}
.btn:hover {background: rgba(114, 111, 111, 0);}
</style>
