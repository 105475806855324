export default {
    haveVideos(state) {
        return state.haveVideos;
    },
    readyForVideoProcessing(_, getters, _2, rootGetters) {

        return getters.haveVideos &&
        rootGetters["headlines/readyForCategoryProcessing"];


    },

    lastVideoUpdate(state) {
        return JSON.parse(JSON.stringify(state.lastVideoUpdate));
    },

    lastVideoDelete(state) {
        return JSON.parse(JSON.stringify(state.lastVideoDelete));
    },
}