import { createNamespacedHelpers } from 'vuex';
import LivestreamService from '../services/livestream/livestream.service';
import _ from 'lodash';
import {  mergeArraysByProp, sortDataBy } from '../utility/helper-functions';
import ContentLockedMixin from '../mixins/contentLockedMixin';

const livestreamsNS = createNamespacedHelpers('webinars');

export default {
    mixins: [ContentLockedMixin],
    computed: {
        ...livestreamsNS.mapGetters(['lastLiveStreamUpdate', 'lastLiveStreamDelete']),
        sortedItems() {
            if(this.upcomingActive)
                return this.upcomingVideos;

            return this.archiveVideos;
        },
    },
    data() {
        return {
            bulkVideos: [],
            upcomingVideos: {
                videos: {},
                fetchMode: true,
                resultsReady: false,
                noMoreItems: false
            },
            archiveVideos: {
                videos: {},
                fetchMode: true,
                resultsReady: false,
                noMoreItems: false,
                limitTo: 0
            },
            sortProp: "starts_at",
            videosPerFetch: 25,
            // searchTerms: null,
            highlightTimeOut: null,
            newVideo: false,
            allowRender: true,
            videoInPlayer: [],
            activeVideo: null,
            currentQuestionsForVideo: [],
            isUpcomingActive: true,
            archiveActive: false,
            upcomingActive: true,
            categories: []
            
        }
    },
    methods: {
        async fetchInitialStreams() {
            this.archiveVideos.resultsReady = false;
            this.upcomingVideos.resultsReady = false;

            // console.log(this.impID)

            this.bulkVideos = await LivestreamService.fetchInitialLiveStreams(this.impID);
            
            this.archiveVideos.resultsReady = true;
            this.upcomingVideos.resultsReady = true;
        },
        async loadMore(videolist) {
            if(videolist === "questions")
                return;
                
            this[videolist].resultsReady = false;
            const limit = this.videosPerFetch;
            let offset;

            if(videolist === "archiveVideos") {
                offset = this.bulkVideos.filter(video => video.status === "ready-for-playback").length;
            }else{
                offset = this.bulkVideos.filter(video => video.status !== "ready-for-playback" && video.status !== "finished" && video.status !== "processing").length;
            }

            const response = await LivestreamService.loadMoreLiveStreams(videolist, this.impID);
            
            let countingVideos = [];
            if(videolist === "archiveVideos") {
                countingVideos = response.filter(video => video.status === "ready-for-playback");

            }else if(videolist === "upcomingVideos") {
                countingVideos = response.filter(video => video.status === "scheduled" || video.stats === "live");
            }

            if(!response.length || !countingVideos.length) {
                this[videolist].noMoreItems = true;
                this[videolist].resultsReady = true;
                return;
            }
   
            countingVideos = countingVideos.slice(offset, offset + limit + 1);
            if(countingVideos.length < this.videosPerFetch) {
                this[videolist].noMoreItems = true;
            }

            let bulkVideos = mergeArraysByProp(this.bulkVideos, countingVideos, "id");

            this.bulkVideos = bulkVideos;
            
            this[videolist].resultsReady = true;
            
        },
        sortUpcomingAndArchives() {
            this.categories = [];
            const upcomingVideos = this.bulkVideos.filter(video => video.status !== "ready-for-playback" &&
            video.status !== "finished" && video.status !== "processing");
            if(upcomingVideos.length) {
                sortDataBy(upcomingVideos, this.sortProp, "timeASC");
                this.sortVideosByDay(upcomingVideos, "upcomingVideos");
                this.categories.push({display_name: 'Upcoming', id: 'upcomingActive'});
            }

            const archiveVideos = this.bulkVideos.filter(video => video.status === "ready-for-playback");

            if(archiveVideos.length) {
                sortDataBy(archiveVideos, this.sortProp, "timeDESC");

                if(this.isShowCase && this.isThereUpcoming)
                    this.archiveVideos.limitTo = 1;

                if(this.archiveVideos.limitTo) {
                    archiveVideos.splice(this.archiveVideos.limitTo);
                }
                    

                this.sortVideosByDay(archiveVideos, "archiveVideos");
                this.categories.push({display_name: 'Archived', id: 'archiveActive'});
            }
                
            const live = this.bulkVideos.find(video => video.status === 'live');
            if(live) {
                this.addCurrentLiveID(live.id);
                //add connect participant code https://app.asana.com/0/830439190133212/1202098893784602
            }
                
            
            
        },
        sortVideosByDay(videos, targetObj) {
            this[targetObj].videos = {};
        
            videos.forEach(video => {

                let thisDay = video[this.sortProp].substring(0,7);

                if(!this[targetObj].videos[thisDay])
                    this[targetObj].videos[thisDay] = [];

                this[targetObj].videos[thisDay].push(video);
            });

            this[targetObj].videos = _.cloneDeep(this[targetObj].videos);

        },
        async fetchQuestionsForVideo(id) {
            return await LivestreamService.fetchQuestionsForVideo(id);
        }
    },
    watch: {
        bulkVideos() {
            this.sortUpcomingAndArchives();
        },
        // archiveVideos: {
        //     handler(value) {
        //         if(value.resultsReady === true && 
        //         this.allowRender) {
        //             const firstVideo = this.archiveVideos.videos[0];

        //             if(firstVideo) {
        //                 const regExRule = /\s(\w*src=+?)"(.+?)"/g;
                        
        //                 //this will need to be checked on a real example:
        //                 const src = (regExRule.exec(firstVideo.content))[2];

        //                 const obj = {
        //                     id: firstVideo.id,
        //                     src: src,
        //                     title: firstVideo.name,
        //                     published_at: firstVideo.started_at
        //                 };

        //                 this.videoInPlayer = [obj];
        //                 this.activeVideo = obj.id;

        //                 this.firstRender = false;
        //             }
        //         }
        //     },
        //     deep: true
        // }
    }
}