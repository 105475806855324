<template>
    <div>
        <div class="window" :class="fontsize">

            <div class="tabs" v-if="tabs.length" ref="tabMenu">
                <ul :class="{flexDirectionRow: details.width >= 357, justifyCenter: details.width >= 750}">
                    <li
                    v-for="tab in tabs"
                    :key="tab.id"
                    :class="{activetab: currentSection === tab.id}"><a href="javascript:void(0)" @click="changeTab(tab.id)">{{ tab.name }}</a></li>
                </ul>
            </div>

            <div style="position: relative; grid-column: 1 / -1;">
            
                <news-container
                :style="verticalHeight"
                :sortedItems="sortedItems"
                :resultsReady="resultsReady"
                :currentSection="currentSection"
                @load-more="loadMore"
                :noMoreItems="noMoreItems"
                :layoutID="details.layoutID"
                :newHeadline="newHeadline"
                @new-headline-viewed="newHeadline = false"
                ></news-container>
                <padlock-overlay
                :impID="details.implementation_id"
                v-if="isLocked"
                class="blur"
                :instructions="true"
                ></padlock-overlay>
            
            </div>


        </div>
    </div>
</template>

<script>
import { eventBus } from '../../main';
import NewsContainer from './components/BreakingHeadlines/NewsContainer.vue';
import Headline from '../../mixins/headline';
import { createNamespacedHelpers } from 'vuex';
import ContentLockedMixin from '../../mixins/contentLockedMixin';
import PadlockOverlay from '../../assets/PadlockOverlay.vue';

const dashboards = createNamespacedHelpers('dashboard');

export default {
    props: ["details"],
    mixins: [Headline, ContentLockedMixin],
    components: {
        NewsContainer,
        PadlockOverlay
    },
    created() {


        //Get default settings:
        const widget = this.$store.getters['widget/getWidgetByImplementationId'](this.details.implementation_id);
        // console.log(widget)
        let menus = JSON.parse(widget?.default_settings?.["heading-control"]?.value || {});

        if(widget?.settings?.["heading-control"])
            menus = JSON.parse(widget.settings["heading-control"].value);

        menus.headings.forEach(element => {
            this.tabs.push({
                id: element.properties.join(''),
                name: element.label,
                properties: element.properties
            });
        });


        eventBus.$on("widget-resize", obj => {
            setTimeout(() => {
                 if(obj.id === this.details.layoutID) {
                    this.verticalHeight = {height: obj.height - this.heightAdjustment + 'px'};
                }
            },400);


        });

        const obj = {
            menu: {
                isSearchEnabled: true,
                // isGlossaryEnabled: true,
            },

        }
        this.$emit('set-frame', obj);


        // console.log("this.readyForHeadlineProcessing",this.readyForHeadlineProcessing, window.bootUp.iDB);

        if(this.readyForHeadlineProcessing)
            // setTimeout(() => {
                this.fetchInitialHeadlines(this.categories);
            // },2000)

    },
    computed: {
        ...dashboards.mapState(['fullscreenId']),
        // ...headlinesNS.mapGetters(['readyForHeadlineProcessing', 'lastHeadlineUpdate']),
        fontsize() {
            if(this.details.userSettings)
                return this.details.userSettings.fontsize;

            return '';
        },
        heightAdjustment() {
            let adj = 118;

            if(this.fullscreenId)
                adj = 123;
            // if(this.details.width >= 417)
                return adj + this.tabMenuHeight;

            // return 166;
        },



    },
    updated() {
        setTimeout(() => {
            this.tabMenuHeight = this.$refs.tabMenu?.getBoundingClientRect().height || 0;
        },200);
    },
    data() {
        return {
            slug: "headlines",
            activeTab: this.details.currentSection,
            verticalHeight: '',
            sources: this.details.userSettings.sources,
            categories: this.details.userSettings.categories,
            // bulkHeadlines: [],
            // sortedItems: {},
            tabs: [
                {
                    id: 0,
                    name: 'All News',
                    properties: []
                },
            ],
            currentSection: 0,
            sortProp: "published_at",
            tabMenuHeight: 0

        }

    },
    methods: {

        changeTab(id) {
            this.currentSection = id;
            this.fetchInitialHeadlines();
            this.noMoreItems = false;
        },

    }

}
</script>

<style lang="scss" scoped>

    .tabs {
        grid-column: 1 / -1;
        margin-top: 7px;
        margin-bottom: 7px;
        position: relative;

        ul {
            display: flex;
            margin: 0;
            padding: 0;
            margin-bottom: -1px;
            justify-content: flex-end;
            height: 100%;
            align-items: flex-end;
            flex-direction: column;

            &.flexDirectionRow {
                flex-direction: row;
            }
            &.justifyCenter {
                justify-content: center;
            }
        }

        li {
            list-style: none;
            padding: 6px 0px;
            border-bottom: 2px solid transparent;
            margin: 0 12px;

            //Poppins
            font-size: 11px;

            //Roboto
            // font-size: 12px;

            &.activetab {
                // border-top: 1px solid $color-twitter;
                // border-left: 1px solid $color-067;
                // border-right: 1px solid $color-067;
                border-bottom: 2px solid $color-15;
                background-color: rgba($color-01, 0.01);

                & a,
                & a:link,
                & a:hover,
                & a:active,
                & a:visited {
                    color: $color-15;
                }

            }

            & a {
                transition: all 0.3s ease;
            }

            & span,
            & a,
            & a:link,
            & a:active,
            & a:visited {
                text-decoration: none;
                color: $color-20;
            }

            & a:hover {
                color: $color-15;
            }


        }

        .tabs-body {

            // border: 1px solid $color-067;
            padding: 15px 2px 5px 2px;
            background-color: rgba($color-01, 0.01);

        }
    }



</style>
