<template>
    <div style="margin: 1px;">
        <div>
            <bar-chart style="margin-top: 5px;" ref="barChart"
            :plugins="plugins"
            :maintain-aspect-ratio="maintainAspectRatio"
            :width="chartWidth"
            :height="chartHeight"
            :handler="handler"
            :min="min"
            :max="max"
            css-classes="bar-chart"
            y-scale-label="Value"
            :chart-data="chartData" 
            :yTicks="yTicks"
            :legendOn="legendOn"
            :iteration="iteration"
            :labelText="labelText"
            :title="title"></bar-chart>
        </div>
    </div>

</template>

<script>

import BarChart from "../widgets/components/CurrencyStrengthMonitor/BarChart.vue";
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
    name: "CurrencyMonitor",
    components: {BarChart},
    props: {
        singleChart: Boolean,
        maintainAspectRatio: Boolean,
        title: String,
        currencyIndex: Number,
        chartWidth: {
            type: Number,
        },
        chartHeight: {
            type: Number,
            default: 200
        },
        currencyCollection: {
            type: Object,
            default: () => {
            }
        },
        handler: {
            type: Function,
            default: () => {
            }
        },
        displayFxMajors: {
            type: Function,
            default: () => {
            }
        },
        chartData: {
            type: Object,
            default: () => {

            }
        },
        min: Number,
        max: Number,
        yTicks: {
            type: Function,
            default: (value) => value
        },
        legendOn: {
            type: Boolean,
            default: true
        },
        iteration: Number,
        labelText: {
            type: Function,
            default: (context) => context.label + ' ' + context.value
        }
    },
    created() {

    },
    data: function () {
        return {
            plugins: [ChartDataLabels],
        }
    },
    
}
</script>

<style lang="scss" scoped>
.chartjs-size-monitor {
    position: relative !important;
}

.graph-numeric-label {
    font-weight: bold;
    font-size: medium; 
    color: $color-01; 
    border-radius: 50%; 
    background-color: $color-20;

    display: inline-block;
    width: 25px;
    height: 25px;
    text-align: center;
}
</style>
