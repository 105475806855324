<template>
    <div>
        <div class="data-table economic-calendar desktop">
            <div class="data-table-title">
                <span class="datecarousel"><span class="date-span">This week: Jan 17 - Jan 23 </span>
                </span>
                <span class="datecarousel"><span class="date-span"><span class="date-span" style="font-weight: bold;"><a href="javascript:void(0)"
                @click="addAndRemoveHighLight"
                class="next-eco-event"><i class="fas fa-arrow-right"></i> Up Next</a></span></span>
                </span>
            </div>
            <div class="data-table-options">
            </div>
            <div class="data-table-header">

                        <div class="data-table-row">

                            <div class="data-table-row-main">
                                <div class="data-table--cell" :style="headerCellStyles">Date</div>
                            </div>

                            <div class="data-table-row-subrows">
                                <div class="data-table-row-subrows--row">
                                    <div class="data-table-row-subrows--row__header">
                                        <div class="data-table--cell" :style="headerCellStyles">Time</div>
                                        <div class="data-table--cell from-medium" :style="headerCellStyles">Currency</div>
                                        <div class="data-table--cell wide-only" :style="headerCellStyles">Impact</div>
                                        <div class="data-table--cell" style="padding-left: 6px;" :style="headerCellStyles">Event name</div>
                                        <div class="data-table--cell" :style="headerCellStyles">Actual</div>
                                        <div class="data-table--cell" :style="headerCellStyles">Forecast</div>
                                        <div class="data-table--cell" :style="headerCellStyles">Low</div>
                                        <div class="data-table--cell" :style="headerCellStyles">High</div>
                                        <div class="data-table--cell wide-only" :style="headerCellStyles">Previous</div>
                                    </div>
                                </div>
                            </div>
                        </div>

            </div>


            
            <div class="data-table-body"
            :style="verticalHeight"
            :id="'body-'+details.layoutID"
            >
                <loading-spinner v-if="isLoading" style="height:100px;"></loading-spinner>
                <div v-if="noEvents && !isLoading"
                style="text-align:center; font-size: 15px; padding: 10px 0;">
                    No events found.
                </div>
                <div 
                class="data-table-body-inner"
                v-else-if="!noEvents && !isLoading"
                :id="'body-inner-'+details.layoutID"
                >

                    <calendar-day
                    v-for="calDay in sortedItems"
                    :key="calDay.date"
                    :calendarDay="calDay.events"
                    :day="calDay.date"
                    :details="details"
                    :calHeight="height"
                    :layoutID="details.layoutID"
                    :autoScroll="autoScroll"
                    :countrySelection="countrySelection"
                    @upcoming-event-position="scrollToUpcomingEventPosition"
                    :isLocked="isLocked"
                    ></calendar-day>

                </div>

            

            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '../../main';
import EcoCalendarService from '../../services/ecocalendar/ecocalendar.service';
import { addDays, addTime, nowUTC, thisWeek, usersTime } from '../../utility/datetime';
import CalendarDay from './components/EcoCalendar/CalendarDay'
import _ from 'lodash';
import { createDateSortedObj, diffArray, sortDataBy } from '../../utility/helper-functions';
import { scroller } from 'vue-scrollto/src/scrollTo';
import LoadingSpinner from '../ui/LoadingSpinner.vue';
import calStyleMixin from '../../mixins/calStyleMixin';
import ContentLockedMixin from '../../mixins/contentLockedMixin';

const dashboards = createNamespacedHelpers('dashboard');
const ecocalendarNS = createNamespacedHelpers('calendar');
const general = createNamespacedHelpers('general');

export default {
    props: {
        fullscreen: Boolean,
        details: Object
    },
    mixins: [calStyleMixin, ContentLockedMixin],
    components: {
        CalendarDay,
        LoadingSpinner
    },
    created() {
        

        eventBus.$on("widget-resize", obj => {
            if(obj.id === this.details.layoutID) {
                this.height = obj.height;
            }

        });
        eventBus.$on("event-backup-fetch", obj => {
             
            if(obj.instanceID === this.$root.instanceID) {
                this.doFrequentFetches = true;
                this.startBackupFetchJob();
            }
            
            
        });

        // const fetchDay = addDays(0);
        // if(this.fetchDay !== fetchDay) {
        //     this.serverFetch = true;
        // }

        if(this.defaultRange === 1) {
            this.fromTo = {
                from: addDays(0),
                to: addDays(0)
            }
        }else if(this.defaultRange === 2) {
            this.fromTo = {
                from: thisWeek(0),
                to: thisWeek(6)
            }
        }

        const from = new Date(this.fromTo.from)
        const to = new Date(this.fromTo.to)
        const tzOffset = from.getTimezoneOffset() * -60000;
        // console.log(tzOffset,new Date(from.getTime() - tzOffset), new Date(to.getTime() - tzOffset));

        // this.fromTo = {
        //     from: "2021-08-08",
        //     to: "2021-08-14",
        // }

        const obj = {
            menu: {
                isAutoScrollEnabled: true,
                isDatepickerEnabled: true,
                isNextEventScrollerEnabled: true,
                isFilterEnabled: true,
                dateRange: [new Date(from.getTime() - tzOffset), new Date(to.getTime() - tzOffset)]
            }

        }
        this.$emit('set-frame', obj);

        this.countrySel = _.cloneDeep(this.countrySelection);

        this.getEvents();

        
    },
    mounted() {
        setTimeout(() => this.addAndRemoveHighLight(),100);

        // setTimeout(() => {
        //     this.scrollToUpcomingEventPosition();
        // }, 1600);
    },
    beforeDestroy() {
        eventBus.$off("event-backup-fetch");
        clearTimeout(this.backupFetching);
        clearTimeout(this.backupFetchingSecondary);
        clearInterval(this.frequentInterval);
        clearInterval(this.rareInterval);
    },
    computed: {
        ...ecocalendarNS.mapGetters(["majorCountries", "lastEcoCalendarUpdate", "lastEcoCalendarDelete", "fetchDay", "missingEvents"]),
        ...dashboards.mapState(['fullscreenId']),
        ...general.mapGetters(['user']),
        timezone() {
            return this.user.timezone;
        },
        countrySelection() {
            if(this.details.userSettings)
                return this.details.userSettings.countries;
            
            return ["ALL"];
        },
        isCountrySelectionOn() {
            if(this.countrySelection.includes("ALL"))
                return false;

            return true;
        },
        impactLevels() {
            if(this.details.userSettings)
                return this.details.userSettings.impact;

            return [2,3];
        },
        defaultRange() {
            if(this.details.userSettings)
                return this.details.userSettings.defaultrange;

            return 2;
        },
        autoScroll() {
            return this.details.autoScrollOn;
        },
        noEvents() {
            return !this.sortedItems.length;
        },
        verticalHeight() {
            let adj = 140;
            // if(this.fullscreenId)
            //     adj = 80;

            return {height: this.height - adj + 'px'};
        }
    },
    data() {
        return {
            isRowOpen: false,
            showHighLightClass: true,
            bulkEvents: [],
            sortedItems: [],
            fromTo: {},
            serverFetch: false,
            isLoading: true,
            countrySel: [],
            height: 0,
            backupFetching: false,
            clearMissingEventTimeout: null,
            backupFetchingSecondary: null,
            frequentInterval: null,
            rareInterval: null,
            initialLoad: true,
            doFrequentFetches: false

        }
    },
    methods: {
        ...ecocalendarNS.mapActions(["setFetchDay", "clearMissingEvents", "setLastEcoCalendarUpdate", "updateMissingEvents", 'removeMissingEvent']),

        async getEvents() {
            this.isLoading = true;
            let response = await EcoCalendarService.fetchInitialEcoCalendarEvents(this.fromTo);
            
            if(this.isCountrySelectionOn) {
                response = response.filter(evnt => this.countrySelection.includes(evnt.country));
            }
            response = response.filter(evnt => this.impactLevels.includes(evnt.impact));

            this.setFetchDay(addDays(0));

            this.bulkEvents = response;
            this.isLoading = false;

            if(this.initialLoad) {
                let isThereRecentEvent = false;
                const now = nowUTC();
                const cutOffTime = addTime(-30, "minutes", now);
                console.log(now, cutOffTime);
                EcoCalendarService.getEventsMissingEventsForToday().then(r => {
                    r.forEach(el => {
                        if(el.timestamp >= cutOffTime)
                            isThereRecentEvent = true;

                        if(!this.missingEvents.includes(el.id)) {
                            this.updateMissingEvents(el.id);
                        }
                    });
                    if(isThereRecentEvent)
                        this.doFrequentFetches = true;
                    this.startBackupFetchJob();
                });
                this.initialLoad = false;
            }

        },
        addAndRemoveHighLight() {
            // const div = this.$refs.upcomingRelease;
            // div.scrollIntoView({behavior: "smooth"});

            // setTimeout(() => this.showHighLightClass = true, 500);
            // setTimeout(() => this.showHighLightClass = false, 2000);
        },
        scrollToUpcomingEventPosition(position) {

            if(!position)
                return;

            const firstScrollTo = scroller();

            firstScrollTo('#body-inner-'+this.details.layoutID, 500, {
                container: '#body-'+this.details.layoutID,
                lazy: false,
                easing: 'linear',
                offset: position,
                force: true,
                x: false,
                y: true
            });
        },
        startBackupFetchJob() {
            console.log("missing",this.missingEvents);
            clearTimeout(this.backupFetching);
            clearTimeout(this.backupFetchingSecondary);
            clearInterval(this.frequentInterval);
            clearInterval(this.rareInterval);
            if(!this.missingEvents.length)
                return;

            
            const date = nowUTC().substring(0,10);
            this.backupFetching = setTimeout(() => {
                
                let count = 0; 
                if(this.doFrequentFetches)
                    this.frequentInterval = setInterval(() => {
                        count+=2000;
                        this.fetchMissingData(date, date);
                        console.log("fetchmiss 1", count)
                        if(count >= 300000)
                            clearInterval(this.frequentInterval);

                    },2000);
                else
                    count = 30000;

                const backupFetchingSecondaryDelay = this.doFrequentFetches ? 285000 :  15000;

                this.backupFetchingSecondary = setTimeout(() => {
                    this.rareInterval = setInterval(() => {
                        count+=15000;
                        this.fetchMissingData(date, date);
                        console.log("fetchmiss 2", count)
                        if(count >= 1800000) {
                            this.clearMissingEvents();
                            clearInterval(this.rareInterval);
                            console.log("missing events now gone", this.missingEvents);
                        }

                    },15000);
                },backupFetchingSecondaryDelay);
                
                    
            },200);

        },
        fetchMissingData(from, to) {
            
            EcoCalendarService.loadMore(from, to, this.missingEvents).then(() => {
                EcoCalendarService.getEventsByIds(this.missingEvents).then(res => {
                    if(!res.length) {
                        clearTimeout(this.backupFetching);
                        clearTimeout(this.backupFetchingSecondary);
                        clearInterval(this.frequentInterval);
                        clearInterval(this.rareInterval);
                    }
                    else
                        res.forEach((el, i) => {
                            setTimeout(() => {
                                console.log(el.actual);
                                if(el.actual || el.actual === 0) {
                                    this.setLastEcoCalendarUpdate(el);
                                    this.removeMissingEvent(el.id);
                                }
                            }, (i+1) * 20);
                            
                        });
                    
                });
                
            });
        }
    },
    watch: {
        details: {
            handler(nVal) {
                let needToUpdate = false;
                
                if(nVal.dateRange.from && 
                nVal.dateRange.to &&
                (nVal.dateRange.from !== this.fromTo.from ||
                nVal.dateRange.to !== this.fromTo.to)) {
                    this.fromTo = nVal.dateRange;
                    needToUpdate = true;
                }

                if(needToUpdate) {
                    this.getEvents();
                }
                    
                
                    
            },
            deep: true
        },
        bulkEvents() {
            this.sortedItems = [];
        
            this.sortedItems = _.cloneDeep(createDateSortedObj(this.bulkEvents, this.timezone));


        },
        user: {
            deep: true,
            handler: function (nVal, oVal) {
                if(nVal.timezone !== oVal.timezone) {
                    console.log(nVal.timezone, oVal.timezone);
                    this.getEvents();
                }
                    
            }
        },
        countrySelection(val) {
            if(diffArray(val, this.countrySel).length) {
                this.countrySel = _.cloneDeep(this.countrySelection);
                this.getEvents();
            }
                
        },
        impactLevels(nVal, oVal) {
            if(diffArray(nVal, oVal).length)
                this.getEvents();
        },
        lastEcoCalendarUpdate(val) {

            //not a great way to handle highlights for updates or fresh news, but working for now
            // clearTimeout(this.highlightTimeOut);
            // this.highlightTimeOut = setTimeout(() => {
            //     this.removeHighlight();
            // },1800);

            let thisDay = usersTime(val.timestamp, true, true, false, {
                dateFormat: "y-m-d"
            }, false, this.timezone);

            thisDay = thisDay.substr(0,10);

            // if(!this.sortedItems[thisDay])
            //     return;

            const foundDay = this.sortedItems.find(day => day.date === thisDay);

            if(!foundDay)
                return;

            if(this.isCountrySelectionOn &&
            !this.countrySelection.includes(val.country)) {
                // console.log("country selection on and this country was not selected: ", val.country);
                return;
            }

            if(!this.impactLevels.includes(val.impact))
                return;

            // const IdPrev = val.previous_version_id;
            const IdCurr = val.id;

            let bulkEvents = [...this.bulkEvents];
            // const indexPrev = bulkEvents.findIndex(element => element.id === IdPrev);
            const indexCurr = bulkEvents.findIndex(element => element.id === IdCurr);


            // if(indexPrev > -1) {
            //     bulkEvents.splice(indexPrev, 1, val);
            // }else 
            if(indexCurr > -1) {
                bulkEvents.splice(indexCurr, 1, val);
            }else{
                bulkEvents.push(val);
                sortDataBy(bulkEvents, "timestamp", "timeASC");
                // this.newVideo = true;
                // playSound();
            }

            this.bulkEvents = bulkEvents;

                
        },
        lastEcoCalendarDelete(val) {
            let bulkEvents = [...this.bulkEvents];
            const index = bulkEvents.findIndex(element => element.id === val);

            if(index > -1) {
                bulkEvents.splice(index, 1);
            }

            this.bulkEvents = bulkEvents;
        }
    }
}
</script>

<style lang="scss">
    .data-table {

        &-title {
            // display: flex;
            display: none;
            justify-content: space-between;
            padding-right: 10px;
        }

        &.economic-calendar {

            color: $input-color-filter;
            // max-width: 1200px;
            padding: 0;

            margin: 10px 0;

            & .data-table-title {
                background-color: #041753;//#04195B;;
                padding: 3px 10px 5px;
                line-height: 18px;
                font-size: 15px;
                border: none;

                &.fixed {
                    position: fixed;
                    top: 0;
                    display: none;
                    z-index: 2;
                }
            }

            & .data-table-options {

                &-row {
                    display: grid;
                    @include respond(desktop) {
                        grid-template-columns: 1.5fr 1.5fr 1fr;
                    }


                    & .data-table-options__countries .data-table-options-col__body {
                        display: grid;
                        grid-template-columns: 1fr 1fr;

                    }

                }



            }

            & .data-table-header {
                @include H7;
                color: $input-color-filter;

                //for firefox
                & .data-table-row-subrows {
                    overflow-y: scroll;
                    @include scrollbarDark;

                    & .data-table--cell:last-child {
                        border-right: 2px solid $separator-line-color;
                    }
                }

                // & .data-table-row-subrows--row__header {
                //     padding-right: 5px;
                // }

                // & .data-table-row {
                //     background-color: #03113d;
                // }

            }

            & .data-table-body {
                overflow-y: scroll; //for firefox: scroll
                overflow-x: hidden;
                position: relative;

                @include scrollbarDark;
            }

            & .data-table-body .data-table-row {
                &:nth-child(2n) {

                    background-color: transparent;

                }
                &:nth-child(2n-1) {

                    background-color: $card-details-background;

                }

            }

                // & .data-table-row-subrows--row__header {

                //     transition: background-color 0.6s ease 0.6s;

                //     & .data-table--cell {

                //         &:nth-child(5),
                //         &:nth-child(6),
                //         &:nth-child(7),
                //         &:nth-child(8),
                //         &:nth-child(9) {
                //             text-align: right;
                //         }

                //     }
                // }

            .vue-custom-tooltip:after, .vue-custom-tooltip {
                max-width: 250px;
            }

        }

        // &.economic-calendar.desktop {
            // & .data-table-row {
            //     display: grid;
            //     grid-template-columns: 1fr 11fr;

            //     &-main {
            //         display: block;

            //         & .data-table--cell {
            //             overflow: visible;
            //         }

            //     }

            //     // &-subrows--row__header {
            //     //     display: grid;
            //     //     // grid-template-columns: 1fr 1fr 1fr 4fr 1fr;
            //     //     // grid-template-columns: 1fr 1fr 1fr 4fr 1fr 1fr 1fr 1fr 1fr;

            //     //     & > div:nth-child(1),
            //     //     & > div:nth-child(2),
            //     //     & > div:nth-child(3) {
            //     //         text-align: center;
            //     //         overflow: hidden;
            //     //     }

            //     //     &.not-numeric-event {
            //     //         grid-template-columns: 1fr 1fr 1fr 4fr 5fr;


            //     //         & > div:nth-child(5) {
            //     //             text-align: center;
            //     //         }
            //     //     }
            //     // }



            //     &-subrows--row__body {
            //         display: grid;
            //         grid-template-columns: 1fr 1fr;
            //     }

            // }
        // }

        &.economic-calendar.mobile {

            & .data-table-body {

                & .data-table-row-subrows {
                    border-bottom: 2px solid $table-inner-border-color;
                }

                // & .data-table-row-subrows:last-child {
                //    border-bottom: none;
                // }
            }


            & .data-table-row {
                display: block;
                // grid-template-columns: repeat(12, 1fr)

                &-main {
                    display: block;

                    & .data-table--cell:first-child {
                        border-right: none!important;
                    }

                }

                &-subrows--row__header {
                    display: grid;
                    grid-template-columns: 1.5fr 2fr 0.75fr 6fr 1.75fr;

                    & > div:nth-child(3) {
                        text-align: center;

                    }
                }
            }


        }

        // &.economic-calendar.desktop .subtable {

        //     & .data-table-row {
        //         display: block;
        //     }
        //     & .data-table-row-main {
        //         display: grid;
        //         grid-template-columns: 2fr 3fr;
        //     }
        // }

    }

    .data-table-header .data-table-row-main {
        display: none;
    }

    .size-md .data-table-header .data-table-row-main {
        display: block;
    }

    .data-table-header .data-table--cell {
        padding-left: 10px;
        padding-right: 10px;
    }

    .data-table-header .data-table-row-main .data-table--cell {
        border-right: none;
    }

    .size-md .data-table-header .data-table-row-main .data-table--cell {
        border-right: 2px solid $separator-line-color;

        &:last-child {
            border-right: none;
        }
    }

    .data-table-header .data-table-row-subrows--row__header {

        // & .data-table--cell {
            
        //     &:nth-child(5) {
        //         border-right: none;
        //     }
        // }
    }

    .size-sm .data-table-header .data-table-row-subrows--row__header {

        & .data-table--cell {
            font-size: 10px;
            &:nth-child(5) {
                border-right: none;
            }
        }
    }

    .size-sm .data-table-header .data-table-row-subrows--row__header .data-table--cell {

        &:nth-child(5) {
            border-right: 2px solid $separator-line-color;
        }

        // &:nth-child(6) {
        //     border-right: none;
        // }
    }

    .size-md .data-table-header .data-table-row-subrows--row__header .data-table--cell {

        &:nth-child(6) {
            border-right: 2px solid $separator-line-color;
        }

        &:last-child {
            border-right: none;
        }
    }


    .data-table-body .data-table--cell {
        border-right: 2px solid $separator-line-color;
        font-size: 11px;

        & .row-title-label {
            color: $input-color-filter;
        }

        & .row-title-label-name {
            max-width: 90%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            // display: block;
        }
    }

    .data-table-row {
        // grid-template-columns: 1fr 11fr;

        &-main {
            display: none;
            display: block;
            border-bottom: 2px solid $separator-line-color;


            & .data-table--cell {
                overflow: hidden;
                padding: 12px 10px;
            }

        }

        &-subrows--row__body {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

    }

    .size-md .data-table-row {
        display: grid;
        grid-template-columns: 85px 1fr;

        &-main {
            display: block;
            border-bottom: none;


            & .data-table--cell {
                overflow: hidden;
                padding: 10px 10px;
            }
        }

    }

    .data-table--cell {

        // white-space: nowrap;
        // text-overflow: ellipsis;

        &.wide-only {
            display: none;
        }
        &.from-medium {
            display: none;
        }
    }


    .size-sm .data-table--cell{
        
        &.from-medium {
            display: block;
        }

    }

    .size-md .data-table--cell {
        &.wide-only {
            display: block;
        }

    }

    .data-table-row-subrows--row__header {
        display: grid;
        grid-template-columns: 35px minmax(15px,2fr) 60px 60px 60px 60px;


        transition: background-color 0.6s ease 0.6s;

        & .data-table--cell {

            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8),
            &:nth-child(9) {
                text-align: center;
                // padding-right: 5px;
            }

        }



        & > div:nth-child(1),
        & > div:nth-child(2),
        & > div:nth-child(3) {
            text-align: center;
            overflow: hidden;
        }

        &.not-numeric-event {
            grid-template-columns: 1fr 0.8fr 2.5fr 1fr;


            & > div:nth-child(5) {
                text-align: center;
            }
        }

    }

    .size-sm .data-table-row-subrows--row__header {
        grid-template-columns: 65px 50px minmax(20px, 100%) 70px 70px 70px 70px;

        &.not-numeric-event {
            grid-template-columns: 1fr 0.8fr 2.5fr 2fr;

            & > div:nth-child(5) {
                text-align: center;
            }
        }
    }

    .size-md .data-table-row-subrows--row__header {
        grid-template-columns: 85px 70px 70px minmax(20px, 100%) 100px 85px 85px 85px 85px;

        &.not-numeric-event {
            grid-template-columns: 1fr 1fr 1fr 4fr 5fr;

        }
    }

    .autoscroll-section {
        margin: 20px 0 15px 0;
        height: 50px;
        background-color: $color-04;
        padding: 10px 25px;
        display: inline-block;
        font-size: 15px;
        color: $color-20;

        & .switch-button {
            align-items: center;
        }
    }



</style>
