<template>
    <div
    :class="{'odd-month': oddMonth}"
    >
        <div 
        class="u-from-lg-flex"
        :class="{'border-b-none': sameDayAsNext}"
        >{{ firstNewDay ? formatDate(rule.rule.created_at) : "" }}</div>
        <div>{{ rule.user.name }}</div>
        <div>{{ rule.user.email }}</div>
        <div  class="u-from-lg-flex" v-if="!isApproved">{{ rule.user.phone_number }}</div>
        <div class="u-from-lg-flex">{{ numberOfWidgets }}</div>
        <div class="u-from-lg-flex" v-if="isApproved">{{ accessLenght }}</div>
        <div class="u-from-lg-flex" v-if="isApproved">
            <span class="cancelled-badge" v-if="isCancelled">
                Cancelled
            </span>
            <span class="active-badge" v-else-if="isBeforeExpiry">
                Active
            </span>
            <span class="expired-badge" v-else>
                Expired
            </span>
            </div>
        <div class="u-from-lg-flex" v-if="isApproved">{{ agentName }}</div>
        
        <div v-if="isRequested">
            <base-button
            mode="o-partner-grey o-height-32 o-font-12"
            :link="false"
            v-if="!isUserEditingLocked"
            @click.native="archiveRequest('archived')"
            :disabled="isProcessing"
            :showSpinner="isProcessing"
            >Archive Request
            </base-button>
        </div>
        <div v-else-if="isArchived">
            <base-button
            mode="o-partner-grey o-height-32 o-font-12"
            :link="false"
            @click.native="archiveRequest('requested')"
            :disabled="isProcessing"
            :showSpinner="isProcessing"
            >Unarchive
            </base-button>
        </div>
        <div v-if="isApproved" style="overflow: visible;"><a v-if="isEm" href="javascript:void(0);" class="client-icon-link" @click="promoteUser">
            <VueCustomTooltip label="Upgrade to admin" position="is-left"><font-awesome-icon :icon="['fas', 'arrow-circle-up']" /></VueCustomTooltip>
            <font-awesome-icon v-if="isPromoting" class="spinning-spinner cursor-not-allowed" :icon="['fa', 'circle-notch']"/>
        </a></div>
        <div v-if="isApproved" style="overflow: visible;"><a href="javascript:void(0);" class="client-icon-link" :class="{'cursor-not-allowed' :!isActive}" v-on="isActive && !isLocking ? { click: () => lockRule()} : {}">
            <VueCustomTooltip v-if="isActive && !isLocking" label="Revoke Access" position="is-left"><font-awesome-icon :icon="['fas', 'lock-open']" /></VueCustomTooltip>
            <font-awesome-icon v-if="isLocking" class="spinning-spinner cursor-not-allowed" :icon="['fa', 'circle-notch']"/>
            <font-awesome-icon v-else-if="!isActive" :icon="['fas', 'trash-alt']" />
        </a></div>

        <div v-if="isRequested">
            <base-button
            mode="o-blue o-height-32 o-font-12"
            :link="false"
            :disabled="isUserEditingLocked"
            @click.native="editRequest(rule.id)"
            >
            <span v-if="isUserEditingLocked">Being edited...</span>
            <span v-else>Approve Request</span>
            </base-button>
        </div>
        <div v-else-if="isArchived"></div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import { addTime, dateTimedifference, usersTime } from '../../../../utility/datetime';
import { createNamespacedHelpers } from 'vuex';
import { frontFacingAssets, talkToServer } from '../../../../utility/helper-functions';
import { eventBus } from '../../../../main';
import userLockedMixin from '../../../../mixins/userLocked';
import Swal from 'sweetalert2';
import _ from 'lodash';

const general = createNamespacedHelpers("general");
const payments = createNamespacedHelpers("payments");
const rules = createNamespacedHelpers("rules");

export default {
    props: ["rule", "sortStatus", "sameDayAsNext", "firstNewDay", "oddMonth"],
    mixins: [userLockedMixin],
    // created() {
    //     if(this.rule.id === 253)
    //         console.log(this.rule)
    // },
    computed: {
        ...general.mapGetters(['user', 'entityQnaToShow', 'entityQnaCustom']),
        ...payments.mapGetters(['widgetsToSelectFromForHomeScreen']),
        ...rules.mapGetters(['userList']),
        // getRuleSetsForThisUser() {
        //     return Object.values(this.getUserRuleSets(this.client.id))[0]  || {rule: {}, widgets: []};
        // },
        isApproved() {
            return this.sortStatus === 'approved';
        }, 
        isRequested() {
            return this.sortStatus === 'requested';
        },
        isArchived() {
            return this.sortStatus === 'archived';
        },
        timezone() {
            return this.user.timezone;
        },
        editIconSrc() {
            return frontFacingAssets() + "filedit.svg";
        },
        eyeIconSrc() {
            return frontFacingAssets() + "eye.svg";
        },
        expiry() {
            const amount = this.rule.rule.unlock_time_units;
            const period = this.rule.rule.unlock_time_period + 's';
            const from = this.rule.rule.updated_at;
            return addTime(amount, period, from);
        }, 
        isBeforeExpiry() {
            return dayjs.utc().format('YYYY-MM-DD HH:mm:ss') < this.expiry;
        },
        isCancelled() {
            return this.rule.rule.rule_status === "cancelled"
        },
        isActive() {
            return !this.isCancelled && this.isBeforeExpiry;
        },
        agentName() {
            return this.userList.find(el => el.id === this.rule.user.account_manager_id)?.name || "N/A";
        },
        accessLenght() {
            if(this.isCancelled) {
                if(this.rule.locked_at && this.rule.unlocked_at) {
                    const locked_at = this.rule.locked_at;
                    const unlocked_at = this.rule.unlocked_at;
                    const unit = "days"
                    const notFullDays = dateTimedifference(unlocked_at, locked_at, "seconds") % 86400 !== 0;
                    let adjustment = notFullDays ? 1 : 0
                    const units = +(dateTimedifference(unlocked_at, locked_at, unit) + adjustment);
                    return  units + " " + unit.replace("s", "") + (units > 1 ? "s" : "");
                }
                return "N/A"
            }
            
            return this.rule.rule.unlock_time_units + " " + this.rule.rule.unlock_time_period + (this.rule.rule.unlock_time_units > 1 ? "s" : "");
        },
        numberOfWidgets() {
            const qnaProp = this.isRequested || this.isArchived ? "qanda_requested" : "qnaOn";
            let qnaValue;
            if(this.isRequested || this.isArchived) {
                qnaValue = this.entityQnaCustom ? this.rule.user[qnaProp] : false;
            }
            else {
                qnaValue = this.entityQnaCustom ? this.rule[qnaProp] : false;
            }

            if(this.rule.id === 210)
                console.log(qnaValue)
            
            const qnaText = qnaValue ? " + Q&A" : ""
            const ruleWidgetsLength = this.rule.widgets.length;
            if(ruleWidgetsLength === this.widgetsToSelectFromForHomeScreen.length)
                return "All widgets" + qnaText;

            return ruleWidgetsLength + " Widget" + (ruleWidgetsLength > 1 ? "s" : "") + qnaText;
        },
        listUserForUserLockEditing() {
            return this.rule.user;
        },
        isEm() {
            return this.user.entityManager;
        },
    },
    data() {
        return {
            isProcessing: false,
            isLocking: false,
            isPromoting: false,
        }
    },
    methods: {
        ...general.mapActions(["setEntity"]),

        formatDate(date) {
            return usersTime(date, true, false, false, {
                        dateFormat: "d m y",
                    }, false, this.timezone);
        },
        editRequest(id) {
            const modal = {
                component: "edit-request",
                title: 'Access Manager',
                icon: 'scroll',
                id: id
            }

            eventBus.$emit('open-side-modal', modal);

        },
        editClient(id) {

            const modal = {
                component: "edit-client",
                title: 'Profile Manager',
                icon: 'scroll',
                user_id: id
            }

            eventBus.$emit('open-side-modal', modal);

        },
        archiveRequest(status) {
            // console.log(status);
            const rule = _.cloneDeep(this.rule);
            rule.rule.rule_status = status;
            if(status === 'archived') 
                Swal.fire({
                    title: 'Are you sure?',
                    type: 'warning',
                    text: 'When you click below, this request will be archived and the client will not be given access to the analyst-desk.',
                    showCancelButton: true,
                    cancelButtonText: 'Don\'t archive',
                    cancelButtonColor: '#1d9deb',
                    confirmButtonColor: '#ff5656',
                    confirmButtonText: 'Yes, archive this request'
                }).then((result) => {
                    if (result.value) {
                        this.isProcessing = true;
                        talkToServer("/b/rules/" + rule.id, "PUT", null, rule.rule, true).then(response => {
                            this.isProcessing = false;
                                if (!response.success && response.error) {
                                    this.serverError = "Something went wrong, please try again later"
                                } else {
                                    Swal.fire({
                                        title: 'Success',
                                        type: 'info',
                                        text: 'The request has been archived and the client will not be given access. They can generate a new request or you can unarchive it to provide access later.',
                                        allowOutsideClick: false,
                                        showCancelButton: false,
                                        confirmButtonColor: '#3B9C83',
                                        confirmButtonText: 'OK'
                                    }).then(() => {});
                                }
                        }).catch(e => {
                            console.log(e);
                            this.isProcessing = false;
                            this.serverError = "Something went wrong, please try again later"
                
                        });
                    }
                });
            else {
                this.isProcessing = true;
                talkToServer("/b/rules/" + rule.id, "PUT", null, rule.rule, true).then(response => {
                    this.isProcessing = false;
                        if (!response.success && response.error) {
                            this.serverError = "Something went wrong, please try again later"
                        }
                }).catch(e => {
                    console.log(e);
                    this.isProcessing = false;
                    this.serverError = "Something went wrong, please try again later"
        
                });
            }

        },
        async lockRule() {
            const swalresp = await Swal.fire({
                title: 'Remove access?',
                type: 'warning',
                text: 'You are about to remove widget access for ' + this.rule.user.name + '.',
                showCancelButton: true,
                cancelButtonColor: '#1d9deb',
                confirmButtonColor: '#ff5656',
                confirmButtonText: 'Confirm'
            });
            
            if(!swalresp.value)
                return;

            this.isLocking = true;
            const rule = _.cloneDeep(this.rule);
            rule.rule.rule_status = "cancelled"

            try {
                const response = talkToServer("/b/rules/" + rule.id, "PUT", null, rule.rule, true);

                if(!response.success && response.error) {
                    this.serverError = "Something went wrong, please try again later";
                    this.isLocking = false;
                }
               
            }
            catch(e) {
                console.log(e);
                this.isLocking = false;
                this.serverError = "Something went wrong, please try again later"
            }
            if(this.serverError)
                return;

            try {
                let profile = _.cloneDeep(this.rule.user);

                profile = {
                    ...profile,
                    qanda_enabled: false,
                    qanda_requested: false,
                }

                const response = talkToServer("/b/user/" + profile.id, "PUT", null, profile, true);

                if(!response.success && response.error) {
                    this.serverError = "Something went wrong, please try again later"
                    this.isLocking = false;
                }
            }
            catch(e) {
                console.log(e);
                this.isLocking = false;
                this.serverError = "Something went wrong, please try again later"
            }

            this.isLocking = false;

            
            // talkToServer("/b/rules/" + rule.id, "PUT", null, rule.rule, true).then(response => {
            //     this.isLocking = false;
            //     if (!response.success && response.error) {
            //         this.serverError = "Something went wrong, please try again later"
            //     } 
                // else {
                    
                    // talkToServer(`/entity/${window.bootUp.refno}`, 'GET', null, null).then(entResp => {

                    //     if (entResp.success) {
                    //         window.bootUp.entity = entResp.data.entity;
                    //         this.$store.dispatch("rules/addBulkRecords")
                    //         // console.log("rule for new user",rule);
                    //     }

                    // });
                // }
            // }).catch(e => {
                
    
            // });
                

            
        },
        promoteUser() {
            Swal.fire({
                title: 'Make admin?',
                type: 'warning',
                text: 'You are about to make ' + this.rule.user.name + ' an admin. They will be able to see, manage and and make edits to your team and clients',
                showCancelButton: true,
                cancelButtonColor: '#1d9deb',
                confirmButtonColor: '#ff5656',
                confirmButtonText: 'Make admin'
            }).then((result) => {
                if (result.value) {
                    this.promoting = true;
                    const rule = _.cloneDeep(this.rule);
                    rule.rule.unlock_time_units = 10;
                    rule.rule.unlock_time_period = "year";
                    rule.qanda_enabled = true;
                    
                    const user = _.cloneDeep(this.rule.user);
                    user.qanda_requested = true,
                    user.qanda_enabled = true,
                    user.bm = 1;
        
                    const ruleUpdate = talkToServer("/b/rules/" + rule.id, "PUT", null, rule.rule, true);
                    const userUpdate = talkToServer("/b/user/" + user.id, "PUT", null, user, true);

                    return Promise.allSettled([ruleUpdate, userUpdate]).then(resp => {

                        const fail = resp.map(el => el.status).find(el => el === 'rejected');
                        this.isPromoting = false;
                        if(!fail) {
                            Swal.fire({
                                title: 'Success',
                                type: 'info',
                                text: user.name + " is now an admin. If they have not yet set up their password, please resend their invite in the 'Pending invites' tab.",
                                allowOutsideClick: false,
                                showCancelButton: false,
                                confirmButtonColor: '#3B9C83',
                                confirmButtonText: 'OK'
                            }).then(() => {
                                eventBus.$emit("nav-change", {
                                instanceID: this.$root.instanceID,
                                comp: 'team'
                            });
                            });
                        } else {
                            Swal.fire({
                                title: 'Error',
                                type: 'warning',
                                text: 'Some of the settings were not saved. Please refresh the terminal to check the user again',
                                allowOutsideClick: false,
                                showCancelButton: false,
                                confirmButtonColor: '#3B9C83',
                                confirmButtonText: 'OK'
                            }).then(() => {});
                        }
                    });
                }
            });
        }
    },
    
}
</script>