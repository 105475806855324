<template>
    <LineTest 
    :chartData="chartData"
    :min="min"
    :max="max"
    :stepSize="10"
    :chartHeight="350"
    :chartWidth="678"
    yLabelString="Implied Basis Points"
    :yTicks="(value) => '   ' + value + 'bps'"
    :iteration="iteration"
    title="Implied Basis Points" />
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { usersTime } from '../../../../utility/datetime';
import LineTest from '../../../charts/LineChartWrapper.vue';

const intrateNS = createNamespacedHelpers('interestRates');

export default {
    props: ["bank", "dates", "rates"],
    components: {
        LineTest
    },
    created() {
        this.processData();
    },
    computed: {
        ...intrateNS.mapGetters(['lastIntRateUpdate']),
        bpsRates() {
            //new sc1
            return this.rates.map(el => Math.round(el));
            //old
            // const rate = this.bank.current_rate;
            // return this.rates.map(el => {
            //     return Math.round((el - rate) * 100)
            // }); 
        },
        min()  {
            const min = Math.floor((Math.min(...this.bpsRates)) * 100) / 100 - 10
            return  min >= 0 ? 0 :  min;
        },
        max()  {
            const max = Math.ceil(Math.max(...this.bpsRates) * 100) / 100 + 10;
            return max <= 100 ? 100 : max;
        }
    },
    data() {
        return {
            chartData: {},
            chart1Defaults: {
                label: "Daily Rates",
                pointBackgroundColor: "#16CEB9",
                borderWidth: 1,
                fill: false,
                pointBorderColor: "#16CEB9",
                backgroundColor: "#16CEB9",
                borderColor: "#16CEB9",
                // alignment: (context) => {
                //     return context.dataIndex % 2 === 0 ? 'bottom' : 'top';
                // },
            },
            // chart2Defaults: {
            //     label: "Current",
            //     borderColor: "#81c784",
            //     pointBackgroundColor: "#81c784",
            //     borderWidth: 1,
            //     fill: false,
            //     pointBorderColor: "#81c784",
            //     backgroundColor: "#81c784",
            // }
            iteration: 0
        }
    },
    methods: {
        processData() {
            
            const labels = this.dates.map(el => usersTime(el, true, false, false, { dateFormat: "d M Y", timeFormat: "24hr"}, "UTC"));
            const fixedLabels = [];

            labels.forEach((el) => {
                const year = el.substr(7,4);
                // const month = el.substr(3,3)
                const foundYear = fixedLabels.find(label => label.includes("-" + year.substr(2,2)));
                // const foundMonth = fixedLabels.find(label => label.includes(month));
                
                // if(foundYear && foundMonth)
                //     fixedLabels.push(usersTime(el, true, false, false, { dateFormat: "jS", timeFormat: "24hr"}, true));
                if(foundYear)
                    fixedLabels.push(usersTime(el, true, false, false, { dateFormat: "d-m", timeFormat: "24hr"}, true));
                else
                    fixedLabels.push(usersTime(el, true, false, false, { dateFormat: "d-m-y", timeFormat: "24hr"},true));
            });


            this.chartData = {
                datasets: [
                    {
                        ...this.chart1Defaults,
                        data: this.bpsRates,
                        
                    },
                ],
                labels: fixedLabels
            }

        }
    },
    watch: {
        bank: {
           handler: function() {
                this.processData();
                this.iteration++;
           },
           deep: true

        },
        // rates: {
        //      handler: function() {
        //         this.processData();
        //         this.iteration++;
        //    },
        //    deep: true
        // }
    }
}
</script>