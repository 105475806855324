import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
    namespaced: true,
    state() {
        return{
            lastLiveStreamUpdate: null,
            lastLiveStreamDelete: null,
            currentLiveIDs: [],
            activeReminders: []
        }
    },
    mutations,
    actions,
    getters
}