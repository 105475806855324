<template>
    <div class="plan-mini-overview">
        <div class="plan-mini-overview--header">
            <h2>Update your payment card</h2>
            <p>Please update your payment card to continue using your product(s) below.</p>
        </div>
        <div class="plan-mini-overview--body">
            <product-mini-view
            v-for="product in renewFails"
            :key="product.id"
            :failed="product"
            ></product-mini-view>
        </div>
        <base-button
        mode="o-blue fullwidth"
        :link="false"
        @click.native="updateCard"
        >Update Payment Method {{ lastFourDigits }}
        </base-button>
        
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import ProductMiniView from './ProductMiniView.vue';

const payments = createNamespacedHelpers("payments");
const general = createNamespacedHelpers("general");

export default {
    components: {
        ProductMiniView
    },
    created() {
        console.log("failed", this.failedProductsToShow, this.$store.getters["general/user"]);
    },
    computed: {
        ...payments.mapState(['paymentMethod']),
        ...general.mapGetters(['renewFails']),

        ccData: function () {
            return this.paymentMethod?.description || "";
        },
        lastFourDigits() {
            return '*' + this.ccData.split("*")?.[1]
        }
    },
    methods: {
        updateCard() {
            this.$emit("card-modal-navigate-to", {
                view: "update-card"
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .plan-mini-overview {
    
        padding: 0 25px 25px 25px;
    
        &--header {

            & h2 {
                font-size: 24px;
                color: $input-color;
                margin-bottom: 30px;
                font-weight: 300;
                letter-spacing: 0.2px;
            }

            & p {
                font-size: 18px;
                color: $input-color-filter;
            }
        }
    }
</style>