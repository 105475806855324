<template>
    <div class="rule-setup">
        <div class="rule-setup-header">
            <h2>{{ title }}</h2><span class="cntdown">Remaining time: {{ formattedTimeLeft }}</span>
        </div>
        <div class="rule-setup-body">
            <div class="rule-setup-body-subtitle">{{ subtitle }}</div>
            <div class="rule-setup-body-details-box">
                <div>
                    <div class="rule-setup-body-details-box-key">Client Name: </div>
                    <div class="rule-setup-body-details-box-value">{{ profile.name }}</div>
                </div>
                <div>
                    <div class="rule-setup-body-details-box-key">Client Email: </div>
                    <div class="rule-setup-body-details-box-value">{{ profile.email }}</div>
                </div>
                <div>
                    <div class="rule-setup-body-details-box-key">Agent: </div>
                    <div class="rule-setup-body-details-box-value">{{ bm.name }}</div>
                </div>
                <div v-if="currentActiveRule">
                    <div class="rule-setup-body-details-box-key">Current access: </div>
                    <div class="rule-setup-body-details-box-value">{{ currentActiveRule.rule.unlock_time_units }} {{ currentActiveRule.rule.unlock_time_period }} access to {{ currentAccessNumberOfWidgets }} from {{ formatDate(currentActiveRule.unlocked_at )}} until {{ formatDate(currentActiveRule.locked_at) }}<br/><span class="cancellation-warning">(This will be cancelled with the new access)</span></div>
                </div>
            </div>

            <div class="rule-setup-body-divided" style="margin: -15px 0">
                <div class="narrow-div">
                    <base-input
                    elementType="select"
                    :class="{invalid: error.unlock_time_units}"
                    v-model="rule.unlock_time_units"
                    forID="unlock_time_units"
                    :inputClass="'small-font partner-input'"
                    formControlClass=""
                    label="Length"
                    labelClass="small-font u-input-color"
                    :invalid="error.unlock_time_units"
                    :invalidMessage="error.unlock_time_units"
                    @clear-validity="clearValidity"
                    @update="error.unlock_time_units = ''"
                    >
                        <option :value="0" selected disabled hidden>0</option>
                        <option v-for="unit in lenghtArray" 
                        :key="unit"
                        :value="unit">{{unit}}</option>
                    </base-input>
                </div>
                <div class="narrow-div-other" style="margin-left: 25px;">
                    <base-input
                    elementType="select"
                    :class="{invalid: error.unlock_time_period}"
                    v-model="rule.unlock_time_period"
                    forID="unlock_time_units"
                    :inputClass="'small-font partner-input'"
                    formControlClass=""
                    label="Days/weeks/months"
                    labelClass="small-font u-input-color"
                    :invalid="error.unlock_time_period"
                    :invalidMessage="error.unlock_time_period"
                    @clear-validity="clearValidity"
                    @change="updatePeriod"
                    >
                        <option :value="0" selected disabled hidden>Select...</option>
                        <option value="day">Day(s)</option>
                        <option value="week">Week(s)</option>
                        <option value="month">Month(s)</option>
                    </base-input>
                </div>
            </div>

            <div class="rule-setup-body-divided" style="margin: -15px 0;">
                <div class="narrow-div">
                    <base-input
                    elementType="select"
                    :class="{invalid: error.currency}"
                    v-model="rule.currency"
                    forID="unlock_time_units"
                    :inputClass="'small-font partner-input'"
                    formControlClass=""
                    label="Currency"
                    labelClass="small-font u-input-color"
                    :invalid="error.currency"
                    :invalidMessage="error.currency"
                    @clear-validity="clearValidity"
                    @update="error.currency = ''"
                    >
                        <option :value="0" selected disabled hidden>$/£/€</option>
                        <option v-for="unit in currencies" 
                        :key="unit"
                        :value="unit">{{unit}}</option>
                    </base-input>
                </div>
                <div class="narrow-div-other" style="margin-left: 25px;">
                    <base-input
                    elementType="input"
                    :class="{invalid: error.deposit_amount}"
                    inputType="number"
                    v-model="rule.deposit_amount"
                    forID="deposit_amount"
                    :placeholder="'To track average client deposit size'"
                    inputClass="partner-input"
                    formControlClass=""
                    label="Deposit Amount"
                    labelClass="small-font u-input-color"
                    :invalid="error.deposit_amount"
                    :invalidMessage="error.deposit_amount"
                    @clear-validity="clearValidity"
                    ></base-input>
                </div>
                <div class="narrow-div-other" style="margin-left: 25px;">
                    <base-input
                    elementType="input"
                    :class="{invalid: error.account_balance}"
                    inputType="number"
                    v-model="rule.account_balance"
                    forID="account_balance"
                    :placeholder="'To track average client account size'"
                    inputClass="partner-input"
                    formControlClass=""
                    label="Current trading account balance"
                    labelClass="small-font u-input-color"
                    :invalid="error.account_balance"
                    :invalidMessage="error.account_balance"
                    @clear-validity="clearValidity"
                    ></base-input>
                </div>
            </div>

            <div class="rule-setup dot-checkbox label-white" style="margin-top: 30px;">
                <label>
                    <input type="checkbox" v-model="qna"/>
                    <span>Access to Q&A</span>
                </label>
            </div>

            <!-- <div 
            class="rule-setup-body-item-selector">
                <h3 >Included widgets</h3>
                <p :class="{'u-color-12': error.widget}" v-if="!hasSelectedWidgets">Your setup doesn't include any widgets.<br />
                These are the tools that your clients will have access to and engage with on the analyst-desk.<br />
                Upon approval, the client will have access to the specific widgets that you include here.</p>
                <div 
                v-if="hasSelectedWidgets && !widgetSelectorOpen"
                class="selected-widgets">
                    <a 
                    v-for="widget in selectedWidgets"
                    :key="widget.implementation_id"
                    class="widget-tags"
                    href="javascript:void(0);"
                    >{{ widget.name }}</a>
                </div>
                <vue-slide-up-down :active="widgetSelectorOpen" :duration="250" class="vue-slide-up-down rule-setup-body-item-selector-action">
                    <div>
                        <h4>Widgets</h4>
                        <p style="margin: 0 0 7px;">Selected widgets</p>
                        <div class="selected-widgets">
                            <a 
                            v-for="widget in selectedWidgets"
                            :key="widget.implementation_id"
                            class="widget-tags"
                            href="javascript:void(0);"
                            @click="addWidget('suggested', [widget.implementation_id])">{{ widget.name }} <font-awesome-icon
                        :icon="['fas', 'minus']" style="margin-left=5px;"/></a>
                        </div>
                        <h4 style="font-size: 12px; margin: 7px 0;">Suggested widgets</h4>
                        <div class="suggested-widgets">
                            <a 
                            v-for="widget in suggestedWidgets"
                            :key="widget.implementation_id"
                            class="widget-tags"
                            href="javascript:void(0);"
                            @click="addWidget('selected', [widget.implementation_id])">{{ widget.name }} <font-awesome-icon
                        :icon="['fas', 'plus']" style="margin-left=5px;"/></a>
                        </div>
                    </div>
                </vue-slide-up-down>
                <div v-if="widgetSelectorOpen" 
                class="widget-buttons" style="margin-top:15px;">
                    <base-button
                    mode="o-tranparent-grey o-white-text o-semibolt-text o-height-30"
                    :link="false"
                    @click.native="cancelWidgetSelection"
                    >Cancel
                    </base-button>
                    <base-button
                    style="margin-left: 15px;"
                    mode="o-tranparent-grey o-white-text o-semibolt-text o-height-30"
                    :link="false"
                    @click.native="saveWidgetSelection"
                    >Save
                    </base-button>
                </div>
                <div v-else
                class="widget-buttons" style="margin-top:15px;">
                        <base-button
                        mode="o-tranparent-grey o-white-text o-semibolt-text o-height-30"
                        :link="false"
                        @click.native="addAllWidgets"
                        >Add all
                        </base-button>
                        <base-button
                        style="margin-left: 15px;"
                        mode="o-tranparent-grey o-white-text o-semibolt-text o-height-30"
                        :link="false"
                        @click.native="widgetSelectorOpen = true"
                        >Add individually
                        </base-button>
                        <base-button
                        v-if="hasSelectedWidgets"
                        style="margin-left: 15px;"
                        mode="o-tranparent-red o-red-text o-semibolt-text o-height-30"
                        :link="false"
                        @click.native="removeAllWidgets"
                        >Remove All
                        </base-button>
                    </div>
            </div> -->

            <div style="margin-top: 20px;" class="error">{{ serverError }}</div>

            <div style="margin-top: 5px; margin-bottom: 15px;">
                <base-button
                mode="o-blue o-height-30"
                :link="false"
                :disabled="isProcessing"
                :showSpinner="isProcessing"
                @click.native="submit"
                >{{ isApproved ? "Update Access" : "Create Access" }}
                </base-button>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '../../../../main';
import _ from "lodash";
import { talkToServer } from '../../../../utility/helper-functions';
import lockUserMixin from '../../../../mixins/lockUserMixin';
import { usersTime } from '../../../../utility/datetime';

const payments = createNamespacedHelpers('payments');
const rules = createNamespacedHelpers('rules');
const general = createNamespacedHelpers('general');

const CONDITIONS = [
    {test: (model) => model.unlock_time_units > 0, message: 'Set lenght', key: "unlock_time_units"},
    {test: (model) => model.unlock_time_period, message: 'Set days/weeks/ months', key: "unlock_time_period"},
    {test: (model) => model.currency, message: 'Set unit', key: "currency"},
    {test: (model) => model.deposit_amount > -1, message: 'Deposit must be 0 or over', key: "deposit_amount"},
    {test: (model) => model.account_balance > -1, message: 'Balance must be 0 or over', key: "account_balance"},
    
];

export default {
    props: ["modal"],
    mixins: [lockUserMixin],
    created() {
        this.suggestedWidgets = [...this.widgetsToSelectFromForHomeScreen];
        
        this.oldSuggestedWidgets = [...this.suggestedWidgets];
        this.oldSelectedWidgets = [...this.selectedWidgets];
        if(this.modal.id) {
            const rule = _.cloneDeep(this.getUserByRuleID(this.modal.id));
            this.profile = rule.user;
            this.rule = rule.rule;
            this.rule.unlock_time_period = 0;
            this.rule.unlock_time_units = 0;
            this.rule.currency = 0;
            
            if(rule.user.qanda_requested)
                this.qna = true;
            //TEMP
            // this.addWidget('selected', rule.widgets);
            //UNDO AFTER TEMP
            this.addWidget('selected', this.widgetsToSelectFromForHomeScreen.map(el => el.implementation_id));


            this.oldSuggestedWidgets = [...this.suggestedWidgets];
            this.oldSelectedWidgets = [...this.selectedWidgets];

            if(this.selectedWidgets.length)
                this.hasSelectedWidgets = true;
        }

        if(!this.profile.account_manager_id)
            this.profile.account_manager_id = this.bms.find(el => el.id === this.profile.account_manager_id)?.id || window.loggedInUserId;

        this.timeLeft = 600;

        const currentActiveRule = this.getAllIndRuleUsers.find(el => el.rule.rule_status === 'approved' && el.user.id === this.profile.id);
        if(currentActiveRule)
            this.currentActiveRule = currentActiveRule;


    },
    computed: {
        ...payments.mapGetters(["widgetsToSelectFromForHomeScreen"]),
        ...rules.mapGetters(['bms', 'getUserByRuleID', 'getAllIndRuleUsers']),
        ...general.mapGetters(['user', 'entityQnaCustom']),
        selectedWidgetImpIDs() {
            return this.selectedWidgets.map(el => el.implementation_id);
        },
        lengthType() {
            return this.rule.unlock_time_period;
        },
        lenghtArray() {
            if(this.lengthType === 'day')
                return 30;
            else if(this.lengthType === 'week')
                return 4;
            else
                return 12;
        },
        isApproved() {
            return this.rule.rule_status === 'approved';
        },
        isRequested() {
            return this.rule.rule_status === 'requested';
        },
        isArchived() {
            return this.rule.rule_status === 'archived';
        },
        title() {
            if(this.isApproved)
                return "Edit existing access";
            else if(this.isRequested)
                return "Create client access";
            else if(this.isArchived)
                return "Edit archived request";
            else 
                return "Create new access";
        },
        subtitle() {
            if(this.isApproved)
                return "Client access will restart by updating access details. To maintain original timing, adjust access length";
            else if(this.isRequested)
                return "Client access will begin immediately and they will receive log in details at the email address below."
            else 
                return "";
        },
        bm() {
            return this.bms.find(el => el.id === this.profile.account_manager_id || window.loggegIdUserId) || { name: "N/A"};
        },
        listUserForLockUserEditing() {
            return this.profile;
        },
        currentAccessNumberOfWidgets() {
            if(!this.currentActiveRule)
                return "";

        const qnaProp = "qanda_enabled";
        const qnaValue = this.entityQnaCustom ? this.currentActiveRule.user[qnaProp] : false;
        const qnaText = qnaValue ? " + Q&A" : ""
        const ruleWidgetsLength = this.currentActiveRule.widgets.length;
        if(ruleWidgetsLength === this.widgetsToSelectFromForHomeScreen.length)
            return "all widgets" + qnaText;

        return ruleWidgetsLength + " Widget" + (ruleWidgetsLength > 1 ? "s" : "") + qnaText;
 
        },

    },
    data() {
        return {
            profile: {
            },
            rule: {},
            error: {},
            currencies: ["USD", "GBP", "EUR", "CHF", "JPY", "CAD", "AUD", "NZD"],
            selectedWidgets: [],
            suggestedWidgets: [],
            widgetSelectorOpen: false,
            oldSuggestedWidgets: [],
            oldSelectedWidgets: [],
            hasSelectedWidgets: false,
            serverError: "",
            isProcessing: false,
            timeLeft: 0,
            qna: false,
            currentActiveRule: null
        }
    },
    methods: {
        ...rules.mapActions(["updateUser"]),

        clearValidity(name) {
            delete this.error[name];
        },
        validateData() {
        this.error = {};
        this.serverError = ""
            CONDITIONS.forEach((condition) => {
                if (!condition.test(this.rule)) {
                    this.error[condition.key] = condition.message;
                }
            });
            if(!this.selectedWidgets.length)
                this.error.widget = true;

                // console.log(this.error)
        },
        async submit() {
            this.validateData();

            if(!_.isEmpty(this.error))
                return;

            if(this.currentActiveRule) {
                const rule = _.cloneDeep(this.currentActiveRule);
                rule.rule.rule_status = "cancelled";

                try {
                    const response = talkToServer("/b/rules/" + rule.id, "PUT", null, rule.rule, true);

                    if(!response.success && response.error) {
                        this.serverError = "Something went wrong, please try again later";
                    }
                
                }
                catch(e) {
                    console.log(e);
                    this.serverError = "Something went wrong, please try again later"
                }
            }
            
            if(this.serverError)
                return;

            this.isProcessing = true;

            const rule = {
                ...this.rule,
                unlock_time_units: +this.rule.unlock_time_units,
                start_from: "login",
                rule_status: "approved",
                deposit_amount: +this.rule.deposit_amount,
                account_balance: +this.rule.account_balance,
                qanda_enabled: this.qna,
                application_mode: "quantum"
            }

            this.profile = {
                ...this.profile,
                request_status: "approved",
                qanda_enabled: this.qna
            }

            try {
                const ruleCall = talkToServer("/b/rules/" + this.rule.id, "PUT", null, rule, true);
                const ruleWidgetCall = talkToServer("/b/rules-widget/" + this.rule.id, "PUT", null, {
                    widget_implementation_ids: this.selectedWidgetImpIDs
                }, true);
                const userUpdate = talkToServer("/b/user/" + this.profile.id, "PUT", null, this.profile, true);
                // console.log({
                //     widget_implementation_ids: this.selectedWidgetImpIDs
                // });
                return Promise.allSettled([ruleCall, ruleWidgetCall, userUpdate]).then(resp => {
                    const fail = resp.map(el => el.status).find(el => el === 'rejected');
                    this.isProcessing = false;
                    if(!fail) {
                        // const ruleWidgets = this.selectedWidgetImpIDs.map(el => {
                        //     return {
                        //         rule_id: rule.id, 
                        //         widget_implementation_id: el
                        //     }
                        // });

                        // this.updateRuleSet({
                        //     rule: rule,
                        //     ruleWidgets: ruleWidgets
                        // });

                        this.updateUser(this.profile);

                        Swal.fire({
                        title: 'Success',
                        type: 'info',
                        text: 'Client access is now active and we have sent their log in information to ' + this.profile.email,
                        allowOutsideClick: false,
                        showCancelButton: false,
                        confirmButtonColor: '#3B9C83',
                        confirmButtonText: 'OK'
                    }).then(() => {
                        eventBus.$emit("close-modal");
                    });

                    }else{
                        Swal.fire({
                        title: 'Error',
                        type: 'warning',
                        text: 'Some of the settings were not saved. Please refresh the terminal to check the user again',
                        allowOutsideClick: false,
                        showCancelButton: false,
                        confirmButtonColor: '#3B9C83',
                        confirmButtonText: 'OK'
                    }).then(() => {
                    });
                    }
                });
                
            }
            catch(e) {
                console.log(e);
                this.serverError = e.statusText;
            }

            

        },

        addWidget(group, impIDs) {
            const toAdd = this.widgetsToSelectFromForHomeScreen.filter(el => impIDs.includes(el.implementation_id))

            if(!toAdd.length)
                return;

            if(group === 'selected') {
                this.selectedWidgets.push(...toAdd);
                this.suggestedWidgets = this.suggestedWidgets.filter(el => !impIDs.includes(el.implementation_id));
            }
            else{
                this.suggestedWidgets.push(...toAdd);
                this.selectedWidgets = this.selectedWidgets.filter(el => !impIDs.includes(el.implementation_id))
            }

        },
        addAllWidgets() {
            this.widgetSelectorOpen = true;
            setTimeout(() => {
                this.suggestedWidgets = [];
                this.selectedWidgets = [...this.widgetsToSelectFromForHomeScreen];
            }, 750);
        },
        removeAllWidgets() {
            this.widgetSelectorOpen = true;
            setTimeout(() => {
                this.selectedWidgets = [];
                this.suggestedWidgets = [...this.widgetsToSelectFromForHomeScreen];
            }, 750);
        },
        cancelWidgetSelection() {
            this.selectedWidgets = [...this.oldSelectedWidgets];
            this.suggestedWidgets = [...this.oldSuggestedWidgets];
            setTimeout(() => {
                this.widgetSelectorOpen = false;
            }, 500);
        },
        saveWidgetSelection() {
            this.widgetSelectorOpen = false;
            this.oldSelectedWidgets = [...this.selectedWidgets];
            this.oldSuggestedWidgets = [...this.suggestedWidgets];
         
        },
        updatePeriod(event) {
            this.error.unlock_time_period = '';
            const value = event.target.value;
            const units = this.rule.unlock_time_units
            if(value === 'week' && units > 4)
                this.rule.unlock_time_units = 0;
            if(value === 'month' && units > 12)
                this.rule.unlock_time_units = 0;

        },
        formatDate(date) {
            return usersTime(date, true, false, false, {
                dateFormat: "d M Y",
            }, false, this.user.timezone);
        },

    },
    watch: {
        widgetSelectorOpen() {
            if(this.selectedWidgets.length) {
                setTimeout(() =>  this.hasSelectedWidgets = true, 500);
            }
            else
                this.hasSelectedWidgets = false;
        },
    }
}
</script>

<style lang="scss">
    .rule-setup {
        letter-spacing: 0.1px;
        color: $input-color;

        & .rule-setup-header {
            display: flex;
            align-items: center;
            margin-top: 25px;
            & h2 {
                font-size: 20px;
                font-weight: 500;
                
                margin: 0;
            }
            & .cntdown {
                font-size: 12px;
                margin-left: 20px;
                color: $core-lightred;
            }
            color: $input-color;
        }

        & .cancellation-warning {
             color: $core-lightred;
        }

        & .rule-setup-body-details-box {
            margin-bottom: 20px;
            font-size: 12px;
            & > div {
                display: flex;
            }

            & .rule-setup-body-details-box-key {
                
                color: $partner-dimmed-text-1;
                text-transform: uppercase;
                flex-basis: 130px;
                letter-spacing: 0.5px;
            }
            & .rule-setup-body-details-box-value {
                
            }
        }

        & .rule-setup-body {
            position: relative;

            & .rule-setup-body-subtitle {
                color: $partner-dimmed-text-1;
                font-size: 12px;
                margin: 15px 0;
            }

            & .rule-setup-body-divided {
                display: flex;

                & .narrow-div {
                    flex-basis: 100px;
                    min-width: 100px;
                }
                & .narrow-div-other {
                    flex-basis: 100%;
                }
            }

            & .rule-setup-body-rule-selector {
                display: flex;
                align-items: center;

                & span {
                    font-size: 13px;
                    color: $input-color;
                    padding: 0 3px;

                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                }

                & select, 
                & input {
                    height: 25px;
                    border: 1px solid $partner-border-2;
                    background-color: $partner-background-2;
                    font-size: 13px;
                    border-radius: 4px;
                    max-width: 100px;
                    color: $checkbox-active;
                    padding: 2px 0px;

                    &.invalid {
                        color: $partner-red;
                    }
                }
                & input {
                    padding-left: 4px;
                }
            }

            & .rule-setup-body-user-selector,
            & .rule-setup-body-item-selector {
                border: 1px solid $partner-border-3;
                border-radius: 20px;
                padding: 10px;
                color: $input-color;
                background-color: $onboard-survey-background;
            }

            & .rule-setup-body-user-selector {
                position: absolute;
                left: 20px;
                top: 35px;
                z-index: 1000;

                &.name {
                    top: -100px;
                }

                &.email {
                    top: -14px
                }
                & .rule-setup-body-user-selector-search-results {
                    height: 250px;
                    width: 300px;
                    overflow-y: auto;

                    @include scrollbarDark;

                    & > div a {
                        height: 28px;
                        width: 100%;
                        display: inline-block;
                        font-size: 12px;
                        padding: 5px 10px;
                        text-decoration: none;
                        color: $input-color;

                        &:hover {
                            background-color: $partner-background-4;
                        }
                    }
                }
            }

            & .rule-setup-body-item-selector {
                margin-top: 20px;
                font-size: 12px;
                color: $partner-dimmed-text-1;

                & h4 {
                    font-size: 14px;
                    margin-bottom: 0;
                    margin-top: 0;
                    font-weight: 400;
                }

                & h3 {
                    margin-top: 5px;
                    font-weight: 500;
                    color: $input-color;
                }

                & p {
                    font-weight: 400;
                    
                }

                & .rule-setup-body-item-selector-action {
                    padding: 10px 15px;
                    background-color: $partner-background-3;
                    border-radius: 4px;
                    height: 35vh;
                    overflow-y: auto;

                    @include scrollbarDark;

                    & .selected-widgets,
                    & .suggested-widgets {
                        background-color: #252525;

                    }
                    & .selected-widgets .widget-tags {
                        background-color: $partner-background-6;
                    }

                }

                & .selected-widgets,
                    & .suggested-widgets {
                        min-height: 48px;
                        padding: 8px;

                        & .widget-tags {
                            color: $input-color;
                            background-color: $partner-background-4;
                            padding: 2px 10px;
                            border-radius: 2px;
                            margin-left: 8px;
                            margin-bottom: 8px;
                            display: inline-block;
                            text-decoration: none;
                        }
                    }

            }
        }
    }
</style>