<template>
    <div>
  <div v-if="maintenance" class="maintenance-section">
          <div>
              <div>
                  <font-awesome-icon :icon="['fas', 'tools']"/>
              </div>
              <div>
                  Our scanner is currently down due to Twitters API closure. We're working to fix it.
              </div>
          </div>
      </div>
		<!-- <div
		v-if="!boards.length"
		class="window social-scanner"
    >
            <div class="no-more-items"
			style="grid-column: 1/-1;"
			><font-awesome-icon :icon="['fas', 'exclamation-triangle']" /> Sorry... this widget has not yet been set up by the admin.</div>
		</div>
    <div
		v-else
		class="window social-scanner"
    ref="socialScanner"
		>
            <div
            v-if="columns.length"
            class="dashboard-row-scrollwrapper scrollable" :id="'scrollable-'+details.layoutID">
                <div class="dashboard-top-row"
                     :style="verticalHeight"
                     :id="'scroller-'+details.layoutID">
                    <social-column
                            :width="colWidth"
                            v-for="(column, index) in columns"
                            :key="column.id"
                            :colIndex="index"
                            :tweetsReady="tweetsReady"
                            :column="column"
                            :impID="details.implementation_id"
                            @auto-scroll-to="autoScrollTo"
                            :autoScrollOn="details.autoScrollOn"
                            :layoutID="details.layoutID"
                            :isLocked="isLocked"
                    ></social-column>
                </div>
            </div>
            <div
            style="grid-column: 1/-1;"
            v-else>
              <div class="no-more-items"><font-awesome-icon :icon="['fas', 'exclamation-triangle']" /> This board has no channels</div>
            </div>
        </div> -->
    </div>
</template>

<script>
import {eventBus} from '../../main';
// import SocialColumn from './components/SocialScanner/SocialColumn';
import {createNamespacedHelpers} from 'vuex';
import {scroller} from 'vue-scrollto/src/scrollTo';
import { sortDataBy } from '../../utility/helper-functions';
import ContentLockedMixin from '../../mixins/contentLockedMixin';

const tweetNS = createNamespacedHelpers('tweetdeck');
const dashboards = createNamespacedHelpers('dashboard');


export default {
    props: {
        fullscreen: Boolean,
        details: Object,
    },
    mixins: [ContentLockedMixin],
    // components: {
    //     SocialColumn
    // },
    async created() {
        eventBus.$on("widget-resize", obj => {
            if (obj.id === this.details.layoutID) {
                let adj = 120;

                if(this.fullscreenId)
                    adj = 120;
              
                this.verticalHeight = {height: obj.height - adj + 'px'};

                setTimeout(() => this.calculateColWidth(), 100);

            }

        });

        this.currentBoard = +this.details.userSettings.defaultboard;

        await this.getBoards();
        await this.getChannels();
        this.getTweetsFromServer();

    },
    mounted() {
      setTimeout(() => this.calculateColWidth(), 100);
      
    },
    computed: {
        ...tweetNS.mapGetters(["haveBoards", "haveChannels", "tweetsFetchedForBoards", "lastBoardUpdate", "lastChannelUpdate"]),
        ...dashboards.mapState(['fullscreenId']),
        activateScroller: function () {
            return !this.fullscreen;
        },
    },
    data() {
        return {
            timeout: null,
            verticalHeight: '',
            // defaultBoard: null,
            currentBoard: null,
            columns: [],
            tweetsReady: false,
            colWidth: 300,
            boards: [],
            maintenance: true
        }
    },
    methods: {
        async getBoards() {
            let response = await window.bootUp.iDB.getSocialBoards();

            if(!response.length) {
              response = await window.bootUp.iDB.getBulkTweetData();
            }
            this.boards = response.filter(board => board.published && !board.deleted_at);
                  const options = {};
                  options.boards = this.boards
            options.current = this.currentBoard;

            if(this.boards.length === 1 || !this.currentBoard) {
              options.current = this.boards[0].id;
              this.currentBoard = this.boards[0].id;
            }

            // console.log("this.getBoards", this.currentBoard);


            const obj = {
                menu: {
                    isAutoScrollEnabled: true,
                    isDropdownEnabled: true,
                    options: options
                }

            }
            this.$emit('set-frame', obj);
            return;
        },
        getChannels() {
			// console.log("getchannel started");
            window.bootUp.iDB.getSocialChannels(this.currentBoard).then((response) => {
                sortDataBy(response, "order_by", "orderbyASC");
                this.columns = response;
				return;
            });
        },
        getTweetsFromServer() {
          this.tweetsReady = false;
            const fetchFromServer = !this.$store.getters["tweetdeck/tweetsFetchedBoard"](this.currentBoard);


            window.bootUp.iDB.getTweetsByBoard(this.currentBoard, fetchFromServer).then(() => {
                this.$store.dispatch("tweetdeck/addFetchedBoard", this.currentBoard);
                this.tweetsReady = true;
            });
        },
        autoScrollTo(x) {
            // console.log("scroll", x);
            const firstScrollTo = scroller();
            firstScrollTo('#scroller-' + this.details.layoutID, 250, {
                container: '#scrollable-' + this.details.layoutID,
                easing: 'ease-in',
                lazy: false,
                offset: x,
                force: true,
                y: true,
                x: true
            });
        },
        calculateColWidth() {
          if(!this.$refs.socialScanner)
            return;
          const width = this.$refs.socialScanner?.offsetWidth;
          const cols = this.columns.length;

          // console.log("width", width);


          if(this.details.panels === 2 || this.details.panels === 4) {
            this.colWidth = width / 2;
            if(this.colWidth > 400)
              this.colWidth = width / 3;
          }
            
          else if(this.details.panels === 3) {
            this.colWidth = width / 2;
            
            if(this.colWidth > 950)
              this.colWidth = this.colWidth = width / 3;
            else if(this.colWidth < 260)
              this.colWidth = width;
          }
            
          else if(this.details.panels === 1 &&
          width / cols < 153 && width / cols > 101) {
            this.colWidth = width / 2;
          }
          else if(this.details.panels === 1 &&
          width / cols < 100) {
            this.colWidth = width;
          }
          else
            this.colWidth = 300;

        
        }
    },
    watch: {
        haveBoards() {
            this.getBoards();
        },
        haveChannels() {
            this.getChannels();
        },
        details: {
          async handler(value) {
            if (value.boardID) {
              this.currentBoard = value.boardID;
              await this.getChannels();
              this.getTweetsFromServer();
            }


          },
          deep: true

        },
        async lastBoardUpdate(nVal) {

			if(!this.boards.length &&
			nVal.published &&
			!nVal.deleted_at) {
				this.currentBoard = nVal.id;
			}else if(nVal.deleted_at || !nVal.published) {
				const found = this.boards.find(el => el.id !== nVal.id);
				if(found)
					this.currentBoard = found.id;
			}

			console.log("watcher", this.currentBoard);

			await this.getBoards();
			await this.getChannels();
			this.getTweetsFromServer();

        },
        lastChannelUpdate() {
          this.getChannels();
        }
    }
}
</script>

<style lang="scss">


.social-scanner {
  

  font-size: 16px;
  color: $input-color;

  .dashboard-column {
    grid-template-rows: 30px 1fr;
    // height: 100%;
    // width: 100%;
    display: grid;
    // background-color: #212b32;
    padding: 0 5px;
  }

  .dashboard-column--title {
    grid-column: 1/-1;
    font-weight: 700;
    
    @include H7;
    color: $input-color-filter;
    // text-transform: uppercase;
    // color: #182025;
    position: relative;
    // padding: 0 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & a {
      font-size: 15px;
    }
  }

  // .dashboard-column--title.twitter-style {
  //   background: -webkit-gradient(linear, left top, right top, from(#253138), color-stop(#1d9deb), to(#253138));
  //   background: linear-gradient(90deg, #253138, #1d9deb, #253138);
  //   padding: 0 0 3px;
  // }

  .dashboard-column--content {

    height: 100%;
    width: 100%;
    padding: 5px 3px 0;
    overflow-y: auto;
    position: relative;
  }

  .popout-tweetdeck-header {
    grid-column: 1/-1;
    margin-bottom: 25px;
    font-size: 28px;

    @include respond(sm-desktop) {
      font-size: 23px;
    }

    &-default {
      color: $color-06;
      letter-spacing: 1.2px;
      text-transform: uppercase;

      & .fab.fa-twitter {
        color: $checkbox-active;
      }
    }
  }

  a svg {
    color: $color-20;
  }

  .dashboard-top-row {
    height: calc(100vh - 300px);
    white-space: nowrap;
    display: flex;

  }

  .dashboard-column {
    width: 300px;
    margin: 0;
    flex: none;
    border: 2px solid $separator-line-color;
    position: relative;

    // @include respond(sm-desktop) {
    //     width: 215px;
    // }


    &--title {

      display: inline-flex;

      &__inner {
        width: 100%;
        // background-color: $color-02;
        padding: 4px 6px;
        line-height: 28px;
        text-align: left;
        display: flex;
        justify-content: space-between;

      }

    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &--content {
      padding: 5px 4px;
      box-sizing: border-box;

      // @include respond(sm-desktop) {
      //     font-size: 14px;
      // }
    }

  }

  .tweetdeck-tweet {
    display: grid;
    grid-template-rows: 60px 1fr 30px;
    border-radius: 3px;
    padding: 5px 10px 10px 10px;
    margin-bottom: 8px;
    background-color: $onboard-survey-background;
    overflow: hidden;
    white-space: normal;

    transition: background-color 0.6s ease 0.6s;

    a {
      color: $checkbox-active;
      font-size: 13px;
      font-weight: 500;

      // @include respond(sm-desktop) {
      //     font-size: 9px;
      // }
    }

    &--header {
      display: grid;
      grid-template-columns: 45px 1fr 50px;

      &__image {
        position: relative;

        & img {
          width: 40px;
          border-radius: 20px;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }

      }

      &__name {
        align-self: center;

        &-1 {
          // font-weight: bold;
          font-size: 14px;
        }

        &-2 {
          color: $input-color-filter;
          font-size: 12px;

          @include respond(sm-desktop) {
            font-size: 11px;
          }
        }
      }

      &__actions {
        justify-self: center;
        align-self: center;
        font-size: 22px;
        color: $checkbox-active;

        @include respond(sm-desktop) {
          font-size: 18px;
        }
      }

      i {
        color: $checkbox-active;
      }
    }

    &--body {
      margin-top: 10px;
      font-weight: 200;
      position: relative;


    }

    &--footer {
      margin-top: 20px;
      align-self: center;
      font-weight: 300;
      color: $input-color-filter;
      display: flex;
      justify-content: space-between;
      font-size: 13px;

      @include respond(sm-desktop) {
        font-size: 9px;
      }

    }

    .twitter-highlight {
      color: $checkbox-active;
      font-weight: normal;
    }

    img.photo-classes,
    img.animated-gif-classes {
      width: 100%;
      height: 150px;
      border-radius: 20px;
      object-fit: cover;
    }

    iframe, video {
      margin-top: 10px;
      border-radius: 20px;
      height: 150px;

    }

    img.photo-classes {
      cursor: pointer;
    }

    .retweet-frame {
      margin: 10px 5px 0 5px;
      border: 1px solid $color-04;
      padding: 10px;
      border-radius: 20px;

    }

  }

  & .scrollable,
  & .scrolly,
  .video-preview-main {

    @include scrollbarDark;
  }

}

.dashboard-row-scrollwrapper {
  grid-column: 1 / -1;

  margin-top: 10px;

  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
}

.dashboard-row-scrollwrapper-fullscreen {
  grid-column: 1 / -1;

  margin-top: 10px;

  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
}

.verified-badge {
  color: $checkbox-active;
}


</style>
