<template>
	<div style="height: 100%;">
		<div class="pdf-wrapper">
			<vue-pdf-app
			class="pdf-element"
			:config="config"
			:pdf="panel.url"
			@after-created="afterCreatedHandler"
			@open="openHandler"
			@pages-rendered="pagesRenderedHandler"
			></vue-pdf-app>
		</div>
	</div>
</template>

<script>
// VuePdfApp component is registered in src/main.js

export default {
	// name: "PdfjsInteraction",
	props: ["panel"],
	data() {
		return {
			info: [],
			config: {
				toolbar: {
					toolbarViewerLeft: {
						findbar: false,
						previous: false,
						next: false,
						pageNumber: false,
					},
					toolbarViewerRight: {
						presentationMode: true,
						openFile: false,
						print: false,
						download: true,
						viewBookmark: false,
					},
					toolbarViewerMiddle: {
						zoomOut: false,
						zoomIn: false,
						scaleSelectContainer: false,
					},
				},
				sidebar: false,
				secondaryToolbar: false

			}
		};
	},
	methods: {
		afterCreatedHandler(pdfApp) {
			// to prevent browser tab title changing to pdf document name
			pdfApp.isViewerEmbedded = true;
		},
		async openHandler(pdfApp) {
			this.info = [];
			const info = await pdfApp.pdfDocument
			.getMetadata()
			.catch(console.error.bind(console));

			if (!info) return;
			const props = Object.keys(info.info);
			props.forEach((prop) => {
				const obj = {
					name: prop,
					value: info.info[prop],
				};
				this.info.push(obj);
			});
		},
		pagesRenderedHandler(pdfApp) {
			setTimeout(() => (pdfApp.pdfViewer.currentScaleValue = "page-fit"));
		},
	},
};
</script>

<style lang="scss">
.pdf-wrapper {
	margin-top: 20px;
	height: calc(85vh - 100px);
}

#toolbarViewer {
	background-color: $onboard-survey-background;
}

#viewerContainer {
	@include scrollbarDark;
	background-color: $onboard-survey-card-background;
}



/* #info {
text-align: center;
flex: 4 0 10px;
align-self: center;
display: none;
} */

input.toolbarField.pageNumber {
	height: auto;
}
</style>