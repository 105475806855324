<template>
    <div class="setup-card">
        <div v-if="!card.current && !card.completed" class="setup-card-overlay"></div>
        <div class="setup-card-inner">
            <div class="setup-card-header">
                
                <div class="setup-card-header-top">
                    <div class="setup-card-header-top-left">
                        <div class="setup-card-header-top-left-step"
                        :class="{'completed': card.completed}">
                            Step {{ card.step }} <font-awesome-icon v-if="card.completed" :icon="['fas', 'check']" /> <font-awesome-icon v-else :icon="['fa', 'times']" />
                        </div>
                        <div class="setup-card-header-top-left-title">
                            {{ card.title }}
                        </div>
                    </div>
                    <div class="setup-card-header-top-right">
                        <base-button
                        mode="setup-card-button-style"
                        :link="false"
                        @click.native="cardAction"
                        >{{ card.buttonText }}
                        </base-button>
                    </div>
                </div>
                <div class="setup-card-header-bottom">
                    {{ card.description }}
                </div>

            </div>
            <div 
            v-if="card.dynamicComponent"
            class="setup-card-main">
                <component
                :is="card.dynamicComponent"
                :card="card"
                >
                </component>
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '../../../../main';
import PartnerDetails from "./PartnerDetails.vue";

export default {
    props: ["card"],
    components: {
        PartnerDetails
    },
    methods: {
        cardAction() {
            eventBus.$emit('open-side-modal', this.card.modal);
        }
    }
}
</script>

