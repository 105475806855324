<template>
        <div class="onboard-backdrop"
        :class="[mode, checkoutLoginMode ? 'check-out' : '']"
        id="scroll-container"
        v-if="!autoLogin"
        >
            <div v-if="!checkoutLoginMode" class="onboard-backdrop-svgs">
                <svg id="vector-1" width="857" height="291" viewBox="0 0 857 291" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_f_123_22661)">
                <path d="M232.055 201.924C90.2482 211.253 -2.3039 263.598 -92.3264 385.384C-274.695 632.101 790.814 710.475 642.423 439.429C548.86 268.529 413.187 190.007 232.055 201.924Z" fill="#5B5063"/>
                </g>
                <defs>
                <filter id="filter0_f_123_22661" x="-313.397" y="0.746338" width="1170.05" height="808.951" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_123_22661"/>
                </filter>
                </defs>
                </svg>
                <svg id="vector-2" width="1155" height="900" viewBox="0 0 1155 900" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_f_123_22659)">
                <path d="M818.313 809.96C909.583 742.528 935.384 676.53 1000.55 583.631C1126.36 404.305 1125.61 258.926 1261.45 87.0817C1538.63 -263.555 1373.84 1074.61 940.926 1185.8C668.785 1255.7 0.792646 1072.8 257.94 959.564C360.636 914.342 433.792 946.941 542.311 918.393C654.306 888.931 725.171 878.775 818.313 809.96Z" fill="#775F74" fill-opacity="0.5"/>
                </g>
                <defs>
                <filter id="filter0_f_123_22659" x="0.12207" y="-171.32" width="1597.63" height="1572.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_123_22659"/>
                </filter>
                </defs>
                </svg>
                <svg id="vector-3" width="1368" height="333" viewBox="0 0 1368 333" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_f_123_22660)">
                <ellipse cx="709.662" cy="-154.043" rx="511.089" ry="282.069" transform="rotate(-6.48121 709.662 -154.043)" fill="#7D7F96" fill-opacity="0.5"/>
                </g>
                <defs>
                <filter id="filter0_f_123_22660" x="0.825195" y="-640.242" width="1417.67" height="972.399" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur_123_22660"/>
                </filter>
                </defs>
                </svg>
            </div>

            <div v-else>
                <external-checkout-component></external-checkout-component>
                
            </div>
            
            <div class="onboard-backdrop-layer" id="scrollable">
            
                    <header>
                        <div class="brand-logo">
                            <span v-if="!checkoutLoginMode">
                                <span class="brand-logo-symbol">FS</span><span class="brand-logo-text first-element">Financial </span><span class="brand-logo-text second-element">Source</span>
                            </span>
                        </div>
                        <div>
                            <nav>
                                <ul style="height: 28px;">
                                    <li><router-link v-if="displayLogin" :to="loginLink">Log in</router-link></li>
                                </ul>
                            </nav>
                        </div>
                    </header>
                    <div class="onboard-backdrop-main">
                        <router-view
                        class="router-view"
                        @emit-error="processError"
                        @register-login-successful="registerLoginSuccessful"
                        :cachedEmail="cachedEmail"
                        :cachedName="cachedName"
                        :promoID="promoID"
                        :specialLink="specialLink"
                        :continueOnboardingState="continueOnboardingState"
                        @onboard-skipped="$emit('onboard-skipped')"
                        @switch-onboard-mode="switchOnboardMode"
                        ></router-view>
                        <div class="error-message">{{ displayedError }}</div>
                    </div>
            
                
            </div>
        </div>
</template>

<script>
// import LoginComponent from './onboard/LoginComponent.vue';
import _ from 'lodash';
import { createNamespacedHelpers } from 'vuex';
import ExternalCheckoutComponent from './onboard/ExternalCheckoutComponent.vue';

const general = createNamespacedHelpers("general");

export default {
    components: {
        ExternalCheckoutComponent
    },
    props: ["continueOnboardingState", "promoID", "specialLink"],
    created() {
        this.pathname = this.$router.currentRoute.path;
    },
    computed: {
        ...general.mapState(["checkoutLoginMode"]),
        ...general.mapGetters(['user', "externalUser"]),

        cachedEmail() {
            return this.errorMessage?.email ? this.errorMessage.email : '';
        },
        cachedName() {
            return this.errorMessage?.name ? this.errorMessage.name : '';
        },
        displayedError() {
            return this.errorMessage?.error ? this.errorMessage.error : '';
        },
        displayLogin() {
            return _.isEmpty(this.user) && this.pathname !== '/login';
        },
        loginLink() {
            return "/login";
        }
    },
    data() {
        return {
            autoLogin: this.externalUser || window.bootUp.remoteAccess,
            errorMessage: null,
            pathname: '',
            mode: ''
        }
    },
    methods: {
        processError(val) {
            if(!val) {
                this.errorMessage = null;
                return;
            }

            if(val.error.indexOf("The email has already been taken.") > -1 ||
            val.error.indexOf("User details cannot be located as no token provided.") > -1) {
                this.$router.push({
                    path: '/'
                });

                val.error += ' Please log in.';
            }

            const errorMessage = _.cloneDeep(this.errorMessage) || {};

            errorMessage.error = val.error;
            if(val.email)
                errorMessage.email = val.email;
            
            this.errorMessage = errorMessage;
            // console.log("err",this.errorMessage);
                
        },
        registerLoginSuccessful(val) {
            // console.log("emitting in onboard");
            this.$emit("register-login-successful", val);
        },
        switchOnboardMode(mode) {
            this.mode = mode;
        }
    },
    watch: {
        '$route' (to) {
            this.pathname = to.path;

        }
    }
}
</script>

<style lang="scss">

.analyst-desk {

    & .onboard-backdrop {

        fieldset {
            border:none;
            margin: 0;
        }

        .label-transition label.focused {
            
        background-color: $input-background-color;
        
        }
        .label-transition input,
        .label-transition select {
            font-family: Poppins;
           
            background-color: $input-background-color;
            &:-webkit-autofill,
            &:-webkit-autofill:hover, 
            &:-webkit-autofill:focus, 
            &:-webkit-autofill:active{
                -webkit-box-shadow: 0 0 0 30px $input-background-color inset !important;
            }
        }
        .label-transition select {
            color: $input-color;
            font-weight: 200;
        }
        .label-transition input::placeholder {
            color: $input-placeholder-color;
            font-weight: 200;
        }
    }

    & .label-transition label {
            position: absolute;
            top: 23px;
            left: 7px;
            font-size: 15px;
            font-weight: 100;
            letter-spacing: 0.5px;
            color: $input-placeholder-color;
            opacity: 0;
            transition: all 0.4s ease;
            transition-property: color, font-size, letter-spacing, top, opacity, background-color;
            display: inline-block;
            padding: 0px 5px;
            background-color: transparent;
            pointer-events: none;
            font-family: sans-serif;
            z-index: 10;
    }
    & .label-transition label.focused {
        position: absolute;
        top: -3px;
        left: 11px;
        font-size: 10px;
        font-weight: normal;
        letter-spacing: 0.3px;
        color: $input-focus-color;
        opacity: 1;
        background-color: transparent;
        pointer-events: all;
    }
    & .label-transition input,
    & .label-transition select {
        background-color: transparent;
        border: 1px solid $input-border-color;
        border-radius: 10px;
        transition: all 0.3s ease;
        font-size: 15px;
        font-family: Poppins;
        height: 48px;
        padding: 12px;
        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px transparent inset !important;
            -webkit-text-fill-color: $input-color !important;
            caret-color: white;
            background-clip: content-box;
        }
    }
    & .label-transition input {
        color: $input-color;
    }
    & .label-transition input:focus,
    & .label-transition select:focus {
        box-shadow: none;
        border-color: $input-focus-color;
    }
    & .label-transition input::placeholder,
    & .label-transition textarea::placeholder
    & .label-transition select {
        color: $input-color;
        font-weight: 100;
    }
    & .label-transition input.delay-blur::placeholder {
        color: transparent;
    }
    & .label-transition textarea.delay-blur::placeholder {
        color: transparent;
    }

    & .onboard-backdrop {
        width: 100%;
        min-height: 100vh;
        background-color: $input-background-color;
        position: relative;
        // background: url("https://i.imgur.com/sqzSnyH.jpg") center/cover;
        z-index: 1;
        overflow: hidden;
        overflow-y: auto;

        &.survey {
            background-color: $onboard-survey-background;

            & .onboard-backdrop-svgs {
                display: none;
            }
        }

        color: $color-20;

        &-layer {
            position: absolute;
            width: 100vw;
            
        }

        & a {
            color: $input-color;
            text-decoration: none;
        } 

    

        &::after {
            content: "";
            width: 100%;
            height: 93%;
            position: absolute;
            top: 0;
            left: 0;
            background: inherit;
            filter: blur(32px);
            transform: scale(1.15);
            z-index: -1;
        }

        $vector-color: rgba(119, 95, 116, 0.3);
        $blur: blur(60px);

        &-svgs {

            & #vector-1 {
                position: fixed;
                width: 45vw;
                height: 35vh;
                left: -8vw;
                top: 80vh;

                background: $vector-color;
                filter: $blur;
                transform: rotate(1.68deg);

                pointer-events: none;
            }

            & #vector-2 {
                position: fixed;
                width: 35vh;
                height: 120vh;
                left: 88vw;
                top: 0;

                background: $vector-color;
                filter: $blur;
                transform: rotate(18.33deg);

                pointer-events: none;

            }

            & #vector-3 {
                position: fixed;
                width: 40vw;
                height: 45vh;
                left: 24vw;
                top: -35vh;

                background: rgba(#7D7F96, 0.5);
                filter: $blur;
                transform: rotate(-6.48deg);

                pointer-events: none;
            }
        }

        

        & header{
            display: flex;
            justify-content: space-between;
            align-items: center;

            padding: 18px;
            font-size: 16px;

            @include respond(lg) {
                padding: 22px;
                font-size: 18px;
            }
        }

        & header ul {
            margin: 0;
            padding: 0;
        }
        & header ul li {
            list-style: none;
        }

        & .brand-logo-symbol,
        & .brand-logo-text {
            display: inline-block;
            padding: 5px;

            @include respond(lg) {
                padding: 7.5px;
            }
        }

        & .brand-logo-symbol {
            box-sizing: border-box;
            color: $input-background-color;
            background-color: $color-20;
            width: 34px;
            height: 34px;
            text-align: center;
            font-weight: bold;
            margin-right: 7px;

            @include respond(lg) {
                width: 43px;
                height: 43px;
                margin-right: 10px;
            }
        }
        & .brand-logo-text {
            letter-spacing: 2.5px;
            text-transform: uppercase;
            font-weight: 100;
            display: none;

            @include respond(xs) {
                letter-spacing: 4px;
                display: inline-block;
            }

            @include respond(lg) {
                letter-spacing: 6px;
            }

            &.first-element {
                font-weight: bold;
            }
        }

        & .onboard-backdrop-main {
            // position: absolute;
            // height: 100%;
            // top: 50%;
            // left: 50%;
            // transform: translate(-50%, -50%);
            width: 100%;

            min-height: calc(100vh - 100px);
            /* padding-top: calc(100vh - 147px); */
            display: flex;
            align-items: center;
            
            flex-direction: column;

            @include respond(lg) {
                margin-top: -43px;
                justify-content: center;
            }

            // & .router-view {
            //     width: 100%;
            // }

            & .embedded-component {
            width: 90%;
            margin: 0 auto;

            @include respond(sm) {
                width: 66%;
            }

            @include respond(md) {
                width: 50%;
            }

            @include respond(lg) {
                width: 850px;
            }

            &.columns {
                @include respond(lg) {
                    width: 860px;
                }
            }

            & .container {
                @include respond(lg) {
                    width: 400px;
                    margin: 0 auto;
                }
            }

            & .wide {
                width: 90vw;
                margin: 0 auto;

                // @include respond(sm) {
                //     width: 66%;
                // }

                // @include respond(md) {
                //     width: 50%;
                // }

                @include respond(lg) {
                    width: 100vw;
                }

            }

            .vue-form-generator > fieldset {
                padding-bottom: 0;
            }

            & fieldset, 
            & .button-div {
                padding: 12px;
            }

            & fieldset:first-child {
                padding-top:0;
            }

            & h1 {
                font-size: 28px;
                
            } 

            & h2 {
                margin: 5px 0;
                font-size: 24px;
                letter-spacing: 0.2px;
                font-weight: normal;
            }

            & .steps-tracker {
                text-align: center;
                text-transform: uppercase;
                font-size: 12px;
            }

            & .form-control-fs {
                margin: 0;
                margin-bottom: 15px;
            }
        }



        }

        & .form-text-div {
            color: $input-color-filter;
            padding: 12px;
            padding-top:5px;
            font-size: 14px;

            &.subtitle {
                font-size: 18px;

                @include respond(md) {
                    max-width: 70%;
                    margin: 0 auto;
                }
                
            }
    
        }

        & .onboard-link {
            color: $input-color;
            text-decoration: underline;

            &.dimmed {
                color: $input-color-filter;
            }
        }

        

        & .errors.help-block {
            display: none;
        }

        & .error-message {
            text-align: center;
            font-size: 10px;
            min-height: 15px;
        }

        & .skip-setup {
            text-align: center;
            font-size: 10px;
            
            & a {
                color: $input-placeholder-color;

                &.white-link {
                    color: $input-color;
                }
            }
        }

        &.check-out {
            background-color: $onboard-survey-background;

            & .onboard-backdrop-layer {
                top: 0;
                height: 100%;
                background-color: rgba(#000000, 0.8);
            }

            & .onboard-backdrop-main {
                min-height: calc(100vh - 175px);
                margin-top: 100px;
                display: block;
            }

            & .label-transition label.focused {
            
                background-color: $partner-background-2;
            
            }
            & .label-transition input,
            & .label-transition select {
            
                background-color: $partner-background-2;
                &:-webkit-autofill,
                &:-webkit-autofill:hover, 
                &:-webkit-autofill:focus, 
                &:-webkit-autofill:active{
                    -webkit-box-shadow: 0 0 0 30px $partner-background-2 inset !important;
                }
            }
            & .label-transition input::placeholder {
                color: $partner-dimmed-text-1;
            }

            & .label-transition label {
                position: static;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0.0px;
                color: $partner-dimmed-text-1;
                opacity: 1;
                font-family: 'Poppins';
            }
            // & .label-transition label.focused {
            //     position: absolute;
            //     top: -3px;
            //     left: 11px;
            //     font-size: 10px;
            //     font-weight: normal;
            //     letter-spacing: 0.3px;
            //     color: $input-focus-color;
            //     opacity: 1;
            //     background-color: transparent;
            //     pointer-events: all;
            // }
            & .label-transition input,
            & .label-transition select {
               
                border: 1px solid $partner-border-1;
               
                font-size: 14px;
    
            }
            

            & .embedded-component {
                width: 90%;
                margin: 0 auto;
                background-color: $partner-background-1;
                padding: 25px 20px 50px 20px;
                border-radius: 20px;

                @include respond(sm) {
                    width: 66%;
                }

                @include respond(md) {
                    width: 50%;
                }

                @include respond(lg) {
                    width: 500px;
                }

                & h1 {
                    font-weight: 500;
                    margin: 5px 0;
                }

                .form-group:not(:last-child) {
                    margin-bottom: 15px;
                }
            }

            & .form-text-div {
                color: $input-color;
                padding: 12px;
                padding-top:5px;
                font-size: 14px;

                &.subtitle {
                    font-size: 14px;
                    margin-bottom: 20px;
                }
        
            }

            .error-message {
                transform: translateY(-50px);
            }

            .pw-reveal {
                top: 40px
            }
        }


    }

    & .plan-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    & .plan-card1 {

        margin: 10px;
        border-radius: 10px;

        transition: all 0.4s ease;
        transition-property: border-color, background-color;

        &.plan {

            width: 300px;
            background-color: $input-background-color;
            border: 1px solid #565E7A;

            &.highlighted {
                border-color: $input-focus-color;
                background-color: #2A3157;
            }

        }

        &.survey-card {
            border: none;
            background-color: $onboard-survey-card-background;
            border: 2px solid transparent;

            width: 100%;

            &.columns {
                width: 100%;
                
                @include respond(lg) {
                    width: 260px;
                }
                
            }

            &.highlighted {
                border: 2px solid $checkbox-active;
            }
        }

        &.fixed {
            & input {
                display: block;
            }
        }

        

        & input {
            display: none;
        }

        & > label {
            padding: 20px;
            height: 100%;
            width: 100%;
            box-sizing: border-box;
            margin-bottom: 0;
            cursor: pointer;
            
        }
    }

    & .invalid-input {
        border-color: $color-12!important;
    }


    // .onboard-overlay {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
        
    //     filter: blur(100px)
    // }

    & .pw-reveal {
        position: absolute;
        top: 13px;
        right: 20px;

        & svg {
            color: $input-color-filter;
        }
    }

    & .card-icon-div {
        margin: 5px;
    }
}
</style>