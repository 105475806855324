import { render, staticRenderFns } from "./SurveyOption.vue?vue&type=template&id=2fc90ea3&scoped=true&"
import script from "./SurveyOption.vue?vue&type=script&lang=js&"
export * from "./SurveyOption.vue?vue&type=script&lang=js&"
import style0 from "./SurveyOption.vue?vue&type=style&index=0&id=2fc90ea3&lang=scss&scoped=true&"
import style1 from "./SurveyOption.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fc90ea3",
  null
  
)

export default component.exports