<template>
    <div>
        <addon-details v-for="addon in currentVisibleAddons"
        :cancel-handler="cancelProduct"
        :reactivate-handler="reactivateProduct"
        :key="addon.slug"
        :addon="addon"></addon-details>
        <span v-if="currentVisibleAddons.length === 0">
            <span class="no-item-message">You have no active indicator subscriptions</span>
        </span>
    </div>
</template>

<script>
import SubscriptionMixin from "../../../mixins/SubscriptionMixin";
import AddonDetails from "../payments/AddonDetails";

export default {
    name: "CurrentWidgetsSection",
    components: {AddonDetails},
    mixins: [SubscriptionMixin]
}
</script>

<style lang="scss">



</style>
