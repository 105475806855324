<template>
    <div>
        <div class="filled-form"
             style="margin-left: 10px; margin-right:5px;padding-top: 5px;float: right">

            <VueCustomTooltip label="Add payment card to upgrade or purchase add-on products."
                              position="is-left" :multiline="true" v-if="!hasActiveCard">
                <div style="width: 100%">

                    <div style="width: 5%; float: left; margin-top: 20px;">
                        <span class="card-box-icon">
                            <font-awesome-icon :icon="['fas', 'exclamation-triangle']"/>
                        </span>
                    </div>
                    <div style="margin-left: 5px; width: 90%; float: right">
                        <h4>Your payment card</h4>
                    </div>
                </div>
            </VueCustomTooltip>
            <template v-else>
                <h4>Your payment card</h4>
                <div class="inside-row" :style="hasActiveCard? 'height: 80px': ''" >
                    <div>
                    <span class="card-box">
                        <template v-if="hasActiveCard">
                            <table style="border: 1px solid; border-radius: 10%; padding: 5px;font-size: small">
                                <tbody>
                                <tr>
                                    <td style="text-align: left"> <font-awesome-icon
                                            :icon="['fas', 'credit-card']"/></td>
                                    <td> <font-awesome-icon :icon="['fa', 'lock']"/></td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                         <span class="card-box-number">{{ cardNumber }}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                       <span class="card-box-number">Expires in {{ cardExpirationDate }}</span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                           <br/>
                        </template>
                    </span>
                        <br/>
                    </div>
                </div>

            </template>

            <div class="inside-row">
                <div>
                    <base-button
                            mode="blue"
                            :link="false"
                            style="margin-right: 15px; width:50px;"
                            @click.native="openUpdateCard"
                    >
                        <font-awesome-icon :icon="['fas', hasActiveCard? 'pencil-alt': 'plus']"/>
                    </base-button>
                    <base-button v-if="hasActiveCard"
                                 mode="green"
                                 :link="false"
                                 style="margin-right: 15px; width:50px;"
                                 @click.native="openViewCardDetails"
                    >
                        <font-awesome-icon :icon="['fas', 'eye']"/>
                    </base-button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
import PaymentCardModal from "./PaymentCardModal";
import PaymentCardDetailsModal from "./PaymentCardDetailsModal";
import dayjs from "dayjs";

const payments = createNamespacedHelpers('payments');
export default {
    name: "CardDetails",
    computed: {
        ...payments.mapState(['paymentMethod']),
        ...payments.mapGetters(['userHasActiveSubscriptions']),
        ccData: function () {
            return this.paymentMethod?.description || "";
        },
        cardNumber: function () {
            return "**** **** **** " + this.ccData.split("*")?.[1];
        },
        cardExpirationDate: function () {
            return dayjs(this.paymentMethod?.valid_until).format("M / YYYY");
        },
        hasActiveCard: function () {
            return this.paymentMethod?.valid_until !== null && this.paymentMethod?.valid_until !== undefined;
        }
    },
    methods: {
        ...payments.mapActions(['loadPaymentMethod']),
        openUpdateCard() {
            this.showModal(PaymentCardModal, {
                edit: this.hasActiveCard
            });
        },
        openViewCardDetails() {
            this.showModal(PaymentCardDetailsModal);
        },
    },
    created: async function () {
        await this.loadPaymentMethod();
    }
}
</script>
