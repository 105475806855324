<template>
    <base-modal title="Card details" :footer="false">
        <credit-card-details></credit-card-details>
    </base-modal>
</template>

<script>
import CreditCardDetails from "./CreditCardDetails";

export default {
    name: "PaymentCardDetailsModal",
    components: {CreditCardDetails}
}
</script>

