import {dataURLtoFile, formatPrice, formatPriceObject, toSystemPrice} from "../utility/helper-functions";
import Swal from "sweetalert2";
import {toSystemDate} from "../utility/datetime";
import EventBus from  "../store/bus";
import {createNamespacedHelpers} from "vuex";
const general = createNamespacedHelpers('general');
export default {
    computed: {
        ...general.mapState(['user'])
    },
    methods: {
        validateField: function (value) {
            if(value !== null && value !== undefined) {
                return false;
            }
            return null;
        },
        showModal: function (modal, paramsOrProps, params = {}, events) {
            let defaultParams = {
                draggable: false,
                clickToClose: false,
                resizable: false,
                height: "auto",
                adaptive: true
            };
            Object.assign(defaultParams, params);
            this.$modal.show(modal, paramsOrProps, defaultParams, events);
        },
        hideModal: function (name, params) {
            this.$modal.hide(name, params);
        },
        toggleModal: function (name, params) {
            this.$modal.toggle(name, params);
        },
        loadModal: function (view, data) {
            import(/* webpackChunkName: "view-[request]" */ `@/${view}.vue`).then(modal => {
                this.showModal(modal.default, {initialModalData: data, isEdit: true}, {width: 1000});
            });
        },
        formatPrice: function (price, showCurrency) {
            return formatPrice(price, showCurrency);
        },
        formatPriceObject: function (price, showCurrency) {
            return formatPriceObject(price, showCurrency);
        },
        isFree: function (price) {
            return price === "Free";
        },
        toSystemDate: function (date, isDate) {
            return toSystemDate(date, isDate);
        },
        showInfoMessage: function (title, message, callback = () => {}) {
            Swal.fire({
                title: title,
                html: message,
                type: 'info',
                confirmButtonColor: '##1d9deb',
                confirmButtonText: 'OK'
            }).then(result => {
                if(result.value) {
                    callback();
                }
            });
        },
        convertFieldDataToFile: function (uploadedFile) {
            const file = dataURLtoFile(uploadedFile);
            const data = new FormData();
            data.append('image', file);
            return data
        },
        scrollToTop() {
            EventBus.$emit("scroll-to-top");
        },
        toSystemPrice: function (price) {
            return toSystemPrice(price);
        },
        priceVatInfo: function (price) {
            let vatPercent = this.user.sales_tax_percent;
            if(vatPercent > 0 && !this.isFree(price)) {
                return "Inc. UK VAT";
            }
            return "";
        },
        routeTo: function (path, parameters) {
            this.$router.push({
                path: path,
                params: parameters
            });
        }
    }
}
