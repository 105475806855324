import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
// import * as shvl from "shvl";
import authModule from './auth/index';
import currencyModule from './currency/index';
import generalModule from './general/index';
import dashboardModule from './dashboard/index';
import widgetModule from './widgets/index';
import announcementsModule from './announcement/index';
import paymentsModule from './payments/index';
import tweetModule from './tweetdeck/index';
import availableCategoriesModule from './availableCategories/index';
import headlinesModule from './headlines/index';
import lookupCategoriesModule from './lookupCategories/index';
import lookupPropertiesModule from './lookupProperties/index';
import livestreamModule from './webinars/index';
import ecocalendarModule from './calendar/index';
import squawkModule from './squawk/index';
import libraryModule from './library/index';
import intRateModule from './interestRates/index';
import GlossaryModule from './glossary/index';
import ResearchModule from './research/index';
import RulesModule from './rules/index';


Vue.use(Vuex);

export const store = new Vuex.Store({
    actions: {
        storeCacheCallData(context, payload) {
            const records = JSON.parse(JSON.stringify(payload));
            delete records.user;
            for(const type in records) {
                if(type !== 'headlineSources' && type !== 'ruleSet') {
                    let legacyType = type;
                    
                    if(type === 'trainingCentreStreams')
                        legacyType = 'webinars';
                    else if(type === 'trainingCentreVideos')
                        legacyType = 'library';

                    const actionName = legacyType + '/addBulkRecords'
                    context.dispatch(actionName, records[type]);
                }
            }
        }
    },
    modules: {
        auth: authModule,
        general: generalModule,
        currency: currencyModule,
        dashboard: dashboardModule,
        widget: widgetModule,
        announcements: announcementsModule,
        tweetdeck: tweetModule,
        availableCategories: availableCategoriesModule,
        headlines: headlinesModule,
        lookupCategories: lookupCategoriesModule,
        lookupProperties: lookupPropertiesModule,
        squawk: squawkModule,
        payments: paymentsModule,
        webinars: livestreamModule,
        calendar: ecocalendarModule,
        library: libraryModule,
        interestRates: intRateModule,
        glossary: GlossaryModule,
        research: ResearchModule,
        rules: RulesModule
    },
    plugins: [
        createPersistedState({
            key: "layouts",
            storage: localStorage,
            paths: [
                'dashboard.activeDashboard',
                'general.countries',
                'general.versionId',
                'general.lastShopVisitDate',
                'general.checkoutLoginMode'
            ]
        }),
        createPersistedState({
            key: "tweetsFetchedForBoards",
            storage: localStorage,
            paths: [
                'tweetdeck.tweetsFetchedForBoards'
            ]
        }),
        createPersistedState({
            key: "componentAfterRefresh",
            storage: localStorage,
            paths: [
                'general.componentAfterRefresh'
            ]
        })
    ]
});
