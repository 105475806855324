<template>
    <div class="live-question"
    :class="classes">
        <a href="javascript:void(0)" 
        @click="questionClick">
       
            <span class="live-question-timestamp">{{ question.response_timestamp }}</span>
     
            
            <span class="live-question-question">
                <span v-if="yourQuestion" class="you-stamp">You:</span> {{ question.question }}
            </span>
            
        </a>
        <!-- <div class="progress">
            <span></span>
            <span></span>
            <span></span>
        </div> -->
    </div>
    
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { eventBus } from '../../../../main'
import { convertTimestampToSecs } from '../../../../utility/datetime'

const general = createNamespacedHelpers("general");

export default {
    props: ["question","layoutID","isLastSection"],
    computed: {
        ...general.mapState(["demoPageMode"]),
        ...general.mapGetters(["userQnaOn", "entityQnaOn"]),

        classes() {
            const arr = [this.question.type];
            if(this.isLastSection)
                arr.push('last-section');

            return arr.join(' ');
        },
        yourQuestion() {
            if(this.demoPageMode)
                return this.question.id % 2 === 0 && this.entityQnaOn;

            return this.question.user_id === window.loggedInUserId;
        },
        userID() {
            return window.loggedInUserId;
        }
    },
    methods: {
        questionClick() {

            const obj = {
                layoutID: this.layoutID,
                seconds: convertTimestampToSecs(this.question.response_timestamp)
            };

            eventBus.$emit("live-question-timestamp-clicked", obj);
        }
    }
}
</script>

<style lang="scss" scoped>
    .live-question {
        padding: 8px 0;
        
        font-size: 12px;

        &:first-child {
            

            & a {
                padding-left: 0;
            }
            
        }

        &:last-child {

            & a {
                padding-right: 0;
            }
            
        }

        & a {
            display: grid;
            grid-template-columns: auto 1fr;
            margin-left: 5px;
            color: $input-color;
            text-decoration: none;
        }

        & .live-question-question {
            margin-top: 4px;
            white-space: wrap;
            
        }
    
        & .live-question-timestamp {
            font-family: monospace;
            display: inline-block;
            
            height: 26px;
            padding: 4px 8px 4px 6px;
            text-align: right;
            // background-color: $separator-line-color;
            color: $core-blue;
            // width: auto;
            font-size: 14px;
            font-weight: 600;

        }

        &.section .live-question-timestamp {
            color: $onboard-green;
        }

        &.section {
            background-color: $widget-faded-background;
        }

        &.section.last-section{
            border-bottom: 2px solid $separator-line-color;
        }

        & .you-stamp {
            text-transform: uppercase;
            font-size: 10px;
            padding: 2px 5px;
            background-color: $separator-line-color;

        }

        // & .progress {
        //     margin-top: 10px;
        //     background-color: $separator-line-color;
        //     display: flex;
        //     justify-content: space-around;

        //     & span {
        //         height: 8px;
        //         width: 8px;
        //         border-radius: 8px;
        //         background-color: $input-color;

        //     }
        // }

    }
</style>