<template>
    <div class="video-legacy"
    :class="{'four-panel': fourPanel}">
        <h1><font-awesome-icon :icon="['fas', 'people-carry']"/></h1>
        <h2>Content moved</h2>
        This content is no longer available as a standalone indicator. Please visit <a href="javascript:void(0);" @click="goToTraining">our new Training Centre</a> to continue watching your favourite videos.
    </div>
</template>

<script>
import { eventBus } from '../../main';

export default {
    props: ["details"],
    // created() {
    //     console.log(this.details);

    // },
    computed: {
        fourPanel() {
            return this.details.panels === 4;
        }
        
    },
    methods: {
        goToTraining() {
            eventBus.$emit("nav-change", {
                instanceID: this.$root.instanceID,
                comp: "videos"
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .video-legacy {
        max-width: 550px;
        margin: 0 auto;
        padding: 0 20px;
        font-size: 20px;
        text-align: center;
        color: $input-color-filter;

        & a {
            color: $checkbox-active;
            text-decoration: none;
        }

        &.four-panel {
            font-size: 16px;

            & h1 {
                font-size: 28px;
                margin-bottom: 10px;
                margin-top: 0;
            }

            & h2 {
                display: none;
            }
        }
    }
</style>