import _ from "lodash";

export default {
    setCurrencies(state, payload) {
        state.currencyData = _.cloneDeep(payload);
    },
    addCurrencyChannels(state, payload) {
        state.currencyChannels.push(payload);
    },
    setCurrencyChannels(state, payload) {
        state.currencyChannels = _.cloneDeep(payload);
        
    },
    setPairs(state, payload) {
        let pairs = _.cloneDeep(state.pairs);
        if (pairs[payload.key]) {
            pairs[payload.key] = {};
        }
        pairs[payload.key] = payload.value;
        state.pairs = pairs;
    },
    setLeaderBoard(state, payload) {
        let leaderboard = _.cloneDeep(state.leaderboard);
        if (leaderboard[payload.key]) {
            leaderboard[payload.key] = {};
        }
        leaderboard[payload.key] = payload.value;
        state.leaderboard = leaderboard;
    },
    setLastUpdate(state, payload) {
        state.lastUpdate = payload;
    },
    setLastPush(state, payload) {
        state.lastPush = payload;
    }
}
