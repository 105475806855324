<template>
    <div class="empty-platform">
        <div class="info-sign">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']"/>
        </div>
        <div class="info-message">
            <span v-if="version === 'popped-out'">This board has been popped out into a separate page.
                <a @click="bringBackPopOut" style="cursor:pointer;color: orange; text-decoration: underline">Click here to bring it back.</a>
            </span>
            <!-- <span v-else-if="version === 'dashboard'">
                <span v-if="isMobileForDashboards">Please select a dashboard to display here from the dashboards menu <font-awesome-icon
                    :icon="['fas', 'th-large']"/> in the top right corner.</span>
                <span v-else>Please select a dashboard to display here or create a new one from the dashboards menu <font-awesome-icon
                    :icon="['fas', 'th-large']"/> in the top right corner.</span>
            </span>
            <span v-else-if="version === 'widget'">
                <span v-if="isTerminalUser">Your dashboard is empty. Please select your widget(s) to display here from the widgets menu <font-awesome-icon
                    :icon="['fas', 'plus']"/> in the top right corner.
                </span>
                <span v-else>Something went wrong when loading your widget. Please refresh the page.
                </span>
            </span> -->

            <!-- <span class="pointer-chevron" :class="version"><font-awesome-icon :icon="['fas', 'angle-double-up']" /></span> -->
        </div>
    </div>
</template>
<script>
import {createNamespacedHelpers} from "vuex";
import _ from "lodash";
import { isMobileForDashboards } from '../../utility/helper-functions';

const dashboards = createNamespacedHelpers('dashboard');
export default {
    props: ["version", "closePopup"],
    computed: {
        ...dashboards.mapGetters(['activeDashboardInfoByInstanceID', 'getPoppedoutDashboardByID']),
        activeDashboardInfo() {
            return this.activeDashboardInfoByInstanceID(this.$root.instanceID, "dashboard");
        },
        isMobileForDashboards() {
            return isMobileForDashboards();
        }
    },
    methods: {
        ...dashboards.mapActions(["setActiveDashboardAndTab", "updateDashboardById"]),
        bringBackPopOut: function () {
            if (this.activeDashboardInfo) {
                const poppedOut = this.getPoppedoutDashboardByID(this.activeDashboardInfo.id);
                if (poppedOut && poppedOut.window && !poppedOut.window.closed) {
                    poppedOut.window.close();
                }
                let dashboard = _.cloneDeep(this.activeDashboardInfo);
                if (dashboard.popout) {
                    dashboard.tab = true;
                    dashboard.popout = false;
                    this.updateDashboardById(dashboard);
                }
                this.setActiveDashboardAndTab({
                    id: this.activeDashboardInfo.id, 
                    instanceID: this.$root.instanceID
                });
            }
        }
    }
}
</script>

<style scoped lang="scss">
.empty-platform {
  padding-top: 12px;
  height: calc(100vh - 73px);
  background-color: rgba($color-00, 0.84);
}

.info-sign,
.info-message {
  text-align: center;
}

.info-sign {
  font-size: 116px;
  color: rgba($color-06, 0.2);
}

.info-message {
  margin-top: 30px;
  font-size: 22px;
  color: $color-06;
}


</style>
