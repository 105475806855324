<template>
    <div>
        <div class="filled-form">

            <div v-if="!hasActiveCard">
                <div>
                    <span class="card-box-icon">
                        <font-awesome-icon :icon="['fas', 'exclamation-triangle']"/>
                    </span>
                </div>
                <div>
                    <h4>No payment card</h4>
                    <p><em>Add payment card to upgrade or purchase add-on products.</em></p>
                </div>
            </div>
            <template v-else>
                <div class="inside-row">
                    <h4 style="margin-top:0;">Your payment card</h4>
                    <div>
                    <span class="card-box">
                        <template v-if="hasActiveCard">
                            <credit-card-details></credit-card-details>
                        </template>
                    </span>
                    </div>
                </div>

            </template>

            <div class="inside-row" style="margin-top: -7rem; float:right;">
                <div>
                    <base-button
                            mode="blue"
                            :link="false"
                            style="margin-right: 15px; width:50px;"
                            @click.native="openUpdateCard"
                    >
                        <font-awesome-icon :icon="['fas', hasActiveCard? 'pencil-alt': 'plus']"/>
                    </base-button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import PaymentCardModal from "./PaymentCardModal";
import PaymentCardDetailsModal from "./PaymentCardDetailsModal";
import dayjs from "dayjs";
import CreditCardDetails from "./CreditCardDetails";
import {createNamespacedHelpers} from "vuex";
const payments = createNamespacedHelpers('payments');
export default {
    name: "BillingCardDetails",
    components: {CreditCardDetails},
    computed: {
        ...payments.mapState(['paymentMethod', 'paymentHistory']),
        ...payments.mapGetters(['userHasActiveSubscriptions']),
        ccData: function () {
            return this.paymentMethod?.description || "";
        },
        cardNumber: function () {
            return "**** **** **** " + this.ccData.split("*")?.[1];
        },
        cardExpirationDate: function () {
            return dayjs(this.paymentMethod?.valid_until).format("M / YYYY");
        },
        hasActiveCard: function () {
            return this.paymentMethod?.valid_until !== null && this.paymentMethod?.valid_until !== undefined;
        }
    },
    methods: {
        ...payments.mapActions(['loadPaymentMethod', 'loadPaymentHistory']),
        openUpdateCard() {
            this.showModal(PaymentCardModal, {
                edit: this.hasActiveCard
            });
        },
        openViewCardDetails() {
            this.showModal(PaymentCardDetailsModal);
        }
    },
    created: async function () {
        await this.loadPaymentMethod();
    }
}
</script>
