<template>
    <div class="account-card">
        <div >
            <table class="invoice-table">
                <thead>
                    <td>Name</td>
                    <td>Date</td>
                    <td>Price</td>
                    <td>Status</td>
                    <td></td>
                </thead>
                <tbody>
                    <tr v-for="(invoice, index) in paymentHistory"
                    :key="invoice.id">
                        <td class="invoice-title">{{ formatTitle(invoice.invoice_lines) }}</td>
                        <td> {{ formatDate(invoice.created_at) }}</td>
                        <td>{{ formatThePrice(invoice.gross) }}</td>
                        <td>
                            <VueCustomTooltip :multiline="true" :label="formatPaymentTooltip(invoice)"
                            v-if="invoice.next_retry_date">
                                <span class="d-md"> {{ formatPaymentStatus(invoice) }}</span>
                            </VueCustomTooltip>
                            <span class="d-md" v-else> {{ formatPaymentStatus(invoice) }}</span>
                        </td>
                        <td>
                            <base-button v-if="invoice.pdf"
                            style="height: 26px; line-height: 12px; font-size: 12px;"
                            mode="o-dark-grey"
                            :link="false"
                            @click.native="getInvoice(invoice)"
                            >Get Invoice
                            </base-button>
                            <!--  -->
                            <span v-if="(selectedInvoices.includes(invoice.invoice_id) || invoice.paid_status !== 'full') && invoice.paid_status !== 'refunded' && index === 0 && isAccessOn(index)">
                                <button
                                        class="retry-button"
                                        :disabled="makingPayment"
                                        @click="retryInvoice(invoice)">
                                    <VueCustomTooltip label="Retry"
                                                        position="is-left">
                                        <font-awesome-icon :icon="['fas', 'redo']"/>
                                    </VueCustomTooltip>
                                </button>

                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-if="noValidInvoices" style="margin-top:5px;">
                <span class="no-item-message">You have no invoices.</span>
            </div>
        </div>
    </div>
</template>

<script>

import PaymentService from "@/services/payments/payment.service"
import Swal from "sweetalert2";
import PaymentInvoiceModal from "@/components/ui/payments/PaymentInvoiceModal";
import PaymentCardModal from "@/components/ui/payments/PaymentCardModal";
import {createNamespacedHelpers} from "vuex";
import ProductPrices from "../../../mixins/product.prices";
import {toSystemDate} from "../../../utility/datetime";
import { precise } from '../../../utility/helper-functions';
import { eventBus } from '../../../main';
import SubscriptionMixin from "../../../mixins/SubscriptionMixin";
// import { formatPrice } from '../../../utility/helper-functions';

const payments = createNamespacedHelpers("payments");
export default {
    name: "InvoiceHistory",
    mixins: [ProductPrices, SubscriptionMixin],
    props: {
        selectedInvoices: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        ...payments.mapState(["paymentHistory", "makingPayment"]),
        noValidInvoices: function () {
            return this.paymentHistory.length === 0;
        },
        accessibleProductsIDs() {
            const activePlan = [this.activePlan?.product_id];
            const promos = this.currentVisiblePromotions.map(el => el.product_id);
            const addons = this.currentVisibleAddons.map(el => el.product_id);

            return [...activePlan, ...promos, ...addons];
        },
    },
    methods: {
        ...payments.mapActions(["loadPaymentHistory", "setMakingPayment"]),
        formatDate: function (date) {
            return this.toSystemDate(date);
        },
        formatTitle: function (lines) {
            return lines?.filter(line => line.line_gross > 0)?.map(line => line.description)?.join(" + ");
        },
        getInvoice: function (invoice) {
            PaymentService.getInvoice(invoice.pdf).then((result) => {
                this.showInvoiceModal(result);
            })
        },
        showInvoiceModal: function (result) {
            let pdf = result?.data?.data?.pdf;
            if (pdf) {
                this.showModal(PaymentInvoiceModal, {content: pdf});
            } else {
                Swal.fire({
                    title: 'Warning!',
                    type: 'info',
                    text: 'This invoice could not be generated. Try again later!',
                    showCancelButton: true,
                    cancelButtonColor: '#0062FF'
                });
            }
        },
        retryInvoice: function (invoice) {
            Swal.fire({
                title: 'Are you sure?',
                type: 'warning',
                text: "When you click below, we'll charge your credit card $" + invoice.gross / 100 + " about the selected invoice . ",
                showCancelButton: true,
                cancelButtonColor: '#383A42',
                confirmButtonColor: '#3B9C83',
                confirmButtonText: 'Pay Invoice!'
            }).then((result) => {
                if (result.value) {
                    this.setMakingPayment(true);
                    this.retry(invoice, true);
                    
                }
            });

        },
        formatPaymentStatus: function (payment) {
            switch (payment.paid_status) {
                case "full":
                    return "Paid";
                case "refunded":
                    return "Refunded";
                default :
                    return "Fail";
            }
        },
        showUpdateCardModal(invoice) {
            Swal.fire({
                title: 'No card',
                type: 'info',
                text: 'Please add a card to make this payment',
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonColor: '#3B9C83'
            }).then(() => {
                    this.showModal(PaymentCardModal, {
                    edit: true,
                    hideHeader: true,
                    showMakePayment: true, 
                    handlePayment: () => this.retry(invoice, false)
                });
            });
           
        },
        formatPaymentTooltip: function (payment) {
            if (this.activePlan.price > 0 && this.activePlan.price !== "0.00") {
                return 'Next try on: ' + toSystemDate(payment.next_retry_date);
            } else {
                return "Plan has expired!";
            }

        },
        formatThePrice(amount) {
            return amount ? '$' + precise(amount / 100) : 'Free';
            
  
        },
        retry(invoice, show)  {
            let id = invoice.id || invoice.invoice_id;
            PaymentService.retryInvoiceAndLoadProducts(id).then((result) => {
                eventBus.$emit('close-modal');
                // console.log("retry", result);
                if (!result?.data?.success) {
                    if(show)
                        this.showUpdateCardModal(invoice);
                }
            })
            .catch((error) => {
                console.log("error",error.response.data);
                if(show)
                    this.showUpdateCardModal(invoice);
            });

        },
        isAccessOn(index) { 
            if(!this.paymentHistory.length)
                return false;
            
            let found = false;
            this.paymentHistory[index].invoice_lines.forEach(el => {
                if(this.accessibleProductsIDs.includes(el.product_id)) {
                    found = true;
                }
            });

            return found;
        }
    },
    created() {
        this.loadPaymentHistory();
        // console.log(this.paymentHistory, this.accessibleProductsIDs);
    },
    watch:{
        paymentHistory: {
            deep: true,
            handler: function (nVal, oVal) {
                // console.log("inv",nVal);
                // console.log(nVal);
                if(nVal && 
                oVal && 
                nVal.length && 
                oVal.length && 
                nVal[0].paid_status !== oVal[0].paid_status 
                && nVal[0].paid_status === "full"
                ) {
                    Swal.fire({
                        title: 'Payment Successful',
                        html: nVal[0].invoice_lines[0].description + " is now active.",
                        type: 'info',
                        confirmButtonColor: '##1d9deb',
                        confirmButtonText: 'OK'
                        // timer: 1500
                    });
                }
                
            }
        }
    }
}
</script>

<style scoped lang="scss">
    .invoice-table {
        font-size: 14px;
        color: $input-color-filter;
        border-collapse: collapse;
        width: 100%;

        & tr,
        & thead {
            border-bottom: 1px solid $separator-line-color;
        }

        thead td {
            @include H7;
        }

        tbody td {
            letter-spacing: 0.1px;
        }

        & td {
            padding: 10px 0;

            &.invoice-title {
                max-width: 250px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        & .retry-button {
            right: 50px; 
            cursor: pointer; 
            background-color: transparent; 
            border: none;

            & svg {
                color: $input-color-filter;
            }

            &:disabled {
                cursor: default;

                & svg {
                    color: $input-border-color;
                }
            }
        }

    }

    

</style>

<style lang="scss">
    .invoice-table .vue-custom-tooltip.is-left:after {
        width: auto!important;
    }
</style>
