import Swal from "sweetalert2";
import {createNamespacedHelpers} from "vuex";

const general = createNamespacedHelpers('general');
const payments = createNamespacedHelpers('payments');
export default {
    data: function () {
        return {
            steps: [],
            myOptions: {
                highlight: true,
                placement: 'left',
                useKeyboardNavigation: false,
                enabledButtons: {
                    buttonSkip: true,
                    buttonPrevious: false,
                    buttonNext: false,
                    buttonStop: false
                },
                labels: {
                    buttonSkip: 'Skip tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Next'
                }
            },
            callbacks: {
                onFinish: function () {
                    Swal.fire({
                        title: 'Tips',
                        type: 'info',
                        text: "You can add multiple widgets to a single dashboard or you can create multiple dashboards to house each widget. You have full control.",
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonColor: '#1d9deb',
                        cancelButtonText: 'Finish'
                    })
                }
            }
        };
    },
    computed: {
        ...general.mapState(['user']),
        ...payments.mapGetters(['activePlan']),
    },
    methods: {
        openTourGuide: function () {
            Swal.fire({
                title: 'Take a Tour!',
                type: 'info',
                text: "We'll show you our best tools.",
                showCancelButton: true,
                cancelButtonText: 'No thanks',
                confirmButtonColor: '#1d9deb',
                confirmButtonText: 'Start tour'
            }).then(() => {
                // if (result.value) {
                //     this.$tours['myTour'].start()
                // }
            });
        },
        initializeSteps: function () {
            this.steps = [
                {
                    target: '#dashboard-step',
                    header: {
                        title: 'Create a dashboard',
                    },
                    content: `Click here to create a dashboard. Dashboards are where you can use our tools and widgets.`,
                    params: {
                        enabledButtons: {
                            buttonSkip: true,
                            buttonPrevious: false,
                            buttonNext: false,
                            buttonStop: false
                        },
                        placement: 'bottom'
                    }
                },
                {
                    target: '#add-dashboard-step',
                    header: {
                        title: 'Create a dashboard',
                    },
                    content: `Click here to create a brand new board.`,
                    params: {
                        enabledButtons: {
                            buttonSkip: true,
                            buttonPrevious: false,
                            buttonNext: false,
                            buttonStop: false
                        },
                    }
                },
                {
                    target: '#dashboard-step-name',
                    header: {
                        title: 'Name',
                    },
                    content: `Type a name for your board here.`,
                    params: {
                        enabledButtons: {
                            buttonSkip: true,
                            buttonPrevious: false,
                            buttonNext: false,
                            buttonStop: false
                        },
                        placement: 'top'
                    }
                },
                {
                    target: '#create-dashboard-button-step',
                    header: {
                        title: 'Name',
                    },
                    content: `Save dashboard.`,
                    params: {
                        enabledButtons: {
                            buttonSkip: true,
                            buttonPrevious: true,
                            buttonNext: false,
                            buttonStop: false,
                        },
                        placement: 'right'
                    }
                },
                {
                    target: '#widget-step-0',
                    header: {
                        title: 'Widget',
                    },
                    content: `Click here to apply this widget to your dashboard and start using it!`,
                    params: {
                        enabledButtons: {
                            buttonSkip: true,
                            buttonPrevious: false,
                            buttonNext: false,
                            buttonStop: false
                        },

                    }
                },
                {
                    target: '#add-dashboard-button-step',
                    header: {
                        title: 'Widget',
                    },
                    content: `Start using this widget!`,
                    params: {
                        enabledButtons: {
                            buttonSkip: true,
                            buttonPrevious: false,
                            buttonNext: false,
                            buttonStop: false
                        },

                    }
                },
                {
                    target: '#widget-board-step',
                    header: {
                        title: 'Widgets',
                    },
                    content: `Move this widget around the board by clicking here to grab it.`,
                    params: {
                        enabledButtons: {
                            buttonSkip: true,
                            buttonPrevious: false,
                            buttonNext: true,
                            buttonStop: false
                        },
                    }
                },
                {
                    target: '#widget-settings-step',
                    header: {
                        title: 'Settings',
                    },
                    content: `View widget settings here.`,
                    params: {
                        enabledButtons: {
                            buttonSkip: true,
                            buttonPrevious: false,
                            buttonNext: true,
                            buttonStop: false
                        },

                    }
                },
                {
                    target: '#widget-resize-step',
                    header: {
                        title: 'Resize',
                    },
                    content: `You can make the widget larger or smaller by dragging the bottom corner.`,
                    params: {
                        enabledButtons: {
                            buttonSkip: true,
                            buttonPrevious: false,
                            buttonNext: false,
                            buttonStop: true
                        },
                        placement: 'bottom'
                    },

                }
            ];
        }

    },
    created() {
        this.initializeSteps();
    }
}
