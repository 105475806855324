import mutations from './mutations';
import actions from './actions';
import getters from './getters';
// {
//     "id": 0,
//     "elements": {
//     "9292626": {
//         "widgetCode": "VIDLIB",
//             "userSettings": {
//             "widgetname": "How to use the terminal",
//                 "fontsize": "medium-font",
//                 "timezone": "Europe/London"
//         },
//         "layout": {
//             "x": 39,
//                 "y": 9,
//                 "w": 53,
//                 "h": 28,
//                 "i": 9292626,
//                 "static": true,
//                 "implementation": 8,
//                 "moved": false
//         }
//     }
// },
//     "name": "User Guide"
// }
export default {
    namespaced: true,
    state() {
        return {
            userGuideDashboard: {
                "default": true,
                "id": 0,
                "elements": {
                    "9292626": {
                        "widgetCode": "VIDLIB",
                        "userSettings": {
                            "widgetname": "How to use the terminal",
                            "fontsize": "medium-font",
                            "timezone": "Europe/London"
                        },
                        "layout": {
                            "x": 39,
                            "y": 9,
                            "w": 53,
                            "h": 28,
                            "i": 9292626,
                            "static": true,
                            "implementation": 8,
                            "moved": false
                        }
                    }
                },
                "name": "User Guide",
                "tab": true
            },
            layouts: {layouts: {}},
            activeDashboard: {},
            poppedoutDashboards: [],
            fullscreenId: 0,
            analChatActiveOn: null
        }
    },
    mutations,
    actions,
    getters
}
