<template>
    <div>
        <div class="irptm-header">
            <div class="irptm-header-wrapper">
               
                <div>
                    <img 
                    style="width: 20px; margin-top: 8px;"
                    :src="flagSrc" :alt="bank.central_bank + ' flag'" />
                </div>
              
                <div><h2>{{ bank.central_bank }}</h2></div>
            </div>
        </div>
        <div class="irptm-body">
            <div class="irptm-body-table">
                <div class="irptm-body-table-row col-sm-2-fs">
                    <div class="irptm-body-table-row--unit">
                        <div class="irptm-body-table-row--unit__header">
                            Next Expected Move
                        </div>
                        <div 
                        style="position: relative;"
                        :class="redOrGreen"
                        class="irptm-body-table-row--unit__body">
                            <padlock-overlay
                            :impID="panel.impID"
                            v-if="panel.isLocked"
                            class="sm-padlock blur"
                            ></padlock-overlay>
                            {{ action }}
                        </div>
                    </div>
                     <div class="irptm-body-table-row--unit">
                         <div class="irptm-body-table-row--unit__header">
                             Change By
                        </div>
                        <div 
                        style="position: relative;"
                        class="irptm-body-table-row--unit__body">
                            <padlock-overlay
                            :impID="panel.impID"
                            v-if="panel.isLocked"
                            class="sm-padlock blur"
                            ></padlock-overlay>
                            {{ formattedChangeBy }}
                        </div>
                    </div>
                </div>
                <div class="irptm-body-table-row col-sm-2-fs">
                    <div class="irptm-body-table-row--unit">
                        <div class="irptm-body-table-row--unit__header">
                            Probability
                        </div>
                        <div 
                        style="position: relative;"
                        class="irptm-body-table-row--unit__body">
                            <padlock-overlay
                            :impID="panel.impID"
                            v-if="panel.isLocked"
                            class="sm-padlock blur"
                            ></padlock-overlay>
                            {{ formattedProbability }}
                        </div>
                    </div>
                     <div class="irptm-body-table-row--unit">
                         <div class="irptm-body-table-row--unit__header">
                             Next Meeting Date
                        </div>
                        <div class="irptm-body-table-row--unit__body">
                            <span>{{ nextMeetingDay.dm }}</span> <span>{{ nextMeetingDay.y }}</span>
                        </div>
                    </div>
                </div>
                <div class="irptm-body-table-row col-sm-2-fs">
                    <div class="irptm-body-table-row--unit">
                        <div class="irptm-body-table-row--unit__header">
                            Current rate
                        </div>
                        <div class="irptm-body-table-row--unit__body">
                            {{ formattedRate }}
                        </div>
                    </div>
                </div>
                <div class="irptm-body-table-row">
                    <div class="irptm-body-table">
                         <div class="irptm-body-table-row--unit__header">
                             Trading Application
                        </div>
                        <!-- <div class="irptm-body-table-row--unit__body" v-html="comments">
                        </div> -->
                        <div class="irptm-body-table-row--unit__body">

                            <span v-if="isThereStandardData">
                                <strong>Implied Interest Rate Curve:</strong>
                                <br />
                                The chart below shows where STIR markets expect future rates to be. This gives us the baseline expectations of the market for the foreseeable future. New information that changes these expectations will cause big price moves.
                                <br />
                                <br />
                                </span>
                            <div 
                            v-if="isThereStandardData"
                            style="position: relative;">
                                <padlock-overlay
                                :impID="panel.impID"
                                v-if="panel.isLocked"
                                :instructions="true"
                                class="blur"
                                ></padlock-overlay>
                                <div
                                class="chart-wrapper"
                                :style="{'width': modalWidth + 'px'}">
                                    <IntRateChart
                                    v-if="panel.isLocked"
                                    :bank="bank"
                                    :standardData="dummyData"
                                    />
                                    <IntRateChart
                                    v-else
                                    :bank="bank"
                                    :standardData="standardData"
                                    />
                                </div>
                            </div>
                             <span v-if="isThereDailyData">
                                <strong>Implied Basis Points For Upcoming Meeting:</strong>
                                <br />
                                The chart below shows how many basis points STIR markets expect rates to be adjusted by at the next meeting. This shows us whether expectations are becoming stronger or weaker into the meeting.
                                <br />
                                <br />
                                </span>

                            <div  
                            v-if="isThereDailyData"
                            style="position: relative;">  
                                <padlock-overlay
                                :impID="panel.impID"
                                v-if="panel.isLocked"
                                :instructions="true"
                                class="blur"
                                ></padlock-overlay>
                                <div
                                class="chart-wrapper"
                                :style="{'width': modalWidth + 'px'}">
                                    <IntRateChart
                                    v-if="panel.isLocked"
                                    :bank="bank"
                                    :standardData="dummyData"
                                    />
                                    <DailyRateChart
                                    v-else
                                    :bank="bank"
                                    :dates="dailyDates"
                                    :rates="dailyRates"
                                    />
                                </div>
                            </div>

                            <span v-if="isThereProbabilityData">
                                <strong>Probability Between Most Probable Outcomes For Upcoming Meeting:</strong>
                                <br />
                                The chart below shows the two most likely outcomes for the next meeting. Probability based on STIR prices and updated in real-time. This shows what would surprise the markets most (and cause the biggest price move).
                                <br />
                                <br />
                                </span>
                            <div
                            v-if="isThereProbabilityData"
                            style="position: relative;">  
                                <padlock-overlay
                                :impID="panel.impID"
                                v-if="panel.isLocked"
                                :instructions="true"
                                class="blur"
                                ></padlock-overlay>
                                <div
                                class="chart-wrapper"
                                :style="{'width': modalWidth + 'px'}">
                                    <IntRateChart
                                    v-if="panel.isLocked"
                                    :bank="bank"
                                    :standardData="dummyData"
                                    />
                                    <RateProbabilityChart 
                                    v-else
                                    :bank="bank" />
                                </div>
                            </div>

                        </div>
                        <div class="last-updated-at">Last updated: <span>{{ humanizedUpdatedAt }}</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import _ from 'lodash';
import { eventBus } from '../../../../main';
import RateProbabilityChart from './RateProbabilityChart.vue';
import IntRateChart from './IntRateChart.vue';
import DailyRateChart from './DailyRateChart.vue';
import PadlockOverlay from '../../../../assets/PadlockOverlay.vue';


import IrptDataMixin from '../../../../mixins/irptdatamixin';
import { processIntRateDatesAndRates } from '../../../../utility/helper-functions';

const intrateNS = createNamespacedHelpers('interestRates');

export default {
    props: ["panel"],
    components: {
        DailyRateChart,
        IntRateChart,
        RateProbabilityChart,
        PadlockOverlay
    },
    mixins: [IrptDataMixin],
    created() {
        eventBus.$on("window-resize", this.handleOverflow);
        this.bank = _.cloneDeep(this.panel.bank);

        this.processChartData();
        this.dummyData = {
            "current": {
                "dates": [
                    "2021-02-02",
                    "2021-03-23",
                    "2021-05-11",
                    "2021-06-22",
                    "2021-08-03",
                    "2021-09-21",
                    "2021-11-02",
                    "2021-12-14"
                ],
                "rates": [
                    3,
                    4.5,
                    4.0,
                    4.8,
                    4.6,
                    3,
                    3.3,
                    3.5
                ]
            },
            "week_ago": {
                "dates": [
                    "2021-02-02",
                    "2021-03-23",
                    "2021-05-11",
                    "2021-06-22",
                    "2021-08-03",
                    "2021-09-21",
                    "2021-11-02",
                    "2021-12-14"
                ],
                "rates": [
                    2.5,
                    3.3,
                    3.8,
                    4.2,
                    4.6,
                    4,
                    4.3,
                    4.6
                ]
            }
        };
        
    },
    mounted() {
        this.$nextTick(() => this.makeOverflow());
    },
    beforeDestroy() {
        eventBus.$off("window-resize", this.handleOverflow)
    },
    computed: {
        ...intrateNS.mapGetters(['lastIntRateUpdate', 'lastIntRateDelete', 'humanizedUpdatedAt']),

        // comments() {
        //     let comments = this.bank.comments;
        //     if(!comments) {
        //         return "";
        //     }
            
        //     /* eslint-disable */
        //     const figures = comments.match(/<figure(.*?)figure>+/g);
        //     if(figures?.length) {
        //         figures.forEach((fig, index) => {
        //             const marker = '#####_' + index + '_#####';
        //             comments = comments.replace(fig, marker);


        //             const json =JSON.parse(fig.match(/data-trix-attachment=\"(.*?)\">/)[1].replaceAll('&quot;','"').match(/^(.*?)}/)[0]);

        //             const href = json.href.replace("[#domain#]", window.bootUp.entity.mapped_domain);

        //             comments = comments.replace(marker, `<img class="processed-figure-img" src="${href}" alt="${json.filename}" />`);
        //         });
                
        //     }
            
            
        //     /* eslint-enable */
        //     return comments;
            
        // }
        
    },
    data() {
        return {
            bank: null,
            modalWidth: '679',
            dummyData: {}

        }
    },
    methods: {
        processChartData() {
            if(this.isThereDailyData) {
            const {dates, rates} = processIntRateDatesAndRates(this.bank.irpt_daily.dates, this.bank.irpt_daily.rates);
                this.dailyDates = dates;
                this.dailyRates = rates;

                // setTimeout(() => {
                //         console.log("ran")
                //         this.dailyDates = ["2022-02-03", "2022-02-04", "2022-02-05"];
                //         this.dailyRates = [2, 3, 4];
                //     },2000)

            }
            if(this.isThereStandardData) {
            const arr = ["current", "week_ago"];
                arr.forEach(el => {
                    this.standardData[el].dates = this.bank.irpt_standard[el].dates;
                    this.standardData[el].rates = this.bank.irpt_standard[el].rates;
                });

                // setTimeout(() => {
                //         console.log("ran")
                //         this.standardData["current"].dates = ["2022-02-03", "2022-02-04", "2022-02-05"];
                //         this.standardData["current"].rates = [2, 3, 4];
                //         this.standardData["week_ago"].dates = ["2022-02-03", "2022-02-04", "2022-02-05"];
                //         this.standardData["week_ago"].rates = [3, 4, 2];
                //     },2000)
                // setTimeout(() => {
                //         console.log("ran")
                //         this.standardData["current"].dates = ["2022-02-03", "2022-02-04", "2022-02-05"];
                //         this.standardData["current"].rates = [3, 5, 4];
                //         this.standardData["week_ago"].dates = ["2022-02-03", "2022-02-04", "2022-02-05"];
                //         this.standardData["week_ago"].rates = [2, 1, 2];
                //     },4000)
                
            }
            if(this.isThereProbabilityData) {
                // setTimeout(() => {
                //     console.log("ran")
                //     const bank = _.cloneDeep(this.panel.bank);
                //     bank.sc1_probability = 49;
                //     bank.sc2_probability = 51;
                //     bank.sc1_change_by = 1.5;
                //     bank.sc2_change_by = 2.5;

                //     this.bank = bank;
                // }, 4000);

            }
        },
        handleOverflow(obj) {
            if(obj.instanceID !== this.$root.instanceID)
                return;

            this.makeOverflow();
        },
        makeOverflow() {
            this.modalWidth = document.querySelector('dialog.wide').offsetWidth - 40
            
        }
    },
    watch: {
        lastIntRateUpdate(val) {
            // console.log(val, this.bank);
            if(val[0].id === this.bank.id)  {
                this.bank = _.cloneDeep(val[0]);

                this.processChartData();
            }

        },
        lastIntRateDelete(val) {
            if(this.bank.id === val)
                eventBus.$emit("close-modal");
        }
    },
}
</script>

<style lang="scss" scoped>
    .irptm-header {

        padding: 0 21px;
        margin: 15px 0;

        &-wrapper {
            display: flex;
            align-items: center;
        }
        & h2 {
            @include H2;
            margin: 0;
            // padding-bottom: 5px;
            margin-left: 20px;
        }
    }

    .irptm-body {
        padding: 0 21px;
        margin-bottom: 20px;
    }

    .irptm-body-table-row {
        margin-bottom: 25px;
    }

    .irptm-body-table-row--unit__header {
        @include H7;
    }

    .irptm-body-table-row--unit__body {
        margin-top: 5px;
        @include H6;
        font-weight: normal;
        color: $input-color-filter;
    }

    .chart-wrapper {
        margin: 20px 0 50px; 
        background-color: #1C1C1C; 
        padding: 10px 0; 
        border-radius: 3px; 
        overflow-x: auto;

        @include scrollbarDark;

        &:last-child {
            margin-bottom: 10px;
        }
    }
</style>

