<template>
    <div :class="fontsize">
        <div 
        class="main-video-grid"
        :class="{'qna-on': vidComQnaOn}"
        :style="innerElementHeight">
            <div class="main-video-grid-center"
            >
                <div v-for="video in videoInPlayer"
                :key="video.src">
                    <component 
                    :is="'video-player'" 
                    :video="video" 
                    :key="video.src"
                    :impID="details.implementation_id"
                    :firstTemp="firstTemp"
                    :isLocked="isLocked"
                    :code="details.widgetCode"
                    :videoAutoPlay="videoAutoPlay"
                    @allow-player-render="allowPlayerRender"
                    ></component>
                </div>

                <div class="video-selection">
                
                    <video-list
                    :origin="details.widgetCode"
                    :listStyle="playlistHeight"
                    :noItemMessage="'No recordings found'"
                    :sortedItems="sortedItems"
                    :resultsReady="resultsReady"

                    :firstTemp="firstTemp"
                    :isLocked="isLocked"
                    :withUpcoming="false"
                    @load-more="loadMore"
                    :noMoreItems="noMoreItems"
                    :layoutID="details.layoutID"
                    :newHeadline="newHeadline"
                    @new-headline-viewed="newHeadline = false"
                    @render-video="renderVideo"
                    :impID="details.implementation_id"
                    ></video-list>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ScrollMixin from '../../mixins/scrollMixins';
import Headline from '../../mixins/headline';
import { eventBus } from '../../main';
import VideoList from './components/VideoPlayers/VideoList';
import VideoPlayer from './components/VideoPlayers/VideoPlayer';

import _ from "lodash";
import { createNamespacedHelpers } from 'vuex';

const general = createNamespacedHelpers("general");

export default {
    props: ["details"],
    components: {
        VideoList,
        VideoPlayer
    },
    mixins: [ScrollMixin, Headline],
    created() {
        eventBus.$on("widget-resize", obj => {
            if(obj.id === this.details.layoutID) {
                setTimeout(() => {
                    // let heightToUse = obj.height;
                    // const playerHeight = document.querySelector('.video-player-' + this.details.implementation_id)?.clientHeight;
                    // if(playerHeight)
                    this.playlistHeight = {height: obj.height - this.heightAdjustment + 'px'};
                   
                    // console.log(obj.height, playerHeight)
                    
                
                    const innerHeight = obj.height - this.heightAdjustment + 90;
                    this.innerElementHeight = {height: innerHeight  + 'px', 'overflow-y': 'auto', 'overflow-x': 'hidden'};

                    // const playerHeight = document.querySelector('.widget-' + this.details.layoutID + ' .main-video-grid-center')?.clientHeight;
                    
                    // if(playerHeight * 1.5 < innerHeight && !this.details.isMdWidget) {

                    //     this.playlistHeight = { height: innerHeight - playerHeight - 110 + 'px' };
                    //     console.log(this.playlistHeight);
                    // }

                    
                }, 2000);
                

            }
            
        });

        const obj = {
            menu: {
                isSearchEnabled: true,
                // isGlossaryEnabled: false,
                isVideoQuestionEnabled: true,
            }
            
        }
        this.$emit('set-frame', obj);

        if(this.readyForHeadlineProcessing) {
            this.fetchInitialHeadlines(this.categories);
        }
    },
    computed: {
        ...general.mapGetters(['userQnaOn']),
        heightAdjustment() {
            if(this.details.width >= 417)
                return 160;

            return 155;
        },
        fontsize() {
            if(this.details.userSettings)
                return this.details.userSettings.fontsize;
            
            return '';
        },
        firstTemp() {
            return this.bulkHeadlines?.[0]?.id;
        },
        vidComQnaOn() {
            return this.details.widgetCode === "VIDCOM" && this.userQnaOn;
        }
        
    },
    data() {
        return {
            slug: "vidcom",
            playlistHeight: '',
            sources: (this.$store.getters["widget/getWidgetByImplementationId"](this.details.implementation_id)).sources,
            categories: (this.$store.getters["widget/getWidgetByImplementationId"](this.details.implementation_id)).categories,
            currentSection: 2,
            fetchMode: true,
            videoInPlayer: [],
            sortProp: "published_at",
            firstRender: true,
            activeVideo: null,
            isVidcom: true,
            forceLoadMoreDone: false,
            innerElementHeight: '',
            videoAutoPlay: false
        }
    },
    provide() {
        return {
            $activeVideo: () => this.activeVideo
        }
    },
    methods: {
        renderVideo(obj) {
            this.videoInPlayer = [obj];
            this.activeVideo = obj.id;
            
            this.$nextTick(() => eventBus.$emit("widget-resize", {
                id: this.details.layoutID,
                height: this.details.height,
                
            }));
            
            this.$emit('vidcom-id-change', obj.id);
        },
        allowPlayerRender(trueOrFalse) {
            this.allowRender = trueOrFalse;
            // console.log("allow", trueOrFalse);
        },
        focusFirstVideoIfNeeded() {

            const firstVideo = this.bulkHeadlines.find(el => el.content.indexOf('iframe') > -1);

            if(firstVideo && this.firstRender) {
                const regExRule = /\s(\w*src=+?)"(.+?)"/g;
                
                const src = (regExRule.exec(firstVideo.content))[2];

                const obj = {
                    id: firstVideo.id,
                    src: src,
                    title: firstVideo.subject,
                    time: firstVideo.published_at,
                    asked_by_user_id: firstVideo.asked_by_user_id,
                    question_text: firstVideo.question_text
                };

                this.renderVideo(obj);

                this.firstRender = false;
            }

            setTimeout(() => {
                this.videoAutoPlay = true;
            }, 500);
                
        }
    },
    watch: {
        resultsReady(val) {
            this.fetchMode = !val;
            this.focusFirstVideoIfNeeded();

            if(val && 
            _.isEmpty(this.sortedItems) &&
            !this.forceLoadMoreDone) {
                this.loadMore();
                this.forceLoadMoreDone = true;
            }
            
        }
    }
}
</script>

<style lang="scss">
    .main-video-grid {
        padding: 10px 0;
        display: block;
        // max-width: 990px;

        font-size: inherit;

        &::-webkit-scrollbar {
            width: 0;
            }

            &::-webkit-scrollbar-corner {
            display: none;
            }

        scrollbar-width: none;
        
        &-center {

            & > div:first-child {
                background-color: $onboard-survey-card-background;
                border-radius: 10px;
            }

            & > div:last-child {
                & > div {
                    background-color: $onboard-survey-card-background;
                    border-radius: 10px;
                }
                
            }

        }
        
    
    }

    .video-player {

        &-resizer {
            max-width: 500px;
            margin: 0 auto;
        }

        // background-color: $color-03;
        color: $color-06;

        &__iframe {
            
            overflow: hidden;
            // Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625)
            padding-top: 56.25%;
            position: relative;

            & iframe {
                position: absolute;
                border: 0;
                height: 100%;
                left: 0;
                
                top: 0;
                width: 100%;
            }
        }

        &__description {

            // padding: 15px 10px 5px 10px;

            @include respond(mobile) {
                padding: 15px 10px 25px 10px;
            }
            

            &--title {
                font-size: 1.33em;
                color: $input-color;
            }

            &-details {

                // display: grid;
                // grid-template-columns: auto auto 1fr;
                //     grid-template-rows: auto;
                //     margin-top: 10px;
                // @include scrollbarDark;

                // color: $input-color-filter;

                // & h1 {
                //     font-size: 24px;
                // }
                // & h2 {
                //     font-size: 22px;
                //     font-weight: normal;
                // }
                // & h3 {
                //     font-size: 18px;
                //     font-weight: normal;
                // }

                // a {
                //     color: $checkbox-active;
                // }

                margin-top: 5px;

                // &--profile {
                //     & .news-profile-img {

                //         // @include respond(desktop) {
                //         //     height: 40px;
                //         //     border-radius: 40px;
                //         // }
            
                //         // @include respond(xl) {
                //         //     height: 45px;
                //         //     border-radius: 45px;
                //         // }
            
            
                //         // @include respond(mobile) {
                //         //     height: 40px;
                //         //     border-radius: 40px;
                //         // }
                //     }
                // }

                &--time {

                    // //display: inline-block;
                    color: $input-color-filter;
                    margin: 5px 0 5px;
                    padding: 8px 0;
                    text-align: center;

                    font-size: 0.9em;

                    // margin-left: 10px;
                }

                & .news--item__feedback {
                    grid-column: 1 / 4;
                    grid-row: 2;
                }

            }

            &-star-rating {
                display: inline-block;
                margin-left: 20px;
            }
        }

        &__content {
            margin-top: 20px;
            padding: 12px 5px;
            background-color: $onboard-survey-background;

            & h3 {
                margin: 0;
                margin-bottom: 8px;
                @include H3;
                font-weight: 500;
                padding-left: 12px;
                color: $input-color;
            }
            
        }
    }

    .tiles .video-player-resizer {
        max-width: unset;
    }

    .answer-card {
            margin-top: 20px;
            padding: 15px;
            border-radius: 20px;
            background-color: $card-details-background;

            &-icon {
                
                display: inline-block;
                width: fit-content;
                padding: 4px 8px;
                border-radius: 9px;
                font-size: 10px;
                

                & > span {
                    display: flex;
                    align-items: center;

                }

                & img {
                    height: 16px;
                    margin-right: 5px;
                }

                &.gray {
                    background-color: $answer-button-background;
                    color: $answer-button-text;
                    padding: 8px 8px;

                    & a {
                        color: $answer-button-text;
                        text-decoration: none;
                    }
                }

                &.yellow {
                    background-color: #EAC551;
                    color: black;
                }
                &.yellow-empty {
                    background-color: transparent;

                    & a {
                        padding: 8px;
                        border: 1px solid #EAC551;
                        color: #EAC551;
                        display: inline-block;
                        text-decoration: none;
                        border-radius: 8px;
                    }
                }
            }

            &-legend {
                font-size: 10px;
                color: $core-gray;
                margin-top: 8px;
                margin-bottom: 3px;
                font-weight: 600;
            }

            &-question {
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                height: 31px;
                line-height: 31px;
                padding: 0 8px;
                background-color: $separator-line-color-2;
                border-radius: 10px;
                color: $input-color;
                font-size: 14px;

            }
        }

    .answer-icon-round {
        padding: 0px;
        height: 27px;
        width: 27px;
        border-radius: 26px;
        display: flex;
        background-color: $onboard-survey-card-background;
        align-items: center;
        justify-content: center;

    }

    .video-preview {
        // background-color: $onboard-survey-card-background;

        overflow: hidden;

        &-label {
            padding: 8px 16px 8px;
            font-size: 18px;
            // line-height: 16px;
            // letter-spacing: 2px;
            color: $input-color;

            &.multi-item {
                font-size: 10px;
            }
            
        }

        &-main {
            height: 100%;
            overflow-y: auto;

            @include scrollbarDark;
        }
    }

    .video-preview-label.VIDCOM {
        padding: 0 16px 8px;
    }

    .video-preview-unit, .video-preview-unit-not-clickable {

        display: grid;
        grid-template-columns: 80px 1fr;
        grid-template-rows: auto;
        
        padding: 20px;
        cursor: pointer;
        transition: background-color 0.2s ease;

        // &:last-child {
        //     border:none;
        // }

        &:first-child {
            @include respond(desktop) {
                margin-top: 0;
            }
            
        }

        @include respond(desktop) {
            padding: 5px;
        }

    

        &__thumbnail {

            position: relative;
            height: fit-content;

            & img {

                width:100%;
                
            }

            
        }

        &__description {

            padding: 10px 10px 0 10px;

            // grid-column: 1 / -1;
            

            &--title {

                letter-spacing: 1px;
                color: $input-color;
                line-height: 16px;
                font-size: 1.137em;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;

            }
            
            &--details {

                display: grid;
                grid-template-columns: auto auto 1fr;
                margin-top: 5px;


                

                &-profile {

                    // @include respond(desktop) {
                    //     grid-column: 1 / 3;
                    // }
        
                    // @include respond(xl) {
                    //     grid-column: 1 / 3;
                        
                    // }
        
                    // @include respond(mobile) {
                    
                    // }
                }

                &-time {
                    line-height: 12px;
                    text-align: center;
                    font-size: 1.1em;
                    color: $input-color-filter;
                    padding: 6px 0px;
                    
                    
                    margin: 5px 0 5px 0;

                    margin-left: 1px;
                }

            }
            
        }

        &-not-clickable {
            cursor: auto;
        }
    }

    #video-library-list {

        & > div:last-child {

            @include respond(desktop) {
                margin-bottom: 40px;
            }
        
            @include respond(mobile) {
                margin-bottom: 70px;
            }
            
        }

        & .video-preview-unit__description {

            @include respond(desktop) {
                padding-top: 10px;
            }
            
        }

        

    }

    .spinner-container {
        height: 60px;
    }

    #video-question {
        padding: 12px;
        margin: 20px 0;

        @include respond(lg) {
            margin-top: 0;
        }

        & > form {
            display: flex;
        }

        & input {
            height: 36px;
            font-size: 14px;
        }

        & button {
            color: $input-color;
            background-color: $checkbox-active;
            width: 35px;
            border: none;
            border-radius: 10px;
            font-size: 14px;
            margin-left: 5px;
            cursor: pointer;

            &:disabled {
                background-color: $input-placeholder-color;
            }
        }
    }

    #video-custom-block {
        height: 0px;
        margin-top: 20px;

        @include respond(lg) {
            height: 60px;
            margin-top: 0px;
            margin-bottom: 20px;
        }
        
    }

    .video-resources {
        padding: 5px 10px;
        color: $input-color;
        letter-spacing: 0.2px;

        & h1 {
            @include H3;
        }
        & h2 {
            @include H4;
        }
        & h3, 
        & h4, 
        & h5, 
        & h6 {
            @include H5;
        }

        & ul,
        & ol {
            margin: 5px 0;
            padding-left: 20px;
        }

        & a,
        & a:link,
        & a:hover,
        & a:active,
        & a:visited {
            color: $checkbox-active;
        }

    }

    // .size-sm .main-video-grid {
    //     padding: 10px 10px;
    //     // background-color: $color-013;
    // }

    .size-xs .video-preview-label.multi-item {
        font-size: 12px;
    }

    .size-sm {
        
        & .main-video-grid {
            // margin: 0 auto;
            .main-video-grid-center {
                max-width: 677px;
                margin: auto;
                display: grid;
                gap: 20px;
                grid-template-columns: minmax(0, 407px) 250px;
                grid-template-rows: auto;
            }

            // &.qna-on {
            //     max-width: 627px;
            //     grid-template-columns: minmax(0, 357px) 250px;
            // }
        }
    }

    .size-sm1 {
        & .main-video-grid {
            & .main-video-grid-center {
                max-width: 816px;
                grid-template-columns: minmax(0, 500px) 296px;
            }
        }
    }

    .size-md .video-preview-label.multi-item {
        font-size: 14px;
    }

    .size-md .main-video-grid {
        // max-width: 950px;
        // width: 861px;
        margin-top: 5px;
        margin-left: auto;
        margin-right: auto;
        padding: 0;

        & .main-video-grid-center {
            max-width: 1070px;
            margin: auto;
            grid-template-columns: minmax(0, 700px) 350px;

        }

        &.qna-on .main-video-grid-center {
            max-width: 930px;
            grid-template-columns: minmax(0, 560px) 350px;

        }
    }

    .size-md .video-player {
        grid-column: 2;
        padding: 0px 0 25px 0;
    }

    .content-card .video-player {
        padding: 0;
    }

    .video-player__description {
        padding: 15px 10px 25px 10px;
    }

    .size-sm .video-player__description {
        padding: 15px 10px 5px 10px;
    }

    .content-card .video-player__description {
        padding: 0;
        padding-top: 8px;
    }

    .size-md .video-player__description--title {
        font-size: 1.478em;
    }

    // .size-sm .video-preview {
    //     grid-column: 1;
    //     grid-row: 1;
    //     border: 1px solid $color-03;
    //     // padding: 3px;
    // }

    // .size-sm .video-preview-main {
    //     padding: 0 5px;
    // }
    .video-selection {
        margin-top: 5px;
    }

    .size-sm .video-selection {
        grid-column: 2;
        grid-row: 1;
        padding: 0;

    }

    .video-preview.unit {
        padding-top: 5px;
    }

    .size-xs .video-preview-unit,
    .size-xs .video-preview-unit-not-clickable {
        padding: 10px 5px;
    }


    .size-xs .video-preview-unit__thumbnail {
        // grid-column: 1 / 7;
    }

    .size-xs .video-preview-unit__description {
        // grid-column: 7 / -1;
        padding: 0 5px;
        font-size: 0.75em;

        &--details-time {
            font-size: 0.70em;
        }
    }

    .size-xs1 .video-preview-unit__thumbnail {
        // grid-column: 1 / 6;
    }

    .size-xs1 .video-preview-unit__description {
        // grid-column: 6 / -1;
        padding: 0 5px;
        font-size: 0.80em;

        &--details-time {
            font-size: 0.75em;
        }
    }

    .size-sm .video-preview-unit,
    .size-sm .video-preview-unit-not-clickable {
        grid-template-columns: 90px 1fr;
    }

    .size-sm .video-preview-unit__thumbnail {
        // grid-column: 1 / 4;
    }

    .size-sm .video-preview-unit__description {
        // grid-column: 4 / -1;
        padding: 0 10px;
        font-size: 0.80em;

        &--details-time {
            font-size: 0.75em;
        }
    }

    .size-md .video-preview-unit,
    .size-md .video-preview-unit-not-clickable {
        grid-template-columns: 130px 1fr;
    }
    .size-md .video-preview-unit__thumbnail {
        // grid-column: 1 / 6;
    }

    .size-md .video-preview-unit__description {
        // grid-column: 6 / -1;
        padding: 0 5px;
        font-size: 0.80em;

        &--details-time {
            font-size: 0.75em;
        }
    }
    .size-md .video-preview-unit__description {
        padding-left: 10px;
    }

    .size-md .video-preview-unit__description--details {
        grid-template-columns: auto auto auto;
    }

    .size-md .video-selection {
        margin-top: 0;
    }

    .video-preview-unit {
        text-decoration: none;
        padding: 0;
    }

    .overview .video-preview-unit {
        cursor: default;
        grid-template-columns: repeat(12, 1fr);
    }
    .overview .video-preview-unit__thumbnail {

        grid-column: 1 / -1;

        @include respond(sm) {
            grid-column: 1 / 4;
        }
        
        & img {
            border-radius: 10px;
        }
    }

    .overview .video-preview-unit__description {
        grid-column: 1 / -1;
        padding: 0 5px;
        font-size: 0.80em;
        margin-left: 10px;
        
        @include respond(sm) {
            grid-column: 4 / -1;
        }
        

        &--details-time {
            font-size: 0.75em;
        }
    }

    .video-library-star-rating {
        margin-top: 20px;
        grid-column: 1 / -1;
        text-align: center;
    } 

    .feedback-form-video-commentary-label {
        // font-size: 16px;
        color: $color-06;

        
    }

    .progress-bar {
        position: absolute;
        bottom: 0px;
        left: 0;
        height: 5px;
        background-color: $core-lightred;
        width: 0;
    }

    .is-locked {
        // cursor: not-allowed;
        & .video-preview-unit__description--title {
            color: $input-color-filter;

        }
    }
    
</style>