import * as workerTimers from 'worker-timers';
import appService from '../services/app.service';
import { formatCountdown } from '../utility/datetime';
import { eventBus } from '../main';

export default {
    created() {
        this.$nextTick(() => {
            this.handleLockUser();
            this.lockUserHeartBeat = workerTimers.setInterval(() => {
                this.handleLockUser();
            }, 1000);
        });
    },
    beforeDestroy() {
        workerTimers.clearInterval(this.lockUserHeartBeat);
        this.lockUserHeartBeat = null;
        appService.unLockUserEditing(this.listUserForLockUserEditing.id);
    },
    computed: {
        formattedTimeLeft() {
            return formatCountdown(this.timeLeft, false);
        }
    },
    data() {
        return {
            lockUserHeartBeat: null,
        }
    },
    methods: {
        handleLockUser() {
            
            if(this.timeLeft === 1)
                eventBus.$emit("close-modal");
            if(this.timeLeft % 60 === 0)
                appService.lockUserEditing(this.listUserForLockUserEditing.id);
            
    
            this.timeLeft--;
        }
    }
    
}