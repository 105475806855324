<template>
    <a
    class="section-card"
    :class="sectionCardClasses"
    @click="cardClicked(cardType)"
    >
        <div>
            <div class="video-card-thumbnail">
                <div class="responsive-16-9-frame">

                    <!-- <iframe
                    v-if="item.video_id" 
                    class="terminal-embed-video" :src="vimeoLink"
                    frameborder="0" 
                    allow="accelerometer; 
                    autoplay; 
                    encrypted-media; 
                    gyroscope; 
                    picture-in-picture; 
                    fullscreen" 
                    allowfullscreen="" 
                    data-ready="true">
                    </iframe> -->

                    <img
                    :src="thumbNail" :alt="element.name" />
                    <padlock-overlay
                    v-if="notAvailable"
                    ></padlock-overlay>
                </div>
            </div>
            <div class="video-card-text">
                <div class="video-card-hint"
                :class="{'not-available': notAvailable}">
                    {{ supText }}
                </div>
                <div class="video-card-title">
                    <span v-if="isLive" class="live-span">
                            <font-awesome-icon :icon="['fas', 'dot-circle']" /> LIVE:
                        </span>
                    {{ element.name }}
                </div>

                
            </div>
        </div>
        <!-- Livestreams -->
        
    </a>
</template>

<script>
import { eventBus } from '../../../main';
import PadlockOverlay from '../../../assets/PadlockOverlay.vue';
import { usersTime } from '../../../utility/datetime';
import LivestreamService from '../../../services/livestream/livestream.service';
import { createNamespacedHelpers } from 'vuex';
import VideoService from '../../../services/video/video.service';
import ContentLockedMixin from '../../../mixins/contentLockedMixin';
import { getServerEnv } from '../../../utility/helper-functions';
import Constants from '../../../utility/constants';

const livestreamNS = createNamespacedHelpers("webinars");
const libraryNS = createNamespacedHelpers("library");
const general = createNamespacedHelpers("general");
const payments = createNamespacedHelpers("payments");
const dashboards = createNamespacedHelpers("dashboard");

export default {
    props: ["item", "cardType"],
    components: {
        PadlockOverlay
    },
    mixins: [ContentLockedMixin],
    created() {
        // console.log("item", this.item, this.isLocked);
        this.checkCount();
    },
    computed: {
        ...livestreamNS.mapGetters(['lastLiveStreamUpdate', 'lastLiveStreamDelete']),
        ...libraryNS.mapGetters(['lastVideoUpdate', 'lastVideoDelete']),
        ...general.mapState(['demoPageMode']),
        ...general.mapGetters(['user']),
        ...payments.mapState(['availableProducts']),
        ...payments.mapGetters(['videoWidgetsForShop']),
        ...dashboards.mapGetters(['layouts']),
        details() {
            return this.item;
        },
        element() {
            return this.videoWidgetsForShop.find(el => el.implementation_id === this.item.impID);
        },
        sectionCardClasses() {
            // const noaction = this.noActions ? 'no-action' : '';
            return this.cardType; //+ ' ' + noaction;
        },
        thumbNail() {
            const src = this.element.image;
            const root = window.bootUp.entity.mapped_domain + '/assets/';
          
            if(src)
                return root + src;
            return '';
        },

        notAvailable() { // based on notAddable in widget cards
            if(this.includedAsAProd)
                return false;
            else if(this.onlyOnOtherPlan)
                return true;
            else if(this.notOnAnyPlan)
                return true;
            
            return false;
        },
        includedAsAProd() {
            return this.element.planDetails.includedPlan.length;
        },
        onlyOnOtherPlan() {
            return !this.element.planDetails.included && !this.notOnAnyPlan;
        },
        notOnAnyPlan() {
            return !this.element.planDetails?.planProducts.filter(el => el.is_visible_front_end).length;
        },
        timezone() {
            return this.user.timezone;
        },
        supText() {
            if(this.notAvailable) {
                if(this.element.planDetails.favorableProduct)
                    return 'Activate now'//'Included in ' + this.element.planDetails.favorableProduct.name;

                return 'Not available for purchase';
            }
            if(this.element.code === 'TCSTREAM') {
                if(this.archiveOnlyCount && this.upcomingOnlyCount === 0) {
                    return "Contains " + this.archiveOnlyCount + " recorded stream" + (this.archiveOnlyCount > 1 ? "s" : "");
                }
                else if(this.isLive) {
                    return "Streaming now"
                }
                else if(this.element.training_centre_order_at)
                    return "Next live event: " + usersTime(this.thisVidDashboard.orderBy, true, true, false, {
                        dateFormat: "d M",
                        timeFormat: "24hr"
                    }, false, this.timezone);

            }
            return 'Video Course, Includes ' + this.videoCount + ' video' + (this.videoCount > 1 ? 's': '');
        },
        thisVidDashboard() {
            return this.layouts["vidpage_" + this.element.implementation_id];
        }

    },
    data() {
        return {
            archiveOnlyCount: 0,
            upcomingOnlyCount: 0,
            videoCount: 0,
            isLive: false
        }
    },
    methods: {
        cardClicked(type) {

            if(this.notAvailable && !this.demoPageMode) {
                if(!this.element.planDetails.favorableProduct)
                    return;

                let prodID = this.element.planDetails.favorableProduct.id;

                //XMAS PROMO
                // const env = getServerEnv();
                // if(this.element.implementation_id === Constants.MTA_COURSE.impID[env])
                // prodID = Constants.MTA_COURSE.prodID[env];
                //XMAS PROMO ends

                //July PROMO
                const env = getServerEnv();
                if(this.element.implementation_id === Constants.RISK_COURSE.impID[env])
                prodID = Constants.RISK_COURSE.prodID[env];
                //July PROMO ends

                const instanceID = this.$root.instanceID;

                const modal = {
                    instanceID
                };

                //individual purchase
                // const mode = "purchase";

                // let panel = {
                //     view: 'widget-config',
                //     id: this.element.implementation_id,
                //     mode,
                //     widget: this.item,
                //     // widgetDirectAddition: this.widgetDirectAddition
                //     isCourse: this.element.code === 'TCVIDEO',
                //     isLiveStream: this.element.code === 'TCSTREAM',
                // };
                //individual purchase

                //SPECIAL PROMO
                // if(this.item.impID === 80) {

                    let panel = {
                        view: 'product-config',
                        id: prodID,
                        
                    };

                // modal.header = false;
                //this.item.promotion_ends_at
                const promoEndDate = Object.values(this.availableProducts).find(el => el.id === prodID)?.promotion_ends_at;
                // console.log(promoEndDate);
                modal.countdown = promoEndDate;
              
                // }
                // SPECIAL PROMO ENDS

                // modal.header = false;
                modal.componentInModal = "widget-shop";
                modal.classes = "terminal-modal simplified";
                modal.panel = panel;
                modal.mode = "wide";

                eventBus.$emit('open-modal', modal);
                

            }
            else if(type === "livestreams" || type === "vidlibs" || type === "withupcoming" || type === "noupcoming") {
                this.$store.dispatch("dashboard/setActiveDashboard", { 
                    target: "videopage",
                    instanceID: this.$root.instanceID,
                    id: "vidpage_" + this.item.impID
                });
                eventBus.$emit("render-video-library-component", { 
                    instanceID: this.$root.instanceID,
                    impID: this.item.impID 
                });
            }
                
        },
        async checkCount() {
            if(this.cardType !== 'vidlibs') {
                const r = await LivestreamService.fetchInitialLiveStreams(this.item.impID, true)
                // .then(r => {
                    const current = r.filter(el => el.status === 'live');
                    const archives = r.filter(el => el.status !== 'scheduled' && el.status !== 'live').length;
                    const upcomings = r.filter(el => el.status === 'scheduled' || el.status === 'live').length;

                    if(current.length) {
                        this.isLive = true;
                    }else{
                        this.isLive = false;
                    }
                        
                    
                    this.archiveOnlyCount = archives;
                    
                    this.upcomingOnlyCount = upcomings;
                // });
            }
            else if(this.cardType === 'vidlibs') {
                
                const topCats = this.element.categories;
                const subCats = await VideoService.getSubCategoriesForCourse(...topCats);
                const itemsToFetch = [...topCats, ...subCats.map(el => el.id)];
                const r = await VideoService.fetchInitialItems({
                    categories: itemsToFetch,
                    type: "course"
          
                });

                this.videoCount = r.videos.length;
                
            }
        }
    },
    watch: {
        lastLiveStreamUpdate() {
            this.checkCount();
        },
        lastLiveStreamDelete() {
            this.checkCount();
        },
        lastVideoUpdate() {
            this.checkCount();
        },
        lastVideoDelete() {
            this.checkCount();
        }
    }
}
</script>

<style lang="scss" scoped>
    a.section-card {
        display: block;
        cursor: pointer;
    }
    .video-card-thumbnail {
        border-radius: 10px;
        overflow: hidden;
    }
    .video-card-text {
        padding: 10px 0;
    }
    .video-card-hint {
        @include H7;
        color: $input-color-filter;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .video-card-title {
        @include H4;
        line-height: 22px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .not-available {
        color: $core-fuschia;
    }
</style>