<template>
    <div class="research-screen-filter">
        <div class="research-screen-filter-header">
        </div>
        
        <div class="research-screen-filter-body">
            <form @submit.prevent style="font-size: 14px; margin-top: 12px;">
                <div class="md-grid-2">
                    <div class="md-grid-2">
                        <div>
                            <div>Countries</div>
                            <div class="md-grid-2" style="margin: 15px 0;">

                                <div class="aggregate-country-checkbox">
                                    <label for="ALL" style="display: inline-block;">
                                    <input type="checkbox" id="ALL" style="height: 14px;" :checked="allCountriesTicked" @change="countriesTickChange('all')">
                                    All countries
                                    </label>
                                </div>

                                <div class="aggregate-country-checkbox">
                                    <label for="G7" style="display: inline-block;">
                                    <input type="checkbox" id="G7" style="height: 14px;" :checked="g7CountriesTicked" @change="countriesTickChange('g7')">
                                    G7 countries
                                    </label>
                                </div>

                                <div class="aggregate-country-checkbox">
                                    <label for="major" style="display: inline-block;">
                                    <input type="checkbox" id="major" style="height: 14px;" :checked="majorCountriesTicked" @change="countriesTickChange('major')">
                                    Major countries
                                    </label>
                                </div>

                            </div>
                            <div class="md-grid-2" style="margin-bottom: 12px;">
                                <div>
                                    <div v-for="item in countryLeft"
                                    :key="item.code"
                                    class="u-compact-checkbox filter-checkbox"
                                    >   
                                        <input 
                                        type="checkbox" v-model="tickedCountries" :value="item.code"
                                        :id="item.code">
                                        <label :for="item.code">{{ item.name }}</label>
                                    </div>
                                </div>
                                
                                <div>
                                    <div v-for="item in countryRight"
                                    :key="item.code"
                                    class="u-compact-checkbox filter-checkbox"
                                    >   
                                        <input 
                                        type="checkbox" v-model="tickedCountries" :value="item.code"
                                        :id="item.code">
                                        <label :for="item.code">{{ item.name }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="md-grid-2">
                        <div>
                            <div>Indicators</div>
                            <div class="md-grid-2">

                                <div v-for="col in originalGroupCols.slice(1)"
                                :key="col.group_id"
                                class="u-compact-checkbox filter-checkbox"
                                style="margin: 15px 0;"
                                >
                                    <div class="aggregate-indicator-checkbox">
                                        <label :for="col.group_id" style="display: inline-block;">
                                        <input type="checkbox" :id="col.group_id" style="height: 14px;" :checked="groupColModel[col.group_id]" @change="groupColTickChange(col.group_id)">
                                        {{ col.name }}
                                        </label>
                                    </div>
                                    <div v-for="data in col.data"
                                    :key="data.data_id"
                                    >
                                        <label :for="data.data_id"><input 
                                        type="checkbox" v-model="tickedGroupedDataIDs" :value="data.data_id"
                                        :id="data.data_id">{{ data.name }}</label>
                                    </div>
                                </div>
                                


                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <base-button
                    mode="o-blue"
                    :link="false"
                    @click.native="applyFilters"
                    >Apply
                    </base-button>
                </div>
            </form>
        </div>
        

    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { diffArray } from '../../../../utility/helper-functions';
import { researchCountries } from '../../../../../test';
import { eventBus } from '../../../../main';

const research = createNamespacedHelpers('research');

export default {
    created() {
        this.allCountries = researchCountries;
        this.tickedCountries = [...this.countries];
        this.tickedGroupedDataIDs = [...this.groupedDataIDs];
    },
    computed: {
        ...research.mapGetters(['groupCols', 'originalGroupCols', 'countries', 'groupedDataIDs']),
        countriesThatCount() {
            return this.allCountries.slice(0);
        },
        countryLeft() {
            return this.countriesThatCount.slice(0,12);
        },
        countryRight() {
            return this.countriesThatCount.slice(12);
        },
    },
    data() {
        return {
            // countries: [],
            allCountriesTicked: false,
            g7CountriesTicked: false,
            majorCountriesTicked: false,
            tickedCountries: [],
            groupColModel: {},
            tickedGroupedDataIDs: []
        }
    },
    methods: {
        ...research.mapActions(['setCountries', 'setGroupedDataIDs']),
        countriesTickChange(tickbox) {
            this.allCountriesTicked = false;
            this.g7CountriesTicked = false;
            this.majorCountriesTicked = false;
            const oldCountries = [...this.tickedCountries];
            this.tickedCountries = [];
            this.$nextTick(() => {
                if(tickbox === 'all') {
                    const isAll = this.allCountries.every(el => oldCountries.includes(el.code));
                    if(!isAll)
                        this.tickedCountries = this.allCountries.map(el => el.code);
                }
                else if(tickbox === 'g7') {
                    const isG7 = this.allCountries.filter(el => el.g7).every(el => oldCountries.includes(el.code));
                    // console.log(isG7)
                    const g7s = this.allCountries.filter(el => el.g7).map(el => el.code);
                    if(isG7)
                        this.tickedCountries = diffArray(oldCountries, g7s);
                    else
                        this.tickedCountries = [...this.tickedCountries, ...g7s];
                }
                else if(tickbox === 'major') {
                    const isMajor = this.allCountries.filter(el => el.major).every(el => oldCountries.includes(el.code));
                    
                    const majors = this.allCountries.filter(el => el.major).map(el => el.code);
                    if(isMajor)
                        this.tickedCountries = diffArray(oldCountries, majors);
                    else
                        this.tickedCountries = [...this.tickedCountries, ...majors];
                }

            });
            
        },
        groupColTickChange(tickbox) {
            // console.log(tickbox);
            const ids = this.originalGroupCols.find(el => el.group_id === tickbox).data.map(el => el.data_id);
            if(!this.groupColModel[tickbox]) {
                ids.forEach(el => {
                    if(!this.tickedGroupedDataIDs.includes(el))
                        this.tickedGroupedDataIDs.push(el);
                });
            }else{
                ids.forEach(el => {
                    const index = this.tickedGroupedDataIDs.indexOf(el);
                    if(index > -1)
                        this.tickedGroupedDataIDs.splice(index,1);
                });
            }
                
        },
        applyFilters() {
            this.setCountries(this.tickedCountries);
            this.setGroupedDataIDs(this.tickedGroupedDataIDs);
            eventBus.$emit("close-modal");
        }
    },
    watch: {
        tickedCountries(nVal) {
            if(this.allCountries.every(el => nVal.includes(el.code)))
                this.allCountriesTicked = true; 
            else {
                this.allCountriesTicked = false; 
            }
            if(this.allCountries.filter(el => el.g7).every(el => nVal.includes(el.code))) {
                this.g7CountriesTicked = true;
            }
            else {
                this.g7CountriesTicked = false;
            }
            if(this.allCountries.filter(el => el.major).every(el => nVal.includes(el.code))) {
                this.majorCountriesTicked = true;
            }
            else {
                this.majorCountriesTicked = false;
            }

                
        },
        tickedGroupedDataIDs(nVal) {
            const obj = {};
            this.originalGroupCols.slice(1).forEach(el => {
                obj[el.group_id] = el.data.map(elem => elem.data_id);
            });
            
            Object.entries(obj).forEach(key => {
                const groupID = key[0];
                const dataIDs = key[1];
                
                if(dataIDs.every(el => nVal.includes(el)))
                    this.groupColModel[groupID] = true;
                else
                    this.groupColModel[groupID] = false;
            });

            
        }
    }
}
</script>

<style lang="scss" scoped>
    .research-screen-filter {
        padding: 12.5px 25px 0;
    }
    .filter-checkbox {

        width: 15vw;

        @include respond(lg) {
            width: 155px;
        }

        @include respond(xl) {
            width: 168px;
        }
    }
</style>