import { render, staticRenderFns } from "./TermsConditions.vue?vue&type=template&id=4ee04314&scoped=true&"
import script from "./TermsConditions.vue?vue&type=script&lang=js&"
export * from "./TermsConditions.vue?vue&type=script&lang=js&"
import style0 from "./TermsConditions.vue?vue&type=style&index=0&id=4ee04314&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ee04314",
  null
  
)

export default component.exports