import AnnouncementService from "/src/services/announcement/announcement.service"
export default {
    fetchAnnouncements({commit}) {
        AnnouncementService.listAllAnnouncements().then((result) => commit('setAnnouncements', result))
    },
    setAnnouncements({commit}, payload) {
        commit('setAnnouncements', payload);
    },
    addAnnouncements({commit}, payload) {
        commit('addAnnouncements', payload);
    },
    setAnnouncementAsReadById({commit, dispatch}, payload) {
        AnnouncementService.setAnnouncementAsReadById(payload).then(() => {
            commit('setAnnouncementAsReadById', payload)
            dispatch("fetchAnnouncements");
        })
    }
}
