import httpClient from "../../api/HttpClient";
import {createUrl} from "../../utility/helper-functions";

export default {
    getAllFeatureRequest: function () {
        return httpClient.get(createUrl("/support/feature-request"),  {spinner: false});
    },
    submitFeatureVote: function (feature) {
        return httpClient.post(createUrl("/support/feature-request/" +feature.id + "/upvote" ), {}, {spinner: true});
    },
    submitFeatureRequest: function (data) {
        return httpClient.post(createUrl("/support/feature-request"), data , {spinner: true});
    }
}
