<template>
    
    <div class="data-table-row">

        <div class="data-table-row-main">
            <div class="data-table--cell"
            :style="cellStyles"
            >{{ formattedDate }}</div>
        </div>

        <div class="data-table-row-subrows">

            <calendar-event
            v-for="(calEvent, index) in calendarDay"
            :key="calEvent.id"
            :calEvent="calEvent"
            :details="details"
            :calHeight="calHeight"
            :nextTimeStamp="nextTimeStamp"
            :eventIndex="index"
            :nextEventIndex="nextEventIndex"
            :countrySelection="countrySelection"
            :layoutID="layoutID"
            :autoScroll="autoScroll"
            :singleLine="calendarDay.length===1"
            :isLocked="isLocked"
            @upcoming-event-position="emitUpcomingEventPosition"
            @check-next-event="checkForNextEventTimeSlot"
            ></calendar-event>

        </div>

    </div>
    
</template>

<script>
import dayjs from 'dayjs';
import { createNamespacedHelpers } from 'vuex';
import { usersTime } from '../../../../utility/datetime';
import CalendarEvent from './CalendarEvent';
import calStyleMixin from '../../../../mixins/calStyleMixin';

const general = createNamespacedHelpers("general");

export default {
    props: ["calendarDay", "day", "details", "calHeight", "layoutID", "autoScroll", "countrySelection", "isLocked"],
    mixins: [calStyleMixin],
    components: {
        CalendarEvent
    },
    created() {
        this.checkForNextEventTimeSlot();
    },
    computed: {
        ...general.mapGetters(['user']),
        timezone() {
            return this.user.timezone;
        },
        formattedDate() {
            return dayjs(this.day).format("ddd Do MMM");
        }
    },
    data() {
        return {
            nextTimeStamp: null,
            nextEventIndex: null
        }
        
    },
    methods: {
        checkForNextEventTimeSlot() {
            // console.log("checking for next event time");
            const today = usersTime(new Date(), true, false, false, {dateFormat: "y-m-d"}, "UTC");
            const calendarDay = usersTime(this.day, true, false, false, {dateFormat: "y-m-d"}, "UTC");

            if(today === calendarDay) {
                const currentTime = usersTime(new Date(), true, true, true, {dateFormat: "y-m-d", timeFormat: "24hr"}, "UTC");
                const nextTimeSlotIndex = this.calendarDay.findIndex(element => element.timestamp > currentTime && !element.actual && !element.name.toLowerCase().includes('speech'));
                if(this.calendarDay[nextTimeSlotIndex]) {
                    this.nextEventIndex = nextTimeSlotIndex;
                    this.nextTimeStamp = this.calendarDay[nextTimeSlotIndex].timestamp;
                    // console.log(nextTimeSlotIndex, this.calendarDay[nextTimeSlotIndex]?.timestamp);
                }
                    
            }
        },
        emitUpcomingEventPosition(position) {
            this.$emit("upcoming-event-position", position);
        }
    },
    watch: {
        calendarDay: {
            handler() {
                this.checkForNextEventTimeSlot();
            },
            deep: true
        }
    }
}
</script>