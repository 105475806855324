<template>
    <div class="product-mini-view">
        <div class="product-mini-view--warning">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']"/>
        </div>
        <div class="product-mini-view--icon">
            <span class="product-icon">{{ abbreviation }}</span>
        </div>
        <div class="product-mini-view--details">
            <h3>{{ failed.product.name }}</h3>
            <p>Access will be lost on {{ downgradeDate }}</p>
        </div>
        <div class="product-mini-view--price">
            <span class="price-numeric">{{ price }}</span>
            <span class="price-period dimmed-text sub"> per {{ period }}</span>
        </div>
    </div>
</template>

<script>
import { toSystemDate } from '../../../utility/datetime';
import { formatAndVatPrice } from '../../../utility/helper-functions';
export default {
    props: ["failed"],
    created() {
        console.log(this.failed);
    },
    computed: {
        abbreviation() {
            return this.failed.product.name.substr(0,3);
        },
        downgradeDate() {
            return toSystemDate(this.failed.product_will_expire_at);
        },
        price() {
            return formatAndVatPrice(this.failed.price);
        },
        period() {
            const length = this.failed.subscription_length === 1 ? '' : this.failed.subscription_length;
            const plural = length ? 's': '';
            
            return length + ' ' + this.failed.subscription_length_type + plural;
        }
    }
}
</script>

<style lang="scss" scoped>
    .product-mini-view {

        display: grid;
        grid-template-columns: 30px 60px 1fr 150px;
        gap: 10px;
        // align-items: center;
        border-bottom: 1px solid $separator-line-color;
        padding: 8px 0;
        margin: 18px 0;

        &--warning {
            color: $core-lightred;
            font-size: 24px;
            line-height: 55px;
        }

        &--icon {

            padding: 5px;

            & .product-icon {
                @include productIcon;
                background-color: $core-yellow;

            }
        }

        &--details {

            & h3 {
                @include H3;
                margin: 0;
                margin-bottom: 2px;
            }
            p {
                margin: 0;
                font-size: 12px;
                color: $core-lightred;
            }
        }

        &--price {

            height: 26px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            & .price-numeric {
                @include H3;
                
            }

            & .price-period {
                margin-left: 3px;
                line-height: 1px;
            }
        }
    }
</style>