<template>
    <div  id="compare-plan-step" ref="plansTable">
        <span id="compare-link"><a href="javascript:void(0);" @click="scrollToPlans">Compare plans</a></span>
        <h3>Compare plans:</h3>
        <table>
            <thead>
            <tr>
                <td>
                </td>
                <td v-for="(plan, index) in availablePlansForComparison" :key="plan.slug"
                    :style="{textAlign: 'center'}">
                    <plan-info :plan="plan"
                               :color="COLORS[index]"
                               :has-trials="plan.trials.length > 0"
                               :price="formatPriceObject(plan.pricing, false)"
                               :change-plan-handler="() => changePlan(plan, activePlan)"
                               :downgrade-plan-handler="() => downgradePlan(plan, activePlan)"
                               :open-trial-options-handler="() => openTrialOptions(plan)"
                               :upgrade-status="calculateUpgradeStatus(plan)"
                               :active-plan="activePlan"
                               :start-date="plan.started_at"
                               :upcoming-plans-slugs="upcomingPlansSlugs"></plan-info>
                </td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="widget in widgetsByProduct" :key="widget.id">
                <td class="widget-handler"
                    @click="showWidgetModal(widget)">
                    <label style="cursor: pointer">
                        <VueCustomTooltip :label="widgetInfoLabel(widget)" v-if="widgetInfoLabel(widget)"
                                          :multiline="true"
                                          position="is-right">
                            <font-awesome-icon :icon="['fas', 'question-circle']" />
                        </VueCustomTooltip>
                        <VueCustomTooltip label="Not included in any plan. Can be purchased as an addon." v-else-if="widget.availablePlans.length === 0"
                                          :multiline="true"
                                          position="is-right">
                            <font-awesome-icon :icon="['fas', 'question-circle']" />
                        </VueCustomTooltip>
                        {{ widget.details.name }}
                    </label>

                </td>
                <td v-for="(plan, index) in availablePlansForComparison" :key="'widget_'+plan.slug" style="text-align: center">
                    <template v-if="isPartOfPlan(widget, plan)">
                        <font-awesome-icon  :style="{color:COLORS[index]}"
                                            :icon="['fas', 'check-circle']" size="2x"/>

                    </template>
                    <span v-else-if ="widget.availablePlans.length === 0">-</span>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

import _ from "lodash";
import Constants from "../../../utility/constants";
import PlanInfo from "./PlanInfo";
import SubscriptionMixin from "../../../mixins/SubscriptionMixin";
import TrialPeriodDetails from "../payments/TrialPeriodDetails";
import { scroller } from 'vue-scrollto/src/scrollTo';

export default {
    name: "PlanWidgetsBox",
    components: {PlanInfo},
    mixins: [SubscriptionMixin],
    props: {},
    data: function () {
        return {
            COLORS: {
                0: '#aa660b',
                1: '#0a5aa3',
                2: '#92950b',
                3: '#1a7133',
                5: '#1a7133',
                6: '#1a7133',
                7: '#1a7133',
                8: '#1a7133',
                9: '#1a7133',
                10: '#1a7133'
            }
        };
    },
    computed: {
        widgetsByProduct: function () {
            let widgets = this.sortedWidgetsByCreationDate.map(widget => {
                let newWidget = _.cloneDeep(widget);
                newWidget.availablePlans = widget?.planDetails?.products
                    ?.filter(product => product.is_promotion === false)
                    ?.filter(product => product.product_type === Constants.PLAN_TYPE)
                    ?.filter(product => product.is_visible_front_end === true || product.id === this.activePlan?.product?.id)
                    ?.filter(product => product.trial_of_product_id === null)
                    ?.map(product => product.slug) || [];
                return newWidget;
            });
            return _.orderBy(widgets, widget => widget.availablePlans.length, ['desc']);
        }
    },
    methods: {
        openTrialOptions: function (product) {
            this.showModal(TrialPeriodDetails, {product: product});
        },
        showWidgetModal: function (widget) {
            let product = widget?.planDetails?.addonProducts?.[0];
            if (product) {
                const WidgetPurchaseModal = () => import("./WidgetPurchaseModal");
                this.showModal(WidgetPurchaseModal, {widget: widget, addonProduct: product});
            }
        },
        widgetInfoLabel: function (widget) {
            if(widget.planDetails?.planWidgetDetails?.showcase && widget.planDetails?.purchased?.length === 0) {
                return widget.planDetails?.includedDetails;
            }
            return "";
        },
        isPartOfPlan: function (widget, plan) {
            let isPartOfPlan = widget.availablePlans.includes(plan.slug);
            if(this.activePlan.product?.slug !== plan.slug) {
                let isShowcase = plan.widgets.filter(entry => entry.implementation_id === widget.implementation_id)?.[0]?.showcase;
                isPartOfPlan = isPartOfPlan && !isShowcase;
            }
            return isPartOfPlan;

        },
        scrollToPlans() {

            const plansTable = this.$refs.plansTable;

            const position = plansTable.offsetTop;

            const firstScrollTo = scroller();

            firstScrollTo('#container', 500, {
                container: '#plan-scroll-wrapper',
                lazy: false,
                easing: 'linear',
                offset: position - 250,
                force: true,
                x: false,
                y: true
            });
        }
    },
    created() {
    }
}
</script>

<style scoped lang="scss">
#compare-plan-step {
    margin-top: 80px;
    position: relative;
}
#compare-link {
    position: absolute;
    top: -70px;
    left: 50%;
    transform: translateX(-50%);

    & a {
        color: $color-twitter;
    }
}
.shop-comp {
    & #compare-link {
        display: none;
    }
}

.subscriptions {
    & #compare-plan-step > h3 {
        display: none;
    }
}

h3 {
    color: $color-20;
}
table {
    border: 1px solid $color-06;
    // border-radius: 20px;
    border-collapse: collapse;
    width: 100%;
}

th, td {
    text-align: left;
    padding: 8px;
    border: 1px solid $color-06;
}

tr {
    background-color: $table-body-row-background-color-2;
}

tr:nth-child(odd) {
    background-color: $table-body-row-background-color-1;
}

.widget-handler label {
    color: $color-20;
    font-weight: 100;
    font-size: 15px;
}

span.vue-custom-tooltip {
    color: $color-20;
}

.widget-handler:hover {
    background-color: $color-04;
}
</style>
