import { capitalizeInitial } from "../../utility/helper-functions";

export default {
    addBulkRecords(context, payload) {
        for(const type in payload) {
            const typeName = capitalizeInitial(type);
            const mutationName = 'addBulk' + typeName;
            let ID = "id";
            if(type === "tweets") {
                ID = "tweet_id";
            }
            const idArray = payload[type].map(element => element[ID]);
            context.commit(mutationName, idArray);

            const processingObject = {
                objStore: `tweet_${type}`, 
                data: payload[type]
            };

            window.bootUp.iDB.storeRecords(processingObject).then(response => {
                if(response) {
                    if(type === "boards")
                        context.dispatch("haveBoards");
                    else if(type === "boards")
                        context.dispatch("haveChannels");
                }
                
            });
        }
        
    },

    addBoard(context, payload) {
        context.commit("addBoard", payload.id);

        const processingObject = {
            objStore: 'tweet_board', 
            data: payload
        };

        window.bootUp.iDB.storeRecords(processingObject);
    },
    deleteBoard(context, payload) {
        context.commit("deleteBoard", payload);
        window.bootUp.iDB.deleteRecordByID("tweet_board", payload);
    },
    haveBoards(context) {
        context.commit("haveBoards");
    },

    addChannel(context, payload) {
        context.commit("addChannel", payload.id);

        const processingObject = {
            objStore: 'tweet_channels', 
            data: payload
        };

        window.bootUp.iDB.storeRecords(processingObject);
    },
    deleteChannel(context, payload) {
        context.commit("deleteChannel", payload);
        window.bootUp.iDB.deleteRecordByID("tweet_channels", payload);
    },
    haveChannels(context) {
        context.commit("haveChannels");
    },


    addTweet(context, payload) {
        context.commit("addTweet", payload.tweet_id);

        const processingObject = {
            objStore: 'tweet_tweets', 
            data: payload
        };

        window.bootUp.iDB.storeRecords(processingObject);
    },
    deleteTweet(context, payload) {
        context.commit("deleteTweet", payload);
        window.bootUp.iDB.deleteRecordByID("tweet_tweets", payload);
    },

    lastTweetUpdate(context, payload) {
        context.commit("lastTweetUpdate", payload);
    },

    lastBoardUpdate(context, payload) {
        context.commit("lastBoardUpdate", payload);
    },

    lastChannelUpdate(context, payload) {
        context.commit("lastChannelUpdate", payload);
    },

    lastHandleUpdate(context, payload) {
        context.commit("lastHandleUpdate", payload);
    },

    lastChannelHandleUpdate(context, payload) {
        context.commit("lastChannelHandleUpdate", payload);
    },

    addUpperMostParent(context, payload) {
        context.commit("addupperMostParent", payload);
    },

    addFetchedBoard(context, payload) {
        context.commit("addFetchedBoard", payload);
    },
    clearFetchBoards(context) {
        context.commit("clearFetchBoards");
    }
    
}