<template>
    <div>
            <template v-if="activePlan && activePlan.product">
                <div style="width: 100%;">
                    <plan-details :plan="activePlan"></plan-details>
                </div>
            </template>
            <div v-if="currentPlans.length === 0" class="no-item-message">
                There is no plan information.
            </div>
          
            <!-- <div v-if="showNextPaymentInformation">
                <label>Next payment</label>
                <div v-for="payment in nextPaymentInformation.payments" :key="payment.paymentPriceDetails" style="font-size: 12px;">
                    <span>
                     <VueCustomTooltip :label="priceVatInfo()" :multiline="true">
                         {{ payment.paymentPriceDetails + ((payment.products[0].product.status === 'renewfail') ? ' (Overdue)' : '')
                         }}
                    </VueCustomTooltip>
                   </span>
                </div>
            </div> -->
        </div>
</template>

<script>
import SubscriptionMixin from "../../../mixins/SubscriptionMixin";
import ShopModal from "../shop/ShopModal";
import EventBus from "../../../store/bus";
import PlanDetails from "../payments/PlanDetails";

export default {
    name: "CurrentPlanSection",
    components: {PlanDetails},
    mixins: [SubscriptionMixin],
    props: {},
    data: function () {
        return {
            currentDate: new Date(),
            isSlideDownOpen: false
        }
    },
    watch: {
        isSlideDownOpen: function (value) {
            this.$emit("toggleChangePlan", value);
        }
    },
    methods: {
        closeSlideDowns() {
            this.isSlideDownOpen = false;
        },
        openPlans() {
            let timeout = 0;
            if (this.isSlideDownOpen)
                timeout = 250;

            setTimeout(() => {
                this.isSlideDownOpen = true;
            }, timeout);
            this.closeSlideDowns();
        },
        showSelectPromotions: function () {
            this.showModal(ShopModal, {activeTab: "currentWidgets"});
        },
        showSelectAddonWidgets: function () {
            this.showModal(ShopModal, {activeTab: "availableWidgets"});
        }
    },
    created() {
        EventBus.$on("scroll-to-top", () => this.$refs?.['subscription']?.scrollIntoView());
    }
}
</script>

<style lang="scss">

@include settings;

.current-sub-wrapper.filled-form {

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  background-color: unset;
  padding: 0;

  color: $color-06;


  min-height: 300px;
}

.current-sub-wrapper > div {
  padding: 20px;
  // border: 1px solid gray;
  background-color: rgba($color-03, 0.6);
}

.narrow-input {

  @include respond(sm) {
    display: inline-block;
  }

}

.narrow-input.with-margin {

  @include respond(sm) {
    margin-right: 20px;
  }
}

#16-digit-card-number {
  width: 155px;
}

#expiry, #cvv {
  width: 55px;
}

</style>

<style lang="scss" scoped>
.settings-tabs .tabs-body-content {

  @include respond(lg) {
    height: 535px;
  }

}

.top-row {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include respond(md) {
    flex-direction: row;
  }
}

.top-row > div {
  width: 100%;
}

.inside-row {
  margin: 5px 0;
  padding: 6px 0;

  @include respond(md) {
    text-align: right;
  }
}

.total-payment,
.addon-name {
  color: $color-06;
}

// .titles > a:hover {
//   color: orange;
// }

.titles > a,
.plan-buttons {
  color: $color-twitter;
}

.details {
  line-height: 17px;
  font-size: 14px;
}

.total-payment {
  margin-top: 10px;
  line-height: 20px;
  font-size: 16px;
  font-style: italic;
}

.total-payment-date,
.total-payment-amount,
.addon-price {
  color: $color-06;
  letter-spacing: 0.3px;
}

.total-payment-amount {
  font-weight: bold;
  letter-spacing: 1px;
}

.card-box {
  color: $color-06;
  margin-right: 20px;
}

.card-box-icon {
  font-size: 20px;
  margin-right: 5px;
}

.bottom-row {
  margin-top: 45px;
}

.payments {
  padding: 5px 0;
}

.payment-unit {
  margin: 5px 0;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgba($color-20, 0.1);
  align-items: center;

  @include respond(sm) {
    justify-content: space-between;
  }
}

.addon {
  margin-top: 10px;
}

.addon-cancel {
  margin-left: auto;

  & a {
    color: $color-04;
    transition: color 0.3s ease;
  }

  & a:hover {
    color: $color-20;
  }

  & a:active,
  & a:visited {
    color: $color-06;
  }
}

.change-plan {
  margin-top: 20px;
}

// .plan-buttons:hover {
//   color: orange;
// }
.plan-change-wrapper {
  float: right;
  width: 5%;
  padding-right: 4px;

  //Poppins
  margin-top: -24%//-46%;

  //Roboto
  // margin-top: -29 //-33%
}
</style>
