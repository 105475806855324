import {createNamespacedHelpers} from "vuex";
import {precise} from "../utility/helper-functions";

const general = createNamespacedHelpers('general');

export default {
    computed: {
        ...general.mapState(['user']),
        salesTaxPercent: function () {
            return this.user.sales_tax_percent;
        },
        displayVatInformation: function () {
            return this.salesTaxPercent > 0;
        }
    },
    methods: {
        calculatePriceWithVat: function (price) {
            if(this.salesTaxPercent > 0) {
                return precise(price * (this.salesTaxPercent/100 + 1));
            }
            return price;
        },
        calculateVat: function (price) {
            if(this.salesTaxPercent > 0) {
                return precise(price * (this.salesTaxPercent/100));
            }
            return price;
        },
    }
}
