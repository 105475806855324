<template>
    <div>
        <h2 class="align-center">{{ title }}</h2>
        <div class="form-text-div dimmed align-center subtitle">{{ subText }}</div>
        <form @submit.prevent class="container">
            <vue-form-generator ref="form"
            :schema="schema"
            :model="model"
            :options="formOptions"
            @model-updated="modelUpdated"
            ></vue-form-generator>
            <fieldset :style="fieldsetStyle">
                <base-button
                mode="o-blue fullwidth"
                :link="false"
                @click.native="getStarted"
                >{{ buttonText }}</base-button>
            </fieldset>
            <div class="form-text-div">Already have an account? <router-link to="login" class="onboard-link">Log in</router-link></div>
        </form>
    </div>
</template>

<script>
import VueFormGenerator from "vue-form-generator";
import { createNamespacedHelpers } from 'vuex';
import OnBoardMixin from '../../../mixins/onBoardMixin';
import { isFreeMTARoute, talkToServer, validateEmail, validateName } from '../../../utility/helper-functions';

const general = createNamespacedHelpers("general");

export default {
    props: ["details", "promoID", "specialLink"],
    mixins: [OnBoardMixin],
    computed: {
        ...general.mapState(["checkoutLoginMode"]),
        title() {
            if(this.checkoutLoginMode)
                    return 'Register';
            else if(isFreeMTARoute(this.promoID)) {
                return 'Register your free account.';
            }
            else if(this.promoID) {
                return 'Register your free account to purchase ' + window.bootUp.promo.name;
            }
            else if (this.specialLink === '/showcaselogin') {
                return 'Register your free account to access our showcase';
            }
                
            return 'Breaking news and fundamental indicators for traders';
        },
        subText() {
            // if(this.checkoutLoginMode)
            //     return 'Register to proceed with your order';
            // else 
            if(isFreeMTARoute(this.promoID)) {
                return "To log in and watch our free macro-masterclass training videos.";
            }
            else if(this.promoID) {
                return 'It takes a few seconds. You can then purchase ' + window.bootUp.promo.name + ' immediately.';
            }
            else if (this.specialLink === '/showcaselogin') {
                return 'It only takes 30 seconds. You\'ll be taken inside the terminal immediately.';
            }

            return 'Level-up your analysis. Enter your email to start for free';
        },
        buttonText() {
            if(this.promoID) {
                return 'Continue';
            }
            return 'Get Started';
        },
        fieldsetStyle() {
            if(this.checkoutLoginMode)
                return "margin-top: 20px;"

            return "margin-top: 8px;"
        }
        
    },
    data() {
        return {
            model: {
                name: '',
                email: this.details.email,
            },
            schema: {
                fields: [
                    {
                        type: "input",
                        inputType: "text",
                        label: "Name",
                        model: "name",
                        readonly: false,
                        required: true,
                        featured: true,
                        disabled: false,
                        styleClasses: "label-transition",
                        placeholder: "Name *"
                    },
                    {
                        type: "input",
                        inputType: "email",
                        label: "Email",
                        model: "email",
                        readonly: false,
                        featured: true,
                        required: true,
                        disabled: false,
                        placeholder: "Email *",
                        styleClasses: "label-transition",
                        validator: VueFormGenerator.validators.required
                    },
                ],
            },
            formOptions: {
                validateAfterLoad: false,
                validateAfterChanged: true,
                validateAsync: false
            }
        }
    },
    methods: {
        modelUpdated() {
            // console.log(this.model);
            return;
        },
        validate() {
            let errorMessages = [];

            const isValidName = validateName(this.model.name);
            const isValidEmail = validateEmail(this.model.email);

            if(!isValidName.value)
                errorMessages.push(isValidName.error);
            if(!isValidEmail.value)
                errorMessages.push(isValidEmail.error);
                

            if(!isValidName.value || !isValidEmail.value) {
                this.$emit("emit-error", {
                    error: errorMessages.join(' ')
                });

                return false;
            }
            
            return true;
        },
        getStarted() {
            const isValid = this.validate();

            if(!isValid) {
                return;
            }

            talkToServer("/journey/start-journey", "POST", null, { email: this.model.email })
            .then(response => {
                if(!response.success) { //
                    const next = this.promoID ? "2" : "1";
                    this.$emit("update-step", {
                        step: next,
                        email: this.model.email,
                        name: this.model.name
                    });
                }else{
                    this.$emit('emit-error', {
                        error: "",
                        email: this.model.email
                    });
                    
                    this.$router.push("/login");
                }
                
                
            }).catch(er => {
                if(er.status === 404) {
                    const next = this.promoID ? "2" : "1";
                    this.$emit("update-step", {
                        step: next,
                        email: this.model.email,
                        name: this.model.name
                    });
                }
            });
                
            // 
        },
    },
    beforeDestroy() {
           
        this.$emit('emit-error', {
            error: "",
            // email: this.model.email
        });

    }
}
</script>