export default {
    fetchPhrases() {
        const queryObject = {
            name: "initial-phrase-fetch",
            objStore: "glossary",
            query: "/widgets/glossary"
        }


        return window.bootUp.iDB.getRecords(queryObject).then(response => {
            // console.log("these are the responses", response);
            response = response.filter(phrase => !phrase.deleted_at);
       
            return response;
        });
    }, 
    fetchPhrasesFromServer() {
        const queryObject = {

            name: "phrase-inital-fetch-server",
            objStore: "glossary",
            query: "/widgets/glossary",

        }

        return window.bootUp.iDB.getRecordsFromServer(queryObject).then(r => r);
    }
}