import AccountService from "@/services/account/account.service.js";
// import { randomizeBetween, talkToServer } from "../../utility/helper-functions";
import AppService from "../../services/app.service";
import { dateTimedifference } from "../../utility/datetime";
import _ from 'lodash';

export default {
    setEntity({commit}, payload) {
        commit("setEntity", payload);
    },
    fetchShopSettings(context) {
        AppService.getShopSettings().then(result => {
            if (result.data && result.data.data) {
                context.dispatch("setLastShopVisitDateList",
                    JSON.parse(result.data.data.json)
                )
            }
        })
    },
    processShopSettings(context, payload) {
        context.dispatch("setLastShopVisitDateList",
            JSON.parse(payload)
        );
    },
    loadCurrentUserSettings({dispatch}, payload) {
        let withCache = false;

        if(payload) {
            withCache = payload.withCache;
        }

        return new Promise(resolve => {
            AccountService.loadCurrentUserSettings(withCache).then(result => {
                let userData = result?.data?.data;
                // console.log("userdata",userData);
                
                if(userData) {
                    dispatch("setRenewFails", userData.user.associated_data.product_access)
                    dispatch("payments/setCurrentShop", [
                        userData.user.associated_data.product_access,
                        userData.user.associated_data.scheduled_product_access
                    ], {root:true});
                    dispatch("payments/setHistoricProductAccess", userData.user.associated_data.historic_product_access, {root:true});
                    dispatch("payments/loadPaymentHistory", {}, {root:true});
                    dispatch("payments/loadPaymentMethod", {}, {root:true});
                    dispatch("setCurrentUser", userData.user.user);
                    dispatch("fetchShopSettings");

                    dispatch("storeCacheCallData", userData, {root: true});
                        
                }
                resolve(result);
            })
        })
    },
    setCountries(context, payload) {
        context.commit('setCountries', payload);
    },
    setLastShopVisitDate(context, [key, value]) {
        context.commit('setLastShopVisitDate', [key, value]);
        context.dispatch('persistShopSettings');
    },
    setLastShopVisitDateList(context, payload) {
        context.commit('setLastShopVisitDateList', payload);
    },
    persistShopSettings(context) {
        AppService.saveShopSettings(context.state.lastShopVisitDate).then(() => {});
    },
    setCurrentUser(context, payload) {
        context.commit('setCurrentUser', payload);
    },
    addSpinner({commit}, spinnerId) {
        commit('addSpinner', spinnerId);
        commit('toggleSpinner');
    },
    removeSpinner({commit}, spinnerId) {
        commit('removeSpinner', spinnerId);
        commit('toggleSpinner');
    },
    removeAllSpinners({commit}) {
        commit('removeAllSpinners');
        commit('toggleSpinner');
    },
    destroyUserStoreData({commit, dispatch}) {
        dispatch("payments/destroyUserStoreData", {}, {root:true})
        commit('destroyUserStoreData');
    },
    setVersionId({commit}, versionId) {
        commit('setVersionId', versionId);
    },
    clearStoreValues({dispatch}) {
        dispatch("headlines/setHeadlineValues", false, {root: true});
        dispatch("library/setVideoValues", false, {root: true});
        dispatch("webinars/setCurrentLiveIDs", [], {root: true});
    },
    setRenewFails({commit}, payload) {
        const productsToShow = [];

        let mostRecentFail;
        let cardUpdatedAt;
             
        payload.forEach(el => {
            if(!el.renewfail_activated_at)
                return;

            if(!mostRecentFail) {
                cardUpdatedAt = el.card_details_updated_at;
                mostRecentFail = el.renewfail_activated_at;
            }

            const newerFail = dateTimedifference(mostRecentFail, el.renewfail_activated_at, "seconds", true) > 0 ? true : false;

            


            if(newerFail)
                mostRecentFail = el.renewfail_activated_at;
            
            productsToShow.push(el);
            // console.log(dateTimedifference(el.card_details_updated_at, el.renewfail_activated_at, "seconds", true));
            // let failAfterUpdate = false;
            // if(el.card_details_updated_at)
            //     failAfterUpdate = dateTimedifference(el.card_details_updated_at, el.renewfail_activated_at, "seconds", true) > 0 ? true : false;

            // if(!el.card_details_updated_at || failAfterUpdate && el.card_details_updated_at)
            //     productsToShow.push(el);
        });

        // console.log("productsToShow", productsToShow);

        const paymentBoundToFail = dateTimedifference(mostRecentFail, cardUpdatedAt, "seconds", true) > 0 ? false : true;

        commit('setPaymentBoundToFail', paymentBoundToFail)
        commit('setRenewFails', productsToShow);
    },
    setActiveNavItem({commit}, payload) {
        commit("setActiveNavItem", payload);
    },
    setComponentAfterRefresh({commit}, payload) {
        commit("setComponentAfterRefresh", payload);
    },
    setDbReady({commit}, payload) {
        commit("setDbReady", payload);
    },
    setLastTrainingCentreOrderUpdated({commit}, payload) {
        commit("setLastTrainingCentreOrderUpdated", payload);
    },
    setWatchHistory({commit}, payload) {
        commit('setWatchHistory', payload);
        if(!_.isEmpty(payload))
            AppService.saveWatchHistory(payload).then(() => {});
    },
    setEntityUpdateTimeout({commit}, payload) {
        commit('setEntityUpdateTimeout', payload);
    },
    setDemoPageMode({commit}, payload) {
        commit('setDemoPageMode', payload);
    },
    setTerminalMenu({commit}, payload) {
        commit('setTerminalMenu', payload);
    },
    setEntityQna({commit}, payload) {
        commit("setEntityQna", payload);
    },
    setUnlockedWidgets({commit}, payload) {
        commit("setUnlockedWidgets", payload);
    },
    setWidgetsChoiceOnDemo({commit}, payload) {
        commit("setWidgetsChoiceOnDemo", payload)
    },
    setExternalUser({commit}, payload) {
        commit("setExternalUser", payload);
    },
    setExternalUserAutoLogin({commit}, payload) {
        commit("setExternalUserAutoLogin", payload);
    },
    setBrokerRegistrationData({commit}, payload) {
        commit("setBrokerRegistrationData", payload);
    },
    setRuleSet({commit}, payload) {
        commit("setRuleSet", payload);
    },
    setCheckoutLoginMode({commit}, payload) {
        commit("setCheckoutLoginMode", payload);
    }
}