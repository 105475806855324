import _ from "lodash";

export default {
    // setRules(state, payload) {
    //     state.rules = payload;
    // },
    // setRuleDetails(state, payload) {
    //     state.ruleDetails = payload;
    // },
    // setRuleWidgets(state, payload) {
    //     state.ruleWidgets = payload;
    // },
    // setRuleAccess(state, payload) {
    //     state.ruleAccess = payload;
    // },
    setUserList(state, payload) {
        state.userList = payload;
    },
    updateUserListUser(state, payload) {
        const index = state.userList.findIndex(el => el.id === payload.id);
        
        const clonedUsers = _.cloneDeep(state.userList);
        if(index === -1)
            clonedUsers.push(payload);
        else 
            clonedUsers.splice(index, 1, payload);

        state.userList = clonedUsers;
    },
    deleteUserListUser(state, payload) {
        const index = state.userList.findIndex(el => el.id === payload);
        
        const clonedUsers = _.cloneDeep(state.userList);
        if(index > -1)
            clonedUsers.splice(index, 1);

        state.userList = clonedUsers;
    },

    updateRule(state, payload) {
        const index = state.rules.findIndex(el => el.id === payload.id);
        
        const clonedRules = _.cloneDeep(state.rules);
        if(index === -1)
            clonedRules.push(payload);
        else 
            clonedRules.splice(index, 1, payload);

        state.rules = clonedRules;

    },
    updateRuleDetails(state, payload) {
        const clonedRuleDetails = _.cloneDeep(state.ruleDetails).filter(el => el.rule_id !== payload.id);
        clonedRuleDetails.push(payload);
        state.ruleDetails = clonedRuleDetails;
    },
    updateRuleWidgets(state, payload) {
        const clonedRuleWidgets = _.cloneDeep(state.ruleWidgets).filter(el => el.rule_id !== payload[0].rule_id);
        clonedRuleWidgets.push(...payload);
        state.ruleWidgets = clonedRuleWidgets;
    },
    updateRuleAccess(state, payload) {
        const clonedRuleAccess = _.cloneDeep(state.ruleAccess).filter(el => el.rule_id !== payload[0].rule_id);
        clonedRuleAccess.push(...payload);
        state.clonedRuleAccess = clonedRuleAccess;
    },
    updateUser(state, payload) {
        const index = state.userList.findIndex(el => el.id === payload.id);

        const clonedUserList = _.cloneDeep(state.userList);
        if(index === -1)
            clonedUserList.push(payload);
        else
            clonedUserList.splice(index, 1, payload);

        state.userList = clonedUserList;

    },
}