<template>
    <div class="rule-setup">
        <div class="rule-setup-header">
            <h2>{{ title }}</h2>
        </div>
        <div class="rule-setup-body">
            <div class="rule-setup-body-subtitle">{{ subtitle }}</div>

            <base-input
            elementType="select"
            :class="{invalid: error.account_manager_id}"
            v-model="profile.account_manager_id"
            forID="agent_name"
            :inputClass="'small-font partner-input'"
            formControlClass=""
            label="Agent"
            labelClass="small-font u-input-color"
            :invalid="error.account_manager_id"
            :invalidMessage="error.account_manager_id"
            @clear-validity="clearValidity"
            @update="error.agent_name = ''"
            >
                <option :value="null" selected>{{ profile.bm ? 'N/A' : 'Select...'}}</option>
                <option v-for="bm in bms"
                :key="bm.id"
                :value="+bm.id">{{ bm.name }}</option>
            </base-input>

            <base-input
                elementType="input"
                :class="{invalid: error.name}"
                inputType="text"
                :required="true"
                v-model="profile.name"
                forID="name"
                :placeholder="'So we can communicate with them about their product'"
                inputClass="partner-input"
                formControlClass=""
                label="Name"
                labelClass="small-font u-input-color"
                :invalid="error.name"
                :invalidMessage="error.name"
                @clear-validity="clearValidity"
                ></base-input>

                <base-input
                elementType="input"
                :class="{invalid: error.email}"
                inputType="email"
                :required="true"
                v-model="profile.email"
                forID="email"
                :placeholder="'So we can send the client their log in details'"
                inputClass="partner-input"
                formControlClass=""
                label="Email"
                labelClass="small-font u-input-color"
                :invalid="error.email"
                :invalidMessage="error.email"
                @clear-validity="clearValidity"
                ></base-input>

                <vue-phone-number-input 
                style="margin-top: 35px;"
                no-example  
                size="sm"
                :default-phone-number="selectedNumber.number"
                :default-country-code="selectedNumber.countryCode"
                :dark="true"
                :darkColor="'#1B1B1B'"
                :class="{'invalid-input-phone': error.phone_number}"
                v-model="selectedNumber.number"   
                @update="updatePhoneNumber"
                >
                </vue-phone-number-input>
                <div class="error" v-if="error.phone_number">{{ error.phone_number }}</div>

                <!-- <div class="rule-setup dot-checkbox label-white" style="margin-top: 30px;">
                    <label>
                        <input type="checkbox" value="1" v-model="profile.bm"/>
                        <span>Is admin user?</span>
                    </label>
                </div> -->

                <div style="margin-top: 20px;" class="error">{{ serverError }}</div>


            <div style="margin-top: 5px; margin-bottom: 15px;">
                <base-button
                mode="o-blue o-height-30"
                :link="false"
                :disabled="isProcessing"
                :showSpinner="isProcessing"
                @click.native="submit"
                >Save
                </base-button>
            </div>




        </div>
    </div>
</template>

<script>
// import _ from "lodash";
// import AccountService from '../../../../services/account/account.service';
// import Swal from "sweetalert2";
import VuePhoneNumberInput from 'vue-phone-number-input';
import { createNamespacedHelpers } from 'vuex';
import countries from '../../../../assets/countries.json';
import { eventBus } from '../../../../main';
import { talkToServer } from '../../../../utility/helper-functions';
import _ from "lodash";

const general = createNamespacedHelpers("general");
const rules = createNamespacedHelpers("rules");
const payments = createNamespacedHelpers("payments");

export default {
    props: ["modal"],
    components: {
        VuePhoneNumberInput
    },
    created() {

        if(this.modal.user_id) {
            this.profile = _.cloneDeep(this.userList.find(el => el.id === this.modal.user_id));
            this.selectedNumber.number = this.profile.sms_notification_number;
            this.selectedNumber.countryCode = countries.find(el => el.callingCodes?.[0] === "+" + this.smsNotificationCountry)?.cca2;
            this.wasBm = this.profile.bm;
        }
        if(!this.profile.account_manager_id && !this.profile.bm)
            this.profile.account_manager_id = this.bms.find(el => el.id === this.profile.account_manager_id)?.id || window.loggedInUserId;
        

    },
    computed: {
        ...general.mapState(["user", "countries"]),
        ...rules.mapGetters(['userList', 'bms']),
        ...payments.mapGetters(["widgetsToSelectFromForHomeScreen"]),

        // getRuleSetsForThisUser() {
        //     return Object.values(this.getUserRuleSets(this.client.id))[0] || {rules: {}, widgets: []};
        // },
        isApproved() {
            if(this.profile.bm)
                return !!this.profile.first_logged_in_at;

            return this.profile.request_status === 'approved';
        },
        isRequested() {
            if(this.profile.bm)
                return !this.profile.first_logged_in_at;

            return this.profile.request_status === 'requested';
        },
        isArchived() {
            return this.profile.request_status === 'archived';
        },
        title() {
            if(this.isApproved)
                return "Edit " + this.userTitle + " details";
            else if(this.isRequested)
                return "Edit " + (this.profile.bm ? "invited " : "new ") + this.userTitle + " details";
            else if(this.isArchived)
                return "Edit archived " + this.userTitle + " details";
            else 
                return (this.profile.bm ? "Invite " : "Create ") + "new " + this.userTitle
        },
        userTitle() {
            return this.profile.bm ? "team mate" : "client";
        },
        subtitle() {
            return "Submit the client details below."
        },
        smsNotificationCountry () {
            if(!this.profile || !this.profile?.sms_notification_country)
                return "";

            if(this.profile.sms_notification_country.indexOf("+") === 0)
                return this.profile.sms_notification_country.substring(1);

            if(this.profile.sms_notification_country.indexOf("00") === 0)
                return this.profile.sms_notification_country.substring(2);
            
            return this.profile.sms_notification_country;
        }
    },
    data() {
        return {
            profile: {},
            selectedNumber: {
                countryCode: 'GB',
                number: ''
            },
            validPhoneNumber: true,
            wasBm: false,
            error: {},
            serverError: "",
            isProcessing: false
        }
    },
    methods: {
        ...rules.mapActions(["updateUser"]),

        clearValidity(name) {
            delete this.error[name];
        },
        validateData() {
            const CONDITIONS = [
                {test: (model) => model.bm || model.account_manager_id && this.userList.find(el => el.id === +model.account_manager_id)?.bm, message: 'No (known) manager', key: "account_manager_id"},
                {test: (model) => model.name?.length > 2 && model.name?.length < 31, message: 'Name must be 3 - 30 characters long', key: "name"},
                {test: (model) => model.email?.length > 5 && model.email?.length < 64 && model.email?.indexOf('@') > 0 && model.email?.indexOf('.') > 2, message: 'Please fill email field.', key: "email"},
                
            ];
            this.error = {};
            CONDITIONS.forEach((condition) => {
                if (!condition.test(this.profile)) {
                    this.error[condition.key] = condition.message;
                }
            });
            if(!this.validPhoneNumber)
                this.error.phone_number = "Please enter a valid phone number or leave it blank";
            // console.log(this.error)
        },
        submit() {
            if(this.profile.account_manager_id > 0)
                this.profile.account_manager_id = +this.profile.account_manager_id;

            this.validateData();
    
            if(!_.isEmpty(this.error))
                return;

            if(this.profile.id) {
                this.persistUser();
            }

        },
        updatePhoneNumber(result) {
            // console.log("r",result);
            delete this.error.phone_number;
            if(result.isValid) {
                this.validPhoneNumber = true;
                this.profile.sms_notification_country = result.countryCallingCode;
                this.profile.sms_notification_number = result.phoneNumber;
                this.profile.phone_number = result.e164;
            }
            else if(!result.phoneNumber) {
                this.validPhoneNumber = true;
                this.profile.sms_notification_country = "";
                this.profile.sms_notification_number = "";
                this.profile.phone_number = "";
            }
            else
                this.validPhoneNumber = false;
        },
        persistUser() {
            this.isProcessing = true;
            talkToServer("/b/user/" + this.profile.id, "PUT", null, this.profile, true).then(response => {
                this.isProcessing = false;
                    if (!response.success && response.error) {
                        this.serverError = "Something went wrong, please try again later"
                    } else {
                        this.updateUser(this.profile);
                        eventBus.$emit("close-modal");

                    }
            }).catch(e => {
                    console.log(e);
                    this.isProcessing = false;
                    this.serverError = "Something went wrong, please try again later"
     
            });
        }
    }
}
</script>