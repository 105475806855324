<template>
    <!-- this is the widget version of Research Component -->
    <div class="research-screen">
        <div
        :style="verticalHeight"
        v-if="chartOn"
        >
            <a class="back-link" href="javascript:void(0);" @click="chartOn = false;"><font-awesome-icon class="arrow" :icon="['fas', 'arrow-left']"/> Back to table view</a>
            <div 
            style="margin-top: 5px; padding: 15px;"
            >
                <!-- <line-chart-wrapper
                :dataPoints="dataPoints"
                :dataIteration="dataIteration"
                :css="chartCss"></line-chart-wrapper> -->
                <!-- <bar-chart-wrapper
                :dataPoints="dataPoints"
                :dataIteration="dataIteration"
                :css="chartCss"
                ></bar-chart-wrapper> -->
            </div>
        </div>
        <div 
        v-else
        class="table-wrapper"
        :style="verticalHeight"
        >
            <table>
                <thead>
                    <draggable
                    :list="groupCols"
                    tag="tr"
                    handle=".handle"
                    :move="checkgroupColMove"
                    >
                        <th v-for="(col, i) in groupCols"
                        :key="col.id"
                        :rowspan="col.rowspan"
                        :colspan="col.data.length === 0 ? 1 : col.data.length"
                        style="max-width: 150px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                        >
                            <font-awesome-icon v-if="i > 0" class="handle" :icon="['fas', 'arrows-alt']"/>
                            {{ col.name }}
                            <a 
                            v-if="i === 0"
                            @click="changeOrder('country')"
                            href="javascript:void(0);"
                            >
                                <sort-up-icon v-if="sortData === 'country'" class="sort-icon" :class="{'highlighted': sortData === 'country' & orderBy === 'desc'}"/>
                                <sort-down-icon class="sort-icon" :class="{'highlighted': sortData === 'country' && orderBy === 'asc'}" />
                            </a>
                            <a 
                            v-if="i === 0"
                            @click="changeOrder('majors-minors')"
                            href="javascript:void(0);"
                            >
                                <redo-icon class="sort-icon" :class="{'highlighted': sortData === 'majors-minors'}" :icon="['fas', 'arrows-alt']"></redo-icon>
                            </a>
                        </th> 
                    </draggable>
                    <draggable
                    :list="groupedData"
                    tag="tr"
                    @end="processGroupCols"
                    handle=".handle"
                    :move="checkgroupMemberMove"
                    >
                        <th v-for="member in groupedData"
                        :key="member.id"
                        >
                        <font-awesome-icon class="handle" :icon="['fas', 'arrows-alt']"/>
                        {{ member.name }}
    
                        <a 
                        @click="changeOrder(member.data_id)"
                        href="javascript:void(0);"
                        >
                            <sort-up-icon v-if="sortData === member.data_id" class="sort-icon" :class="{'highlighted': sortData === member.data_id & orderBy === 'desc'}"/>
                            <sort-down-icon class="sort-icon" :class="{'highlighted': sortData === member.data_id && orderBy === 'asc'}" />
                        </a>
                        </th>
                    </draggable>
                </thead>
                <draggable
                :list="countryRows"
                tag="tbody"
                handle=".handle"
                @end="sortData = null"
                >
                    <tr v-for="country in countryRows"
                    :key="country.code"
                    >
                        <th>
                            <font-awesome-icon class="handle" :icon="['fas', 'arrows-alt']"/>
                            {{ country.name }}</th>
                        <td v-for="(data, ii) in country.data"
                        :key="country.code + ii "><a class="research-data-link" href="javascript:void(0);" @click="chartOn = true">{{ country.data[ii] }}</a></td>
                    </tr>
                </draggable>
            </table>
        </div>
    </div>
</template>

<script>
import Draggable from 'vuedraggable';
import _ from 'lodash';
import { createNamespacedHelpers } from 'vuex';
import { researchCountries, researchData } from '../../../test';
import SortDownIcon from '../../assets/SortDownIcon.vue';
import SortUpIcon from '../../assets/SortUpIcon.vue';
import RedoIcon from '../../assets/RedoIcon.vue';
// import LineChartWrapper from '../charts/LineChartWrapper.vue';
// import BarChartWrapper from '../charts/BarChartWrapper.vue';
// import { diffArray } from '../../utility/helper-functions';
import { eventBus } from '../../main';
const research = createNamespacedHelpers('research');

export default {
    props: ["details"],
    components: {
        // BarChartWrapper,
        Draggable,
        RedoIcon,
        // LineChartWrapper,
        SortUpIcon,
        SortDownIcon
    },
    created() {
        eventBus.$on("widget-resize", obj => {
            if (obj.id === this.details.layoutID) {
                let adj = 120;

                if(this.fullscreenId)
                    adj = 120;

                const height = obj.height - adj;

                this.chartCss.height = height - 30;
              
                this.verticalHeight = {height: obj.height - adj + 'px'};

            }

        });

        this.allCountries = researchCountries;
        // this.countries = this.allCountries.filter(el => el.major).map(el => el.code);
        const countries = this.allCountries.filter(el => el.major).map(el => el.code);
        this.setCountries(countries);
        const groupedDataIDs = ["gdp-ppp","inflation-yoy","inflation-mom","gdp-total","inflation-c-yoy", "manufacturing-pmi", "current-account-gdp"];
        this.setGroupedDataIDs(groupedDataIDs);

        this.processGroupCols();
        this.processCountryRows();
        
        this.dataPoints = {
            datasets: [
                {
                    label: "Data 1",
                    borderColor: "#16CEB9",
                    pointBackgroundColor: "#16CEB9",
                    borderWidth: 1,
                    fill: false,
                    pointBorderColor: "#16CEB9",
                    backgroundColor: "#16CEB9",
                    data: [190, 310, 30, 50, 20],
                },
                {
                    label: "Data 2",
                    borderColor: "#81c784",
                    pointBackgroundColor: "#81c784",
                    borderWidth: 1,
                    fill: false,
                    pointBorderColor: "#81c784",
                    backgroundColor: "#81c784",
                    data: [19, 8, 21, 3, 4],
                }
            ],
            labels: [
                "2020-12-01",
                "2020-12-02",
                "2020-12-03",
                "2020-12-04",
                "2020-12-05",
            ]
        }
    

        setTimeout(() => {

            this.dataPoints.labels = [
                "2020-12-31",
                "2020-12-30",
                "2020-12-29",
                "2020-12-28",
                "2020-12-27",
            ];

            this.dataPoints.datasets[0].data = [210, 298, 45, 43, 26];
            this.dataPoints.datasets[1].data = [22, 12, 56, 43, 32];
            // this.dataPoints.datasets = [
            //     {
            //         label: "Container 1",
            //         borderColor: "#16CEB9",
            //         pointBackgroundColor: "black",
            //         borderWidth: 1,
            //         fill: false,
            //         pointBorderColor: "black",
            //         backgroundColor: "#16CEB9",
            //         data: [13, 8, 21, 3, 4]
            //     },
            //     {
            //         label: "Container 2",
            //         borderColor: "#81c784",
            //         pointBackgroundColor: "black",
            //         borderWidth: 1,
            //         fill: false,
            //         pointBorderColor: "black",
            //         backgroundColor: "#81c784",
            //         data: [19, 31, 3, 5, 2],
            //     }
            // ],
            this.dataIteration++;
        },2500);
        // setTimeout(() => {
        //     console.log("new2")
        //     this.dataPoints = [
        //         [8, 8, 2, 1, -6],
        //         [5, 6, 4, -2, 1]
        //     ];
        //     this.dataIteration++;
        // },4500);

        const obj = {
            menu: {
                isFilterEnabled: true,
            }

        }
        this.$emit('set-frame', obj);
    },
    computed: {
        ...research.mapGetters(['groupCols', 'originalGroupCols', 'countries', 'groupedDataIDs']),
    },
    data() {
        return {
            groupedData: [],
            countryRows: [],
            sortData: "majors-minors",
            orderBy: "asc",
            // countries: [],
            allCountriesTicked: false,
            g7CountriesTicked: false,
            majorCountriesTicked: false,
            groupColModel: {},
            // groupedDataIDs: ["gdp-ppp","inflation-yoy","inflation-mom","gdp-total","inflation-c-yoy", "manufacturing-pmi", "current-account-gdp"],
            groupedColsToDisplay: [],
            dataPoints: [],
            dataIteration: 0,
            verticalHeight: '',
            chartOn: false,
            chartCss: {
                height: 0
            },
            sorting: false
        }
        
    },
    methods: {
        ...research.mapActions(['setGroupCols', 'setCountries', 'setGroupedDataIDs']),
        checkgroupColMove(a) {
            // console.log(a,b,c)
            if(a.draggedContext.futureIndex === 0) 
                return false;

            // console.log(a)
            // this.setGroupCols();

        },
        // end(a,b,c,d) {
        //     console.log(a,b,c,d);
        //     // let groupCols = [];
        //     // // this.groupCols.forEach(el => {
        //     // //     groupedData.push(...el.members);
        //     // // });
        //     // groupedData = this.groupCols.map(el => el.members).flat();

        //     // // console.log(groupedData);
        //     // this.groupedData = groupedData;
        // },
        checkgroupMemberMove(a) {
            if(a.draggedContext.element.group_id !== a.relatedContext.element.group_id)
                return false;
                
            this.sorting = true;
            let list = [...a.relatedContext.list];
            
            list.splice(a.draggedContext.index,1);
            list.splice(a.draggedContext.futureIndex,0,a.draggedContext.element)
          
            console.log(list.map(el => el.data_id));
            const groupedDataIDs = list.map(el => el.data_id);
            this.setGroupedDataIDs(groupedDataIDs);

        },
        processCountryRows() {
            const sortIndex = this.groupedData.map(el => el.data_id).findIndex(el => el === this.sortData);
            let countryRows = researchCountries.filter(el => this.countries.includes(el.code));
            // console.log(JSON.parse(JSON.stringify(this.countryRows)));
            // let countryRows = [...researchCountries].splice(1);
            countryRows.forEach(el => {
                const dataIDs = this.groupedData.map(el => el.data_id);
                const countryData = dataIDs.map(id => {
                    // console.log(id, el);
                    const dataElements = researchData.find(dataElement => dataElement.data_id === id);
                    if(dataElements) {
                        const found = dataElements.values.find(val => {
                            if(Object.prototype.hasOwnProperty.call(val, el.code))
                                return val[el.code]
                        });
                        
                        // console.log(found)
                        if(found)
                            return found[el.code];
                        else
                            return 'N/A';
                        
                    }
                });
                el.data = countryData;
                // console.log(countryRows);
            });

            if(this.sortData === 'majors-minors')
                countryRows = _.orderBy(countryRows, ['major', 'name'], [true, 'asc']);
            else if(this.sortData === 'country') 
                countryRows = _.orderBy(countryRows, el => el.name, this.orderBy);
            else if(this.sortData)
                countryRows = _.orderBy(countryRows, el => el.data[sortIndex], this.orderBy);
            else {
                const countryOrder = this.countryRows.map(el => el.code);
                countryRows.sort((a, b) => countryOrder.indexOf(a.code) - countryOrder.indexOf(b.code));
                // countryRows.sort((a, b) => {
                //     return this.countryRows.indexOf(a) - this.countryRows.indexOf(b)
                // });
            }

            this.countryRows = countryRows;
        },
        changeOrder(id) {
            if(id !== this.sortData) {
                this.orderBy = "asc";
            }else{
                if(this.orderBy === 'asc')
                    this.orderBy = 'desc';
                else
                    this.orderBy = 'asc'
            }
            this.sortData = id;
            this.processCountryRows();
        },
        applyFilters() {
            this.processGroupCols();
            this.processCountryRows();
        },
        processGroupCols() {
            this.sorting = false;
            const originalGroupCols = _.cloneDeep(this.originalGroupCols);
            const groupedColsToDisplay = [];
            this.groupedDataIDs.forEach(el => {
                const groupedData = [...originalGroupCols.map(el => el.data).flat()];
                const found = groupedData.find(data => data.data_id === el);

                if(found) {
                    const foundGroup = groupedColsToDisplay.find(elem => elem.group_id === found.group_id);
                    
                    let groupColData;
                    if(!foundGroup) {
                        groupColData = _.cloneDeep(originalGroupCols.find(elem => elem.group_id === found.group_id));
                        groupColData.data = [found];
                        groupedColsToDisplay.push(groupColData);
                    }else{
                        const dataGr = groupedColsToDisplay.find(elem => elem.group_id === found.group_id);
                        dataGr.data.push(found);
                    }
                    

                //    if(groupColData)
                    

                }


            });
            this.setGroupCols(groupedColsToDisplay);
        }
    },
    watch: {
        groupCols: {
            handler: function(nVal) {
                if(nVal) {
                    // console.log("nVal",nVal.map(el => el.data).flat())
                    this.groupedData = [...nVal.map(el => el.data).flat()];
                    this.processCountryRows();
                }
            },
            deep: true
        },
        countries() {
            this.applyFilters();
        },
        groupedDataIDs() {
            if(!this.sorting)
                this.applyFilters();
        }
    }
}



</script>

<style lang="scss">
    .research-screen {
        // @include standardContainer;
        font-size: 14px;

        &-filter {
            margin: 15px 0 20px 0;
            color: $input-color;

            &-header {
                margin-bottom: 10px;

                & a {
                    color: $input-color;
                }
            }
        } 

        .sort-icon {
            & path {
                fill: $separator-line-color;
            }
            &.highlighted path {
                fill: $input-color;
            }
        }

        .aggregate-country-checkbox label,
        .aggregate-indicator-checkbox label {
            color: $input-color;
            letter-spacing: 0.5px;
            text-transform: uppercase;
        }
    
        .table-wrapper {
            margin: 10px 0;
            max-width: 1350px;
            overflow-x: auto;
            color: $input-color-filter;

            width: 100%;
            // max-height: 450px;
            overflow: auto;
            border: 1px solid $separator-line-color;

            @include scrollbarDark;
        }
        table {
            border: none;
            border-collapse: separate;
            border-spacing: 0;
            // table-layout: fixed;
            // border: 1px solid white;
            width: max-content;

        }
        table td,
        table th {
            border: 1px solid $separator-line-color;
            padding: 5px 5px;
        }
        table thead tr:first-child th {
            padding: 3px;
            position: sticky;
            top: 0;
            z-index: 1;
            width: 150px;
            background: $onboard-survey-card-background;
        
        }
        table thead tr:nth-child(2) th {
            padding: 3px;
            position: sticky;
            top: 28px;
            z-index: 1;
            width: 150px;
            background: $onboard-survey-card-background;
        
        }
        table tbody th {
            position: relative;
        }
        table thead tr:first-child th:first-child{
            position: sticky;
            left: 0;
            z-index: 2;
            background: $onboard-survey-card-background;
        }
        table tbody th {
            position: sticky;
            left: 0;
            z-index: 1;
            background: $onboard-survey-card-background;
        }

        .research-data-link {
            color: $input-color-filter;
            text-decoration: none;
        }
        .back-link {
            color: $input-color;
            text-decoration: none;
        }
    }
</style>