import Constants from "../utility/constants";
import { formatPriceObject, precise } from "../utility/helper-functions";

export default {
    props: {
        startDate: String,
    },
    data: function () {
        return {
            UPGRADE_STATUS: {
                UPCOMING: 0,
                CURRENT: 1,
                UPGRADE: 2,
                DOWNGRADE: 3
            }
        }
    },
    computed: {
        subscription: function () {
            if(!this.selectedCommitment)
                return '';
                
            if(this.selectedCommitment.trial_of_product_id)
                return this.selectedCommitment.subscription_length + "-" + this.selectedCommitment.subscription_length_type + " access";

            const defaultLenght = this.selectedCommitment.subscription_length === 1
            const length =  defaultLenght ? "" : this.selectedCommitment.subscription_length;
            const plural = defaultLenght ? "" : "s";
            const subOrFixed = this.plan.product_type === 'plan' ? "per" : "for the";

            const text =  subOrFixed + " " + length + " " + this.selectedCommitment.subscription_length_type + plural;

            return text;
        },
        planName() {
            return this.plan.name;
        },
        isCurrent: function () {
            return this.upgradeStatus === this.UPGRADE_STATUS.CURRENT;
        },
        isUpcoming: function () {
            return this.upgradeStatus === this.UPGRADE_STATUS.UPCOMING;
        },
        isUpgrade: function () {
            return this.upgradeStatus === this.UPGRADE_STATUS.UPGRADE;
        },
        isDowngrade: function () {
            return this.upgradeStatus === this.UPGRADE_STATUS.DOWNGRADE;
        },
        upcomingPlanInfo: function () {
            return "This plan starts on " + this.toSystemDate(this.startDate);
        },
        vatAdded() {
            const vat = this.$store.state.general.user.sales_tax_percent;
            return this.country === "GBR" || vat;
        },
    },
    methods:{
        calculateCommitmentPrice(comm, withVAT=true) {
            
            if(!comm)
                return '';

            let price = formatPriceObject(comm.pricing, false, withVAT);
            
            const vat = this.$store.state.general.user.sales_tax_percent;
            // console.log("this.plan.pricing", vat, this.$store.state.general.user);
            //onboarding does not have country saved yet, needs to be passed in
            if(this.country) {
                if(this.isVat && !vat) {
                    return !isNaN(price) ? precise(price * 1.2) : price;
                }
                else if(!this.isVat && vat) {
                    return !isNaN(price) ? precise(price / 1.2) : price;
                }
    
                return price;
            }else{
                return price;
            }
        },
        renderCommitment(comm, withVAT=true) {
            const lengthNum = comm.subscription_length;
            const defaultLength = lengthNum === 1;
            const lengthType = comm.subscription_length_type;
            let name = "";
            if(comm.product_type === 'plan')
                name = lengthNum + '-' + Constants.PLAN_CONSTANTS.commitment_texts[lengthType];
            else {
                name = lengthNum + '-' + comm.subscription_length_type + ' access'
            }
            const savings = this.renderSavings(comm, withVAT);

            if(comm.trial_of_product_id)
                return "Trial. " + lengthNum + "-" + lengthType + " access. $" + this.calculateCommitmentPrice(comm, withVAT);

            const ending = defaultLength && lengthType === 'month' ? '' : savings;
            return name + " $" + this.calculateCommitmentPrice(comm, withVAT) + ". " + ending ;
        },
        calculateSubLengthValue(comm) {
            return comm.subscription_length * Constants.PLAN_CONSTANTS.length_values[comm.subscription_length_type];
        },
        renderSavings(comm, withVAT=true) {
            const basePeriod = Constants.PLAN_CONSTANTS.length_values.month
            if(this.calculateSubLengthValue(comm) <= basePeriod)
                return "";
            let theBase = this.plan;
            if(!theBase) {
                const idToCompare = comm.parent_id ? comm.parent_id : comm.id;

                theBase = this.mergedPlans.find(el => el.id === idToCompare);
            }

            const basePrice = +this.calculateCommitmentPrice(theBase, withVAT)
            const periodMultiplier = Constants.PLAN_CONSTANTS.length_values[comm.subscription_length_type] / basePeriod;
            const projectedPrice = basePrice * periodMultiplier * comm.subscription_length;
            
            const savings = projectedPrice - +parseFloat(this.calculateCommitmentPrice(comm, withVAT));
            return savings > 0 ? "Save " +  Math.ceil(savings / projectedPrice * 100) + "%" : "";
        }
    }
}
