<template>
    <indicator-page></indicator-page>
</template>

<script>
import IndicatorPage from './IndicatorPage.vue';

export default {
    components: {IndicatorPage},
    methods: {
        registerLoginSuccessful(val) {
            this.$emit("register-login-successful", val);
        },
    }
}
</script>