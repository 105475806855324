export default {
    loadUserCurrencies({dispatch}) {
        dispatch("setCurrencies")
    },
    setCurrencyChannels(context, payload) {
        context.commit('setCurrencyChannels', payload);
    },
    addCurrencyChannels(context, payload) {
        context.commit('addCurrencyChannels', payload);
    },
    setPairs(context, payload) {
        context.commit('setPairs', payload);
    },
    setLeaderBoard(context, payload) {
        context.commit('setLeaderBoard', payload);
    },
    setLastUpdate(context, payload) {
        context.commit('setLastUpdate', payload);
    },
    setLastPush(context, payload) {
        context.commit('setLastPush', payload);
    }
}
