<template>
    <div class="sunset-popup">
        <p>We made this decision based on the fact that our real-time video trade ideas indicator serves a very similar purpose each day.</p>
        <p>If you wish to follow our trade ideas and analysis as markets unfold, while submitting questions, that indicator will remain active.</p>
        <p>Closing down the daily livestreams allows us to focus on brand new training products and formats that will be more useful.</p>
        <p>If you would like further information on this decision please contact our support team.</p>
        <br />
        <base-button
        mode="o-blue"
        :link="false"
        @click.native="close"
        >OK
        </base-button>
    </div>
</template>

<script>
import { eventBus } from '../../../../main'
export default {
    methods: {
        close() {
            eventBus.$emit("close-modal");
        }
    }
}
</script>

<style lang="scss" scoped>
    .sunset-popup {
        padding: 25px;
        color: $input-color-filter;
        text-align: center;
    }
</style>