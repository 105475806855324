export default {
    addBulkRecords(context, payload) {

        const processingObject = {
            objStore: `availableCategories`, 
            data: [
                {
                    id: 1,
                    availableCategories: payload
                }
            ]
        };

        window.bootUp.iDB.storeRecords(processingObject).then(response => {
            if(response) {
                context.dispatch("haveAvailableCategories", true);
            }
            
        });
        
    },

    haveAvailableCategories(context, payload) {
        context.commit("haveAvailableCategories", payload);
    },
}