<template>
    <div class="widget-list">
        <div class="widget-list-header">
            <h2 class="widget-list-header-title">Choose an indicator</h2>
        </div>
        <div class="widget-list-body">
            <div class="widget-list-body-content">
                <section-card
                v-for="item in widgetsOnTheHomeScreen"
                :key="item.implementation_id"
                :cardType="'widgets'"
                :item="item"
                :noActions="false"
                :widgetDirectAddition="true"
                ></section-card>
            </div>
        </div>
        
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import SectionCard from '../SectionCard.vue';
const payments = createNamespacedHelpers('payments')

export default {
    components: {
        SectionCard
    },
    computed: {
        ...payments.mapGetters(['widgetsOnTheHomeScreen']),
    }
}
</script>

<style lang="scss" scoped>
    .widget-list {

        padding: 0 20px;
    
        &-header {

            margin-left: 35px;

            &-title {
                @include H2;


            }
        }

        &-body {
            height: 535px;
            overflow-y: auto;

            @include scrollbarDark;
        }
    }

    dialog .widget-list {
        padding-bottom: 15px;
    }
</style>




