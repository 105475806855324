<template>
    <div class="vp-d">
        <div v-if="displayHeaders" class="video-module-header">
            <a href="javascript:void(0);" @click="isSlideDownOpen = !isSlideDownOpen">
                <span>{{ catName }}</span>
                <span><font-awesome-icon v-if="isSlideDownOpen" :icon="['fa', 'chevron-up']"/>
                <font-awesome-icon v-else :icon="['fa', 'chevron-down']"/></span>
            </a>
        </div>
        <vue-slide-up-down :active="isSlideDownOpen" :duration="250" class="vue-slide-up-down">
            <video-item
            v-for="(video, i) in videosPerday"
            :key="video.id"
            :index="i"
            :isLocked="isLocked"
            :video="video"
            :firstTemp="firstTemp"
            :noReminder="noReminder"
            :impID="impID"
            @render-video="renderVideo"
            ></video-item>
        </vue-slide-up-down>
        
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import VideoService from '../../../../services/video/video.service';
import { usersTime } from '../../../../utility/datetime';
import VideoItem from './VideoItem';
const widgets = createNamespacedHelpers("widget");


export default {
    props: ["videosPerday", "objKey", "topLevelCategory", "widgetCode", "noReminder", "impID", "index", "activeTab", "firstTemp", "isLocked"],
    components: {
        VideoItem
    },
    created() {
        if(this.widgetCode === 'TCVIDEO')
            VideoService.getCourseCat(+this.objKey).then(res => {
                this.catName = res[0].display_name;
            });

        this.isSlideDownOpen = !this.displayHeaders;
        
        if(this.widgetCode === 'TCSTREAM') {
            this.catName = usersTime(this.objKey, true, false, false, { dateFormat : "M Y" }, true);
            
            if(this.index === 0)
                this.isSlideDownOpen = true;
        }
        
    },
    computed: {
        ...widgets.mapGetters(['getWidgetByImplementationId']),
        displayHeaders() {
            if(this.widgetCode === 'TCVIDEO' && +this.objKey !== this.topLevelCategory)
                return true;

            else if(this.widgetCode === 'TCSTREAM' && 
            this.getWidgetByImplementationId(this.impID)?.training_centre_group_by && this.activeTab === 'archiveVideos')
                return true;

            return false;
        }
    },
    data() {
        return {
            catName: '',
            isSlideDownOpen: false
        }
    },
    methods: {
        renderVideo(obj) {
            this.$emit("render-video", obj);
        }
    }
}
</script>

<style lang="scss" scoped>
    .video-module-header a {
        color: $input-color-filter;
        display: flex;
        justify-content: space-between;
        padding: 5px 15px 5px 5px;
        background-color: $modal-footer;
        border-top: 1px solid $onboard-survey-background;
        // border-bottom: 1px solid $onboard-survey-background;
        text-decoration: none;
    }
</style>

<style lang="scss">
    .stream-list .vp-d .video-preview-unit-wrapper:first-child {
        padding-top: 7px;
    }
</style>

