<template>
    <BarTest 
    :chartData="chartData"
    :legendOn="false"
    :chartHeight="350"
    :min="0"
    :max="100"
    :chartWidth="678"
    :yTicks="(value) => '   ' + value + '%'"
    :iteration="iteration"
    :labelText=" context => context.value + '% chance of a rate of ' + context.label"
    title="Probability Between Most Probable Outcomes" />
</template>

<script>
import BarTest from '../../../charts/BarChartWrapper.vue';
export default {
    props: ["bank"],
    components: {
        BarTest
    },
    created() {
        this.processData();
    },
    data() {
        return {
            barColor: ["#16CEB9", "#81c784"],
            chart1Defaults: {
                    // label: "Probability of this change",
                    label: false,
                    pointBackgroundColor: "#16CEB9",
                    borderWidth: 1,
                    fill: false,
                    pointBorderColor: "#16CEB9",
                    backgroundColor: (c) => this.renderBackgroundColor(c),
                    borderColor: (c) => this.barColor[c.dataIndex % this.barColor.length]
                },
                // chart2Defaults: {
                //     label: "Data 2",
                //     borderColor: "#81c784",
                //     pointBackgroundColor: "#81c784",
                //     borderWidth: 1,
                //     fill: false,
                //     pointBorderColor: "#81c784",
                //     backgroundColor: "#81c784",
                // },
                iteration: 0
            }
        },
        methods: {
            renderBackgroundColor(c) {
                return this.barColor[c.dataIndex % this.barColor.length]
            },
            processData() {
                let dataset = [];
                let labelset = [];

                if(this.bank.sc1_change_by < this.bank.sc2_change_by) {
                    dataset = [this.bank.sc1_probability, this.bank.sc2_probability];
                    labelset = [
                        this.bank.sc1_change_by.toFixed(3) + '%',
                        this.bank.sc2_change_by.toFixed(3) + '%',
                    ];    
                }else {
                    dataset = [this.bank.sc2_probability, this.bank.sc1_probability];
                    labelset = [
                        this.bank.sc2_change_by.toFixed(3) + '%',
                        this.bank.sc1_change_by.toFixed(3) + '%',
                    ]; 
                }
                this.chartData = {
                    datasets: [
                        {
                            ...this.chart1Defaults,
                            data: dataset,
                            
                        },
                    ],
                    labels: labelset
                };
            }
        },
        watch: {
            bank: {
            handler: function() {
                this.processData();
                this.iteration++;
            },
            deep: true

            },
        }
    }
</script>