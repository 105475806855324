<template>
    <div>
        <h3 class="plan-name" :style="{color: color}">
            {{ plan.name }}</h3>
        <p>
            <span class="dimmed-text sub" v-if="!isFree(formatPriceObject(plan.pricing))">$ </span>

            <VueCustomTooltip :multiline="true" :label="priceVatInfo(formatPriceObject(plan.pricing, false))"> {{ formatPriceObject(plan.pricing, false) }}</VueCustomTooltip>
            <span class="dimmed-text sub"
                 v-if="!isFree(formatPriceObject(plan.pricing))"> per {{ plan.subscription_length_type }}</span>
        </p>
        <base-button v-if="activePlan && activePlan.product && activePlan.product.slug === plan.slug"
                     mode="grey"
                     :link="false"
                     disabled="disabled"
        >Current
        </base-button>
        <VueCustomTooltip :multiline="true" v-else-if="upcomingPlansSlugs.includes(plan.slug)"
                          position="is-bottom">
            <base-button
                    mode="grey"
                    :link="false"
                    disabled="disabled"
            >Upcoming
            </base-button>
        </VueCustomTooltip>
        <template v-else-if="isUpgrade">
            <VueCustomTooltip style="float: left; width: 55%" v-if="hasTrials && !isCurrentPlanTrial"
                              label="Take a trial of our pro plan before upgrading"
                              position="is-top" :multiline="true">
                <base-button
                        mode="orange"
                        :link="false"
                        @click.native="openTrialOptionsHandler(plan)"
                >Take trial
                </base-button>
            </VueCustomTooltip>
            <base-button :style="hasTrials && !isCurrentPlanTrial? 'float: right; width: 40%': ''"
                         mode="green"
                         :link="false"
                         @click.native="changePlanHandler(plan)"
            >Upgrade
            </base-button>
        </template>
        <base-button v-else
                     mode="blue"
                     :link="false"
                     @click.native="downgradePlanHandler(plan)"
        >Downgrade
        </base-button>
    </div>
</template>

<script>
import PlanDetailsHandler from "../../../mixins/plan.details.handler";
import {createNamespacedHelpers} from "vuex";
const payments = createNamespacedHelpers('payments');
export default {
    name: "PlanInfo",
    mixins: [PlanDetailsHandler],
    props: {
        color: String,
        upcomingPlansSlugs: {
            type: Array,
            default: () => []
        },
        activePlan: {
            type: Object,
            default: () => {
            }
        },
        plan: {
            type: Object,
            default: () => {
            }
        },
        changePlanHandler: {
            type: Function
        },
        downgradePlanHandler: {
            type: Function
        },
        openTrialOptionsHandler: {
            type: Function
        },
        upgradeStatus: {
            type: Number,
            default: 1
        },
        price: String,
        hasTrials: Boolean
    },
    computed: {
        ...payments.mapGetters(['isCurrentPlanTrial'])
    }
}
</script>

<style scoped lang="scss">
    .plan-name {
        font-weight: 500;
        font-size: 22px;
        letter-spacing: 0.2px;
        padding: 5px;
        border-radius: 5px;
        display: inline-block;
        
    }

    .dimmed-text {
    color: $color-06;
    }

    .dimmed-text.sub {
        font-size: 12px;
    }

</style>
