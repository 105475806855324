<template>
    <div style="margin: 1px;">
        <div>
            <line-chart style="margin-top: 5px;" ref="lineChart"
            :plugins="plugins"
            :maintain-aspect-ratio="maintainAspectRatio"
            :width="chartWidth"
            :height="chartHeight"
            :handler="handler"
            :min="min"
            :max="max"
            :stepSize="stepSize"
            :yLabelString="yLabelString"
            css-classes="line-chart"
            y-scale-label="Value"
            :yTicks="yTicks"
            :iteration="iteration"
            :datapointFormatter="datapointFormatter"
            :chart-data="chartData" :title="currency"></line-chart>
        </div>
    </div>

</template>

<script>

import LineChart from "../widgets/components/CurrencyStrengthMonitor/LineChart.vue";
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
    name: "CurrencyMonitor",
    components: {LineChart},
    props: {
        singleChart: Boolean,
        maintainAspectRatio: {
            type: Boolean,
            default: false
        },
        currency: String,
        currencyIndex: Number,
        chartWidth: {
            type: Number,
           
        },
        chartHeight: {
            type: Number,
            default: 200
        },
        handler: {
            type: Function,
            default: () => {
            }
        },
        chartData: {
            type: Object,
            default: () => {

            }
        },
        min: Number,
        max: Number,
        stepSize:Number,
        yLabelString: {
            type: String,
            default: ""
        },
        yTicks: {
            type: Function,
            default: (value) => value
        },
        datapointFormatter: {
            type: Function,
            default: (value) => value
        },
        iteration: Number

    },
    created() {
        
    },
    data: function () {
        return {
            plugins: [ChartDataLabels],
        }
    },
}
</script>

<style lang="scss" scoped>
.chartjs-size-monitor {
    position: relative !important;
}

.graph-numeric-label {
    font-weight: bold;
    font-size: medium; 
    color: $color-01; 
    border-radius: 50%; 
    background-color: $color-20;

    display: inline-block;
    width: 25px;
    height: 25px;
    text-align: center;
}
</style>
