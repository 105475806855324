<template>
    <div>
        <div v-if="isCourse || isLiveStream" class="course-instruction-header">
                <div class="course-instruction-header-top">
                    <h3>
                        <button href="javascript:void(0);" v-if="showVids" @click="showVids = false"><font-awesome-icon class="arrow" :icon="['fas', 'arrow-left']"/></button>
                        {{ itemName }}</h3>
                    <select v-if="categories.length && showVids"
                    v-model="currentCat"
                    >
                        <option v-for="(cat, i) in categories"
                        :value="cat.id"
                        :key="cat.id"
                        >{{ i === 0 && isCourse ? 'Main' : displayName(cat) }}</option>
                        
                    </select>
                    <div v-else class="no-select"></div>
                </div>
                <!-- <div class="course-instruction-header-bottom">
                    <div class="course-instruction-header-bottom-desc" v-html="item.description">
                    </div>
                </div> -->
            </div>
        <div v-if="showVids" class="overview"
        >

            <item-instruction-course
            :style="'height:' + containerH + 'px;'"
            v-if="isCourse" 
            :currentCat="currentCat"
            :item="item"
            @cats-ready="handleCatsReady"
            >
            </item-instruction-course>

            <item-instruction-stream 
            :style="'height:' + containerH + 'px;'"
            v-else-if="isLiveStream"
            :item="item"
            :currentCat="currentCat"
            @cats-ready="handleCatsReady"
            >
            </item-instruction-stream>

        </div>
        <div v-else ref="container">
            <div class="video-optimizer-div" style="margin-top: 15px;">
                <div class="responsive-16-9-frame">
                    <iframe v-if="item.video_id" 
                    class="terminal-embed-video" :src="'https://player.vimeo.com/video/' + item.video_id" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen" allowfullscreen="">
                    </iframe>
                    <img v-else 
                    :src="thumbnail" :alt="itemName">
                </div>
            </div>
            <div class="widget-instruction-description">
                <h3 v-if="!isCourse && !isLiveStream">{{ itemName }}</h3>
                <p v-if="!isCourse && !isLiveStream" class="description" v-html="item.description"></p>
                <p 
                v-if="mode === 'purchase'"
                class="activation">
                <a href="javascript:void(0);" class="help-link" @click="$emit('show-activation')">How activation works?</a>
                <span v-if="isCourse || isLiveStream" class="link-separator">|</span>
                <a v-if="isCourse || isLiveStream" class="help-link" href="javascript:void(0);" @click="showVids = true"> See {{ isLiveStream ? 'events' : 'videos' }}</a>
                </p>
            </div>
        </div>
    </div>
    
</template>

<script>

import ItemInstructionCourse from './ItemInstructionCourse.vue';
import ItemInstructionStream from './ItemInstructionStream.vue';

// import LivestreamService from '../../../services/livestream/livestream.service';

export default {
    props: ["item", "mode", "isCourse", "isLiveStream"],
    components: {
        ItemInstructionCourse,
        ItemInstructionStream,
        
    },
    async created() {
        // console.log("item", this.isCourse)
        // if(this.isCourse) {
        //     this.topLevelCategories = this.item.categories[0];
        //     const topcat = await VideoService.getCourseCat(this.topLevelCategories);
        //     const subcats = await VideoService.getSubCategoriesForCourse(this.topLevelCategories)
        //     this.categories = [...topcat, ...subcats];
        //     this.currentCat = topcat[0].id;
       
        //     const obj = {
        //         categories: [this.topLevelCategories, ...subcats.map(el => el.id)],
        //             type: "course"
        //         }
            
        //     this.bulkItems = await VideoService.fetchInitialItems(obj);
        //     this.sortItems();
        // }
        // if(this.isLiveStream) {
            // this.archiveVideos.resultsReady = false;
            // this.upcomingVideos.resultsReady = false;

            // this.bulkItems = await LivestreamService.fetchInitialLiveStreams(this.impID);
            // console.log("",this.bulkItems);
            
            // this.archiveVideos.resultsReady = true;
            // this.upcomingVideos.resultsReady = true;
 
        // }
    },
    mounted() {
        this.$nextTick(() => {
            this.containerH = this.$refs.container.getBoundingClientRect().height + 18;
  
        });
    },
    computed: {
        itemName() {
            return this.item.name.replace('Terminal: ', '') + (this.item?.slug?.indexOf('legacy') > -1 ? ' Annual Plan' : '');
        },
        thumbnail() {
            return window.bootUp.entity.mapped_domain + '/assets/' + this.item.image;
        }
    },
    data() {
        return {
            showVids: false,
            containerH: 0,
            categories: [],
            currentCat: null

        }
    },
    methods: {
        handleCatsReady(arr) {
            this.categories = arr;
            this.currentCat = arr[0]?.id;
        },
        displayName(cat) {
            if(this.isLiveStream)
                return cat.display_name + " Live Streams";

            return cat.display_name;
        }
    }
}
</script>

<style lang="scss" scoped>
    .overview {
        overflow: hidden;
    }
</style>

<style lang="scss">
    .widget-instruction-description {
        text-align: center;
        max-width: 85%;
        margin: 0 auto;

        & h3 {
            
            margin: 18px 0;
            font-size: 24px;
            letter-spacing: 0.2px;
            font-weight: 300;
            color: $input-color;

            & svg {
                font-size: 20px;
                color: $input-color-filter;
            }
        }

        & p {
            margin: 15px 0;
            font-size: 18px;
            letter-spacing: 0.1px;

            &.activation a {
                color: $input-color;
                font-size: 16px;
            }

            &.description {
                color: $input-color-filter;
            }
        }

        .link-separator {
            margin: 0 10px;
        }
        
    }
    .course-instruction-header {
        color: $input-color-filter;
        // margin-top: -10px;
        margin-bottom: 15px;

        & .course-instruction-header-top {

            @include respond(lg) {
                display: flex;
                align-items: center;
            }
            
        }

        & h3 {
            @include H2;
            font-size: 16px;

            @include respond(md) {
                font-size: 20px;
                
            }
            

            & button {
                background-color: transparent;
                border: none;
                color: $input-color;
                cursor: pointer;
            }
            
        }

        & select {
            font-size: 16px;
            

            @include respond(lg) {
                margin-left: 10px;
                width: 50%;
                margin-left: auto;
            }
        }
        & .no-select {
            width: 50%;
            margin-left: 10px;
        }

        & .course-instruction-header-bottom-desc {
            font-size: 12px;
            margin: 20px 0 30px;

                @include respond(md) {
                    font-size: 16px;
                }
                @include respond(lg) {
                    margin: unset;
                }
        }


    }
    .widget-instruction-description {
        & .link-separator {
            
            display: none;
            @include respond(lg) {
                display: inline-block;
            }
        }
        & .help-link {
            display: block;
            margin: 12px 0;

            @include respond(lg) {
                display: inline-block;
            }
        }
    } 
</style>