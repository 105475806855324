import { render, staticRenderFns } from "./ItemInstruction.vue?vue&type=template&id=3524bcf6&scoped=true&"
import script from "./ItemInstruction.vue?vue&type=script&lang=js&"
export * from "./ItemInstruction.vue?vue&type=script&lang=js&"
import style0 from "./ItemInstruction.vue?vue&type=style&index=0&id=3524bcf6&lang=scss&scoped=true&"
import style1 from "./ItemInstruction.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3524bcf6",
  null
  
)

export default component.exports