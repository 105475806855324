<template>
    <div>
        <component 
        :is="paginable"
        :items="slicedData"
        :allItems="items"
        :paginatorProps="paginatorProps">
        </component>
        <div  class="pagination-row">
            <div  class="pagination-controls">

                <button v-if="showPaginationControls" @click="getItems(-1)" :disabled="currentPageNumber === 1"><font-awesome-icon class="arrow" :icon="['fas', 'arrow-left']"/> Previous page</button>

                <span class="pagination-numbers">
                    <span v-if="showPaginationControls" style="margin-right: 10px;"> 
                        <input type="text" :value="currentPageNumber" @change="getPage"/> / {{ pageNumbers }}
                    </span> 
                    <span v-if="items.length">Total records: {{ items.length }}</span>
                </span>

                <button v-if="showPaginationControls" @click="getItems(1)" :disabled="currentPageNumber === pageNumbers">Next page <font-awesome-icon class="arrow" :icon="['fas', 'arrow-right']"/></button>
                
            </div>
        </div>
    </div>
</template>

<script>
// import { eventBus } from '../../main';
import ClientProfileTable from './broker/components/ClientProfileTable.vue'

export default {
    props: ["items", "paginable", "paginatorProps", "perPage", "startAt"],
    components: {
        ClientProfileTable
    },
    created() {
        this.rerenderItems(); 
       
    },
    computed: {
        pageNumbers() {
            return Math.ceil(this.items.length / this.perPage);
        },
        showPaginationControls() {
            return this.items.length > this.perPage;
        }
    },
    data() {
        return {
            currentPageNumber: 0,
            slicedData: []
        }
    },
    methods: {
        rerenderItems() {
            // this.$nextTick(() => {

                this.currentPageNumber = this.startAt - 1;
                this.getItems(1);
            // });
        },
        getItems(modifier) {
            // console.log(this.items.length);
            if(this.items.length < this.perPage)
                this.slicedData = this.items.slice(0);

            let start = (this.currentPageNumber - 1) * this.perPage + modifier * this.perPage;
            
            if(start < 0 || start > this.items.length - 1)
                return;

            let end = start + this.perPage - 1;
            
            if(end > this.items.length - 1)
                end = this.items.length - 1

            this.slicedData = this.items.slice(start, end + 1);
            // console.log(start, end, this.items, this.slicedData);
            
            this.currentPageNumber = this.currentPageNumber + modifier;
            this.$emit('start-at', this.currentPageNumber)
        },
        getPage(event) {
            let newPage = event.target.value;
            if(isNaN(newPage)) 
                newPage = this.currentPageNumber;
            else if(newPage < 1)
                newPage = 1;
            else if(newPage > this.pageNumbers)
                newPage = this.pageNumbers;

            this.currentPageNumber = newPage - 1;
            this.getItems(1);
        }
    },
    // watch: {
    //     paginatorProps: {
    //         handler: function(nval) {
                

    //             console.log("navl",nval.items.length)
    //             this.items = nval.items;
    //             this.rerenderItems();
        
    //         },
    //         deep: true
    //     }
    // }
}
</script>

<style lang="scss">
    .pagination-row {
        display: flex;
        justify-content: center;
        padding: 10px 0 20px;
        font-size: 14px;
        font-weight: 500;
        color: $client-purple;

        & .pagination-numbers {
            color: $input-color;
        }

        & .pagination-controls {
            & button {
                background-color: transparent;
                border: none;
                color: $client-purple;
                cursor: pointer;
                font-size: 14px;

                &:first-child {
                    margin-right: 25px;
                }
                &:last-child {
                    margin-left: 25px;
                }

                &:disabled {
                    color: $client-border-1;
                    cursor: default;
                }
                
            }

            & input {
                background-color: transparent;
                padding: 2px;
                width: 35px;
                height: 23px;
                font-size: 14px;
                color: $input-color;
                border: 1px solid $client-purple;
                border-radius: 4px;
                text-align: center;
                font-weight: 500;
            }
        }

    }
</style>