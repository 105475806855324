<template>
    <div>
        <div class="image-wrapper">
            <img :src="panel.url" alt="">
        </div>
    </div>
    
</template>

<script>
import { eventBus } from "../../../main";

export default {
    props: ["panel"],
    methods: {
        close() {
            eventBus.$emit("close-modal");
        }
    }
}
</script>

<style lang="scss" scoped>
    .image-wrapper {
        margin-top: 20px;
        padding: 20px 20px 20px 20px;
        height: calc(85vh - 120px);
        text-align: center;
        overflow-y: auto;
        @include scrollbarDark;
        margin-bottom: 45px;
        display: flex;
        align-items: center;
    }
    img {
        max-width: 100%;
        max-height: 100%;
        display: block;
        margin: 0 auto;
    }
    .form-control-fs {
        margin-top: 35px;
    }
</style>