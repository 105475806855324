export default {
	data() {
		return {
			fetchMode: true,
			isScrolledDown: false
		}
	},
    methods: {
        setupScrollListener() {
			this.$nextTick(function() {
			this.$refs.scrollable.addEventListener('scroll', this.onScroll);
				this.onScroll(); // needed for initial loading on page
			});
		},
		removeScrollListener() {
			this.$refs.scrollable.removeEventListener('scroll', this.onScroll);
		},
    },
}