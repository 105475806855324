<template>
    <button v-if="!link" :class="mode" :disabled="disabled" :id="id"><slot></slot><span v-if="showSpinner">&nbsp;<font-awesome-icon class="spinning-spinner"
                        :icon="['fa', 'circle-notch']"/></span></button>
    <router-link v-else :to="to" :class="mode"><slot></slot></router-link>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            required: false,
            default: null
        },
        mode: {
            type: String,
            required: false,
            default: null
        },
        link: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        to: {
            type: String,
            required: false,
            default: '/'
        },
        showSpinner: {
          type: Boolean,
          required: false,
          default: false
        }
    }
}
</script>

<style scoped lang="scss">
button,
a {
  text-decoration: none;
  padding: 8px 17px;
  background-color: $color-01;
  color: $color-20;
  cursor: pointer;
  display: inline-block;
  border: none;
  text-transform: capitalize;
  border-radius: 10px;
  outline: none;

  @include respond(lg) {
    height: 48px;
  }
  
  
  //Poppins
  font-family: 'Poppins';
  font-size: 14px;

  //Roboto
  // font: inherit;

  // &:hover {
  //     background-color: rgba($color-01, 0.8);
  //   }

  &.blue {
    background-color: $color-twitter;

    &:hover {
      background-color: rgba($color-twitter, 0.8);
    }
  }
  &.orange {
    background-color: $color-159;

    &:hover {
      background-color: rgba($color-159, 0.8);
    }
  }
  &.green {
    background-color: $core-lightgreen;
    color: $input-color;

    // &:hover {
    //   background-color: $color-131
    // }
  }
  &.o-green {
    background-color: $onboard-green;
    color: $input-color;

    // &:hover {
    //   background-color: $color-131
    // }
  }
  &.red {
    background-color: $core-lightred;

    // &:hover {
    //   background-color: rgba($color-12, 0.8);
    // }
  }
  &.white {
    background-color: $color-20;
    color: $color-01;

    &:hover {
      background-color: rgba($color-20, 0.65);
    }

  }
  &.o-blue {
    background-color: $checkbox-active;
    
  } 
  &.o-grey {
    background-color: $input-placeholder-color;
    color: $input-color;
    border-radius: 10px;

    @include respond(lg) {
      height: 48px;
    }
  
  }
  &.o-tranparent-grey {
    background: transparent;
    border: 1px solid #898989;
    color: #898989;
  }
  &.o-tranparent-red {
    background: transparent;
    border: 1px solid $partner-red;
    color: $partner-red;
  }
  &.o-dark-grey {
    background-color: $separator-line-color;
    color: $input-color;
    
  }
  &.setup-card-button-style {
    background: transparent;
    color: $input-color;
    border: 2px solid $disabled-button-text;
    height: 45px;
    font-size: 14px;
    letter-spacing: 0.1px;
    width: 170px;
  }
  &.o-width-125 {
    width: 125px;
  }
  &.o-white-text {
    color: $input-color;
  }
  &.o-white-border {
    border: 1px solid $input-color;
  }
  &.o-border-radius-0 {
    border-radius: 0;
  }
  &.o-blue-border {
    border-color: $client-border-3;
  }
  &.o-red-text {
    color: $partner-red;
  }
  &.o-semibold-text {
    font-weight: 600;
  }
  &.o-height-30 {
    height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  &.o-height-40 {
    height: 40px;
  }
  &.o-partner-grey {
    background-color: $partner-dimmed-text-3;
    color: $card-details-background;
  }
  &.o-font-12 {
    font-size: 12px;
  }
  &.o-auto-height {
    height: auto;
  }
  &.o-height-32 {
    height: 32px;
  }
  &.narrow-button {
    @include respond(lg) {
      height: 37px;
    }
    
  }
  &.tiny-icon-button {
    font-size: 16px;
    padding-left: 16.5px;
    padding-right: 16.5px;
  }
  &.text-tr-init {
    text-transform: initial;
  }
  &.widget-button {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @include respond(lg) {
      width: 195px;
    }
    @include respond(xl) {
      width: 200px;
    }
  }
  &.platform-action {
    font-size: 12px;
    font-weight: 600;
    height: 38px;
    border-radius: 2px;
    padding-left: 10px;
    padding-right: 10px;
  }
  &.fullwidth {
    width: 100%;
  }
  &.extra-h-padding {
    padding-left: 15%;
    padding-right: 15%;
  }
  &.video-button {
    font-size: 0.75em;
    height: 24px;
    padding: 0 6px;
    margin-left: 3px;
  }
  // &.search {
  //   font-size: 12px;
  //   color: $color-01;
  //   background-color: $color-15;
  //   outline-style: none;
  //   border-style: none;
  //   cursor: pointer;
  //   text-align: left;
  //   padding: 7px 12px;
  //   border-radius: 3px;
  //   text-align: center;

  //   &:hover {
  //     background-color: rgba($color-15, 0.8);
  //   }
  // }
  &.modal-button {
    position: absolute;
    bottom: 30px;
  }
  &.right {
    right: 30px;
  }
}

// a:hover,
// a:active,
// button:hover,
// button:active {

// }

.flat {
  background-color: transparent;
  color: $color-06;
  border: none;
  border-bottom: 1px dotted $color-06;
  padding: 2px 0;

  &.no-text-transform {
    text-transform: none;
  }

  &:hover {
      background-color: transparent;
    }
}

.outline {
  background-color: transparent;
  border-color: $color-01;
  color: $color-01;
}
button:disabled,
button[disabled]{
  background-color: $input-placeholder-color;
    color: $input-color;
    // pointer-events:none;
    cursor: not-allowed;
}
// .flat:hover,
// .flat:active,
// .outline:hover,
// .outline:active {
//   background-color: $color-01;
//   color: $color-20
// }


</style>

<style lang="scss">

.spinning-spinner {
  animation: spinner 1.5s infinite linear;
 
}

.broker-mode {

  & button {
    @include respond(lg) {
      font-size: 11px;
    }
    @include respond(xl) {
      font-size: 14px;
    }
  }
}

</style>