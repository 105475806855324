export const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
const advancedFormat = require('dayjs/plugin/advancedFormat');
const relativeTime = require('dayjs/plugin/relativeTime');
const weekday = require('dayjs/plugin/weekday');
const duration = require('dayjs/plugin/duration');
dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(duration);

export const rightNowUTCWithFormatting = withFormatting => {
    if(withFormatting)
        return dayjs.utc().format();

    return dayjs.utc().unix();
};

export const nowUTC = () => {
    return dayjs.utc().format('YYYY-MM-DD HH:mm:ss');
}

export const createTimeStamp = jsDate => {
    return dayjs(jsDate).format("YYYY-MM-DD");
}

export const usersTime = (dateToProcess,withDate,withTime,withSeconds=false, withFormat=false, ignoreTimezone=false, tzone) => {

    const user = {
        timezone: tzone || window.user.timezone,
        // timezone: "Europe/Budapest",
        // timezone: "Australia/Sydney",
        // timezone: "America/Los_Angeles",
        date_format: window.user.date_format,
        time_format: window.user.time_format,
    };

    if(withFormat) {
        user.date_format = withFormat.dateFormat || "M d";
        user.time_format = withFormat.timeFormat || "HH:mm";
    }

    // console.log(user.date_format)

    // console.log(user.date_format)

    let dateFormat="Do MMM"; // have a default
    let timeFormat = "HH:mm"; // use this as default.
    let timezone = "UTC"; // use this as default.
    let fullFormat = "";

    if (user) {
        if (user.date_format === "d-m-y") dateFormat = "DD-MM-YY";
        else if (user.date_format === "m-d-y") dateFormat = "MM-DD-YY";
        else if (user.date_format === "M d") dateFormat = "MMM DD";
        else if (user.date_format === "d M") dateFormat = "DD MMM";
        else if (user.date_format === "d M Y") dateFormat = "DD MMM YYYY";
        else if (user.date_format === "jS M") dateFormat = "Do MMM";
        else if (user.date_format === "jS") dateFormat = "Do";
        else if (user.date_format === "jS M Y") dateFormat = "Do MMM YYYY";
        else if (user.date_format === "M js") dateFormat = "MMM Do";
        else if (user.date_format === "D js M") dateFormat = "ddd Do MMM";
        else if (user.date_format === "M js Y") dateFormat = "MMMM Do YYYY";
        else if (user.date_format === "y-m-d") dateFormat = "YYYY-MM-DD";
        else if (user.date_format === "d-m") dateFormat = "DD-MM";
        else if (user.date_format === "d-m-y") dateFormat = "DD-MM-YY";
        else if (user.date_format === "m/y") dateFormat = "MM/YY";
        else if (user.date_format === "M Y") dateFormat = "MMM YYYY";
        else if (user.date_format === "d m y") dateFormat = "D MMM YY";

        if (user.time_format === "ampm") {
            timeFormat = "h:mm";
        }
        else if (user.time_format === "24hr") {
            timeFormat = "HH:mm";
        }
        if (withSeconds){
            if (user.time_format === "ampm") timeFormat += " s"; // \s
            else if (user.time_format === "24hr") timeFormat += ":ss";
        }


        if (user.time_format === "ampm") timeFormat = "h:mm a";

        timezone=user.timezone;
    }
    if (withDate) fullFormat+= dateFormat;
    if (withDate && withTime) fullFormat += " ";
    if (withTime) fullFormat += timeFormat;
//    console.log("date processing is ",dateToProcess);
    if (dateToProcess.length===19 && dateToProcess.indexOf(" ")!==false){
        dateToProcess=dateToProcess.replace(" ","T")+"Z";
    }
    if (dateToProcess.length===10){
        // just date
        dateToProcess+="T00:00:00Z";
    }
    let output;
    if(ignoreTimezone === true)
        output = dayjs(dateToProcess);
    else if(ignoreTimezone === "UTC")
        output = dayjs(dateToProcess).utc();
    else
        output = dayjs(dateToProcess).tz(timezone);

        // console.log(output.format(fullFormat));
    return output.format(fullFormat);
}
export const formatAnnouncementDate = function (date, withCountdown) {
    let announcementDate = dayjs(date);
    let currentDate = dayjs();
    let difference = announcementDate.diff(currentDate, 'days');
    if(difference > 3) {
        return announcementDate.format('dddd, MMMM D')
    } else if(withCountdown && difference <= 1) {
        let distance = new Date(date).getTime() - new Date().getTime();
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);
        return " in " + days + " days, " + hours + " hours, " + minutes + " minutes, " + seconds + " seconds";
    } else {
        return announcementDate.fromNow()
    }
}

export const toSystemDate = function (date, isDate, format = "DD MMMM YYYY") {
    let finalDate = isDate ? date: dayjs(date);
    return finalDate.format(format);
}
export const calculateDay =  function (numberOfUnits, unit) {
    let currentDate = dayjs();
    let endDate = currentDate.add(numberOfUnits, unit);
    return toSystemDate(endDate, true);
}

export const isWeekend = () => {
    const day = dayjs().tz('Europe/London').day();
    const time = dayjs().tz('Europe/London').format('HH:mm');
    if((day===5 && time >= '22:00') ||
        day===6 ||
        (day===0 && time < '21:45'))
        return true;
    else
        return false;
}

export const thisWeek = (days) => {
    // console.log("now", dayjs().weekday(days).format("YYYY-MM-DD"))
    return dayjs().weekday(days).format("YYYY-MM-DD");
}

export const startOfMonth = (yearAndMonth, add) => {
    return dayjs(yearAndMonth).add(add, 'months').startOf('month').format("YYYY-MM-DD");
}

export const endOfMonth = (yearAndMonth, add) => {
    return dayjs(yearAndMonth).add(add, 'months').endOf('month').format("YYYY-MM-DD");
}

export const addDays = (days, refDate=null, dateobject=false) => {
    
    if(!refDate && !dateobject) {
        return dayjs(new Date()).utc().add(days, 'days').format("YYYY-MM-DD");
    }

    if(!refDate && dateobject)
        return dayjs(new Date()).add(days, 'days').toDate();

    return dayjs(refDate).add(days, 'days').format("YYYY-MM-DD");
}

export const addMonth = (months, refDate=null) => {
    if(!refDate) {
        return dayjs(new Date()).utc().add(months, 'months').format("YYYY-MM-DD");
    }

    return dayjs(refDate).add(months, 'months').format("YYYY-MM-DD");
}

export const addTime = (amount, unit, refDate=null) => {
    if(!refDate) {
        return dayjs(new Date()).add(amount, unit).format("YYYY-MM-DD HH:mm:ss");
    }

    return dayjs(refDate).add(amount, unit).format("YYYY-MM-DD HH:mm:ss");
}

export const dateTimedifference = (from, to, unit, float=false) => {
    return dayjs(to).diff(from, unit, float);
};

export const convertTimestampToSecs = timestamp => {
    const hoursIncluded = timestamp.length > 5;
    const secondRule = hoursIncluded ? /.{5}$/gim : /.{2}$/gim;

    const firstValue = timestamp.match(/^[0-9]{2}/gim)[0];
    let secondValue = timestamp.match(secondRule)[0];
    let thirdValue;

    if(hoursIncluded) {
        thirdValue = secondValue.match(/.{2}$/gim)[0];
        secondValue = secondValue.match(/^[0-9]{2}/gim)[0];
    }

    const obj = {};

    if(hoursIncluded) {
        obj.hours = firstValue
        obj.minutes = secondValue;
        obj.seconds = thirdValue;
    }else{
        obj.minutes = firstValue;
        obj.seconds = secondValue;
    }

    return dayjs.duration(obj).asSeconds();
}
export const isMarketWeekend = function () {
    let currentDate = new Date();
    let day = currentDate.getDay();
    let hours = usersTime(new Date(), true, true, true, {
        dateFormat: "y-m-d",
        timeFormat: "24hr"
    }, false, "Europe/London").substring(11,13);
   
    let isWeekend = false;
    switch (day) {
        case 5:
            isWeekend = hours >= 22;
            break;
        case 6:
            isWeekend = true;
            break;
        case 0:
            isWeekend = hours <= 21;
            break;
        default: break;
    }
    return isWeekend;
}

export const formatCountdown = (seconds, forceHours=true) => {
    let remainingTimeFormatted = '';
    let remainingTime = seconds > 0 ? seconds : 0;

    let time = Math.floor(remainingTime / 3600);
    
    time = time > 0 ? time : 0; 
    if(forceHours || time > 0)
        remainingTimeFormatted+= (time > 9 ? time : '0' + time) + ':';
    remainingTime = remainingTime % 3600;

    time = Math.floor(remainingTime / 60);
    time = time > 0 ? time : 0; 
    remainingTimeFormatted+= (time > 9 ? time : '0' + time) + ':';
    remainingTime = remainingTime % 60;

    time = remainingTime;
    remainingTimeFormatted+= (time > 9 ? time : '0' + time);
    
    return remainingTimeFormatted;
        
}

export const calculateRemainingTime = endDate => {
    const now = usersTime(new Date(), true, true, true, { dateFormat : "y-m-d", timeFormat: "24hr" }, "UTC");
    endDate = endDate.replace('T', ' ').replace('.000000Z','');
    
    const difference = dateTimedifference(now, endDate, 'days');
    const displayAsDays = difference >= 1;

    if(displayAsDays)
        return difference + ' day' + (difference > 1 ? 's': '');

    const diffInSeconds = dateTimedifference(now, endDate, 'seconds');

    return formatCountdown(diffInSeconds);
};

export const humanizedTimeDifference = (from, to) => {
    const fr = from.replace("T", " ").replace(".000000Z", "");
    const t = to.replace("T", " ").replace(".000000Z", "");
   
    return dayjs(fr).from(dayjs(t));
}

// function convertDateToUTC(date) {
//     return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
// }

export const getMostRecentFriday = () => {
    const day = dayjs().day();
    let friday = dayjs().day(5);
    
    if(day === 0)
        friday = dayjs().day(-2);

    return usersTime(friday, true, false, false, { dateFormat : "y-m-d"}).substring(0,10);
}