<template>
    <div class="client-profile-table"
    :class="isApproved ? 'is-approved' : ''"
    >
        <div></div>
        <div style="text-align: center;" v-if="noClients">No clients in this group.</div>
        <div v-else>
            <div class="client-profile-table-table">
                <div class="client-profile-table-header">
                    <div 
                     class="client-profile-table-header-row"
                    >
                        <div class="u-from-lg-flex">Created</div>
                        <div>Name</div>
                        <div>Email</div>
                        <div class="u-from-lg-flex" v-if="!isApproved">Phone</div>
                        <div class="u-from-lg-flex">Access type</div>
                        <div class="u-from-lg-flex" v-if="isApproved">Access duration</div>
                        <div class="u-from-lg-flex" v-if="isApproved">Access status</div>
                        <div class="u-from-lg-flex" v-if="isApproved">Agent</div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div class="client-profile-table-body">
                    <div 
                    class="client-profile-table-body-row"
                    > 
                        <div class="client-profile-table-body-row-data"
                        >
                            <client-profile-tablerow
                            class="client-profile-table-body-row-clients"
                            v-for="(rule, i) in clients"
                            :rule="rule"
                            :key="rule.id"
                            :sortStatus="sortStatus"
                            :sameDayAsNext="sameDayAsNext(i)"
                            :firstNewDay="firstNewDay(i)"
                            :oddMonth="oddMonth(rule.rule.created_at.substr(0,7))"
                            >
                            </client-profile-tablerow>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClientProfileTablerow from './ClientProfileTablerow.vue';


export default {
    props: ["items", "allItems", "paginatorProps"],
    // created() {
        
    // },
    components: {
        ClientProfileTablerow
    },
    computed: {
        clients() {
            return this.items;
        },
        sortStatus() {
            return this.paginatorProps.sortStatus;
        },
        noClients() {
            return !this.clients.length;
        },
        isApproved() {
            return this.sortStatus === 'approved';
        }, 
        isRequested() {
            return this.sortStatus === 'requested';
        },
        isArchived() {
            return this.sortStatus === 'archived';
        },
    },
    methods: {
        sameDayAsNext(i) {
            if(i < this.clients.length -1) {
                return this.clients[i].rule.created_at.substr(0,10) === this.clients[i+1].rule.created_at.substr(0,10);
            }
            
            return false;
        },
        firstNewDay(i) {
            if(i === 0)
                return true;
            else 
                return this.clients[i].rule.created_at.substr(0,10) !== this.clients[i-1].rule.created_at.substr(0,10);
        },
        oddMonth(currMonth) {
            let dates = [];
            this.allItems.forEach(el => {
                const month = el.rule.created_at.substr(0,7)
                if(!dates.includes(month))
                    dates.push(month)
            });
            
            return dates.findIndex(el => el === currMonth) % 2;
        }
    }
   
}
</script>

<style lang="scss">
    $border: 1px solid $client-border-2;
    .client-profile-table {
        padding-top: 10px;
        padding-bottom: 10px;
        color: $input-color;


        & .client-profile-table-table {
            border-bottom: $border;
            font-size: 12px;

        }

        & .client-profile-table-header-row {
            display: grid;
            grid-template-columns: 150px 1fr 150px 150px;

            @include respond(lg) {
                grid-template-columns: 80px 150px 1fr 120px 150px 150px 150px;
            }

            & > div {
                border-bottom: $border;
                height: 48px;
                display: flex;
                align-items: center;
                padding-left: 5px;
                color: $partner-dimmed-text-3;
                font-weight: 700;
            }

        }

        & .client-profile-table-body-row {
            
        }

        & .client-profile-table-body-row-data {
            display: block;
        }

        & .client-profile-table-body-row-clients {
            display: grid;
            grid-template-columns: 150px 1fr 150px 150px;

            @include respond(lg) {
                grid-template-columns: 80px 150px 1fr 120px 150px 150px 150px;

            }

            background-color: $card-details-background;;

            &.odd-month {
                background-color:  $onboard-survey-card-background;
            }
            
            & > div {
                border-bottom: $border;
                height: 60px;
                display: flex;
                align-items: center;
                padding-left: 5px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                &:nth-child(2) {
                    @include respond(lg) {
                        border-left: $border;
                    }
                }
            }
        }

        &.is-approved {

            & .client-profile-table-header-row {
                grid-template-columns: 150px 1fr 150px 150px;

                @include respond(lg) {
                    grid-template-columns: 80px 150px 1fr 150px 120px 100px 150px 50px 50px;
                }
            }
            & .client-profile-table-body-row-clients {
                grid-template-columns: 150px 1fr 150px 150px;

                @include respond(lg) {
                    grid-template-columns: 80px 150px 1fr 150px 120px 100px 150px 50px 50px;
                }
            }
        }
        & .active-badge,
        & .expired-badge,
        & .cancelled-badge {
            padding: 3px 10px;
        }

        & .active-badge {
            color: $onboard-green;
        }
        & .expired-badge {
            color: $core-yellow;
        }
        & .cancelled-badge {
            color: $core-lightred;
        }

        .client-icon-link {
            font-size: 22px;
            color: $input-color-filter;
        }

    }
</style>