<template>
    <div>
        <div class="steps-tracker">{{ progress }}%</div>
        <div class="progress">
            <div class="progress-bar">
                <span class="progress-bar-element progress-bar-done" :style="progressBarStyle"></span>
                <!-- <span class="progress-bar-element progress-bar-undone"></span> -->
            </div>
        </div>
        <h2 class="align-center u-f-weight-700">Building your terminal...</h2>
    </div>
</template>

<script>

import { randomizeBetween } from '../../../utility/helper-functions'
export default {
    created() {
        const percentages = [2, 5, 10, 25, 50, 80, 100];
        percentages.forEach(perc => setTimeout(() => {
            this.progress = perc;
        },this.loadingTime * perc / 100));

        setTimeout(() => {
            this.$emit("update-step", { step: "-1" });
        }, this.loadingTime + 850);

    },
    computed: {
        progressBarStyle() {
            return 'width: ' + this.progress + '%;';
        }
        
    },
    data() {
        return {
            loadingTime: randomizeBetween(3000,5000),
            progress: 0
        }
    }
}
</script>

<style lang="scss" scoped>
    .progress {
        max-width: 250px;
        margin: 15px auto 18px;

        & .progress-bar {
            position: static;
            height: 3px;
            border-radius: 10px;
            width: 100%;
            background-color: $onboard-survey-progress-bar-background;
            display: flex;
        }
        & .progress-bar-element {
            height: 100%;
        }
        & .progress-bar-done {
            background-color: $checkbox-active;
            // width: 25%;
        }
    }
    
    // .progress-bar-undone {
    //     background-color: $onboard-survey-progress-bar-background;
    //     // width: 75%;
    // }
</style>