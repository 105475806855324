<template>
    <div class="terminal-support-wrapper">
        <div class="terminal-support-top">
            <h2>Terminal Support</h2>
            <div class="instruction-row">
                <p>Please complete the form below and click "Send Request". Our support team will reply to your email address within 24 hours.</p>
            </div>
            <div class="modal-form-body">
                <form @submit.prevent>

                    <div class="form-group featured required label-transition field-input">
                        <label for="terminal-support-email" class="focused"><span>We'll reply to this email</span></label>
                        <div class="field-wrap">
                            <div class="wrapper">
                                <input id="terminal-support-email" maxlength="50" type="email" placeholder="We'll reply to this email" required="required" class="form-control-fs" @keyup="clearValidity('email')" v-model="email.val" :class="{'invalid-input': !email.isValid}" />
                            </div>
                        </div>
                    </div>

                    <div class="form-group featured required label-transition field-input">
                        <label for="subject"><span>Subject line</span></label>
                        <div class="field-wrap">
                            <div class="wrapper">
                                <input id="subject" type="text" maxlength="50" placeholder="Subject line" required="required" class="form-control-fs" @keyup="clearValidity('subject')" v-model="subject.val" :class="{'invalid-input': !subject.isValid}" />
                                <div class="char-count" style="margin-top: -20px; text-align: right;">
                                    <span class="char-count-current">{{ subject.val.length }}</span>
                                    <span class="char-count-maximum">/ 50</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group featured required label-transition field-input">
                        <label for="problem"><span>Please provide as many details as possible</span></label>
                        <div class="field-wrap">
                            <div class="wrapper">
                                <textarea id="problem" type="text" maxlength="1000" placeholder="Please provide as many details as possible" required="required" class="form-control-fs" v-model="problem.val" :class="{'invalid-input': !problem.isValid}" @keyup="clearValidity('problem')"></textarea>
                                <div class="char-count" style="margin-top: -27px; text-align: right;">
                                    <span class="char-count-current">{{ problem.val.length }}</span>
                                    <span class="char-count-maximum">/ 1000</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style="margin-top: 15px;">
                        <vue-dropzone
                        ref="bugImgUpload"
                        id="dropzone-multi-img"
                        :options="dropzoneOptions"
                        @vdropzone-error="errorAdd"
                        @vdropzone-complete="updateUploadedFile"
                        @vdropzone-file-added="fileAdded"
                        @vdropzone-removed-file="removeAllFiles"
                        ></vue-dropzone>
                        <div class="error-message">{{ uploadError }}</div>
                    </div>
                

                </form>
            </div>
        </div>
        
        
        
        <div class="modal-form-bottom">
            
            <div class="error-message align-right">
                <span v-if="!formIsValid">Please fill in the highlighted field.</span>
            </div>
            <base-button mode="o-blue" :disabled="uploading" @click.native="submitForm">Send Request</base-button>
    
        </div>
        
    </div>
</template>

<script>
import OnBoardMixin from "../../../mixins/onBoardMixin";
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import AppService from "../../../services/app.service";
import {eventBus} from "../../../main";
import { validateEmail } from '../../../utility/helper-functions';
export default {
    mixins: [OnBoardMixin],
    components: {
        vueDropzone: vue2Dropzone,
    },
    data() {
        return {
            uploadedFile: null,
            uploading: false,
            email: {
                val: window.user.email,
                isValid: true,
            },
            subject: {
                val: '',
                isValid: true,
            },
            problem: {
                val: '',
                isValid: true,
            },
            dropzoneOptions: {
                url: 'https://httpbin.org/post',
                addRemoveLinks: true,
                maxFilesize: 1,
            },
            formIsValid: true,
            errorMessage: null,
            uploadError: ''
        }
    },
    methods: {
        submitForm() {
            this.validateForm();
            if(this.formIsValid) {
                this.uploading = true;
                if(this.uploadedFile) {
                    let image = this.convertFieldDataToFile(this.uploadedFile);
                    AppService.uploadImage(image).then(result => {
                        if(result?.data?.success) {
                            let images = [result.data.data.url];
                            this.saveSupportTicket(images);
                        }
                    })
                } else {
                    this.saveSupportTicket([]);
                }
            }
        },
        saveSupportTicket: function (images) {
            const obj = {
                content: this.problem.val,
                email: this.email.val,
                subject: this.subject.val,
                images: images
            }
            AppService.submitSupportTicket(obj).then(result => {
                this.uploading = false;
                if(result?.data?.data) {
                    eventBus.$emit('close-modal');
                    this.showInfoMessage("Thank You!", "Your message has been successfully submitted. Our team will reply to you via email within the next 24 hours.");
                }else{
                     this.showInfoMessage("Error", "Something has gone wrong. Please try again later", () => eventBus.$emit('close-modal'));
                }
            })
        },
        validateForm() {
            this.formIsValid = true;

            if((validateEmail(this.email.val)).error) {
                this.email.isValid = false;
                this.formIsValid = false;
            }


            if(this.problem.val === '') {
                this.problem.isValid = false;
                this.formIsValid = false;
            }
            if(this.subject.val === '') {
                this.subject.isValid = false;
                this.formIsValid = false;
            }

        },
        clearValidity(input) {
            this[input].isValid = true;
            this.formIsValid = true
        },
        removeAllFiles() {
            const currentFile = this.$refs.bugImgUpload.getAcceptedFiles();
            if (currentFile.length) {
                const toBeDeleted = currentFile[0];
                this.$refs.bugImgUpload.removeFile(toBeDeleted);
            }
        },
        fileAdded() {
            this.removeAllFiles();
            this.uploading = true;
        },
        updateUploadedFile: function (response) {
            if(response.status === "success") {
                this.uploadError = '';
                this.uploadedFile = response;
            } else {
                this.uploadedFile = null;
            }
            this.uploading = false;
        },
        errorAdd(file, message) {
            this.$refs.bugImgUpload.removeFile(file);
            this.uploadError = message;
        }
    }
}
</script>
