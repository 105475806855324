<template>
    <div class="how-activation-works">
        <h2>
            <a href="javascript:void(0);"
            @click="$emit('back-to-instructions');"
            >
                <font-awesome-icon class="arrow" :icon="['fas', 'arrow-left']"/>
            </a> How activation works
        </h2>
        <p>{{ explanation }}</p>
        <!-- SPECIAL PROMO -->
        <div v-if="isErbPromo" class="scrollable">
            <div class="scroller">
                <section-card v-for="item in promoItemsForProduct"
                :key="item.implementation_id"
                :cardType="'widgets'"
                :item="item"
                :noActions="true"
                >
                </section-card>
            </div>
        </div>
        <!-- SPECIAL PROMO ENDS-->
        <div v-else class="scrollable">
            <div class="scroller">
                <section-card v-for="item in widgetsForProduct"
                :key="item.implementation_id"
                :cardType="'widgets'"
                :item="item"
                :noActions="true"
                >
                </section-card>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Constants from '../../../utility/constants';
import { collateMTAItems, collatePromoItems, getServerEnv, isErbPromo } from '../../../utility/helper-functions';
import SectionCard from '../SectionCard.vue';
import _ from "lodash";

const widgets = createNamespacedHelpers("widget");

export default {
    props: ["isLiveStream", "isCourse", "product", "impID"],
    components: {
        SectionCard
    },
    // created() {
    //     console.log(this.product, this.impID);
    // },
    computed: {
        ...widgets.mapGetters(["getWidgetByImplementationId"]),

        isProduct() {
            return !!this.product;
        },
        widgetsForProduct() {
            if(this.isProduct) {
                const env = getServerEnv();
                if(Constants.MTA_PROMO[env] === this.product.id) {
                    const widgets = _.cloneDeep(this.product.widgets);
               
                    return collateMTAItems(widgets);
                }
                return this.product.widgets;
            }

            return [];
        },
        explanation() {
            if(this.isProduct) {
                const env = getServerEnv();
                if(this.product.id === Constants.MTA_PROMO[env])
                    return "You can activate this product by purchasing it. When you complete your purchase you will see the following items activated and added to your terminal.";
                else if(this.product.id === Constants.ERB_PROMO[env])
                    return "You can activate this product by purchasing it. When you complete your purchase you'll receive a product access information email immediately.";
                else
                    return "You can activate this product by purchasing it. When you complete your purchase you will see the following items activated and added to your terminal.";
    
            }

            return "You can activate this indicator by selecting a purchase option below. When you complete your purchase this indicator will become active on your home screen. You can add it to dashboards immediately."
            
            // if(this.impID === 75) {
            //     return "You can activate this livestream series by purchasing our 30-day technical challenge bundle. When you complete your purchase this series will become active in your training centre.";
            // }
            // else if(this.isCourse) {
            //     return "You can activate this course by upgrading your plan.  When you complete your purchase this course will become active in your Training Centre."
            // }

            // else if(this.isLiveStream) {
            //     return "You can activate this live stream series by upgrading your plan.  When you complete your purchase this series will become active in your Training Centre."
            // }

            // return "You can activate this indicator by upgrading your plan.  When you complete your purchase this indicator will become active on your home screen. You can add it to dashboards immediately.";
        },
        //SPECIAL PROMO
        isErbPromo() {
            const env = getServerEnv();
            return isErbPromo() || this.product?.id === Constants.ERB_PROMO[env];
        },
        promoItemsForProduct() {
            return collatePromoItems(this.widgetsForProduct);
        }
        //SPECIAL PROMO ENDS
    }
}
</script>

<style lang="scss" scoped>
    .how-activation-works {
        padding: 15px 35px;

        h2 {
            @include H2;
            color: $input-color;
            margin-bottom: 15px;

            @include respond(md) {
                margin-bottom: 30px;
            }

            
            
            

            & a {
                @include H2-back
            }
        }

        p {
            color: $input-color-filter;
            font-size: 14px;
            
            @include respond(md) {
                font-size: 18px;
            }
            
        }

        & .scrollable {
            height: 247px;
            overflow-x: hidden;
            overflow-y: auto;

            @include scrollbarDark;
        }

    }
    
</style>