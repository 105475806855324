<template>
    <div class="plan-card" :key="plan.slug">
        <slot></slot>
        <div class="plan-top" style="height: 370px;">
            <h3>{{ plan.name }}</h3>
            <p class="plan-price">
                <span class="dimmed-text sub" v-if="!isFree(productPrice)">$</span>
                <VueCustomTooltip :label="priceVatInfo(productPrice)" :multiline="true"> {{ productPrice }}</VueCustomTooltip>
                <span class="dimmed-text sub" v-if="!isFree(productPrice)">{{ subscription }}</span></p>

            <template v-if="isCurrent">
                <base-button
                             mode="grey"
                             :link="false"
                             disabled="disabled"
                >Current
                </base-button>
                <base-button :style="isCurrentPlanTrial? 'float: right; width: 40%': ''" v-if="isCurrentPlanTrial"
                             mode="green"
                             :link="false"
                             @click.native="changePlanHandler(plan)"
                >Upgrade
                </base-button>
            </template>

            <VueCustomTooltip :multiline="true" v-else-if="isUpcoming"
                              :label="upcomingPlanInfo"
                              position="is-bottom">
                <base-button
                        mode="grey"
                        :link="false"
                        disabled="disabled"
                >Upcoming
                </base-button>
            </VueCustomTooltip>
            <template v-else-if="isUpgrade || plan.trial_of_product_id > 0">
                <VueCustomTooltip style="float: left; width: 55%" v-if="hasTrials && !isCurrentPlanTrial"
                                  label="Take a trial of our pro plan before upgrading"
                                  position="is-top" :multiline="true">
                    <base-button
                            mode="orange"
                            :link="false"
                            @click.native="openTrialOptionsHandler(plan)"
                    >Take trial
                    </base-button>
                </VueCustomTooltip>
                <base-button :style="hasTrials && !isCurrentPlanTrial? 'float: right; width: 40%': ''"
                             mode="green"
                             :link="false"
                             @click.native="changePlanHandler(plan)"
                >Upgrade
                </base-button>
            </template>
            <base-button v-else
                         mode="blue"
                         :link="false"
                         @click.native="downgradePlanHandler(plan)"
            >Downgrade
            </base-button>
            <br/>
            <h5 class="dimmed-text" v-if="plan.trial_of_product_id > 0"><em>
                Trial active
            </em>
            </h5>
            <h5 class="dimmed-text" v-else><em>
                {{ explainer }}
            </em>
            </h5>
            <div class="product-description">
                <div class="dimmed-text">
                    <span>{{ description }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PlanDetailsHandler from "../../../mixins/plan.details.handler";
import {createNamespacedHelpers} from "vuex";
const payments = createNamespacedHelpers('payments');
export default {
    name: "ProductDetails",
    mixins: [PlanDetailsHandler],
    props: {
        plan: {
            type: Object,
            default: () => {
            }
        },
        changePlanHandler: {
            type: Function
        },
        downgradePlanHandler: {
            type: Function
        },
        openTrialOptionsHandler: {
            type: Function
        },
        upgradeStatus: {
            type: Number,
            default: 1
        },
        price: String,
        hasTrials: Boolean
    },
    computed: {
        ...payments.mapGetters(['isCurrentPlanTrial']),
        truncatedDescription: function () {
            return this.plan.description?.substr(0, 157) + "...";
        },
        productPrice: function () {
            if(this.plan.trial_of_product_id > 0) {
                return this.formatPriceObject(this.plan.mainTrialProduct.pricing)
            } else {
                return this.price;
            }
        },
        description() {
            const regex = new RegExp("<[^>]*>?",'gm')
            return this.plan.description.replace(regex, '');
        },
        explainer: function () {
            switch (this.upgradeStatus) {
                case this.UPGRADE_STATUS.UPCOMING:
                    return "Upcoming plan";
                case this.UPGRADE_STATUS.CURRENT:
                    return "Current plan";
                case this.UPGRADE_STATUS.UPGRADE:
                    return "Plan will start immediately";
                case this.UPGRADE_STATUS.DOWNGRADE:
                    return "Plan will start when your current plan billing period ends";
                default:
                    return "";
            }
        }
    }
}
</script>

<style lang="scss">
.plan-card {
//   box-shadow: 0 0 4px rgba($color-00, 0.5);
  padding: 10px;
//   border-radius: 15px;
  
  text-align: center;
  background-color: rgba($color-03, 0.6);
  position: relative;
  

  @include respond(lg) {
    margin-bottom: 0;
  }

  & h2 {
    font-size: 26px;
    font-weight: normal;
  }

  & p,
  & a,
  & a:hover,
  & a:active,
  & a:visited {
    font-size: 12px;
    letter-spacing: 0.75px;
    color: $color-06;

    // Poppins
    //

    // //Roboto
    // margin: 35px 0;

  }

  & a,
  & a:hover,
  & a:active,
  & a:visited {
    font-size: 13px;
    letter-spacing: 1px;
    display: inline-block;
  }

  & p.plan-price {
    font-size: 18px;
    color: $color-20;
    letter-spacing: 1px;
  }

  & p .plan-price-period,
  & .video-description {
    color: $color-06;
    font-size: 12px;

  }
}

.plan-top h3 {
    font-weight: 500;
    font-size: 25px;
    letter-spacing: 1px;
    color: $color-20;

    // Poppins
    margin: 10px 0;

    //Roboto
    //
}

.product-description {
  overflow: hidden;
  font-size: 13px;

}

.dimmed-text {
    color: $color-06;
}

.dimmed-text.sub {
    font-size: 12px;
}

</style>
