<template>
    <div class="search">
        <form class="search--bar" @submit.prevent>
            <!--<div class="search--bar__title">Search</div>-->
            <div class="search--bar__input1">
                <input id="keywords" type="text" name="keywords" placeholder="keywords..." v-model="keywords">
            </div>
            <div class="search--bar__input2">

                <date-picker v-model="dateRange" range
                :shortcuts="dateRangeShortcuts"></date-picker>

                <!-- <input name="daterange" id="daterange" class="daterange" placeholder="date..."> -->
            </div>
            <!-- <button class="search--bar__submit" type="submit" @click="submit">
                    Search
            </button> -->
            <base-button 
            class="search--bar__submit" 
            :mode="'search'"
            @click.native="submit"
            >Search</base-button>
            <!--<div class="search--bar__exit">
                <font-awesome-icon :icon="['fas', 'times-circle']" />
            </div>-->
        </form>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { addDays, endOfMonth, startOfMonth } from '../../../utility/datetime';
import { createTimeStamp } from '../../../utility/datetime';

export default {
    props: ["forWidget"],
    components: {
        DatePicker
    },

    created() {
        this.dateRange = this.defaultRange;
    },
    computed: {
        defaultRange() {
            if(this.forWidget === "breaking-headlines") {
                return [new Date(addDays(-30)), new Date()];
            }
            else if(this.forWidget === "vid-com") {
                return [new Date("2019-01-01"), new Date()];
            }

            return '';
        }
    },
    data() {
        return {
            keywords: '',
            dateRange: '',
            dateRangeShortcuts: [
                { text: 'Today', onClick: () => [addDays(0, null, true), addDays(0, null, true)] },
                {
                    text: 'Yesterday',
                    onClick: () => [addDays(-1, null, true), addDays(-1, null, true)]
                },
                {
                    text: 'Last 7 Days',
                    onClick: () => [new Date(addDays(-6)), new Date(addDays(0))]
                },
                {
                    text: 'Last 30 Days',
                    onClick: () => [new Date(addDays(-30)), new Date(addDays(0))]
                },
                {
                    text: 'This Month',
                    onClick: () => [new Date(startOfMonth(new Date(), 0)), new Date(endOfMonth(new Date(), 0))]
                },
                {
                    text: 'Last Month',
                    onClick: () => [new Date(startOfMonth(new Date(), -1)), new Date(endOfMonth(new Date(), -1))]
                },

            ]
        }
    }, 
    methods: {
        submit() {
            const [from, to] = this.dateRange;

            let params = null;

            // console.log(this.forWidget,this.dateRange);

            if(this.keywords !== '' && this.dateRange !== '')
                params = {
                    keywords: this.keywords,
                    from: createTimeStamp(from),
                    to: createTimeStamp(to)
                }
                
            this.$emit("search-request", params);
        }
    }

}
</script>

<style lang="scss">
    .search {
		grid-column: 1 / -1;

        &--bar {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            text-transform: capitalize;
            background-color: $onboard-survey-card-background;
            align-items: baseline;
            
    //         @include respond(mobile) {
    // //			margin-top: 1rem;
    //             grid-template-rows: repeat(4, 1fr);
    //             grid-row-gap: 0.5rem;
    //             height: 16rem;
    //             font-size: 1.8rem;
    //             text-transform: uppercase;
    //             font-weight: bold;
    //         }
            
            & > div {

                line-height: 50px;
                
            }

            & > div {

                line-height: 60px;

                &:nth-child(1),
                &:nth-child(2) {
                    margin-left: 15px;
                    margin-right: 15px;
                }
                
            }

            .size-sm & > div {

                line-height: 60px;

                &:nth-child(1) {
                    margin-left:10px;
                    margin-right: 10px;
                }

                &:nth-child(2) {
                    margin-left:10px;
                    margin-right: 10px;
                }
                
            }
            
            &__title {
                
                letter-spacing: 1.5px;
                color: $color-20;
                
                // @include respond(mobile) {
                //     grid-column: 2 / 4;
                //     grid-row: 1;
                //     line-height: 6rem;
                // }
                
            }

            &__title {
                // grid-column: 1 / -1;
                text-align: center;
            }

            .size-sm &__title {
                // grid-column: 1 / 3;
                margin-left: 20px;
                text-align: left;
            }
            
            & input {
                height: 36px;
                width: 100%;
                // background-color: $color-03;
                outline: none;
                font-size: 14px;
                padding: 10px;
                color: $input-color;
                background-color: $card-details-background;
                transition: all 0.5s ease;
                border-radius: 10px;
                box-sizing: border-box;

                &::placeholder {
                    color: $input-placeholder-color;
                }

                // &:focus {
                //     box-shadow: 0 0 2px 2px $color-16;
                //     background-color: $color-03;
                    
                // }
                
                // @include respond(mobile) {
                //     font-size: 1.6rem;
                //     height: 2.2rem;
                // }
                
            }

            &__input1 {
                grid-column: 1 / -1;
            }

            .size-sm &__input1 {
                grid-column: 1 / 6;
            }
            
            &__input2 {
                
                color: $color-15;

                & .mx-datepicker-range {
                    width: 100%;

                    
                }

                & .mx-input {
                    height: 36px!important;
                    background-color: $card-details-background;
                    color: $input-color;
                    font-size: 14px;
                    border-radius: 10px;
                    border: none;
                }

                & .mx-icon-clear,
                & .mx-icon-calendar {
                    color: $color-20;
                }




                
                
                
            }

            &__input2 {
                grid-column: 1 / -1;
                
            }

            .size-sm &__input2 {
                grid-column: 6 / 11;
                
            }

            &__submit {
                
                
                // @include respond(mobile) {
                //     grid-column: 12 / -1;
                //     grid-row: 3;
                //     display: flex;
                //     margin-left: 0.5rem;
                //     font-size: 1.6rem;
                // }
                
            }

            &__submit.search {
                height: 36px;
                margin-left: 15px;
                margin-right: 15px;
                font-size: 14px;
                color: $input-color;
                background-color: $checkbox-active;
                outline-style: none;
                border-style: none;
                cursor: pointer;
                text-align: left;
                margin: 15px 15px;
                padding: 0 12px;
                border-radius: 10px;
                text-align: center;
            }

            .size-sm &__submit {
                grid-column: 11 / 12;
                margin-left: 5px;
            }
            

            &__exit {
                color: $color-15;
                font-size: 20px;
                text-align: center;
                cursor: pointer;
                
                // @include respond(mobile) {
                //     grid-column: 11 / 12;
                //     line-height: 6rem;
                //     grid-row: 1;
                //     color: #d5ad35;
                //     font-size: 1.8rem;
                // }
                
            }

            &__exit {
                position: absolute;
                top: 0px;
                right: 10px;
            }

            .size-sm &__exit {
                position: static;
                grid-column: 12 / -1;
            }
            
        }

        &--bar {
            position: relative;
            padding-bottom: 10px;
            padding-top: 10px;
        }

        .size-sm &--bar {
            position: static;
            height: 60px;
            padding-bottom: 0px;
            padding-top: 0px;
        }
        
    }

</style>