<template>
    <div class="video-player">
        <div class="video-player__iframe">
            <padlock-overlay
                :impID="impID"
                v-if="isLocked"
                :instructions="true"
                class="blur"
                ></padlock-overlay>
            <div class="iframe-box">
                <video ref="videoPlayer" class="video-js" controls>
                    <source :src="video.src">
                </video>
            </div>
        </div>
        <sunset-ribbon v-if="this.impID === 65"></sunset-ribbon>
        <div class="video-player__description">
            <div class="video-player__description--title disable-select">
                <span class="live-span">
                    <font-awesome-icon :icon="['fas', 'dot-circle']" /> LIVE:
                </span>{{ video.title }}</div>
            <div class="video-player__description-details u-mt-2">
                <div class="video-player__description-details--profile">
                </div>
                <!-- <video-question></video-question> -->
            </div>
        </div>
    </div>
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css';
import { createNamespacedHelpers } from 'vuex';
// import { usersTime } from '../../../../utility/datetime';
const livestreamsNS = createNamespacedHelpers('webinars');
const general = createNamespacedHelpers('general');
import * as workerTimers from 'worker-timers';
import VideoTrackerMixin from '../../../../mixins/videoTrackerMixin';
import SunsetRibbon from './SunsetRibbon.vue';

export default {
    name: "VideoPlayer",
    props: ["video", "impID"],
    mixins: [VideoTrackerMixin],
    components: {
        SunsetRibbon
    },
    created() {
        const progress = this.watchHistory[this.code][this.video.id]?.progress;
        this.percentage = progress ? progress : 0;

        this.watched = this.getGTrackVideoProgress();
        console.log("saved", this.watched);
    },
    data() {
        return {
            player: null,
            options: {
                // fullscreenToggle: true
            },
            interval: null,
            percentage: 0,
            code: 'TCSTREAM'
        }
    },
    mounted() {
        const mainObject = this;
        this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
            // console.log('onPlayerReady', this);
            this.play();
            this.on('play', () => { 
                console.log("play");
                mainObject.blockPlayerRender();
                mainObject.clrInterval();
                mainObject.interval = workerTimers.setInterval(() => {
                    mainObject.watched+= (mainObject.step[mainObject.impID] || 0);
                    mainObject.gTrackVideoProgress();
                    console.log("watched", mainObject.watched);

                }, (mainObject.step[mainObject.impID] || 60) * 1000);

            });
            this.on('ended', () => { 
                console.log("ended");
                mainObject.allowPlayerRender();
                mainObject.$emit('stream-ended', mainObject.video.id);
                mainObject.clrInterval();
                    
            });
            this.on('pause', () => {
                console.log("pause");
                mainObject.clrInterval();
            });
        });

    },
    computed: {
        ...livestreamsNS.mapGetters(['currentLiveIDs']),
    },
    methods: {
        ...general.mapActions(['setWatchHistory']),

        blockPlayerRender() {
            this.$emit("allow-player-render", false);
        },
        allowPlayerRender() {
            this.$emit("allow-player-render", true);
        },
        // clrInterval() {
        //     if(this.interval) {
        //         workerTimers.clearInterval(this.interval);
        //         this.interval = null;
        //     }
        // }
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose()
        }
        this.clrInterval();
    }
}
</script>

<style lang="scss">
    .live-span {
        color: $input-color-filter;

        & svg {
            color: $core-lightred;
            animation: pulse 1.5s infinite;
            animation-timing-function: ease;
        }
    }
</style>

<style lang="scss" scoped>
    .iframe-box {
        position: absolute;
        border: 0;
        height: 100%;
        left: 0;
        
        top: 0;
        width: 100%;
    }
    .video-js {
        width: 100%;
        height: 100%;
    }
    .live-span {
        & svg {
            font-size: 0.9em;
        }
    }
</style>