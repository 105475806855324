import { orderBy } from "lodash";
import { sortDataBy } from "../../utility/helper-functions";

export default {
    fetchInitialLiveStreams(impID, noslice=false) {

        const queryObject = {
            name: "initial-livestream-fetch",
            objStore: "livestreams",
            impID: impID
        };

        // console.log(queryObject);

        return window.bootUp.iDB.getRecords(queryObject).then(response => {
            sortDataBy(response, "created_at", "timeDESC");
            if(noslice) {
                return response;
            }
            return response.slice(0,25);
        });
    },
    loadMoreLiveStreams(videolist, impID) {

        const status = videolist === "archiveVideos" ? "finished" : "";

        const queryObject = {
            name: "livestreams-load-more",
            objStore: "livestreams",
            status: status,
            impID: impID
        };

        // console.log(queryObject);

        return window.bootUp.iDB.getMoreRecords(queryObject).then(response => {
            return response;
        });
    },
    fetchQuestionsForVideo(id) {
        const queryObject = {
            name: "fetch-stream-question-by-id",
            objStore: "livestream_questions",
            prop: 'live_stream_id',
            value: id
        };

        return window.bootUp.iDB.getRecordByProperty(queryObject).then(response => {
            return orderBy(response, [el => el.type, el => el.updated_at], ['desc', 'asc']);
        });
    },
    fetchNextUpcomingByImpIDs(arr) {
        return window.bootUp.iDB.getNextUpcomingLiveStreamByImpIDs(arr).then(response => {
            return response;
        });
    },
}