<template>
<!-- v-if="fullscreenId === 0" -->
    <div class="header"
    :class="{'management-mode': isUserBm}">
      <div>
          <div v-if="hideNav"
          style="height:60px;"
          ></div>
          <nav v-else>
              <ul :class="{popout: isPopOut}">

                  <li
                  style="display:none;">
                      <span v-if="activeDashboard >= 0" 
                      >
                          <span>
                              <span style="cursor: pointer;padding-right: 10px" class="active" v-if="isPopOut">
                                  {{ activeDashboardInfo.name
                                  }}</span>
                              <a v-if="showPopOutIcon" href="javascript:void(0);" @click="popout"
                                style="padding-left: 150px">
                                  <font-awesome-icon
                                          :icon="['fas', 'window-restore']"/>
                                  <span data-v-68062d04=""
                                        data-v-57224588=""
                                        class="icon-description"
                                        >  Popout</span>
                              </a>
                          </span>
                      </span>
                      <!--<span v-else-if="fullscreenId > 0">
                          <span style="cursor: pointer" @click="closeFullscreen">Exit Full Screen</span>
                      </span>-->
                      <br/>
                      <div style="display: flex;" class="tabs-menu-wrapper">
                          <div class="left" v-if="tabbedDashboards.length > 17" @click="swipeLeft">
                              <font-awesome-icon
                                      :icon="['fa', 'chevron-left']"/>
                          </div>
                          <div class="tabs-menu center" v-if="!isPopOut && fullscreenId === 0" id="content" ref="content">
                              <div v-for="tab in tabbedDashboards" :key="tab.id" class="internal"
                                  :style="{paddingLeft:'2px', minWidth:activeDashboard === tab.id ? '260px': '60px', width: activeDashboard === tab.id ? '260px' : tabbedDashboards.length <= 17 ? (930/(tabbedDashboards.length-1) + 'px'): '60px', maxWidth: '260px'}">

                                  <VueCustomTooltip :label="tab.name" style="width: 100%" position="is-bottom">
                                      <button @click="changeActiveDashboard(tab.id)" class="buttontext"
                                              :class="{'active': activeDashboard === tab.id}">

                                      <span style="width: 95%; float:left;" class="text">
                                          {{ tab.name }} </span>
                                          <span @click="openCloseDashboardDialog(tab)" class="close-icon">
                                          <sup><span aria-hidden="true">&times;</span></sup>
                                      </span>
                                      </button>
                                  </VueCustomTooltip>

                              </div>

                          </div>
                          <div class="right" v-if="tabbedDashboards.length > 17"  @click="swipeRight">
                              <font-awesome-icon
                                      :icon="['fa', 'chevron-right']"/>
                          </div>
                      </div>
                  </li>
                  <!-- <li
                  class="u-smallscreen-only"
                  style="line-height: 13px;"
                  >
                      <span class="icon-description">Mobile view - some functionalities may be limited</span>
                  </li> -->
                  <li class="dashboard-menu-unit">
                      <!-- v-if="!isPopOut && fullscreenId === 0" -->
                      <dashboard-menu 
                      v-if="!isPopOut"
                      :layoutHasWidgets="layoutHasWidgets"
                      :currentComp="currentComp"></dashboard-menu>
                  </li>
              </ul>
          </nav>
      </div>
    </div>
</template>

<script>
import {getParameterByName, isEmptyObject} from '../../utility/helper-functions';
import DashboardMenu from './DashboardMenu';
import {createNamespacedHelpers} from 'vuex';
import _ from "lodash";
import Swal from "sweetalert2";
import {eventBus} from "../../main";

const dashboards = createNamespacedHelpers('dashboard');
const general = createNamespacedHelpers('general');

export default {
    props: ["currentComp"],
    components: {DashboardMenu},
    computed: {
        ...dashboards.mapState(['fullscreenId']),
        ...dashboards.mapGetters(['layouts', 'tabbedDashboards', 'getPoppedoutDashboardByID',
            'getDashboardById', 'activeDashboardInfoByInstanceID', 'isThereLayoutByInstanceID', 'activeDashboardByInstanceID']),
        ...general.mapGetters(["isUserBm"]),
        isThereLayout() {
            return this.isThereLayoutByInstanceID(this.$root.instanceID);
        },
        activeDashboard() {
            return this.activeDashboardByInstanceID(this.$root.instanceID, "dashboard");
        },
        activeDashboardInfo() {
            return this.activeDashboardInfoByInstanceID(this.$root.instanceID, "dashboard");
        },
        layoutHasWidgets: function () {
            return this.activeDashboardInfo && !isEmptyObject(this.activeDashboardInfo?.elements || {});
        },
        showPopOutIcon() {
            return !this.isPopOut && this.isThereLayout && this.layoutHasWidgets && this.fullscreenId === 0;
        },
        hideNav() {
          return this.currentComp === 'checkout-component'
        },
    },
    data() {
        return {
            dashboardId: null,
            isPopOut: getParameterByName('popout')
        }
    },
    created() {
        eventBus.$on('fullscreen-widget', id => {
            this.toggleFullscreen(id);
        });
    },
    methods: {
        ...dashboards.mapActions(['setActiveDashboardAndTab', 'updateDashboardById', 'setFullScreenId']),
        closeFullscreen() {
            this.setFullScreenId(0);
        },
        changeActiveDashboard(id) {
            const poppedOut = this.getPoppedoutDashboardByID(id);
            if (poppedOut && poppedOut.window && !poppedOut.window.closed) {
                poppedOut.window.focus();
            }
            this.setActiveDashboardAndTab({
                id: id, 
                instanceID: this.$root.instanceID
            });
        },
        closeDashboardTab(dashboard) {
            const poppedOut = this.getPoppedoutDashboardByID(dashboard.id);
            if (poppedOut && poppedOut.window) {
                poppedOut.window.close();
            }
            dashboard.tab = false;
            dashboard.popout = false;
            this.updateDashboardById(dashboard);
            this.updateActiveDashboard();
        },
        setNextActiveDashboardId() {
            let tabbedDashboardsNotInPopups = this.tabbedDashboards.filter(entry => !entry.popout);
            if (tabbedDashboardsNotInPopups.length > 0) {
                this.setActiveDashboardAndTab({
                id: tabbedDashboardsNotInPopups[0].id, 
                instanceID: this.$root.instanceID
            });
            } else {
                this.setActiveDashboardAndTab({
                id: -1, 
                instanceID: this.$root.instanceID
            });
            }
        },
        updateActiveDashboard: function () {
            if (this.tabbedDashboards.length > 0) {
                this.setActiveDashboardAndTab({
                  id: this.tabbedDashboards[0].id, 
                  instanceID: this.$root.instanceID
                });
            } else {
                this.setActiveDashboardAndTab({
                  id: -1, 
                  instanceID: this.$root.instanceID
              });
            }
        },
        openCloseDashboardDialog(dashboard) {
            event.stopImmediatePropagation();
            Swal.fire({
                title: 'Are you sure?',
                type: 'warning',
                text: 'Do you want to close this tab?',
                showCancelButton: true,
                cancelButtonColor: '#1d9deb',
                confirmButtonColor: '#ff5656',
                confirmButtonText: 'Close'
            }).then((result) => {
                if (result.value) {
                    this.closeDashboardTab(dashboard);
                }
            });
        },

        popout: function () {
            this.dashboardId = this.activeDashboard;
            let currentDashboard = _.cloneDeep(this.layouts[this.dashboardId]);
            currentDashboard = {...currentDashboard, popout: true};
            this.updateDashboardById(currentDashboard);
            this.setNextActiveDashboardId();
            const height = window.innerHeight;
            const newWindow = window.open("/?popout=y&dashboard=" + this.dashboardId, `dashboard-${this.dashboardId}`, "target=_blank,toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=920,height=" + height);

            const popout = {};

            popout.id = this.dashboardId;
            popout.name = `dashboard-${this.dashboardId}`;
            // popout.h = newWindow.outerHeight;
            // popout.w = newWindow.outerWidth;
            // popout.x = newWindow.screenX;
            // popout.y = newWindow.screenY;
            popout.window = newWindow;
            this.$store.dispatch('dashboard/addDashboard', popout);

        },
        scrollTo(element, scrollPixels, duration) {
            const scrollPos = element.scrollLeft;
            if (!((scrollPos === 0 || scrollPixels > 0) && (element.clientWidth + scrollPos === element.scrollWidth || scrollPixels < 0))) {
                const startTime =
                    "now" in window.performance
                        ? performance.now()
                        : new Date().getTime();

                window.requestAnimationFrame((timestamp) => this.scroll(element, scrollPos, scrollPixels, startTime, timestamp, duration));
            }
        },
        scroll: function (element, scrollPos, scrollPixels, startTime, timestamp, duration) {
            const timeElapsed = timestamp - startTime;
            const progress = Math.min(timeElapsed / duration, 1);
            element.scrollLeft = scrollPos + scrollPixels * progress;
            if (timeElapsed < duration) {
                window.requestAnimationFrame((ts) => this.scroll(element, scrollPos, scrollPixels, startTime, ts, duration));
            }
        },
        swipeLeft() {
            const content = this.$refs.content;
            this.scrollTo(content, -300, 800);
        },
        swipeRight() {
            const content = this.$refs.content;
            this.scrollTo(content, 300, 800);
        }
    }
}
</script>

<style scoped lang="scss">
.header {
  
  &.management-mode {
    background-color: $partner-background-1;
  }
  & > div {
    max-width: 1350px;
    margin: 0 auto;
    // padding: 0 25px 0 0px;

    @include respond(sm) {
      padding: 0 35px;
    }
  }
}
// nav {
//   background: $color-00;
//   padding: 0 28px;
// }

ul {
  margin: 0;
  padding: 0;
  display: flex;
  height: 60px;
  // justify-content: center;
}

ul.popout {
  height: 30px;
}

li {
  list-style: none;
  color: $color-20;
  align-self: center;
}

li a {
  color: $color-20;
  text-decoration: none;
}
.dashboard-menu-unit {
  @include respond(lg) {
    width: 100%;
  }
}

li:nth-child(1),
li:nth-child(2) {
  width: 150px;
  
}

li:nth-child(1),
li:nth-child(2) {
  flex: 1;
  text-align: center;
}

li:nth-child(4) {
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
}

.header-center {

  & > span:not(:first-child) {
    margin-left: 12px;
  }
}
.tabs-menu-wrapper {
  padding-top: 2px;
  padding-right: 15px;
  padding-left: 15px;
}
.tabs-menu {
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: flex-end;
  overflow: -moz-scrollbars-none;
}

.tabs-menu > span > .active, .tabs-menu > div > span > .active {
  color: black;
  background-color: #D5B957;
  width: 260px;
}

.tabs-menu > div > span > button:hover {
  color: black;
  background-color: #D5B957;
}

button {
  cursor: pointer;
}

.buttontext:hover .close-icon {
  display: inline-flex;
}

.close-icon {
  display: none;
  float: right;
  width: 3%;
}

.close-icon:hover {
  display: inline;
}

.buttontext {
  color: #C3C3C3;
  background-color: #465660;
  max-width: 260px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  border-radius: 10px;
  padding-right: 7px;
}

.text {
  max-width: 260px;
  width: 95%;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
}

.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: $color;
      color: black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, .1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}

.left {
  float: left;
  cursor: pointer;
  border: 1px solid black;
  width: 3%;
}

.internal {
  height: 100%;
}

.right {
  cursor: pointer;
  float: right;
  border: 1px solid black;
  width: 3%;
}
</style>
