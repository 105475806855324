import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
    namespaced: true,
    state() {
        return {
            originalGroupCols: [
                {
                    id: 1,
                    name: "Countries",
                    data: [],
                    rowspan: 2,
                },
                {
                    id: 2,
                    group_id: "gdp",
                    name: "Gross Domestic Product",
                    colspan: 3,
                    data: [ 
                        {
                            id: 1,
                            name: "GDP",
                            group_id: "gdp",
                            data_id: "gdp-total",
                        },
                        {
                            id: 2,
                            name: "GDP per capita PPP",
                            group_id: "gdp",
                            data_id: "gdp-ppp",
                        },
                        {
                            id: 3,
                            name: "GDP Growth YoY",
                            group_id: "gdp",
                            data_id: "gdp-yoy",
                            
                        },
                    ]
            
                },
                {
                    id: 3,
                    group_id: "inflation",
                    name: "Inflation",
                    colspan: 3,
                    data: [
                        {
                            id: 4,
                            name: "Inflation YoY",
                            group_id: "inflation",
                            data_id: "inflation-yoy",
                        },
                        {
                            id: 5,
                            name: "Inflation MoM",
                            group_id: "inflation",
                            data_id: "inflation-mom",
                        },
                        {
                            id: 6,
                            name: "Core Inflation YoY",
                            group_id: "inflation",
                            data_id: "inflation-c-yoy",
                        },
                    ]
                },
                {
                    id: 4,
                    group_id: "pmi",
                    name: "PMI",
                    colspan: 3,
                    data: [
                        {
                            id: 7,
                            name: "Manufacturing PMI",
                            group_id: "pmi",
                            data_id: "manufacturing-pmi",
                        },
                        {
                            id: 8,
                            name: "Non-Manufacturing PMI",
                            group_id: "pmi",
                            data_id: "non-manufacturing-pmi",
                        },
                        {
                            id: 9,
                            name: "Services PMI",
                            group_id: "pmi",
                            data_id: "services-pmi",
                        },
                    ]
                },
                {
                    id: 5,
                    group_id: "gov",
                    name: "Government",
                    colspan: 4,
                    data: [
                        {
                            id: 10,
                            name: "Current Account",
                            group_id: "gov",
                            data_id: "current-account",
                        },
                        {
                            id: 11,
                            name: "Current Account to GDP",
                            group_id: "gov",
                            data_id: "current-account-gdp",
                        },
                        {
                            id: 12,
                            name: "Government Debt to GDP",
                            group_id: "gov",
                            data_id: "gov-debt-gdp",
                        },
                        {
                            id: 13,
                            name: "Government Budget",
                            group_id: "gov",
                            data_id: "gov-budget",
                        },
                    ]
                }
            
            ],
            groupCols: [],
            countries: [],
            groupedDataIDs: []
        }
    },
    mutations,
    actions,
    getters
}

