<template>
    <div class="filled-form"
         style="margin-left: 14px; padding-top: 5px;">
        <label>Current plan</label>
        <template v-if="activePlan && activePlan.product">
            <div>
                <plan-details :plan="activePlan"></plan-details>
            </div>
        </template>
        <div v-if="currentPlans.length === 0">
            There is no plan information.
        </div>
        <div>
            <div>
                <base-button
                        v-if="isSlideDownOpen"
                        style="width:124px;"
                        mode="white"
                        :link="false"
                        @click.native="closeSlideDowns"
                >Close Plans
                </base-button>
                <base-button
                        v-else
                        style="width:124px;"
                        mode="blue"
                        :link="false"
                        @click.native="openPlans"
                >Change Plan
                </base-button>
            </div>
        </div>

        <br/>
        <collapsible title="Upcoming plans" v-if="upcomingPlans.length > 0" button-class="defaultButton">
            <label>Upcoming plans</label>
            <div v-for="plan in upcomingPlans" :key="plan.slug">
                <plan-details :plan="plan"></plan-details>
            </div>
        </collapsible>
        <div>
            <div class="addon">
                <span class="titles">
                    <a @click="showSelectAddonWidgets" style="cursor:pointer;">Add-ons:</a>
                </span>
                <template v-if="currentVisibleAddons.length > 0">
                    <addon-details v-for="addon in currentVisibleAddons"
                                   :cancel-handler="cancelProduct"
                                   :reactivate-handler="reactivateProduct"
                                   :key="addon.slug"
                                   :addon="addon"></addon-details>
                </template>
                <span v-else>
                    <br/>
                    <span class="no-item-message">You have no active add-on products.</span>
                </span>
            </div>
        </div>
        <div>
            <div class="addon">
                <span class="titles">
                    <a @click="showSelectPromotions" style="cursor:pointer;">Promotions:</a>
                </span>
                <addon-details v-for="addon in currentVisiblePromotions"
                               :cancel-handler="cancelProduct"
                               :reactivate-handler="reactivateProduct"
                               :key="addon.slug"
                               :addon="addon"></addon-details>
                <span v-if="currentVisiblePromotions.length === 0">
                    <br/>
                    <span class="no-item-message">You have no active promo products.</span>
                </span>
            </div>
        </div>
        <br/>
        <div v-if="showNextPaymentInformation">
            <span class="titles">Next payment:</span>
            <br/>
            <span class="total-payment-amount">{{ nextPaymentInformation + ((activePlan.status === 'renewfail') ? ' (Overdue)': '') }} </span>
        </div>
        <div v-else>
            <span class="titles">Next payment:</span>
            <br/>
            <span class="no-item-message">You have no scheduled payments due.</span>
        </div>

    </div>
</template>

<script>
import Collapsible from "../forms/Collapsible";
import PlanDetails from "./PlanDetails";
import AddonDetails from "./AddonDetails";
import {eventBus} from "../../../main";
import SubscriptionMixin from "../../../mixins/SubscriptionMixin";


export default {
    name: "CurrentPaymentInfo",
    components: {AddonDetails, PlanDetails, Collapsible},
    mixins: [SubscriptionMixin],
    props: {},
    data: function () {
        return {
            currentDate: new Date(),
            isSlideDownOpen: false
        }
    },
    watch: {
        isSlideDownOpen: function (value) {
            this.$emit("toggleChangePlan", value);
        }
    },
    methods: {
        closeSlideDowns() {
            this.isSlideDownOpen = false;
        },
        openPlans() {
            let timeout = 0;
            if (this.isSlideDownOpen)
                timeout = 250;

            setTimeout(() => {
                this.isSlideDownOpen = true;
            }, timeout);
            this.closeSlideDowns();
        },
        showSelectPromotions: function () {
            this.openWidgetsModal("currentWidgets");
        },
        showSelectAddonWidgets: function () {
            this.openWidgetsModal("availableWidgets");
        },
        openWidgetsModal: function (tab) {
            let modal = {
                header: false,
                componentInModal: "widget-shop",
                mode: "wide",
                panel: {
                    view: "widget-list",
                    mode: "create",
                    addingMode: true,
                    tab: tab

                }
            }
            eventBus.$emit('open-modal', modal);
        }
    }
}
</script>

<style lang="scss">

@include settings;

.narrow-input {

  @include respond(sm) {
    display: inline-block;
  }

}

.narrow-input.with-margin {

  @include respond(sm) {
    margin-right: 20px;
  }
}

#16-digit-card-number {
  width: 155px;
}

#expiry, #cvv {
  width: 55px;
}


</style>

<style lang="scss" scoped>
.settings-tabs .tabs-body-content {

  @include respond(lg) {
    height: 535px;
  }

}

.top-row {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include respond(md) {
    flex-direction: row;
  }
}

.top-row > div {
  width: 100%;
}

.inside-row {
  margin: 5px 0;
  padding: 6px 0;

  @include respond(md) {
    text-align: right;
  }
}

.total-payment,
.addon-name,
.titles {
  color: $color-06;
}

.titles > a:hover {
  color: orange;
}

.details {
  line-height: 17px;
  font-size: 14px;
}

.total-payment {
  margin-top: 10px;
  line-height: 20px;
  font-size: 16px;
  font-style: italic;
}

.total-payment-date,
.total-payment-amount,
.addon-price {
  color: $color-20;
  letter-spacing: 0.3px;
}

.total-payment-amount {
  font-weight: bold;
  letter-spacing: 1px;
}

.card-box {
  color: $color-06;
  margin-right: 20px;
}

.card-box-icon {
  font-size: 20px;
  margin-right: 5px;
}

.bottom-row {
  margin-top: 45px;
}

.payments {
  padding: 5px 0;
}

.payment-unit {
  margin: 5px 0;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgba($color-20, 0.1);
  align-items: center;

  @include respond(sm) {
    justify-content: space-between;
  }
}

.addon {
  margin-top: 10px;
}

.addon-cancel {
  margin-left: auto;

  & a {
    color: $color-04;
    transition: color 0.3s ease;
  }

  & a:hover {
    color: $color-20;
  }

  & a:active,
  & a:visited {
    color: $color-06;
  }
}

</style>
