import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
    namespaced: true,
    state() {
        return {
            entity: null,
            versionId: 0,
            countries: [],
            user: {},
            spinnersList: [],
            showSpinner: false,
            lastShopVisitDate: {},
            renewFails: [],
            paymentBoundToFail: false,
            activeNavItem: '',
            componentAfterRefresh: '',
            dbReady: false,
            lastTrainingCentreOrderUpdated: {},
            watchHistory: {},
            entityUpdateTimeout: null,
            demoPageMode: false,
            terminalMenuOptions: ['home', 'dashboard', 'videos','account'],
            terminalMenu: [],
            unlockedWidgets: [],
            widgetsChoiceOnDemo: [],
            externalUser: false,
            externalUserAutoLogin: false,
            brokerRegistrationData: {},
            ruleSet: null,
            checkoutLoginMode: false,
        }
    },
    mutations,
    actions,
    getters
}