<template>
    <div 
    class="section-card"
    :class="sectionCardClasses"
    >
        <div class="widget-thumbnail">
            <img :src="thumbNail" alt="" srcset="">
            <padlock-overlay
            v-if="notAddable && !noActions || !userQnaOn && item.code === 'ANALCHAT'"
            :impID="details.implementation_id"
            ></padlock-overlay>
            <!-- <div class="image-overlay"></div> -->
        </div>   
        <div class="widget-content">
            <h2>{{ itemName }}</h2> 
            <div 
            v-html="item.description"
            class="widget-content-description"></div>
        </div>   
        <div 
        v-if="!noActions"
        class="widget-action">
            <div
            v-if="forExternalUsers"
            >

            </div>
            <div 
            v-else
            class="widget-action-info"
            >
                <div
                v-if="isOnShowcase">
                    <VueCustomTooltip 
                    v-if="tooltipText"
                    :label="tooltipText" 
                    :multiline="true"
                    position="is-top"
                    >
                        <font-awesome-icon :icon="['fas', 'star']" class="showcase-star" />
                    </VueCustomTooltip>
                </div>
                <div
                class="active-on-plan"
                v-else-if="includedAsAProd">
                    <div class="active-on-plan-1">{{ includedDetails }}</div>
                    <div class="active-on-plan-2"
                    v-if="onlyOnOtherPlan"
                    >You are on {{ currentPlanName }}</div>
                </div>
                <div
                v-else-if="favorableProductName">
                    <div 
                    class="only-on-plan"
                    >
                    <!-- Included in {{ favorableProductName }} -->
                    </div>
                    <!-- <div class="only-on-plan-2"
                    >You are on {{ currentPlanName }}</div> -->
                </div>
                <div
                class="active-on-plan"
                v-else-if="!onlyOnOtherPlan && !notOnAnyPlan">
                    <div class="active-on-plan-1">Included in your plan</div>
                    <div class="active-on-plan-2">You are on {{ currentPlanName }}</div>
                </div>
                <div 
                class="not-on-any-plan"
                v-else-if="availableAsAddon" 
                >
                    <!-- <div 
                   
                    class="as-addon lg"><a href="javascript:void(0);">Buy widget for {{ addonPrice }} <font-awesome-icon class="arrow" :icon="['fas', 'arrow-right']"/></a> 
                    </div> -->
                    <div>Not included in any plan</div>
                </div>
            </div>
            
            <div class="widget-action-buttons">
                <div 
                    v-if="availableAsAddon && notOnAnyPlan" 
                    class="as-addon"
                    >
                </div>
                <!-- <div 
                    v-else-if="availableAsAddon && !!onlyOnOtherPlan" 
                    class="as-addon"
                    ><a 
                    @click="buyWidget"
                    href="javascript:void(0);">Buy indicator for {{ addonPrice }} <font-awesome-icon class="arrow" :icon="['fas', 'arrow-right']"/></a> 
                </div> -->
                <base-button
                class="u-largescreen-only"
                @click.native="openWidgetSetup"
                :mode="'text-tr-init narrow-button widget-button ' + buttonColor"
                >{{ buttonText }}</base-button>
                <base-button
                class="u-smallscreen-only"
                @click.native="openWidgetSetup"
                :mode="'text-tr-init narrow-button widget-button ' + buttonColor"
                >{{ buttonTextMobile }}</base-button>
            </div>
        </div>   
        <div class="bottom-banner"></div>    
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '../../../main';

import PadlockOverlay from '../../../assets/PadlockOverlay.vue';
import { formatAndVatPrice, isMobileForDashboards } from '../../../utility/helper-functions';
import IntercomMixin from '../../../mixins/intercomMixin'; 
import SubscriptionMixin from '../../../mixins/SubscriptionMixin';
import ContentLockedMixin from '../../../mixins/contentLockedMixin';

const general = createNamespacedHelpers("general");
const payments = createNamespacedHelpers("payments");
const dashboards = createNamespacedHelpers("dashboard");

export default {
    props: ["cardType", "item", "noActions", "widgetDirectAddition"],
    components: {
        PadlockOverlay
    },
    mixins: [IntercomMixin, SubscriptionMixin, ContentLockedMixin],
    computed: {
        ...general.mapState(['demoPageMode', 'externalUser']),
        ...general.mapGetters(['userQnaOn']),
        ...payments.mapGetters(['activePlan','upcomingPlans', 'sortedWidgetsByCreationDate']),
        ...dashboards.mapGetters(['tabbedDashboards', 'analChatActiveOn']),
        forExternalUsers() {
            return this.demoPageMode || this.externalUser;
        },
        details() {
            return this.item;
        },
        sectionCardClasses() {
            const noaction = this.noActions ? 'no-action' : '';
            return this.cardType + ' ' + noaction;
        },
        itemName() {
            return this.item.name.replace('Terminal: ', '');
        },
        isOnShowcase() { //
            if(this.noActions)
                return false;
                
            return this.item.planDetails.isOnShowcase;
        },
        tooltipText() {
            if(this.isOnShowcase)
                return this.item.planDetails.includedDetails;

            return '';
        },
        onlyOnOtherPlan() { //
            if(this.noActions)
                return false;

            return this.item.planDetails.onlyOnOtherPlan;
        },
        availableOnPlan() { //
            return this.item.planDetails.availableOnPlan;
        },
        favorableProductName() {
            return this.item.planDetails.favorableProduct?.name;
        },
        notOnAnyPlan() { //
            return this.item.planDetails?.notOnAnyPlan;
        },

        buttonText() {
            const target = this.item.code === "ANALCHAT" ? 'home screen' : 'dashboard'; 

            let addOrRemove = '+ Add to ' + target;
            if(this.item.code === "ANALCHAT" && this.analChatActiveOn)
                addOrRemove = 'Remove';

            if(this.forExternalUsers && !this.userQnaOn && this.item.code === 'ANALCHAT')
                return 'Unlock';
            else if(this.includedAsAProd)
                return addOrRemove;
            else if(this.favorableProductName)
                return 'Purchase now';//'Upgrade your plan';
            else if(this.notOnAnyPlan)
                return 'Buy indicator for ' + this.addonPrice;
            
            return addOrRemove;
        },
        buttonTextMobile() {
            if(this.forExternalUsers && !this.userQnaOn && this.item.code === 'ANALCHAT')
                return 'Unlock';
            if(this.includedAsAProd)
                return "Open indicator";
            else if(this.favorableProductName)
                return 'Upgrade your plan';
            else if(this.notOnAnyPlan)
                return 'Buy indicator for ' + this.addonPrice;
            
            return '';
        },
        buttonColor() {
            if(this.notAddable || this.forExternalUsers && !this.userQnaOn && this.item.code === 'ANALCHAT')
                return 'o-grey';
            if(this.item.code === "ANALCHAT" && this.analChatActiveOn)
                return 'red';
            
            return 'green';
        },
        notAddable() {
            if(this.includedAsAProd)
                return false;
            else if(this.onlyOnOtherPlan)
                return true;
            else if(this.notOnAnyPlan)
                return true;
            
            return false;
        },

        currentPlanName() {
            return this.activePlan.product.name;
        },

        availableAsAddon() { //
            return this.item.planDetails.availableAsAddon && !this.item.planDetails.favorableProduct;
        },
        addonPrice() {
            if(this.item.planDetails?.priceNumeric > 0)
                return formatAndVatPrice(this.item.planDetails?.priceNumeric)
    
            return '';
        },
        includedAsAProd() { //
            if(this.noActions)
                return false;
            return this.item.planDetails.includedAsAProd;
        },
        includedDetails() { //
            if(this.noActions)
                return '';

            return this.item.planDetails.includedDetails;
        },
        thumbNail() {
            let src = this.item.image || this.item.details?.image;

            if(!src)
                src = this.sortedWidgetsByCreationDate.find(el => el.implementation_id === this.item.implementation_id)?.details?.image;

            let root = window.bootUp.entity.mapped_domain + '/assets/';
            if(this.item.imgOverride) 
                root = ''
          
            if(src)
                return root + src;

            return '';
        }

    },
    methods: {
        ...dashboards.mapActions(["setActiveDashboardAndTab"]),

        openWidgetSetup() {
            // if(this.forExternalUsers && !this.userQnaOn) {
            //     this.showBrokerRequest(this.item.implementation_id);
            //     return;
            // }
             if(this.forExternalUsers && !this.userQnaOn && this.item.code === 'ANALCHAT') {
                this.showBrokerRequest(this.impID);
                return;
             }
                
            if(this.item.code === "ANALCHAT" && this.analChatActiveOn) {
                this.removeAnalChatWidget();
                return;
            }

            if(isMobileForDashboards() && this.includedAsAProd) {
                this.setActiveDashboardAndTab({
                    id: "mobile_finso_" + this.item.implementation_id,
                    instanceID: this.$root.instanceID
                });

                eventBus.$emit("nav-change", {
                    instanceID: this.$root.instanceID,
                    comp: "dashboard"
                });
                return;
            }

            const modalAlreadyOpen = !!document.querySelector(".terminal-modal");
            const instanceID = this.$root.instanceID;
            const mode = this.notAddable ? "purchase" : "";
            const panel = {
                view: 'widget-config',
                id: this.item.implementation_id,
                mode,
                widget: this.item,
                widgetDirectAddition: this.widgetDirectAddition
            };
            
            if(modalAlreadyOpen) {
                // console.log("sh nav to");
                eventBus.$emit("shop-navigate-to", {
                    ...panel,
                    instanceID,
                });
            }
            else {
                const modal = {
                    instanceID
                };

                // modal.header = false;
                modal.componentInModal = "widget-shop";
                modal.classes = "terminal-modal simplified";
                modal.panel = panel;
                modal.mode = "wide";

                eventBus.$emit('open-modal', modal);
            }

            // console.log(this.item);
            
    
        },
        buyWidget() {
            eventBus.$emit("nav-change", {
                instanceID: this.$root.instanceID,
                comp: "checkout",
                details: {
                    type: "addon",
                    selectedItem: this.item
                }
            });
        },
        // renderIncludedAddonName() {
            // if(this.noActions)
            //     return '';

            // const implementation_id = this.item.implementation_id;
            // const promos = this.currentAddons.filter(el => el.product.product_sub_type === 'bundle');
            // let currMasterPlan = this.activePlan;
            // if(currMasterPlan.product.parent_id)
            //     currMasterPlan = this.mergedPlans.find(el => el.id === currMasterPlan.product.parent_id);
            // const subs = this.currentAddons.filter(el => el.product.product_sub_type === 'addon');

            // if(promos?.length) {
            //     const includedInPromos = this.collectIncludingProds(promos, implementation_id);
            //     if(includedInPromos.length)
            //         return "Included in your " + includedInPromos[0].product.name;
            // }
            
            // if(currMasterPlan) {
            //     const includedInPlan = this.collectIncludingProds([currMasterPlan], implementation_id);
            //     if(includedInPlan.length)
            //         return "Included in your " + this.activePlan.product.name;
            //     else if(this.upcomingPlans[0] && !this.upcomingPlans[0]?.is_visible_front_end) {
            //         return "Included in your " + this.upcomingPlans[0]?.product.name;
            //     }
            // }
            
            // if(subs?.length) {
            //     const includedInSubs = this.collectIncludingProds(subs, implementation_id);
            //     if(includedInSubs.length)
            //         return "Subscribed to indicator";
            // }

        //     return this.item.planDetails.includedDetails;
        // },
        // collectIncludingProds(prodArray, implementation_id) {
        //     const includedInProds = [];
        //         prodArray.forEach(prod => {
        //             if(prod.product)
        //                 prod.product.widgets.forEach(w => {
        //                     if(w.pivot && w.pivot.widget_implementation_id === implementation_id)
        //                         includedInProds.push(prod);
        //                 });
        //             else {
        //                 prod.widgets.forEach(w => {
        //                     if(w.implementation_id === implementation_id)
        //                         includedInProds.push(prod);
        //                 });
        //             }
        //         });

        //     return includedInProds;
        // }
    }
}
</script>

<style lang="scss" scoped>
    .widget-thumbnail {
        position: relative;
        
        
        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            
        }
    }

    .no-action .widget-thumbnail img {
        object-fit: contain;
    }    

    .widget-content {

        margin-top: 5px;

        @include respond(sm) {
            margin-top: 10px;
        }

        @include respond(md) {
            margin-top: 5px;
        }

        @include respond(lg) {
            margin-top: unset;
            
            height: 83px;
        }

        @include respond(xl) {
            display: auto;
            height: auto;
        }

        & h2 {
            font-size: 15px;
            font-weight: 600;
            margin: 0;
            margin-bottom: 15px;
            line-height: 20px;
            color: $input-color;
            // 

            // @include respond(xl) {
            //     width: 250px;
            //     white-space: nowrap;
            //     overflow: hidden;
            //     text-overflow: ellipsis;
            // }
        }
    }

    .widget-action {
        
        & > div:first-child {
            margin-bottom: 5px;
        }

        @include respond(lg) {
            display: flex;
            
            flex-direction: row;
            justify-self: auto;
            justify-content: flex-end;
            width: 100%;
            height: 40px;

            & > div:first-child {
                margin-bottom: 0;
                margin-right: 20px;
            }
        }

        @include respond(xl) {
            justify-self: center;
            flex-direction: column;
            justify-content: space-between;
            height: auto;

             & > div:first-child {
                margin-right: 0;
            }
        }


        &-info {
            text-align: center;
            
            @include respond(lg) {
                text-align: unset;
                height: 100%;
                display: flex;
                align-items: center;
            }

            @include respond(xl) {
                height: auto;
                align-items: unset;
                align-self: self-end;
            }

            & .only-on-plan,
            & .active-on-plan,
            & .not-on-any-plan {

                text-align: center;
                font-size: 12px;
                text-transform: uppercase;
                letter-spacing: 1px;

                @include respond(lg) {
                    text-align: right;
                }
                
            }

            & .active-on-plan-1 {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            & .only-on-plan {
                color: $core-fuschia;
                // height: 33px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;

                &-2 {
                    font-size: 12px;
                    color: $input-color-filter;
                }
            }

            & .active-on-plan-2 {
                text-transform: initial;
                letter-spacing: initial;

                color: $input-color-filter;

            }
        }

        & .as-addon {
            margin-bottom: 5px;
            font-size: 14px;
            text-align: center;

            // &.lg {

            //     display: none;

            //     @include respond(lg) {
            //         text-align: right;
            //         display: block;
            //         margin-bottom: 0;
            //     }

            //     @include respond(xl) {
            //         display: none;
            //     }
            // }
            // @include respond(lg) {
            //     display: none;
            // }

            @include respond(xl) {
                text-align: right;
                display: block;
            }

            & a {
                color: $input-color;
                text-decoration: none;
            }
            

            & .arrow {
                color: $input-color-filter;
            }
        }

        &-buttons {
            display: flex;
            align-self: self-end;
            flex-direction: column;
            text-align: right;
        }
    }
</style>

<style lang="scss">
    .widget-content-description {
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.2px;
        color: $input-color-filter;


        & > div {

            @include respond(lg) {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }
    }
</style>