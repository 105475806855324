<template>
    <div class="client-profile-table team"
    :class="isApproved ? 'is-approved' : ''"
    >
        <div></div>
        <div style="text-align: center;" v-if="noClients">No team members in this group.</div>
        <div v-else>
            <div class="client-profile-table-table">
                <div class="client-profile-table-header">
                    <div 
                     class="client-profile-table-header-row"
                    >
                        <div class="u-from-lg-flex"></div>
                        <div>Name</div>
                        <div>Email</div>
                        <div class="u-from-lg-flex">
                            <span v-if="isApproved">Joined</span>
                            <span v-else>Invite Sent</span></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div class="client-profile-table-body">
                    
                    <team-profile-tablerow
                    class="client-profile-table-body-row-clients"
                    v-for="rule in rules"
                    :rule="rule"
                    :key="rule.id"
                    :sortStatus="sortStatus"
                    >
                    </team-profile-tablerow>
                        
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import TeamProfileTablerow from './TeamProfileTablerow.vue';


export default {
    props: ["rules", "sortStatus"],
    // created() {
    //     console.log(this.rules)
    // },
    components: {
        TeamProfileTablerow
    },
    computed: {
        noClients() {
            return _.isEmpty(this.rules);
        },
        isApproved() {
            return this.sortStatus === 'approved';
        }, 
        isRequested() {
            return this.sortStatus === 'requested';
        },
        isArchived() {
            return this.sortStatus === 'archived';
        },
    },
   
}
</script>

<style lang="scss">
    
    .client-profile-table.team {

        & .client-profile-table-body-row-clients {
            
            & > div {
               
                &:nth-child(2) {
                    @include respond(lg) {
                        border-left: none;
                    }
                }
            }
        }

        &.team {

            & .client-profile-table-header-row {
                grid-template-columns: 150px 1fr 150px 150px;

                @include respond(lg) {
                    grid-template-columns: 80px 150px 1fr 100px 50px 50px 50px;
                }
            }
            & .client-profile-table-body-row-clients {
                grid-template-columns: 150px 1fr 150px 150px;

                @include respond(lg) {
                    grid-template-columns: 80px 150px 1fr 100px 50px 50px 50px;
                }
            }
        }

    }
</style>