<template>
    <component
    :is="componentToBeRendered"
    :details="details">
    </component>
</template>

<script>
import VideoCourse from './VideoCourse.vue';
import VideoTilestyle from './VideoTilestyle.vue';
import findComponent from '../../mixins/componentToBeRendered';

export default {
    props: ["details"],
    mixins: [findComponent],
    components: {
        VideoCourse,
        VideoTilestyle
    },
    computed: {
        componentToBeRendered() {
            const style = this.$store.getters['widget/getWidgetByImplementationId'](this.details.implementation_id).training_centre_widget_style;
            if(style === 'Q&A')
                return "video-tilestyle";

            return "video-course";
        },
    }
}
</script>

