import { render, staticRenderFns } from "./VideoQuestion.vue?vue&type=template&id=b90ad456&scoped=true&"
import script from "./VideoQuestion.vue?vue&type=script&lang=js&"
export * from "./VideoQuestion.vue?vue&type=script&lang=js&"
import style0 from "./VideoQuestion.vue?vue&type=style&index=0&id=b90ad456&lang=scss&scoped=true&"
import style1 from "./VideoQuestion.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b90ad456",
  null
  
)

export default component.exports