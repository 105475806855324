<template>
    <div class='VuePagination' :class='props.theme.wrapper'>
        <nav :class='props.theme.nav'>

            <ul v-show="props.showPagination" :class="props.theme.list">

                <li class="pagination-nav-button blue" :class="props.theme.prev" @click="props.setPrevPage">
                    <a v-bind="{...props.aProps,...props.prevProps}"><font-awesome-icon :icon="['fas', 'chevron-left']"/></a>
                </li>

                <li class="pagination-nav-button" v-for="page in props.pages" :key="page" :class="props.pageClasses(page)"
                    v-on="props.pageEvents(page)">
                    <a v-bind="props.aProps" :class="props.theme.link">{{page}}</a>
                </li>

                <li class="pagination-nav-button blue" :class="props.theme.next" @click="props.setNextPage">
                    <a v-bind="{...props.aProps, ...props.nextProps}"><font-awesome-icon :icon="['fas', 'chevron-right']"/></a>
                </li>

            </ul>

            <p v-show="props.hasRecords" :class='props.theme.count'>{{props.count}}</p>

        </nav>
    </div>

</template>

<script>
    export default {
        name: 'MyPagination',
        props: ['props'],
    }
</script>

<style lang="scss">
    .pagination-nav-button {
        float: left;
        
        text-decoration: none;
        padding: 8px 17px;
        font: inherit;
        color: rgba(254, 254, 254, 0.8);
        cursor: pointer;
        border-radius: 2px;
        margin-right: 0.5rem;
        // display: inline-block;
        border: none;
        text-transform: capitalize;
    }

    .pagination-nav-button a {
        color: $color-06;
    }

    .pagination-nav-button.blue {
        background-color: $color-twitter;
    }

    .pagination-nav-button.blue a {
        color: $color-20;
    }

    .pagination li.disabled.pagination-nav-button.blue {
        background-color: $color-04;
        cursor:unset;
        
    }
    .pagination li.disabled.pagination-nav-button.blue a {
        color: $color-06;
        
    }

</style>