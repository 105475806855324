<template>
    <div v-if="widget.planDetails && widget.planDetails.included">
        <span>
            You currently have access to this widget.
        </span>
    </div>
    <div v-else>
        <span>To activate this widget, please select your preferred option below:</span>
        <table>
            <tbody>
            <tr v-if="upgradeProduct">
                <td>
                    <span>Upgrade your plan</span>
                </td>
                <td>
                    <base-button mode="blue" @click.native="upgradePlan">Select</base-button>
                </td>
            </tr>
            <tr v-if="subscriptionProduct">
                <td>
                    <span>Add {{ subscriptionProduct.name }} to your current plan</span>
                </td>
                <td>
                    <base-button mode="blue" @click.native="addSubscription">Select</base-button>
                </td>
            </tr>

            </tbody>
        </table>
        <vue-slide-up-down :active="upgradeProductToggle" :duration="250" class="vue-slide-up-down">
            <change-plan v-if="upgradeProductToggle"></change-plan>
        </vue-slide-up-down>
    </div>

</template>

<script>
import ChangePlan from "../forms/ChangePlan";
import SubscriptionMixin from "../../../mixins/SubscriptionMixin";

export default {
    name: "WidgetPurchase",
    components: {ChangePlan},
    mixins: [SubscriptionMixin],
    props: {
        widget: Object
    },
    data: function () {
        return {
            subscriptionProduct: null,
            upgradeProduct: null,
            upgradeProductToggle: false
        }
    },
    methods: {
        initializePurchaseOptions: function () {
            this.subscriptionProduct = this.widget?.planDetails?.addonProducts?.[0];
            this.upgradeProduct = this.widget?.planDetails?.planProducts?.[0];
        },
        upgradePlan: function () {
            this.upgradeProductToggle = !this.upgradeProductToggle;
        }
    },
    created() {
        if (!this.widget?.planDetails?.included) {
            this.initializePurchaseOptions();
        }
    }
}
</script>

<style scoped>

</style>
