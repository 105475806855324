

export default {
    boards(state) {
        return state.boards;
    },
    channelsInBoard(state) {
        return (id) => {
            return state.channels.filter(channel => channel.board_id === id);
        };
    },
    tweetsInChannel(state) {
        return (id) => {
            return state.tweets.filter(tweet => tweet.channel_id === id);
        };
    },
    lastTweetUpdate(state) {
        return JSON.parse(JSON.stringify(state.lastTweetUpdate));
    },
    lastBoardUpdate(state) {
        return JSON.parse(JSON.stringify(state.lastBoardUpdate));
    },
    lastChannelUpdate(state) {
        return JSON.parse(JSON.stringify(state.lastChannelUpdate));
    },
    lastHandleUpdate(state) {
        return JSON.parse(JSON.stringify(state.lastHandleUpdate));
    },
    lastChannelHandleUpdate(state) {
        return JSON.parse(JSON.stringify(state.lastChannelHandleUpdate));
    },
    haveBoards(state) {
        return state.haveBoards;
    },
    haveChannels(state) {
        return state.haveChannels;
    },
    upperMostParents(state) {
        return state.upperMostParents;
    },

    latestTweets(state) {
        return state.latestTweets;
    },

    tweetsFetchedForBoards(state) {
        return state.tweetsFetchedForBoards;
    },
    tweetsFetchedBoard: (state) => (id) => {
        return state.tweetsFetchedForBoards.indexOf(id) > -1;
    }

}