<template>
    <a 
    style="text-decoration: none; color: unset;"
    @click="cardClicked(item.id)"
    href="javascript:void(0);">
        <div 
        
        class="section-card"
        :class="cardType"
        >
            <div 
            v-if="cardType !== 'dashboards'"
            class="section-card-header">
                <div class="section-card-header-left">
                    <div 
                    v-if="waitText"
                    class="section-card-header-sup">{{ waitText }}</div>
                    <div 
                    v-else
                    class="section-card-header-sup">{{ supText }}</div>
                    <div class="section-card-header-main"> 
                        <span v-if="isLive" class="live-span">
                            <font-awesome-icon :icon="['fas', 'dot-circle']" /> LIVE:
                        </span>
                        {{ itemName }}</div>
                </div>
                <div class="section-card-header-right">
                    <div v-if="item.forceShowcase" class="showcase-ribbon">
                        Free
                    </div>
                    <dashboard-mini-menu
                    mode="home-screen"
                    v-if="showDashboardMenuOption"
                    :item="item"
                    ></dashboard-mini-menu>
                    <span
                    v-else-if="!item.no_showcase_on_trial">
                        <VueCustomTooltip 
                        v-if="tooltipText"
                        :label="tooltipText" 
                        :multiline="true"
                        position="is-top"
                        >
                            <font-awesome-icon :icon="['fas', 'star']" class="showcase-star" />
                        </VueCustomTooltip>
                    </span>
                </div>
            </div>

            <div 
            v-if="cardType === 'dashboards'"
            class="section-card-header"
            style="height: 60px; display: flex; align-items: center;">
                <div class="section-card-header-left">
                    <div 
                    class="section-card-header-sup"></div>
                    <div class="section-card-header-main"></div>
                </div>
                <div class="section-card-header-right">
                    <dashboard-mini-menu
                    mode="home-screen"
                    v-if="showDashboardMenuOption"
                    :item="item"
                    ></dashboard-mini-menu>
                    <span
                    v-else-if="!item.no_showcase_on_trial">
                        <VueCustomTooltip 
                        v-if="tooltipText"
                        :label="tooltipText" 
                        :multiline="true"
                        position="is-top"
                        >
                            <font-awesome-icon :icon="['fas', 'star']" class="showcase-star" />
                        </VueCustomTooltip>
                    </span>
                </div>
            </div>     

            <div class="section-card-body">

                <newdashboard-icon
                class="dashboard-icon"
                v-if="cardType === 'dashboards' && item.id === 'new-dashboard-card'"
                ></newdashboard-icon>

                <dashboard-icon
                class="dashboard-icon"
                v-else-if="cardType === 'dashboards'"
                ></dashboard-icon>

                <img 
                v-else-if="imageLink"
                :src="imageLink" alt="" srcset="">

            </div>

            <div 
            v-if="cardType === 'dashboards' && item.id === 'new-dashboard-card'"
            class="section-card-footer"
            style="position: relative; height: 37px">
                <base-button 
                style="position: absolute; top: 50%; top: -22%; left: 50%; transform: translateX(-50%); width: 100%;"
                id="create-dashboard-button" 
                mode="green narrow-button"
                >
                    <span v-if="demoPageMode">
                        <span style="margin-right:5px; white-space: nowrap;"><font-awesome-icon :icon="['fas', 'plus']"/> dashboard</span>
                    </span>
                    <span v-else>
                        <span class="d-xl" style="margin-right:5px;">Create new dashboard</span>
                        <span class="d-none-xl" style="margin-right:5px; white-space: nowrap;"><font-awesome-icon :icon="['fas', 'plus']"/> dashboard</span>
                    </span>
                </base-button>
            </div>   

            <div 
            v-if="cardType === 'dashboards' && item.id !== 'new-dashboard-card'"
            class="section-card-footer">
                <div class="section-card-footer-left">
                    <div class="section-card-footer-sup">{{ supText }}</div>
                    <div class="section-card-footer-main"> {{ item.name }}</div>
                </div>
                <div class="section-card-footer-right">
                </div>
            </div>
   
            <div v-if="!item.forceShowcase && !permanentOffer" class="bottom-banner">{{ deadlineText }} {{ remainingTime }}</div>    
        </div>
    </a>
</template>

<script>
import { calculateRemainingTime, dateTimedifference, formatCountdown, usersTime } from '../../../utility/datetime';
import { createNamespacedHelpers } from 'vuex';
import DashboardMiniMenu from '../DashboardMiniMenu.vue';
import DashboardIcon from '../../../assets/DashboardIcon.vue'
import Swal from "sweetalert2";
import { eventBus } from '../../../main';
import NewdashboardIcon from '../../../assets/NewDashboardIcon.vue';
import * as workerTimers from 'worker-timers';
import LivestreamService from '../../../services/livestream/livestream.service';
import _ from 'lodash';
import VideoService from '../../../services/video/video.service';
import Constants from '../../../utility/constants';

const dashboards = createNamespacedHelpers('dashboard');
const payments = createNamespacedHelpers('payments');
const livestreamNS = createNamespacedHelpers("webinars");
const general = createNamespacedHelpers("general");

export default {
    props: ["cardType", "item"],
    components: {
        DashboardIcon,
        DashboardMiniMenu,
        NewdashboardIcon
    },
    created() {
        this.handleContent();
    },
    beforeDestroy() {
        if(this.interval) {
            workerTimers.clearInterval(this.interval);
            this.interval = null;
        }
    },
    computed: {
        ...dashboards.mapGetters(['defaultDashboards', 'customDashboards', 'systemDashboards', 'dashboards']),
        ...livestreamNS.mapGetters(['lastLiveStreamUpdate', 'lastLiveStreamDelete', 'currentLiveIDs','isCurrentlyLive']),
        ...general.mapState(['demoPageMode', 'externalUser']),
        ...general.mapGetters(['entityUpdateTimeout']),
        userDashboards() {
            return [
                ...this.defaultDashboards,
                ...this.customDashboards,
                ...this.systemDashboards.filter(el => !el.mobile && !el.unique_id),
            ];
        },

        isDashboards() {
            return this.cardType === 'dashboards';
        },
        isProducts() {
            return this.cardType === 'products';
        },
        isStream() {
            return this.item.code === 'TCSTREAM';
        },
        isCourse() {
            return this.item.code === 'TCVIDEO';
        },
        supText() {
            if(this.isCourse) {
                return '...';
            }
            if(this.isStream) {
                if(!this.remainingTime)
                    return '...';

                return "Next live event: " + this.remainingTime;
            }
            else if(this.isProducts) {
                if(!this.remainingTime)
                    return '...';

                if(this.permanentOffer)
                    return 'Available to purchase today';

                return 'Available to buy until ' + this.promoEndDateFormatted;
            }
            else if(this.isDashboards && !this.item.unique_id)
                return 'Dashboard';
            else if(this.isDashboards && this.item.unique_id)
                return 'Showcased Dashboard';

            return '';
        },
        imageLink() {
            const base = window.bootUp.entity.mapped_domain;
            if(this.item.forceShowcase && !this.externalUser && !this.demoPageMode) {
                return base  + this.itemImage;
            }

            if(this.isProducts) {
                // if(this.item.image?.indexOf("[#domain#]") > -1)
                    // return this.item.image.replaceAll("[#domain#]", window.bootUp.entity.mapped_domain);
                // return base + (this.externalUser || this.demoPageMode ? '/' : '/assets/') + this.itemImage;
                return base + '/assets/' + this.itemImage;
            }

            return '';
        },
        showDashboardMenuOption() {
            if(this.isDashboards && !this.item.unique_id  && this.item.id !== 'new-dashboard-card')
                return true;

            return false;
        },
        tooltipText() {
            if(this.isDashboards && this.item.id !== 'new-dashboard-card') {
                const timestamp = (this.$store.getters["payments/activePlan"])?.product?.widgets?.find(widget => widget.implementation_id && widget.implementation_id === this.item.unique_id).showcase_ends_at;

                let text = 'Normally on Pro plan.';
                let until = timestamp ? ' Showcased here for free until ' +  usersTime(timestamp, true, false) : '';
                return text + until;
            }

            return '';
            
        },
        deadlineText() {
            if(this.item.forceShowcase) 
                return "Starts in ";
            
            return "Launch ends in "
        },
        promoEndDate() {
            if(this.item.forceShowcase) {
                return this.item.next_upcoming.starts_at;
            }
            else if(this.isProducts && this.item.promotion_ends_at)
                return this.item.promotion_ends_at;

            return '';
        },
        timezone() {
            return this.user.timezone;
        },
        promoEndDateFormatted() {
            if(this.promoEndDate)
                return usersTime(this.promoEndDate, true, false, false, { dateFormat : "d M" }, "UTC");

            return '';
        },
        // liveEventFormatted() {
        //     if(this.promoEndDate)
        //         return usersTime(this.promoEndDate, true, true, false, { dateFormat : "d M", timeFormat: "24hrs" }, this.timezone);

        //     return '';
        // },
        
    },
    data() {
        return {
            remainingTime: '',
            interval: null,
            waitText: '',
            itemName: '',
            itemImage: '',
            isLive: false,
            permanentOffer: true
        }
    },
    methods: {
        ...dashboards.mapActions(['setActiveDashboard', 'setActiveDashboardAndTab']),
        ...payments.mapActions(['deleteProductBySlug']),
        
        cardClicked(id) {
            // console.log("card clicked", id);
            if(this.isDashboards) {

            
                if(id === 'new-dashboard-card') {
                    this.createDashboard();
                    return;
                }
                    
                this.setActiveDashboardAndTab({
                    id: id,
                    instanceID: this.$root.instanceID
                });

                eventBus.$emit("nav-change", {
                    instanceID: this.$root.instanceID,
                    comp: "dashboard"
                });
            }
            else if(this.item.forceShowcase) {

                const id = this.item.id;
                this.setActiveDashboard({
                    target: "videopage",
                    id: "vidpage_" + id,
                    instanceID: this.$root.instanceID
                });
                eventBus.$emit("nav-change", {
                    instanceID: this.$root.instanceID,
                    comp: "videos"
                });

            }
            else if(this.isProducts) {
                if(this.isCourse || this.isStream) {
                    eventBus.$emit("nav-change", {
                        instanceID: this.$root.instanceID,
                        comp: 'videos'
                    });
                    return;
                }

                const instanceID = this.$root.instanceID
                const modal = {
                    instanceID
                };

                const panel = {
                    view: 'product-config',
                    id,
                };

                // modal.header = false;
                modal.componentInModal = "widget-shop";
                modal.classes = "terminal-modal simplified";
                modal.panel = panel;
                modal.countdown = this.promoEndDate
                modal.mode = "wide";

                eventBus.$emit('open-modal', modal);

            }
        },
        createDashboard() {
            if(this.userDashboards.length >= 20) {
                const text = "To create a new dashboard, please delete an existing one to make room.";

                Swal.fire({
                    title: 'Too many boards',
                    html: text,
                    type: 'warning',
                    confirmButtonColor: '#3B9C83',
                    confirmButtonText: 'OK'
                    // timer: 1500
                });

                return;
            }
        
            const modal = {
                instanceID: this.$root.instanceID,
                mode: 'wide',
                classes: 'terminal-modal',
                footer: true,
                // panel: {}
            };
            
            modal.title = "Create a New Dashboard";
            
            modal.componentInModal = "edit-dashboard";

            eventBus.$emit('open-modal', modal);
        },
        async handleContent() {
            if(this.isStream) {
                // console.log(JSON.parse(JSON.stringify(this.item)));
                this.interval = workerTimers.setInterval(() => {
                    const now = usersTime(new Date(), true, true, true, { dateFormat : "y-m-d", timeFormat: "24hr" }, "UTC");
                
                    const timestamp = this.promoEndDate;
                    let fullSeconds = dateTimedifference(now, timestamp, "seconds") - 1;
                    if(fullSeconds > 86400) {
                        // const days = Math.round(fullSeconds / 86400);
                        this.remainingTime = usersTime(this.promoEndDate, true, true, false, { dateFormat : "d M", timeFormat: "24hrs" }, this.timezone);
                    }else{
                        this.remainingTime = formatCountdown(fullSeconds);
                    }

                }, 1000);

                

                    const idArr = [this.item.implementation_id];

                    LivestreamService.fetchNextUpcomingByImpIDs(idArr).then(r => {
                        if(!_.isEmpty(r)) {
                            const item = Object.values(r)?.[0];
                            if(item) {
                                this.itemName = item.name;
                                this.itemImage = item.thumbnail_url;

                                if(this.externalUser || this.demoPageMode) {
                                    this.isLive = item.status === 'live';
                                    this.itemName = this.item.name;
                                    this.itemImage = this.item.image;
                                }
                            }
                        }

                    });
              

                this.handleLive();
            }
            else if(this.isCourse) {
                const topCats = this.item.categories;
                const subCats = await VideoService.getSubCategoriesForCourse(...topCats);
                const itemsToFetch = [...topCats, ...subCats.map(el => el.id)];
                const r = await VideoService.fetchInitialItems({
                    categories: itemsToFetch,
                    type: "course"
          
                });

                this.waitText = 'Includes ' + r.videos.length + ' video' + (r.videos.length > 1 ? 's': '');
                this.itemImage =  this.item.image;
                this.itemName = this.item.name;
            }
            else if(this.isStream) {
                this.waitText = "Live Stream Series";
                this.itemName = this.item.name;
                this.itemImage =  this.item.image;
            }
            else if(this.cardType === 'products') {
                this.itemName = this.item.name + (this.item.slug.indexOf('legacy') > -1 ? ' (Annual)' : '');
                this.itemImage = this.item.image;

                    const now = usersTime(new Date(), true, true, true, { dateFormat : "y-m-d", timeFormat: "24hr" }, "UTC");
                
                    const timestamp = this.promoEndDate;
                    let fullSeconds = dateTimedifference(now, timestamp, "seconds") - 1;
                    
                    if(fullSeconds < Constants.PERMANENT_OFFER_DEADLINE) {
                        this.interval = workerTimers.setInterval(() => this.remainingTime = calculateRemainingTime(this.promoEndDate), 1000);
                        this.permanentOffer = false;
                    }else{
                        this.permanentOffer = true;
                        this.remainingTime = calculateRemainingTime(this.promoEndDate);
                    }
    
                
            }
        },
        handleLive() {
            if(this.externalUser || this.demoPageMode) {
                return;
            }
            else if(this.item.forceShowcase && this.item.next_upcoming.status === 'live')
                this.isLive = true;
            else
                this.isLive = false;
        }
    },
    watch: {
        remainingTime(val) {
            
            if(val === '00:00:00') {

                workerTimers.clearInterval(this.interval);
                this.interval = null;

                if(this.isStream) {
                    this.waitText = "Stream will start shortly";
                    if(this.isLive)
                        this.waitText = "Streaming now"
                }
                else {
                    setTimeout(() => eventBus.$emit("remove-promo", {
                        instanceID: this.$root.instanceID,
                        id: this.item.id
                    }), 1000);
                }

                
            }
        },
        lastLiveStreamUpdate() {
            if(this.item.forceShowcase) {
                this.handleContent();
            }
        },
        lastLiveStreamDelete() {
            if(this.item.forceShowcase) {
                this.handleContent();
            }
        },
        entityUpdateTimeout(val) {
            if(val)
                this.handleContent();
        }
    }
}
</script>

<style lang="scss">
    .section-card-body .dashboard-icon{
        position: absolute;
        top: 45%;
        transform: translate(-50%, -50%);
        left: 50%;
    }
    #create-dashboard-button {
        text-transform: capitalize;
    }
</style>