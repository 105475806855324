import Vue from 'vue';
import Swal from "sweetalert2";
import {eventBus} from "../main";
import {store} from "../store/store";

export const ExplorerMode = {
    bind(el, binding) {
        if (window.bootUp.isExploreMode) {
            Swal.fire({
                customClass: {
                    cancelButton: 'flat'
                },
                title: 'Create Free Account',
                type: 'info',
                text: 'You’ll be able to take unlimited trials of all our widgets and customise the terminal to your preferences.',
                showCancelButton: true,
                cancelButtonText: 'No thanks',
                confirmButtonColor: '#1d9deb',
                confirmButtonText: 'Create Account'
            }).then((result) => {
                if (result.value) {
                    eventBus.$emit('close-modal');
                    store.dispatch('auth/setLoginFormMode', 'register');
                    eventBus.$emit('logout-successful');
                }
            });

        } else {
            el.addEventListener(binding.arg, binding.value);
        }
    },

    unbind(el, binding) {
        el.removeEventListener(binding.arg, binding.value);
    }

};

Vue.directive('explorer-on', ExplorerMode);
