<template>
    <div class="embedded-component" v-if="!isExternalClientMode">
        <div v-if="!success">
        <h2 class="align-center">Enter new password</h2>
        <div
        class="form-text-div dimmed align-center subtitle">Choose a secure password, re-type it to confirm and click 'Change Password' to finish setting up your new password.</div>
        <span id="pw-reveal1" class="pw-reveal">
            <a @click="togglePwVisibility(0)" v-if="schema.fields[0].inputType === 'password'"><font-awesome-icon :icon="['fa', 'eye']"/></a>
            <a @click="togglePwVisibility(0)" v-else><font-awesome-icon :icon="['fa', 'eye-slash']"/></a>
        </span>
        <span id="pw-reveal2" class="pw-reveal">
            <a @click="togglePwVisibility(1)" v-if="schema.fields[1].inputType === 'password'"><font-awesome-icon :icon="['fa', 'eye']"/></a>
            <a @click="togglePwVisibility(1)" v-else><font-awesome-icon :icon="['fa', 'eye-slash']"/></a>
        </span>
        <form 
        
        @submit.prevent class="container"
        >
            <vue-form-generator ref="form"
            :schema="schema"
            :model="model"
            :options="formOptions"
            @model-updated="modelUpdated"
            ></vue-form-generator>
            <fieldset style="margin-top: 10px;">
                <base-button
                mode="o-blue fullwidth"
                :link="false"
                @click.native="resetPassword"
                >Change Password</base-button>
            </fieldset>
        </form>
        
        </div>
        <div v-else>
            <div class="align-center">
                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="check-circle" class="svg-inline--fa fa-check-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="height: 48px;">
                    <path fill="currentColor" d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z">
                    </path>
                </svg>
            </div>
            <h2 class="align-center">Password changed</h2>
            <div
            class="form-text-div dimmed align-center subtitle">{{ success }}</div>
        </div>
        
    </div>
</template>

<script>
// DUMMY LINK:
// http://localhost:8080/pwr-rst?email=hello%40peterbalint.hu&nonce=aZYGG8CRg5LK312gzB46lkW0xLs7KjysWcPG8b2C6MWOEzSLmToKVz6dfvxx
import VueFormGenerator from "vue-form-generator";
import OnBoardMixin from '../../../mixins/onBoardMixin';
import AccountService from "../../../services/account/account.service";
import { isExternalClientMode } from '../../../utility/helper-functions';

const CONDITIONS = [
    {
        test: (model) => model.password?.length > 0, 
        message: 'Please fill password field.'
    },
    {
        test: (model) => model.password?.length >= 8 && model.password?.length <= 32, 
        message: 'Password must be between 8 and 32 characters.'
    },
    {
        test: (model) => model.password2?.length > 0,
        message: 'Please fill confirm password field.'
    },
    {
        test: (model) => model.password === model.password2,
        message: 'Passwords do not match.',
    },
]

export default {
    mixins: [OnBoardMixin],
    props: {
        token: String,
        email: String
    },
    created() {
        if(isExternalClientMode())
            this.$router.push("/home");
    },
    mounted() {
        document.querySelector('.label-transition.password').appendChild(document.getElementById('pw-reveal1'));
        document.querySelector('.label-transition.password2').appendChild(document.getElementById('pw-reveal2'));
    },
    computed: {
        isExternalClientMode() {
            return isExternalClientMode();
        },
    },
    data() {
        return {
            success: "",
            errorMessages: [],
            model: {
                password: '',
                password2: '',
            },
            schema: {
                fields: [
                    {
                            
                        type: "input",
                        inputType: "password",
                        label: "New Password",
                        model: "password",
                        featured: true,
                        required: true,
                        placeholder: "New Password *",
                        styleClasses: "label-transition password",
                        validator: VueFormGenerator.validators.string
                
                    },
                    {
                            
                        type: "input",
                        inputType: "password",
                        label: "Re-type Password",
                        model: "password2",
                        featured: true,
                        required: true,
                        placeholder: "Re-type Password *",
                        styleClasses: "label-transition password2",
                        validator: VueFormGenerator.validators.string
                
                    }
                ]
            },
            formOptions: {
                validateAfterLoad: false,
                validateAfterChanged: true,
                validateAsync: false
            }
        }
    },
    methods: {
        modelUpdated() {
            return;
        },
        validate: function () {
            this.errorMessages = [];
            CONDITIONS.forEach((condition) => {
                if (!condition.test(this.model)) {
                    this.errorMessages.push(condition.message);
                }
            })
            console.log(this.errorMessages);
            this.$emit('emit-error', {
                error: this.errorMessages.join(' '),
            });
            return this.errorMessages.length === 0;
        },
        resetPassword() {

            let isValid = this.validate();
            if (isValid) {
                AccountService.resetPassword({
                    password: this.model.password,
                    password_confirmation: this.model.password2,
                    nonce: this.token,
                    email: this.email
                })
                    .then(result => {
                        if (result.data.success) {
                            this.success = "Your password was updated successfully. You will be redirected to log in.";
                            setTimeout(() => this.$router.push("/login"), 2000);
                        } else {
                            Object.values(result.data).forEach(message => this.errorMessages.push(message[0]));
                            this.$emit('emit-error', {
                                error: this.errorMessages,
                            });
                        }
                    })
                    .catch((error) => {
                        Object.values(error?.response?.data?.data || {}).forEach(message => this.errorMessages.push(message[0]));
                        this.$emit('emit-error', {
                            error: this.errorMessages,
                        });
                    })
            }
        },
        togglePwVisibility(i) {
            let type = this.schema.fields[i].inputType;
            if(type === 'password')
                type = 'text';
            else if(type === 'text')
                type = 'password';

            this.schema.fields[i].inputType = type;
        }
    },
    beforeRouteLeave(to, from, next) {
        this.$emit('emit-error', {
            error: "",
            // email: this.model.email
        });
        next();
    }
}
</script>