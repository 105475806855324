import { createNamespacedHelpers } from 'vuex';
import HeadlineService from '../services/headlines/headline.service';
import ContentLockedMixin from '../mixins/contentLockedMixin';
import { usersTime } from '../utility/datetime';
import { arrayIntersect, mergeArraysByProp, missingArrayElements, sortDataBy } from '../utility/helper-functions';
import _ from "lodash";


const headlinesNS = createNamespacedHelpers('headlines');
const general = createNamespacedHelpers("general");
const widget = createNamespacedHelpers("widget");
const dashboards = createNamespacedHelpers('dashboard');

export default {
    mixins: [ContentLockedMixin],
    created() {
        this.fix();
        // this.removeRemovedData();
        // console.log(this.sources, this.categories);
    },
    computed: {
        ...headlinesNS.mapGetters(['readyForHeadlineProcessing', 'lastHeadlineUpdate']),
        ...general.mapGetters(['user']),
        ...widget.mapGetters(['getWidgetByImplementationId']),
        ...general.mapState(['demoPageMode']),
        ...dashboards.mapGetters(['activeDashboardInfoByInstanceID']),
        timezone() {
            return this.user.timezone;
        },
        searchValues() {
            return this.details.searchTerms;
        },
        headlineSettings() {
            return this.details.userSettings;
        },
        activeDashboardInfo() {
            return this.activeDashboardInfoByInstanceID(this.$root.instanceID, "dashboard");
        },
        // sourcesArray() {
        //     return this.details.userSettings.sources;

        // },
        // categoriesArray() {
        //     return this.details.userSettings.categories;
        // }

    },
    data() {
        return {
            bulkHeadlines: [],
            sortedItems: {},
            headLinesPerFetch: 25,
            resultsReady: true,
            searchTerms: null,
            noMoreItems: false,
            highlightTimeOut: null,
            newHeadline: false,
            serverForceFetchDone: false,

        }
    },
    methods: {
        ...dashboards.mapActions(["updateDashboardById"]),
        async fetchInitialHeadlines() {
            this.resultsReady = false;
            // console.log(this.sources,this.categories);
            let props = this.tabs?.find(el => el.id === this.currentSection)?.properties || [];

            if(this.slug === 'vidcom')
                props = [2];

            const obj = {
                sources: this.sources,
                categories: this.categories,
                props: props
            };

            this.bulkHeadlines = await HeadlineService.fetchInitialHeadlines(obj);
            const min = this.demoPageMode ? 4 : 10;

            if(this.bulkHeadlines.length && this.bulkHeadlines.length > min) {
                this.resultsReady = true;
            }
            //in case headlines have not been loaded on the cache
            else if(!this.serverForceFetchDone) {
                this.fetchHeadlinesFromServer();
                this.serverForceFetchDone = true;
            }else {
                this.resultsReady = true;
                // this.noMoreItems = true;
            }
            // console.log("ready",this.resultsReady, this.serverForceFetchDone)

        },
        async loadMore () {
            if(!this.resultsReady)
                return;

            this.resultsReady = false;
            const limit = this.headLinesPerFetch;
            let offset = this.bulkHeadlines.length;
            let fromTo = "*/*";
            let keywords ="*";
            let searchMode = false;

            // console.log(this.searchTerms);

            if(this.searchTerms) {
                fromTo = this.searchTerms.from + "/" + this.searchTerms.to + "T23:59:59";
                keywords = this.searchTerms.keywords;
                offset = this.bulkHeadlines.length ? this.bulkHeadlines.length : 0;
                searchMode = true;
            }

            let props = this.tabs?.find(el => el.id === this.currentSection)?.properties || [];

            if(this.slug === 'vidcom')
                props = [2];

            const response = await HeadlineService.loadMoreHeadlines(this.slug, this.sources, this.categories, props, offset, limit, searchMode, fromTo, keywords);
            let bulkHeadlines = mergeArraysByProp(this.bulkHeadlines, response, "id");
            sortDataBy(bulkHeadlines, this.sortProp, "timeDESC");

            this.bulkHeadlines = bulkHeadlines;
            if(response.length < 4) {
                this.noMoreItems = true;
            }


            this.resultsReady = true;

        },
        async fetchHeadlinesFromServer() {
            if(this.slug === 'headlines')
                await HeadlineService.fetchHeadlinesFromServer({categories: this.categories});
            else if(this.slug === 'vidcom')
                await HeadlineService.fetchCategoryHeadlines({
                    sources: [1,2],
                    categories: this.categories,
                    prop: [2],
                    offset: 0,
                    limit: 50,
                    fromTo: '*/*',
                    isSearchMode: false,
                    keywords: "*"
                });

            this.fetchInitialHeadlines();
        },
        removeHighlight() {
            let bulkHeadlines = this.bulkHeadlines;
            bulkHeadlines.forEach(element => {
                if(element.new) {
                    element.new = false;
                }
            });

            this.bulkHeadlines = [];
            this.bulkHeadlines = bulkHeadlines;

        },
        removeRemovedData() {
            // console.log(this.details);
            if(this.details.widgetCode !== 'HEADLINES')
                return;

            const active = _.cloneDeep(this.activeDashboardInfo);
            let updatedWidgetSources = [];
            let updatedWidgetCategories = [];
            
            const currentWidgetSources = this.details.userSettings.sources;
            const defaultWidgetSources = this.getWidgetByImplementationId(this.details.implementation_id)?.settings?.sources?.formJSON?.model?.sources;
            const missingSourceElements = missingArrayElements(defaultWidgetSources, currentWidgetSources);
            if(missingSourceElements.length) {
                updatedWidgetSources = currentWidgetSources.filter(el => !missingSourceElements.includes(el));
                active.elements[this.details.layoutID].userSettings.sources = updatedWidgetSources;
            }

            let currentWidgetCategories = this.details.userSettings.categories;
            const defaultWidgetCategories = this.getWidgetByImplementationId(this.details.implementation_id)?.settings?.categories?.formJSON?.model?.categories;
           
            const missingCategoryElements = missingArrayElements(defaultWidgetCategories, currentWidgetCategories);
            if(missingCategoryElements.length) {
                updatedWidgetCategories = currentWidgetCategories.filter(el => !missingCategoryElements.includes(el));
                active.elements[this.details.layoutID].userSettings.categories = updatedWidgetCategories;
            }

            if(missingSourceElements.length || missingCategoryElements.length) {
                if(updatedWidgetSources.length)
                    this.sources = updatedWidgetSources;
                if(updatedWidgetCategories.length)
                    this.categories = updatedWidgetCategories;
                // this.updateDashboardById(active);
                this.fetchInitialHeadlines();
            }
        },
        fix() {
            const active = _.cloneDeep(this.activeDashboardInfo);
            const foundLegacySource = this.sources.findIndex(el => el === 100000000); 
            let sourceFix = false;
            let catFix= false;
            if(foundLegacySource > -1) {
                this.sources.splice(foundLegacySource, 1, ...[1,2]);
                active.elements[this.details.layoutID].userSettings.sources = [...this.sources];
                sourceFix = true;
            }
       
            let currentWidgetCategories = this.details.userSettings.categories;
            if(!currentWidgetCategories) {
                currentWidgetCategories = this.getWidgetByImplementationId(this.details.implementation_id)?.categories;
                this.categories = currentWidgetCategories;
                catFix = true;
                active.elements[this.details.layoutID].userSettings.categories = currentWidgetCategories;
            }
            
            if(sourceFix || catFix)
                this.updateDashboardById(active);


        }
    },
    watch: {
        readyForHeadlineProcessing(val) {
            if(val) {
                this.fetchInitialHeadlines();
            }
        },
        bulkHeadlines() {
            this.sortedItems = {};

            this.bulkHeadlines.forEach(headline => {

                let thisDay = usersTime(headline[this.sortProp], true, true, false, {
                    dateFormat: "y-m-d"
                }, false, this.timezone);

                thisDay = thisDay.substring(0,10);

                if(!this.sortedItems[thisDay])
                    this.sortedItems[thisDay] = [];

                this.sortedItems[thisDay].push(headline);
            });


            this.sortedItems = _.cloneDeep(this.sortedItems);

        },
        lastHeadlineUpdate(val) {

            let bulkHeadlines = _.cloneDeep(this.bulkHeadlines);

            val.forEach(headline => {
                if(headline.deleted_at) {
                    const index = bulkHeadlines.findIndex(element => element.id === headline.id);

                    if(index > -1)
                        bulkHeadlines.splice(index,1);

                }else{
                    let props = this.tabs?.find(el => el.id === this.currentSection)?.properties || [];

                    if(this.slug === 'vidcom')
                        props = [2];
                    if(!this.searchTerms &&
                    this.sources.includes(headline.source_id) &&
                    arrayIntersect(this.categories, headline.categories) &&
                    (arrayIntersect(headline.props, props) || this.currentSection === 0)) {

                        //not a great way to handle highlights for updates or fresh news, but working for now
                        clearTimeout(this.highlightTimeOut);
                        this.highlightTimeOut = setTimeout(() => {
                            this.removeHighlight();
                        },1800);

                        const ID = headline.id;
                        const index = bulkHeadlines.findIndex(element => element.id === ID);

                        if(index > -1) {
                            bulkHeadlines.splice(index, 1, headline);
                        }else{
                            bulkHeadlines.push(headline);
                            headline.new = true;
                            this.newHeadline = true;
                            // playSound();
                        }
                    }
                }
            });

            sortDataBy(bulkHeadlines, this.sortProp, "timeDESC");

            this.bulkHeadlines = bulkHeadlines;

            if(this.isVidcom)
                this.focusFirstVideoIfNeeded();

        },
        searchValues(val) {
                
            this.noMoreItems = false;
            this.bulkHeadlines = [];
            if(val) {
                this.searchTerms = val;
                this.loadMore();
                // this.initialFetchDone = false;
            }else{
                this.searchTerms = null;
                this.fetchInitialHeadlines();
                
                // this.initialFetchDone = true;
            }



        },
        headlineSettings: {
            handler(val) {
                if(this.searchTerms)
                    this.$emit("close-search");

                this.bulkHeadlines = [];

                this.categories = val.categories;
                this.sources = val.sources;

                this.fetchInitialHeadlines();
            },
            deep: true
        },
        user: {
            deep: true,
            handler: function () {
                this.bulkHeadlines = _.cloneDeep(this.bulkHeadlines);
            }
        }
        // sourcesArray(val) {
        //     if(val) {
        //         this.categories = [];
        //         this.sources = val;
        //         this.fetchInitialHeadlines();
        //     }

        // },
        // categoriesArray(val) {
        //     if(val) {
        //         this.sources = [];
        //         this.categories = val;
        //         this.fetchInitialHeadlines();
        //     }

        // }
    }
}
