<template>
    <div :class="fontsize"
    :id="'vidlib-' + details.layoutID">
        <div class="breadcrumb" v-html="breadcrumb"></div>
        <div v-if="noVideosFound" class="folder-empty"><font-awesome-icon :icon="['fas', 'exclamation-triangle']"/> No videos found.</div>
        <div v-else-if="!bulkItems.length && resultsReady" class="folder-empty"><font-awesome-icon :icon="['fas', 'exclamation-triangle']"/> This folder is empty.</div>
        <div
        :style="verticalHeight"
        class="video-library-container"
        ref="scrollable"
        :id="'videos-' + details.layoutID">
            <div
            ref="scroller" 
            :id="'videos-inner-' + details.layoutID"
            style="position: relative;"
            class="grid-layout video-sections">
                <content-card
                v-for="item in bulkItems"
                :key="getItemID(item)"
                :item="item"
                @open-folder="folderChange"
                >
                </content-card>
            
                
                <div style="grid-column: span 3">
                    <loading-spinner v-if="!resultsReady" style="height:100px;"></loading-spinner>
                    <div v-if="noMoreItems && !noVideosFound" class="no-more-items"><font-awesome-icon :icon="['fas', 'exclamation-triangle']" /> No more items</div>

                    <span ref="bottom-of-col"></span>
                </div>
                
                

            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '../../main';
import scrollMixin from '../../mixins/scrollMixins';
import LoadingSpinner from '../ui/LoadingSpinner';
import ContentCard from './components/VideoPlayers/ContentCard.vue'
// import VideoQuestion from './components/VideoQuestion';
const dashboards = createNamespacedHelpers('dashboard');
import VideoService from '../../services/video/video.service';
import { arrayIntersect, mergeArraysByProp, sortDataBy } from '../../utility/helper-functions';
import _ from "lodash";


const libraryNS = createNamespacedHelpers('library');

export default {
    props: ["details"],
    mixins: [scrollMixin],
    components: {
        ContentCard,
        LoadingSpinner
    },
    created() {
        eventBus.$on("widget-resize", obj => {
            if (obj.id === this.details.layoutID) {
                // const scrollerHeight = this.$refs.scroller.getBoundingClientRect().height;
                // const cardHeight = this.$refs.card.getBoundingClientRect().height;
                let adj = 192;

                if(this.fullscreenId)
                    adj = 185;


                // if(obj.height < scrollerHeight) {
                    this.verticalHeight = {height: obj.height - adj + 'px'};
                // }else{
                    // console.log("scroll", scrollerHeight);
                    // this.verticalHeight = {height: scrollerHeight - adj + 'px'};
                // }
                // this.verticalHeight = {height: scrollerHeight - cardHeight * 3  + 'px'};
                // console.log(scrollerHeight, obj.height -70);
            }

        });

        const obj = {
            menu: {
                isSearchEnabled: true,
                // isGlossaryEnabled: false,
                // isVideoQuestionEnabled: true,
            }
            
        }
        this.$emit('set-frame', obj);

        this.setupScrollListener();


        if(this.topLevelCategories.length === 1) {
            this.currentCategories = this.topLevelCategories;
        }
        this.itemsToFetch = this.topLevelCategories;
        

        // if(this.readyForVideoProcessing)
            this.fetchInitialItems();

        
    },
    mounted() {
        document.getElementById(('vidlib-' + this.details.layoutID)).addEventListener("click", event =>
        {
            if(event.target.className.indexOf("breadcrumb-clickable") > -1) {
                this.itemsToFetch = [+event.target.getAttribute('data-target')];
                if(this.itemsToFetch < 0) {
                    this.itemsToFetch = this.topLevelCategories;
                }
                this.$emit("close-search");
                this.fetchInitialItems();
            }
                
               
        });
    },
    computed: {
        ...dashboards.mapState(['fullscreenId']),
        ...libraryNS.mapGetters(['readyForVideoProcessing', 'lastVideoUpdate', 'lastVideoDelete']),
        
        fontsize() {
            if(this.details.userSettings)
                return this.details.userSettings.fontsize;
            
            return '';
        },
        searchValues() {
            return this.details.searchTerms;
        },
        noVideosFound() {
            return !this.bulkItems.length && this.searchTerms || this.searchTerms && this.currentCategories === -1;
        }
    },
    data() {
        return {
            verticalHeight: '',
            topLevelCategories: (this.$store.getters['widget/getWidgetByImplementationId'](this.details.implementation_id)).categories, //[38],//[56, 57],//
            currentCategories: -1,
            itemsToFetch: null,

            bulkItems: [],
            itemsPerFetch: 12,
            resultsReady: false,
            searchTerms: null,
            noMoreItems: false,
            serverForceFetchDone: false,

            breadcrumb: '',

            scrollToOptions: {
				el: 'videos-inner-' + this.layoutID,
				container: 'videos-' + this.layoutID,
				duration: 500,
				lazy: false,
				easing: 'linear',
				offset: 0,
				force: true,
				x: false,
				y: true
			},
        }
    },
    methods: {
        async fetchInitialItems() {
            this.resultsReady = false;
            
            const obj = {
                categories: this.itemsToFetch,
            };

            this.bulkItems = await VideoService.fetchInitialItems(obj);

            // console.log("bulkitems", this.bulkItems);

            if(this.bulkItems.length) {
                this.resultsReady = true;
            }
            // in case videos have not been loaded on the cache
            else if(!this.serverForceFetchDone) {
                this.fetchItemsFromServer();
            }else if(this.serverForceFetchDone && !this.bulkItems.length)
                this.resultsReady = true;


            this.serverForceFetchDone = true;
            // console.log("itemsToFetch", this.itemsToFetch);
            if(this.itemsToFetch.length === 1)
                this.currentCategories = _.cloneDeep(this.itemsToFetch);
            else
                this.currentCategories = -1;

            // console.log("curr Cats", this.currentCategories);

            this.buildBreadcrumb();
            
        },
        async loadMore () {

            this.resultsReady = false;
            const limit = this.itemsPerFetch;
            let offset = this.bulkItems.length;
            let from = "";
            let to = ""
            let keywords ="*";
            let searchMode = false;

            if(this.searchTerms) {
                from = this.searchTerms.from;
                to = this.searchTerms.to;
                keywords = this.searchTerms.keywords;
                offset = this.bulkItems.length ? this.bulkItems.length : 0;
                searchMode = true;
            }

            // console.log(this.currentCategories);

            const response = await VideoService.loadMoreItems(this.currentCategories, offset, limit, searchMode, from, to, keywords);

            // console.log("load more", response);

            let bulkItems = mergeArraysByProp(this.bulkItems, response, "id");
            if(response.length)
                sortDataBy(bulkItems, "order_by", "orderbyASC");

            this.bulkItems = bulkItems;

            if(!response.length) {
                this.noMoreItems = true;
            }
            this.resultsReady = true;


        },
        async fetchItemsFromServer() {
            await VideoService.fetchItemsFromServer();
            this.fetchInitialItems();
        },

        onScroll() {
            const bottomOfCol = this.$refs["bottom-of-col"];
			if (bottomOfCol) {
				const marginBottomOfCols = bottomOfCol.getBoundingClientRect().top;
				const innerHeight = this.$refs.scrollable.getBoundingClientRect().height;
				const scrollerHeight = this.$refs.scroller.getBoundingClientRect().height;

                // console.log(marginBottomOfCols - innerHeight);
                // console.log(this.resultsReady);

				if ((marginBottomOfCols - innerHeight) < 270 &&
				this.resultsReady && 
                !this.noMoreItems) {                 
					// console.log("scroll-down-enough");
					this.resultsReady = false;
                    this.loadMore();
				}                
				
				if((scrollerHeight - marginBottomOfCols) > 0) {
					// console.log("scrolled down");
					this.isScrolledDown = true;
				}
                // else{
				// 	this.isScrolledDown = false;
                //     // console.log("scrolled up");
				// 	this.$emit("new-video-viewed");
				// }
            }
        },
        getItemID(item) {
            if(item.video_id)
                return item.video_id;

            return item.id;
        },
        folderChange(id) {
            // console.log("curr folder", id)
            this.$emit("close-search");
            this.$refs.scrollable.scrollTop = 0;
            this.itemsToFetch = [id];
            this.noMoreItems = false;
            this.fetchInitialItems();
        },
        async buildBreadcrumb() {
            if(this.currentCategories === -1) {
                this.breadcrumb = `
                <div class="breadcrumb-root-element">
                    ${this.details.userSettings.widgetname}
                </div>
            `;
                return;
            }
                

            let rootID = -1;
            
            if(this.topLevelCategories.length === 1) {
                rootID = this.topLevelCategories[0];
            }

            // console.log("currcats",this.currentCategories);

            const foundCats = await VideoService.getCatsUpwards(this.currentCategories[0], this.topLevelCategories);

           
            if(this.topLevelCategories.length === 1) {
                foundCats.splice(foundCats.length-1,1);
            }
          

            // console.log("found cats",foundCats);

            if(!foundCats.length) {
                this.breadcrumb = `
                <div class="breadcrumb-root-element">
                    ${this.details.userSettings.widgetname}
                </div>
            `;
            }else{
                this.breadcrumb = `
                    <div class="breadcrumb-root-element">
                        <a href="javascript:void(0);" class="breadcrumb-clickable" data-target="${rootID}">${this.details.userSettings.widgetname}</a>
                `;
            }

            foundCats.reverse().forEach((cat, index) => {
                if(foundCats.length - 1 > index)
                    this.breadcrumb+= ` > <a href="javascript:void(0);" class="breadcrumb-clickable" data-target="${cat.id}">${cat.display_name}</a>`
                else
                    this.breadcrumb+= ` > ${cat.display_name}`;
            })

            this.breadcrumb+= '</div>';

        }
    },
    watch: {
        readyForVideoProcessing(val) {
            if(val) {
                this.fetchInitialItems();
            }
        },
        lastVideoUpdate(val) {

            let bulkItems = _.cloneDeep(this.bulkItems);

            val.forEach(item => {

                if(!this.searchTerms &&
                // this.sources.includes(headline.source_id) &&
                arrayIntersect(this.categories, item.categories)
                // (headline.props.includes(this.currentSection) || this.currentSection === 0)
                ) {

                    const ID = item.id;
                    const index = bulkItems.findIndex(element => element.id === ID);

                    if(index > -1) {
                        bulkItems.splice(index, 1, item);
                    }else{
                        const highestOrderBy = Math.max.apply(Math, bulkItems.map(element => element.order_by));

                        if(val.order_by <= highestOrderBy) {
                            bulkItems.push(item);
                            sortDataBy(bulkItems, "order_by", "orderbyASC");
                        }
                        
                    }
                }
                
            });

            this.bulkItems = bulkItems;

        },
        lastVideoDelete(val) {
            let bulkItems = [...this.bulkItems];
            const index = bulkItems.findIndex(element => element.id === val);

            if(index > -1) {
                bulkItems.splice(index, 1);
            }

            this.bulkItems = bulkItems;
        },
        searchValues(val) {
                
            this.noMoreItems = false;
            if(val) {
                this.searchTerms = val;

                if(this.noVideosFound || !this.bulkItems.length) {
                    this.bulkItems = [];
                    this.resultsReady = true;
                    return;
                }

                this.bulkItems = [];
                this.loadMore();
            }else{
                this.searchTerms = null;
                this.bulkItems = [];
                this.fetchInitialItems();
            }
        },
    }
    // 
    
}
</script>

<style lang="scss" scoped>
    .video-library-container {
        overflow: auto;

       @include scrollbarDark;


    }

    .breadcrumb {
        margin: 5px 0 15px 75px;
        @include SubHead2;
        color: $input-color;

    }

    .folder-empty {
        color: $color-06;
        text-align: center;
        padding: 15px 0;
    }

    .grid-layout {

        display: grid;
        row-gap: 30px;
        

        grid-auto-flow: dense;

    }

    .grid-layout-cell {
        grid-column: 1 / -1;
    }

    .video-sections {

        margin: 15px 5px 20px;

        grid-column: 1 / -1;

        

        
        grid-template-columns: 1fr;
    

        grid-template-rows: auto;

        // &-message {
        //     grid-column: 2;
        //     margin: 15px 0;
        //     text-align: center;
        //     font-size: 16px;
        //     color: $color-06;
        // }

        .content-card-label-details-time {
            background-color: $color-03;
        }
        
    }

    .size-sm .video-sections {
        margin: 15px 75px 30px;
    }

    .size-md .video-sections {
        grid-template-columns: repeat(3, minmax(200px, 1fr));
        margin: 15px 75px 25px 75px;
        grid-gap: 30px;
    }

    .size-md .grid-layout-cell {
        grid-column: auto;
    }

</style>

<style lang="scss">
    .breadcrumb-clickable {
        color: $core-yellow!important;
    }

    .video-library-container {
        overflow: auto;

       @include scrollbarDark;

       & .content-card-label-title {
           font-size: 14px;
       }

    }

    .content-card-text {
    height: 19em;
    margin-top: 1.7em;
    padding: 0 1em;

    @include respond(mobile) {
        height: 21em;
    }

    @include respond(tablet) {
        height: 15em;
    }
    

    @include respond(xl) {
        height: 20em;
        padding: 0 1.5em;
        margin-top: 2em;
    }

    & h3 {
        font-size: 1.3em;
        font-weight: 100;
        letter-spacing: 0.1px;
        margin-bottom: 1.5em;
        color: $color-06;

        @include respond(mobile) {
            font-size: 1.5em;
            letter-spacing: 0.11px;
            margin-bottom: 1.7em;
        }

        @include respond(xl) {
            font-size: 1.5em;
            letter-spacing: 0.11px;
            margin-bottom: 1.7em;
        }
    }

    & p,
    & a {
        font-size: 1.2em;
        letter-spacing: 0.1px;
        padding: 3px 0;
        transition: color 0.3s ease;

        @include respond(mobile) {
            font-size: 1.4em;
           
        }

        @include respond(xl) {
            font-size: 1.4em;
        }

    }

    & a {
        color: $color-15;

        &:hover,
        &:active {
            color: $color-155;
        }
    }

}
</style>