class HttpCancel {
    constructor(source, url) {
        this.source = source;
        this.url = url;
    }

    cancel() {
        this.source.cancel(this.url);
    }

    token() {
        return this.source.token;
    }
}
export default HttpCancel;