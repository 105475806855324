<template>
    <div class="main-container">
        <ul>
            <li>You agree that your product access will begin immediately.</li>
            <li>Trials will expire automatically and not incur any further charges. You can take unlimited trials or upgrade at any time.</li>
            <li>Subscription plans will be charged every month until cancelled.</li>
            <li>You can cancel any product at any time to avoid future charges</li>
        </ul>
        <div style="margin: 25px 0; text-align: center;">
            <base-button
            mode="o-blue fullwidth"
            :link="false"
            @click.native="close"
            >Continue</base-button>
            <a class="external-tc-link" href="https://financialsource.co/terms-conditions/" target="_blank">View full terms & conditions here</a>
        </div>
    </div>
</template>

<script>
import { eventBus } from '../../../../main'
export default {
    methods: {
        close() {
            eventBus.$emit('close-modal');
        }
    }
}
</script>

<style lang="scss" scoped>
    .main-container {
        font-size: 15px;
        padding: 0 15px;
        margin-top: -15px;
    }
    ul {
        margin: 0;
        padding: 0 0 0 15px;
    }
    li:not(:first-child) {
        margin-top: 25px;
        letter-spacing: 0.1px;
    }
    .external-tc-link {
        display: inline-block;
        margin-top: 12px;
        color: $input-color;
    }
</style>