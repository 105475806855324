export default {
    addBulkRecords(_, payload) {
        const filtered = payload.filter(el => el.widget_implementation_id);
        const processingObject = {
            objStore: `livestreams`, 
            data: filtered
            
        };

        window.bootUp.iDB.storeRecords(processingObject).then(() => {});
        
    },

    lastLiveStreamUpdate(context, payload) {
        context.commit("lastLiveStreamUpdate", payload);
    },

    lastLiveStreamDelete(context, payload) {
        context.commit("lastLiveStreamDelete", payload);
    },

    addCurrentLiveID(context, payload) {
        context.commit("addCurrentLiveID", payload);
    },
    removeCurrentLiveID(context, payload) {
        context.commit("removeCurrentLiveID", payload);
    },
    setCurrentLiveIDs(context, payload) {
        context.commit("setCurrentLiveIDs", payload);
    },

    setActiveReminders(context, payload) {
        context.commit("setActiveReminders", payload);
    },
    addActiveReminder(context, payload) {
        context.commit("addActiveReminder", payload);
    },
    removeActiveReminder(context, payload) {
        context.commit("removeActiveReminder", payload);
    },
}