import { talkToServer } from "../utility/helper-functions";
import Swal from 'sweetalert2';
import { createNamespacedHelpers } from "vuex";
const squawk = createNamespacedHelpers('squawk');


export default {
    data() {
        return {
            tokenRetries: 0
        }
    },
    methods: {
        ...squawk.mapActions(["setSquawkAvailable"]),
        setOrGetAudioTokens() {
            let linkAndToken;
            const channels = ['audio_test', 'forex']
    
            const testToken = localStorage.getItem('adt');
            const fxToken = localStorage.getItem('adfx');
    
            if(testToken && fxToken) {
                this.createPlayerHTML(null, testToken, fxToken);
            }else{
                talkToServer('/widgets/squawk/token', "GET", null, null, true).then(response => {
                    // console.log("squawk",response);
                    
                    
                    // response = JSON.parse(response);
                  
                    channels.forEach((channel) => {
                        // const selectedChannel = 
                        response["channels"].forEach((element) => {
                            // console.log(element);
                            if(element.label === channel) 
                                linkAndToken = element.stream[0][0];
                                // console.log('linkAndToken');
                                // console.log(linkAndToken);
                        });
    
                        if(channel === 'audio_test') {
                            // console.log(linkAndToken.replace(/\?token.*$/,''));
                            // linkAndToken = linkAndToken.replace(/^(.*?)token=/,'');
                            window.localStorage.setItem('adt', linkAndToken);
                            
                            
                        }else if(channel === 'forex') {
                            // linkAndToken = linkAndToken.replace(/^(.*?)token=/,'');
                            window.localStorage.setItem('adfx', linkAndToken);
                        }
    
                    });
    
                    this.setOrResetPlayer();
                    
                });
            }
    
        },
        setOrResetPlayer(connection=null) {
            const testToken = localStorage.getItem('adt');
            const fxToken = localStorage.getItem('adfx');
            // console.log(testToken+ ' '+fxToken);
    
            if(testToken && fxToken)
                this.createPlayerHTML(connection, testToken, fxToken);
            else if(this.tokenRetries<2) {
                this.tokenRetries++;
                this.setOrGetAudioTokens();
            }else{

                Swal.fire({
                    title: 'Audio Squawk No Connection',
                    html: 'Please sign in again and restart your squawk.',
                    type: 'warning',
                    confirmButtonColor: '##1d9deb',
                    confirmButtonText: 'OK'
                });
    
                this.tokenRetries=0;
            }
        },
        createPlayerHTML(connection, test, fx) {

            let playerDiv;

            const isPlayerSetup = document.getElementById("squawk-player");

            if(!isPlayerSetup) {
                // console.log("setting up player");
                playerDiv = document.createElement('div');
                playerDiv.setAttribute('id', 'squawk-player');
                document.body.appendChild(playerDiv);
            }

            const dataChannels = `[
                {
                    "name": "Forex",
                    "label": "forex",
                    "lag": 0,
                    "stream": [
                        "${fx}"
                    ]
                },
                {
                    "name": "Test Audio",
                    "label": "audio_test",
                    "lag": false,
                    "stream": [
                        "${test}"
                    ]
                }
            ]`;

            playerDiv.setAttribute("data-channels", dataChannels);

            this.loadSquawkScript().then(() => {
                // console.log(response);

                if(!connection) {

                    window.webrtc = window.WebRtcPlayer({ 
                        selector: '#squawk-player', 
                        initial_state: { single: 'forex' }
                        
                    });
    
                }else{
    
                    playerDiv.setAttribute("data-delivery", connection);
        
                    window.webrtc = window.WebRtcPlayer({ 
                        selector: '#squawk-player', 
                        delivery: connection, 
                        initial_state: { single: 'forex' }
                        
                    });
                }
                
                this.setSquawkAvailable(true);

            });


        },
        loadSquawkScript() {
            return new Promise(function(resolve) {
                let script = document.createElement('script')
                script.async = true;
                script.src = 'https://audio.newsquawk.com/js/player/widget.js'
                document.body.appendChild(script);
                script.onload = () => {
                    resolve({message: "success"});
                }
            });

        }
    }
}