<template>
    <div class="bottom-row">
        <div class="filled-form"
            >
            <label>Your Invoices</label>
            <div class="payments">
                <table style="width: 100%">
                    <tbody>
                    <tr class="payment-unit"
                        :class="selectedInvoices.includes(payment.invoice_id) || payment.paid_status !== 'full'?'highlighted': ''"
                        v-for="payment in paymentHistory" :key="payment.id">
                        <td style="text-align: left; width: 40%">
                            <span class="d-md">{{ formatTitle(payment.invoice_lines) }}</span>
                        </td>
                        <td style="text-align: left; width: 20%">
                            <span class="d-sm">{{ formatDate(payment.created_at) }}</span>
                        </td>
                        <td style="text-align: right; width: 20%;">
                            <span class="d-md"> $ {{ payment.gross / 100 }}</span>
                        </td>
                        <td style="text-align: right; width: 10%;">
                            <VueCustomTooltip :multiline="true" :label="formatPaymentTooltip(payment)"
                                              v-if="payment.next_retry_date">
                                <span class="d-md"> {{ formatPaymentStatus(payment)}}</span>
                            </VueCustomTooltip>
                            <span class="d-md" v-else> {{ formatPaymentStatus(payment)}}</span>

                        </td>
                        <td style="width: 170px; text-align: center;">
                            <span>
                                <base-button v-if="payment.pdf"
                                             mode="blue"
                                             :link="false"
                                             @click.native="getInvoice(payment)"
                                >Get Invoice
                                </base-button>
                            </span>
                        </td>
                        <td style="width: 50px;">
                            <span>
                                <button v-if="(selectedInvoices.includes(payment.invoice_id) || payment.paid_status !== 'full') && payment.paid_status !== 'refunded'"
                                        @click="retryInvoice(payment)" style="right: 50px; cursor: pointer">
                                    <VueCustomTooltip label="Retry"
                                                      position="is-top">
                                        <font-awesome-icon :icon="['fas', 'redo']" style="color: green"/>
                                    </VueCustomTooltip>
                                </button>

                            </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="noValidInvoices">
                <span class="no-item-message">You have no invoices.</span>
            </div>
        </div>
    </div>
</template>

<script>
import PaymentService from "@/services/payments/payment.service"
import Swal from "sweetalert2";
import PaymentInvoiceModal from "./PaymentInvoiceModal";
import PaymentCardModal from "./PaymentCardModal";
import {createNamespacedHelpers} from "vuex";
import ProductPrices from "../../../mixins/product.prices";
import {toSystemDate} from "../../../utility/datetime";

const payments = createNamespacedHelpers("payments");
export default {
    name: "PaymentHistory",
    mixins: [ProductPrices],
    props: {
        selectedInvoices: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        ...payments.mapState(["paymentHistory"]),
        noValidInvoices: function () {
            return this.paymentHistory.length === 0;
        }
    },
    methods: {
        ...payments.mapActions(["loadPaymentHistory"]),
        formatDate: function (date) {
            return this.toSystemDate(date);
        },
        formatTitle: function (lines) {
            return lines.filter(line => line.line_gross > 0).map(line => line.description).join(" + ");
        },
        getInvoice: function (invoice) {
            PaymentService.getInvoice(invoice.pdf).then((result) => {
                this.showInvoiceModal(result);
            })
        },
        showInvoiceModal: function (result) {
            let pdf = result?.data?.data?.pdf;
            if (pdf) {
                this.showModal(PaymentInvoiceModal, {content: pdf});
            } else {
                Swal.fire({
                    title: 'Warning!',
                    type: 'info',
                    text: 'This invoice could not be generated. Try again later!',
                    showCancelButton: true,
                    cancelButtonColor: '#1d9deb'
                });
            }
        },
        retryInvoice: function (invoice) {
            Swal.fire({
                title: 'Are you sure?',
                type: 'warning',
                text: "When you click below, we'll charge your credit card $" + invoice.gross / 100 + " about the selected invoice . ",
                showCancelButton: true,
                cancelButtonColor: '#1d9deb',
                confirmButtonColor: '#ff5656',
                confirmButtonText: 'Pay Invoice!'
            }).then((result) => {
                if (result.value) {
                    this.showModal(PaymentCardModal, {
                        showMakePayment: true, handlePayment: () => {
                            let id = invoice.id || invoice.invoice_id;
                            PaymentService.retryInvoiceAndLoadProducts(id).then(() => {
                                // if (result?.data?.data) {
                                //     Swal.fire({
                                //         title: 'Success!',
                                //         type: 'info',
                                //         text: 'The payment was successful!',
                                //         showCancelButton: true,
                                //         cancelButtonColor: '#1d9deb'
                                //     });
                                // }
                            });
                        }
                    });
                }
            });

        },
        formatPaymentStatus: function (payment) {
            switch (payment.paid_status) {
                case "full": return "Paid";
                case "refunded": return "Refunded";
                default : return "Fail";
            }
        },
        formatPaymentTooltip: function (payment) {
            if(this.activePlan.price > 0 && this.activePlan.price !== "0.00") {
                return 'Next try on: ' + toSystemDate(payment.next_retry_date);
            } else {
                return "Plan has expired!";
            }

        }
    },
    created() {
        this.loadPaymentHistory();
    }
}
</script>

<style lang="scss" scoped>
.bottom-row {
  margin-top: 45px;
}

.payments {
  padding: 5px 0;
}

.payment-unit {
  margin: 5px 0;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid rgba($color-20, 0.1);
  align-items: center;

  @include respond(sm) {
    justify-content: space-between;
  }
}

.highlighted {
  background-color: #c6751a;
}
</style>
