import { sortDataBy } from "../../utility/helper-functions";

export default {
    fetchInitialEcoCalendarEvents(fromTo) {

        const queryObject = {
            name: "initial-ecocalendar-fetch",
            objStore: "ecocalendar",
            fromTo: fromTo,
        }

        // console.log(queryObject);

        return window.bootUp.iDB.getRecords(queryObject).then(response => {
            sortDataBy(response, "timestamp", "timeASC");
            return response;
        });
    },
    loadMore(from, to, ids) {
          
            const queryObject = {
                name: "ecocalendar-load-more",
                objStore: "ecocalendar",
                fromTo: {
                    from: from,
                    to: to,
                },
                ids: ids
            }
            return window.bootUp.iDB.getRecordsFromServer(queryObject).then(response => {
                // console.log(response);
                return response;
            });
     
        
    },
    getEventsByIds(ids) {
        return window.bootUp.iDB.getCalEventsByIds(ids);
    },
    getEventsMissingEventsForToday() {
        return window.bootUp.iDB.getEventsMissingEventsForToday();
    }
    
}