<template>
    <div class="e-client e-subpage"
    :style="elementHeight"
    >
        <external-login
        v-if="loginOn"
        @register-login-successful="registerLoginSuccessful"
        @back-to-home="loginOn = false"
        @password-reset="openPasswordReset"
        ></external-login>
        <external-register
        v-if="requestOn"
        @back-to-home="requestOn = false"
        ></external-register>
        <external-passwordreset
        v-if="passwordResetOn"
        @back-to-home="passwordResetOn = false"
        ></external-passwordreset>
        <external-newpassword
        v-if="newPasswordOn"
        @back-to-home="newPasswordClose"
        ></external-newpassword>
        <div v-if="entityLocked">
            <entity-locked></entity-locked>
        </div>
        <div v-else>
            <div 
            class="dark-section">
                <div class="e-client-container">

                    <external-client-nav @open-login="loginOn = true"></external-client-nav>
                    <div 
                    class="e-client-hero">
                        <div class="e-client-hero-prompt">
                            <h1>{{ page.title }}</h1>
                            <p>{{ page.subTitle }}</p>
                            <base-button
                            mode="o-blue o-border-radius-0"
                            :link="false"
                            style="margin-top: 10px;"
                            @click.native="requestOn = true;"
                            >Get Access
                            </base-button>
                        </div>
                        <div 
                        :class="{'padded-img': page.isParentPage}"
                        class="e-client-hero-video">
                            <img style="max-width: 100%; max-height: 100%;" :src="getFrontFaceAsset(page.heroIMG)" :alt="page.heroIMG">
                        </div>
                    </div>
                </div>
            </div>

            <div class="light-section">

                <div class="e-client-container">
                    <div class="e-client-testimonials">
                    </div>
                </div>
            </div>

            <div class="medium-dark-section">

                <div class="e-client-container">
                    <div class="e-client-included">
                        <div class="e-client-section-header">
                            <div class="e-client-section-header-title"><span></span><h2>How it Works</h2></div>
                            <div class="e-client-section-header-rating uppercase-fs" v-html="page.howItWorksDesc">
                            </div>
                        </div>
                        <div class="e-client-section-body">
                            <div class="e-client-benefit-grid">
                            <div 
                            v-for="(card, i) in page.howItWorksCards"
                            :key="card.id"
                            :class="{'md-centered': i === 2}"
                            class="e-client-benefit">
                                    <div
                                    class="e-client-benefit-card"
                                    >
                                        <div class="e-client-benefit-text">{{ card.text }}</div>
                                        
                                    </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
            v-if="page.isParentPage" 
            class="dark-section"
            >

                <div class="e-client-container">
                    <div class="e-client-included">
                        <div class="e-client-section-header">
                            <div class="e-client-section-header-title"><span></span><h2>Core Indicators</h2></div>
                            <div class="e-client-section-header-rating uppercase-fs" v-html="page.includedDesc">
                            </div>
                        </div>
                        <div class="e-client-section-body">
                            <div class="e-client-included-grid">
                            <div 
                            v-for="included in page.includedWidgets"
                            :key="included.id"
                            class="e-client-included-card"
                            :class="{'md-centered': included.centeredMD}"
                            >
                                    <div class="e-client-included-icon responsive-16-9-frame"><img :src="getFrontFaceAsset(included.img)" alt="" /></div>
                                    <div class="e-client-included-title">{{included.title}}</div>
                                    <div class="e-client-included-text">{{ included.text }}</div>
                                    <div class="e-client-included-text-bold">{{ included.textBold }}</div>
                                    <div class="e-client-included-button">
                                        <base-button
                                        mode="o-tranparent-grey o-blue-border o-white-text o-border-radius-0 o-auto-height"
                                        :link="true"
                                        target="_blank"
                                        :to="included.route"
                                        >Learn more
                                        </base-button>
                                    </div>
                                
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div 
            v-else
            class="dark-section">

                <div class="e-client-container">
                    <div class="e-client-whoisit">
                        <div class="e-client-section-header">
                            <div class="e-client-section-header-title"><span></span><h2>Who it's for</h2></div>
                            <div class="e-client-section-header-rating uppercase-fs" v-html="page.whoItsFor">
                            </div>
                        </div>
                        <div class="e-client-included-video">
                            <iframe
                        class="terminal-embed-video" :src="'https://player.vimeo.com/video/' + page.whoItsForVideo"
                        frameborder="0" 
                        allow="accelerometer; 
                        autoplay; 
                        encrypted-media; 
                        gyroscope; 
                        picture-in-picture; 
                        fullscreen" 
                        style="position:absolute;top:0;left:0;width:100%;height:100%; border-radius: 40px;"
                        allowfullscreen="" 
                        data-ready="true">
                        </iframe>
                        </div>
                    </div>
                </div>
            </div>

            <div
            :style="backgroundIMG"
            class="light-section start-now">
                <div class="e-client-container">
                    <div 
                    :class="{'large-top-padding': !page.isParentPage}"
                    class="e-client-start"
                    >
                        <h2 class="uppercase-fs">Unlock our analyst desk<br/> today</h2>
                        <base-button
                        mode="o-blue o-border-radius-0"
                        :link="false"
                        style="margin-top: 30px;"
                        @click.native="requestOn = true;"
                        >Get Access
                        </base-button>
                    </div>
                </div>
            </div>

            <div 
            class="light-section ad-footer">
                <div class="e-client-container">
                    <div class="e-client-footer">
                        <div v-if="aa" style="color: white; line-height: 14px; width: 200px; margin: 0 auto;">

                            <div style="font-size: 6px; font-weight: 300; letter-spacing: 1.5px; margin-left: 0%; text-align: left;">THE</div>
                            <div style="font-size: 16px;">AA Financial Research</div>
                            <div style="font-size: 6px; font-weight: 300; letter-spacing: 1.5px; margin: 0 auto; text-align: center;">ANALYST DESK</div>
                        </div>
                        <img v-else class="site-logo" :src="siteLogo" alt="Site logo" srcset="">
                        <p>Our products and commentary provides general advice that do not take into account your personal objectives, financial situation or needs. The content of this website must not be construed as personal advice.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { frontFacingAssets, isExternalClientMode } from '../../../utility/helper-functions';
import externalPageContentMixin from '../../../mixins/externalPageContentMixin';
import ExternalLogin from './ExternalLogin.vue';
import ExternalRegister from './ExternalRegister.vue';
import ExternalNewpassword from './ExternalNewpassword.vue';
import ExternalPasswordreset from './ExternalPasswordreset.vue';
import ExternalClientNav from './ExternalClientNav.vue';
import EntityLocked from './EntityLocked.vue'
import { createNamespacedHelpers } from 'vuex';

const general = createNamespacedHelpers("general");

export default {
    components: {
        EntityLocked,
        ExternalClientNav,
        ExternalLogin,
        ExternalNewpassword,
        ExternalPasswordreset,
        ExternalRegister
    },
    mixins: [externalPageContentMixin],
    created() {
        if(!isExternalClientMode())
            this.$router.push("/login");

        this.pathname = this.$router.currentRoute.path.replace("/", "");
    },
    mounted() {
        setTimeout(() => window.scrollTo(0,0), 1);
    },
    computed: {
        ...general.mapGetters(["entityQnaOn", "entityLocked"]),
        elementHeight() {
            if(this.loginOn || this.passwordResetOn || this.newPasswordOn)
                return 'height: 100vh;';
            else if(this.requestOn)
                return 'height: 1100px;';

            return 'height: auto;';
        },
        siteLogo() {
            return frontFacingAssets() + window.bootUp.entity.name + '.png';
        },  
        aa() {
            return window.bootUp.entity.name.indexOf('aafinancialr') > -1;
        },
        backgroundIMG() {
            return "background: url('" + frontFacingAssets() + "Footer CTA BG.png') no-repeat center center; ";
        },
        videoThumbnail() {
            return frontFacingAssets() + 'Hero Video Thumbnail.png';
        },
        page() {
            return this.externalPageContent[this.pathname];
        }
    },
    data() {
        return {
            pathname: '',
            loginOn: false,
            requestOn: false,
            newPasswordOn: false,
            passwordResetOn: false,
        }
    },
    methods: {
        getFrontFaceAsset(img) {
            return frontFacingAssets() + img;
        },
        registerLoginSuccessful(val) {
            this.$emit("register-login-successful", val);
        },
        openPasswordReset() {
            this.loginOn = false;
            this.passwordResetOn = true;
        }
    },
}
</script>