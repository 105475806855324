<template>
    <base-modal title="Shop" :footer="false" mode="wide">
        <div ref="subscription" class="shop-comp">
            <tabs :cache-lifetime="0" :options="{ defaultTabHash: activeTab, useUrlFragment: false}" ref="tabs"
                  @changed="updateActiveTab">
                <tab name="Plans" id="currentPlans" :suffix="newPlansSuffix">
                    <div v-if="currentTab === '#currentPlans'">
                        <div>
                            <div class="modal-info-box">
                                <span class="modal-info-box-icon">
                                    <font-awesome-icon :icon="['fas', 'info-circle']" size="2x"/>
                                </span>
                                <span class="modal-info-box-text">
                                    <p>
                                        Subscribing to our premium plan provides the best value access to all our tools,
                                        indicators and widgets. <br/>You can trial a plan as many times as you like before
                                        upgrading.
                                    </p>
                                </span>
                            </div>
                            <div id="compare-link"><a href="javascript:void(0);" @click="scrollToPlans">Compare plans</a></div>
                            <change-plan style="width: 100%"></change-plan>
                        </div>

                    </div>
                </tab>
                <tab name="Promos" id="currentWidgets" :suffix="newPromosSuffix">
                    <view-products v-if="currentTab === '#currentWidgets'"></view-products>
                </tab>
                <tab name="Addons" id="availableWidgets" :suffix="newAddonsSuffix">
                    <addon-shop v-if="currentTab === '#availableWidgets'"></addon-shop>
                </tab>
            </tabs>
        </div>
    </base-modal>
</template>

<script>
import ViewProducts from "./ViewProducts";
import AddonShop from "./AddonShop";
import EventBus from "../../../store/bus";
import ChangePlan from "../forms/ChangePlan";
import _ from 'lodash';
import {createNamespacedHelpers} from "vuex";
import { scroller } from 'vue-scrollto/src/scrollTo';

const payments = createNamespacedHelpers('payments');
export default {
    name: "ShopModal",
    components: {ChangePlan, AddonShop, ViewProducts},
    props: {
        activeTab: {
            type: String,
            default: 'currentPlans'
        }
    },
    data: function () {
        return {
            currentTab: _.clone(this.activeTab)
        }
    },
    computed: {
        ...payments.mapGetters(['newAddons', 'newPromos', 'newPlans']),
        newAddonsSuffix: function () {
            return this.newAddons.length > 0 ? "<span class='badge'>" + this.newAddons.length + "</span>" : "";
        },
        newPlansSuffix: function () {
            return this.newPlans.length > 0 ? "<span class='badge'>" + this.newPlans.length + "</span>" : "";
        },
        newPromosSuffix: function () {
            return this.newPromos.length > 0 ? "<span class='badge'>" + this.newPromos.length + "</span>" : "";
        }
    },
    methods: {
        updateActiveTab: function (currentTab) {
            this.currentTab = currentTab.tab.hash;
        },
        scrollToPlans() {
            
            const plansTable = document.getElementById('compare-plan-step');
            
            const position = plansTable.offsetTop;

            const firstScrollTo = scroller();

            firstScrollTo('#currentPlans', 500, {
                container: '#plan-scroll-wrapper',
                lazy: false,
                easing: 'linear',
                offset: position - 250,
                force: true,
                x: false,
                y: true
            });
        }
    },
    created() {
        let context = this;
        EventBus.$on("scroll-to-top", () => {
            context.$refs?.['subscription']?.scrollIntoView()
        });
    },
    mounted() {
        this.$nextTick(() => {
            const wrapper = document.querySelector('.tabs-component-panels');
            wrapper.setAttribute('id', 'plan-scroll-wrapper');
        });
    }
}
</script>

<style lang="scss">
dialog {
    color: white;
}

.shop-comp {
    margin: 31px 15px 15px 15px;
}

.tabs-component-tabs {
    border: solid 1px #ddd;
    border-radius: 6px;
    margin: 0;
    padding: 0;
}

@include respond(lg) {
    .tabs-component-tabs {
        border: 0;
        align-items: stretch;
        display: flex;
        justify-content: flex-start;
        margin-bottom: -1px;
    }
}

.tabs-component-tab {
    color: #999;
    margin-right: 0;
    list-style: none;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
}

.tabs-component-tab:hover {
    color: #666;
}

.tabs-component-tab.is-active {
    color: #cdcdcd;
}

.tabs-component-tab.is-disabled * {
    color: #cdcdcd;
    cursor: not-allowed !important;
}

@include respond(lg) {
    // .tabs-component-tab {
    //     border: none;
    //     margin-right: .5em;
    //     // transform: translateY(2px);
    // }

    .tabs-component-tab.is-active {
        // border: solid 1px #ddd;
        
        border-bottom: solid 1px #253138;
        border-top: solid 1px $color-twitter;
        border-left: 1px solid $color-069;
        border-right: 1px solid $color-069;
        background-color: rgba($color-01, 0.15);
        
        z-index: 2;
        transform: translateY(0);

        .tabs-component-tab-a {
            color: $color-20;
            
        }
    }
}

.tabs-component-tab-a {
    align-items: center;
    color: $color-twitter;
    display: flex;
    padding: 14px 15px;
    text-decoration: none;
    font-size: 16px;
    font-weight: 100;
    position: relative;
    
}

.tabs-component-panels {
    padding: 10px;
}

@include respond(lg) {
    .tabs-component-panels {
        // border: solid 1px #ddd;
        // border-radius: 0 6px 6px 6px;
        // box-shadow: 0 0 10px rgba(0, 0, 0, .05);
        // padding: 10px;
        border: 1px solid $color-069;
        padding: 15px;
        background-color: rgba($color-01, 0.15);
        height: 455px;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
                width: 5px;
        }
        &::-webkit-scrollbar-track {
            background: rgba($color-01, 0.4);

        }
        &::-webkit-scrollbar-thumb {
            background: rgba($color-03, 1);
            border-radius: 10px;
        }
        &::-webkit-scrollbar-corner {
            display: none;
        }
    }
}

.backdrop {
    height: 100% !important;
}

.shop-comp .change-plan {
    padding: 0;
    margin: 0;
}
</style>

<style lang="scss" scoped>
    #compare-link {
        margin-bottom: 20px;
        text-align: center;

        & a {
            color: $color-twitter;
        }
    }
</style>
