<template>
    <div>
        <component 
        :is="componentInModal"
        @card-modal-navigate-to="navigate"
        ></component>
    </div>
</template>

<script>
import PlanMiniOverview from './PlanMiniOverview.vue';
import TermsConditions from '../onboard/components/TermsConditions.vue';
import UpdateCard from '../forms/UpdateCard.vue';

export default {
    props: ["panel"],
    components: {
        PlanMiniOverview,
        TermsConditions,
        UpdateCard
    },
    created() {
        this.componentInModal = this.panel.view;
    },
    data() {
        return {
            componentInModal: null
        }
    },
    methods: {
        navigate(obj) {
            this.componentInModal = obj.view;
        }
    }
}
</script>